import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  EventosCRMType,
  UpdateEventosCRMType,
  bodegaType,
  marcaType,
  zonaType,
} from "../../types";


export const EventosPage: React.FC<{}> = () => {
  const listadoMarcas: marcaType[] = [];
  const listadoZona: zonaType[] = [];
  const [open, setOpen] = React.useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const [zona, setZona] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);
  const listadoBodegas: bodegaType[] = [];
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataZona, setDataZona] = useState<zonaType[]>(listadoZona);
  const [marca, setMarca] = React.useState(1);
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const [dataBodega, setDataBodega] = useState<bodegaType[]>(listadoBodegas);

  useEffect(() => {
    getListado();
    getMarcas();
    getZonas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);


  function setParametro(
    eveId: number,
    eveDescripcion: string,
    idMarca: number,
    eveActivo: number,
    idZona: number,
    idBodega: number,
    eveInicio: string,
    eveFin: string,
    eveZona: string,
  ) {
    console.log("tabla a guardar >> ");
    if (eveId === 0) {
      const nuevaTabla: EventosCRMType = {
        eveDescripcion: eveDescripcion,
        idMarca: idMarca,
        eveActivo: eveActivo,
        idZona: idZona,
        idBodega: idBodega,
        eveInicio: eveInicio,
        eveFin: eveFin,
        eveZona: eveZona,
      };
      console.log("datos a guardar >> ", JSON.stringify(nuevaTabla, null, 4));
      postGuardarRegistro(nuevaTabla);
    } else {
      const nuevaTabla: UpdateEventosCRMType = {
        eveId: eveId,
        eveDescripcion: eveDescripcion,
        idMarca: idMarca,
        eveActivo: eveActivo,
        idZona: idZona,
        idBodega: idBodega,
        eveInicio: eveInicio,
        eveFin: eveFin,
        eveZona: eveZona,
      };
      console.log(
        "datos a actualizar >> ",
        JSON.stringify(nuevaTabla, null, 4)
      );
      postUpdateRegistro(nuevaTabla);
    }
  }

  async function postGuardarRegistro(registroGuardar: EventosCRMType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          getListado();
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postUpdateRegistro(registroGuardar: UpdateEventosCRMType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          getListado()
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const columns: GridColDef[] = [
    // { field: "eveId", headerName: "ID", editable: false, width: 125 },
    {
      headerClassName: "theme-header",
      field: "eveDescripcion",
      headerName: "Descripción",
      editable: false,
      width: 225,
    },
    {
      headerClassName: "theme-header",
      field: "eveZona",
      headerName: "Zona - Agencia",
      editable: false,
      width: 175,
    },
    {
      headerClassName: "theme-header",
      field: "idMarca",
      headerName: "Marca",
      editable: false,
      width: 175,
      valueGetter: ({ row }) => {
        return row.idMarca === 1 ? "Chevrolet" : row.idMarca === 2 ? "GAC" : "Volkswagen";
      },
    },
    {
      headerClassName: "theme-header",
      field: "eve_activo",
      headerName: "Activo",
      editable: false,
      width: 125,
      valueGetter: ({ row }) => {
        return row.eveActivo === true ? "Si" : "No";
      },
    },
    {
      headerClassName: "theme-header",
      field: "eveInicio",
      headerName: "Fecha inicio",
      editable: false,
      width: 150,
      valueGetter: ({ row }) => {
        return String(row.eveInicio).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header",
      field: "eveFin",
      headerName: "Fecha fin",
      editable: false,
      width: 150,
      valueGetter: ({ row }) => {
        return String(row.eveFin).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Editar",
      sortable: false,
      width: 125,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => {
              getRegistro(Number(params.row.eveId)); setCheked({
                ...cheked,
                toda_zona: Number(params.row.idBodega) === 0 ? true : false,
              }); setAgencia(Number(params.row.idBodega)); setMarca(Number(params.row.idMarca)); setZona(Number(params.row.idZona)); getBodegas(Number(params.row.idZona), Number(params.row.idBodega));
            }}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
  ];



  const [cheked, setCheked] = React.useState({
    activo: true,
    toda_zona: false,
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };

  const handleChangeZona = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZona(Number(event.target.value));
    getBodegas(Number(event.target.value), 0);
  };

  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
  };

  const handleDateInicio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      eveInicio: event.target.value,
    });
  };

  const handleDateFin = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      eveFin: event.target.value,
    });
  };

  const [data, setData] = React.useState<UpdateEventosCRMType>({
    eveId: 0,
    eveDescripcion: "",
    idMarca: 0,
    eveActivo: 0,
    idZona: 0,
    idBodega: 0,
    eveInicio: "",
    eveFin: "",
    eveZona: ""
  });

  const dataEvento = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  async function getBodegas(idZona: number, idBodega: number) {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega/zona/" +
          Number(idZona)
        )
        .then((response) => {
          setDataBodega(response.data);
          setAgencia(idBodega);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getZonas() {
    try {
      await axios
        .get<zonaType[]>("https://apisatelite.azurewebsites.net/dimzona")
        .then((response) => {
          setDataZona(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataZona, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<UpdateEventosCRMType[]>([]);

  async function getListado() {
    try {
      await axios
        .get<UpdateEventosCRMType[]>(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm"
        )
        .then((response) => {
          setListaDatos(response.data);
          setMostrar(true);
          setOpen(false);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRegistro(id: number) {
    try {
      await axios
        .get<UpdateEventosCRMType>(
          "https://apisatelite.azurewebsites.net/sateliteeventoscrm/" + String(id)
        )
        .then((response) => {
          // setData(response.data);
          console.log("mi fecha", response.data.eveInicio.substring(0, 10))
          setData({
            ...data,
            eveId: response.data.eveId,
            eveDescripcion: response.data.eveDescripcion,
            idMarca: response.data.idMarca,
            eveActivo: response.data.eveActivo,
            idZona: response.data.idZona,
            idBodega: response.data.idBodega,
            eveInicio: response.data.eveInicio.substring(0, 10),
            eveFin: response.data.eveFin.substring(0, 10),
            eveZona: response.data.eveZona
          });
          setMarca(response.data.idMarca);
          setOpen(true);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function reiniciarRegistro() {
    const nuevo: UpdateEventosCRMType = {
      eveId: 0,
      eveDescripcion: "",
      idMarca: 0,
      eveActivo: 1,
      idZona: 0,
      idBodega: 0,
      eveInicio: "",
      eveFin: "",
      eveZona: "",
    };
    setMarca(0);
    setData(nuevo);
    setOpen(true);
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Eventos - Gestión Externa
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Grid flexDirection="row">
                <Box sx={{ bgcolor: "background.paper" }}>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1.5,
                      mb: 1.5,
                      marginLeft: "80%",
                      width: "12%",
                      backgroundColor: "rgba(21,57,105,0.75)"
                    }}
                    onClick={function (event) {
                      reiniciarRegistro()
                    }}
                  >
                    Nuevo
                  </Button>
                  <Box
                    sx={{
                      "& .theme-header": {
                        backgroundColor: "rgba(21,57,105,0.75)",
                        color: "rgba(255,255,255,1)",
                      },
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.eveId}
                      sx={{ height: 600 }}
                      editMode="row"
                      rows={listaDatos}
                      columns={columns}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                      }}
                      pageSizeOptions={[10, 20, 30]}
                      slotProps={{
                        pagination: {
                          labelRowsPerPage: "Registros por página:",
                        },
                      }}
                      pagination
                      localeText={{
                        noRowsLabel: "No se ha encontrado datos.",
                        noResultsOverlayLabel:
                          "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                      }}
                    />
                  </Box>
                  <Modal
                    open={open}
                    onClose={(e) => { setOpen(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleModal}>
                      <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2, mb: 2, textAlign: "center" }}
                      >
                        Detalle del Evento
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Evento:
                        </Typography>
                        <TextField
                          name="eveDescripcion"
                          value={data.eveDescripcion}
                          size="small"
                          margin="normal"
                          onChange={dataEvento}
                          sx={{ mt: 1, mb: 1, width: "60%" }}
                        ></TextField>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Marca:
                        </Typography>
                        <TextField
                          id="outlined-select-currency"
                          select
                          size="small"
                          margin="normal"
                          value={marca}
                          onChange={handleChangeMarca}
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            width: "60%",
                          }}
                        >
                          {dataMarca.map((option) => (
                            <MenuItem key={option.marId} value={option.marId}>
                              {option.marNombre}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Zona:
                        </Typography>
                        <TextField
                          id="outlined-select-currency"
                          select
                          value={zona}
                          onChange={handleChangeZona}
                          size="small"
                          margin="normal"
                          sx={{ mt: 0.75, mb: 0.75, width: "25%" }}
                        >
                          {dataZona.map((option) => (
                            <MenuItem key={option.zonId} value={option.zonId}>
                              {option.zonNombre}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography
                          sx={{ mt: 0.75, mb: 0.75, marginLeft: "2%" }}
                          style={{ display: "inline-block" }}
                        >
                          Toda la zona:
                        </Typography>
                        <Switch
                          checked={cheked.toda_zona}
                          onChange={handleCheked}
                          name="toda_zona"
                          sx={{ mt: 0.75, mb: 0.75, marginLeft: "0%" }}
                        />
                        {cheked.toda_zona ? (
                          <></>
                        ) : (
                          <>
                            <Typography
                              sx={{
                                mt: 1,
                                mb: 1,
                                width: "10%",
                                marginLeft: "10%",
                              }}
                              style={{ display: "inline-block" }}
                            >
                              Agencia:
                            </Typography>
                            <TextField
                              id="outlined-select-currency"
                              select
                              value={agencia}
                              //helperText="Please select your currency"
                              onChange={handleChangeAgencia}
                              size="small"
                              margin="normal"
                              sx={{ mt: 0.75, mb: 0.75, marginLeft: "10%", width: "60%" }}
                            >
                              {dataBodega.map((option) => (
                                <MenuItem key={option.bodId} value={option.bodId}>
                                  {option.agencia}
                                </MenuItem>
                              ))}
                            </TextField>
                          </>
                        )}
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Inicio:
                        </Typography>
                        <TextField
                          size="small"
                          type="date"
                          margin="normal"
                          value={data.eveInicio}
                          onChange={handleDateInicio}
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            width: "60%",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Fin:
                        </Typography>
                        <TextField
                          size="small"
                          margin="normal"
                          type="date"
                          value={data.eveFin}
                          onChange={handleDateFin}
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            width: "60%",
                          }}
                        >
                        </TextField>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          Activo:
                        </Typography>
                        <Switch
                          checked={cheked.activo}
                          onChange={handleCheked}
                          name="activo"
                          sx={{
                            mt: 0.5,
                            mb: 0.5,
                            marginLeft: "2%",
                            marginRight: "2%",
                          }}
                        />
                        <Typography
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "20%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          <strong>{cheked.activo === true ? "Activo" : "Inactivo"} </strong>
                        </Typography>
                      </Box>

                      <Button
                        variant="contained"
                        sx={{
                          mt: 1.5,
                          mb: 1.5,
                          marginLeft: "40%",
                          width: "20%",
                        }}
                        onClick={function (event) {
                          setParametro(data.eveId, data.eveDescripcion, marca, cheked.activo === true ? 1 : 0, zona, (cheked.toda_zona ? 0 : agencia), data.eveInicio, data.eveFin, (cheked.toda_zona ? String(dataZona.find(option => option.zonId === zona)?.zonNombre) : String(dataBodega.find(option => option.bodId === agencia)?.agencia)));
                        }}
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Modal>

                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
