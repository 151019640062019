import React, { useState, MouseEvent, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import logoSatelite from "../assets/logo_satelite.png";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import { SateliteUserProgramaType, VSateliteUsuarioType } from "../types";

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: "20px",
  borderRadius: 0,
  // "&:hover": {
  //   backgroundColor: "rgba(128,128,128,0.25)",
  // },
  "&:hover::before": {
    content: '""',
    position: "absolute",
    left: "10%",
    right: "10%",
    bottom: 0,
    height: "3px",
    backgroundColor: "white",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "18px",
  borderRadius: 0,
  padding: "8px 16px",
  transition: "color 0.3s ease-in-out, background-color 0.3s ease-in-out",
  "&.styled-menu-item": {
    borderBottom: "2px solid transparent",
    marginBottom: "4px", // Margin bottom para separación
    width: "100%",
    textAlign: "center", // Centrar texto
  },
  "&:hover": {
    color: "rgba(21,57,105,0.75)", // Cambiar el color de texto en hover
    backgroundColor: "rgba(192, 192, 192, 0.5)", // Color de fondo gris en hover
  },
  // "&:hover::before": {
  //   content: '""',
  //   position: "absolute",
  //   left: "15%",
  //   right: "15%",
  //   bottom: 0,
  //   height: "2px",
  //   backgroundColor: "rgba(21,57,105,0.75)",
  // },
}));

const Navbar: React.FC = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      getUsuario(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  const [anchorElVentas, setAnchorElVentas] = useState<null | HTMLElement>(
    null
  );
  const [anchorElFI, setAnchorElFI] = useState<null | HTMLElement>(null);
  const [anchorElUafe, setAnchorElUafe] = useState<null | HTMLElement>(null);
  const [anchorElAdmin, setAnchorElAdmin] = useState<null | HTMLElement>(null);

  const [subMenuAnchorElTrafico, setSubMenuAnchorElTrafico] =
    useState<null | HTMLElement>(null);
  const [subMenuAnchorElRentabilidad, setSubMenuAnchorElRentabilidad] =
    useState<null | HTMLElement>(null);
  const [subMenuAnchorElFISeguros, setSubMenuAnchorElFISeguros] =
    useState<null | HTMLElement>(null);
  const [subMenuAnchorElCrearTrafico, setSubMenuAnchorElCrearTrafico] =
      useState<null | HTMLElement>(null);


  const handleClick =
    (setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>) =>
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

  const handleClose =
    (setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>) =>
    () => {
      setAnchorEl(null);
    };

  const handleSubMenuClickTrafico = (event: MouseEvent<HTMLElement>) => {
    setSubMenuAnchorElTrafico(event.currentTarget);
  };

  const handleSubMenuCloseTrafico = () => {
    setSubMenuAnchorElTrafico(null);
  };

  const handleSubMenuClickRentabilidad = (event: MouseEvent<HTMLElement>) => {
    setSubMenuAnchorElRentabilidad(event.currentTarget);
  };

  const handleSubMenuCloseRentabilidad = () => {
    setSubMenuAnchorElRentabilidad(null);
  };

  const handleSubMenuClickCrearTrafico = (event: MouseEvent<HTMLElement>) => {
    setSubMenuAnchorElCrearTrafico(event.currentTarget);
  };

  const handleSubMenuCloseCrearTrafico = () => {
    setSubMenuAnchorElCrearTrafico(null);
  };

  const handleSubMenuClickSeguros = (event: MouseEvent<HTMLElement>) => {
    setSubMenuAnchorElFISeguros(event.currentTarget);
  };

  const handleSubMenuCloseSeguros = () => {
    setSubMenuAnchorElFISeguros(null);
  };

  const [usuario, setUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });

  const [sateliteUsuarioProgramas, setSateliteUsuarioProgramas] = useState<
    SateliteUserProgramaType[]
  >([]);

  function buscarId(_id: number) {
    return sateliteUsuarioProgramas.find((registro) => registro.prgId === _id);
  }

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
            correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setUsuario(response.data);
          getUsuarioProgramas(response.data.id);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(usuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuarioProgramas(_id: number) {
    try {
      await axios
        .get<SateliteUserProgramaType[]>(
          "https://apisatelite.azurewebsites.net/sateliteuserprograma/consultar/" +
            String(_id)
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuarioProgramas(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <div>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "rgba(21,57,105,0.75)" }}
        >
          <Toolbar>
            <Grid
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              container
              alignItems="center"
            >
              <Grid item>
                <a href="/">
                  <img
                    src={logoSatelite}
                    width={150}
                    style={{ marginRight: "80px", marginLeft: "50px" }}
                    className=""
                  />
                </a>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <a
                    href="/"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      display: "block",
                    }}
                  >
                    <StyledButton color="inherit">
                      <strong>INICIO</strong>
                    </StyledButton>
                  </a>
                  {buscarId(2) ||
                  buscarId(9) ||
                  buscarId(3) ||
                  buscarId(6) ||
                  buscarId(7) ||
                  buscarId(8) ||
                  buscarId(4) ? (
                    <>
                      <StyledButton
                        color="inherit"
                        onClick={handleClick(setAnchorElVentas)}
                      >
                        <strong>COMERCIAL</strong>
                      </StyledButton>
                      <Menu
                        anchorEl={anchorElVentas}
                        open={Boolean(anchorElVentas)}
                        onClose={handleClose(setAnchorElVentas)}
                        PaperProps={{
                          sx: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        {buscarId(25) || buscarId(26) || buscarId(27) || buscarId(28) || buscarId(29) ? (
                          <>
                            {" "}
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuClickCrearTrafico}
                            >
                              Crear tráfico
                              <ArrowRightIcon fontSize="small" />
                            </StyledMenuItem>
                            <Menu
                              anchorEl={subMenuAnchorElCrearTrafico}
                              open={Boolean(subMenuAnchorElCrearTrafico)}
                              onClose={handleSubMenuCloseCrearTrafico}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                sx: {
                                  borderRadius: 0,
                                },
                              }}
                            >
                              {buscarId(25) ? (
                                <>
                                  <a
                                    href="/ventas/negocio/crear"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseCrearTrafico}
                                    >
                                      Showroom
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                              {buscarId(26) ? (
                                <>
                                  <a
                                    href="/ventas/negocio/creargexterna"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseCrearTrafico}
                                    >
                                      Gestión externa
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                               {buscarId(27) ? (
                                <>
                                  <a
                                    href="/ventas/negocio/crearreferido"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseCrearTrafico}
                                    >
                                      Referidos
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                              {buscarId(29) ? (
                                <>
                                  <a
                                    href="/referidos/index"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseCrearTrafico}
                                    >
                                      Aprobar referidos
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                              {buscarId(28) ? (
                                <>
                                  <a
                                    href="/eventos/index"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseCrearTrafico}
                                    >
                                      Eventos
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </Menu>
                          </>
                        ) : (
                          <></>
                        )}
                         {buscarId(30) ? (
                          <>
                            <a
                              href="/cotizador/negocios"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Cotizador
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                         {buscarId(23) ? (
                          <>
                            <a
                              href="/muc/index"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Metas MUC
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                        {buscarId(24) ? (
                          <>
                            <a
                              href="/cotizador/parametros"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Parametros cotizador
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}

                        {buscarId(9) ? (
                          <>
                            <a
                              href="/politicacomercial/index"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Política comercial
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}

                        {buscarId(3) ? (
                          <>
                            {" "}
                            <a
                              href="/trackingveh/index"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Tracking de vehículos
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}

                        {buscarId(4) ? (
                          <>
                            <a
                              href="/ventas"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElVentas)}
                              >
                                Ventas CRM
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}

                        {buscarId(22) || buscarId(21) ? (
                          <>
                            {" "}
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuClickRentabilidad}
                            >
                              Rentabilidad
                              <ArrowRightIcon fontSize="small" />
                            </StyledMenuItem>
                            <Menu
                              anchorEl={subMenuAnchorElRentabilidad}
                              open={Boolean(subMenuAnchorElRentabilidad)}
                              onClose={handleSubMenuCloseRentabilidad}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                sx: {
                                  borderRadius: 0,
                                },
                              }}
                            >
                              {buscarId(21) ? (
                                <>
                                  <a
                                    href="/tablarenta/autoriza"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseRentabilidad}
                                    >
                                      Autorizaciones
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                              {buscarId(22) ? (
                                <>
                                  <a
                                    href="/modelorentabilidad"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseRentabilidad}
                                    >
                                      Por vehículo
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </Menu>
                          </>
                        ) : (
                          <></>
                        )}

                        {buscarId(6) || buscarId(7) || buscarId(8) ? (
                          <>
                            {" "}
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuClickTrafico}
                            >
                              Tráfico
                              <ArrowRightIcon fontSize="small" />
                            </StyledMenuItem>
                            <Menu
                              anchorEl={subMenuAnchorElTrafico}
                              open={Boolean(subMenuAnchorElTrafico)}
                              onClose={handleSubMenuCloseTrafico}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                sx: {
                                  borderRadius: 0,
                                },
                              }}
                            >
                              {buscarId(6) ? (
                                <>
                                  <a
                                    href="/datacleaning/index"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseTrafico}
                                    >
                                      DataCleaning
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                              {buscarId(7) ? (
                                <>
                                  <a
                                    href="/traficocrmche"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseTrafico}
                                    >
                                      Diario
                                    </StyledMenuItem>
                                  </a>{" "}
                                </>
                              ) : (
                                <></>
                              )}

                              {buscarId(8) ? (
                                <>
                                  <a
                                    href="/traficocrm"
                                    style={{
                                      textDecoration: "none",
                                      color: "inherit",
                                      display: "block",
                                    }}
                                  >
                                    <StyledMenuItem
                                      className="styled-menu-item"
                                      onClick={handleSubMenuCloseTrafico}
                                    >
                                      Mensual
                                    </StyledMenuItem>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </Menu>
                          </>
                        ) : (
                          <></>
                        )}
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                  {buscarId(12) || buscarId(13) || buscarId(14) ? (
                    <>
                      <StyledButton
                        color="inherit"
                        onClick={handleClick(setAnchorElFI)}
                      >
                        <strong>F&I</strong>
                      </StyledButton>
                      <Menu
                        anchorEl={anchorElFI}
                        open={Boolean(anchorElFI)}
                        onClose={handleClose(setAnchorElFI)}
                        PaperProps={{
                          sx: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <StyledMenuItem
                          className="styled-menu-item"
                          onClick={handleSubMenuClickSeguros}
                        >
                          Seguros <ArrowRightIcon fontSize="small" />
                        </StyledMenuItem>
                        <Menu
                          anchorEl={subMenuAnchorElFISeguros}
                          open={Boolean(subMenuAnchorElFISeguros)}
                          onClose={handleSubMenuCloseSeguros}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          PaperProps={{
                            sx: {
                              borderRadius: 0,
                            },
                          }}
                        >
                          <a
                            href="/fi/index"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              display: "block",
                            }}
                          >
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuCloseSeguros}
                            >
                              Abrir
                            </StyledMenuItem>
                          </a>
                          <a
                            href="/fi/detalle"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              display: "block",
                            }}
                          >
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuCloseSeguros}
                            >
                              Detalle
                            </StyledMenuItem>
                          </a>
                          <a
                            href="/fi/resumen"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                              display: "block",
                            }}
                          >
                            <StyledMenuItem
                              className="styled-menu-item"
                              onClick={handleSubMenuCloseSeguros}
                            >
                              Reporte
                            </StyledMenuItem>
                          </a>
                        </Menu>
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                  {buscarId(16) || buscarId(17) ? (
                    <>
                      <StyledButton
                        color="inherit"
                        onClick={handleClick(setAnchorElUafe)}
                      >
                        <strong>UAFE</strong>
                      </StyledButton>
                      <Menu
                        anchorEl={anchorElUafe}
                        open={Boolean(anchorElUafe)}
                        onClose={handleClose(setAnchorElUafe)}
                        PaperProps={{
                          sx: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        {buscarId(16) ? (
                          <>
                            <a
                              href="/uafe/listado"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElUafe)}
                              >
                                Revisión de páginas
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                        {buscarId(17) ? (
                          <>
                            <a
                              href="/uafe/pesosmatrizriesgo"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                                display: "block",
                              }}
                            >
                              <StyledMenuItem
                                className="styled-menu-item"
                                onClick={handleClose(setAnchorElUafe)}
                              >
                                Pesos - Matriz de Riesgo
                              </StyledMenuItem>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                  {buscarId(19) ? (
                    <>
                      <StyledButton
                        color="inherit"
                        onClick={handleClick(setAnchorElAdmin)}
                      >
                        <strong>ADMINISTRACIÓN</strong>
                      </StyledButton>
                      <Menu
                        anchorEl={anchorElAdmin}
                        open={Boolean(anchorElAdmin)}
                        onClose={handleClose(setAnchorElAdmin)}
                        PaperProps={{
                          sx: {
                            borderRadius: 0,
                          },
                        }}
                      >
                        <a
                          href="/usuarios"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                            display: "block",
                          }}
                        >
                          <StyledMenuItem
                            className="styled-menu-item"
                            onClick={handleClose(setAnchorElAdmin)}
                          >
                            Usuarios
                          </StyledMenuItem>
                        </a>
                      </Menu>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Grid>
              <Grid item xs />
            </Grid>
            <WelcomeName />
            <SignInSignOutButton />
          </Toolbar>
        </AppBar>
      </div>
    );
  } else {
    return (
      <div style={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          style={{ backgroundColor: "rgba(21,57,105,0.75)" }}
        >
          <Toolbar>
            <a href="/">
              <img
                src={logoSatelite}
                width={150}
                style={{ marginRight: "80px", marginLeft: "50px" }}
                className=""
              />
            </a>
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>
            <WelcomeName />
            <SignInSignOutButton />
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

export default Navbar;
