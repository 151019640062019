import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { green, orange, red } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { BlobOptions } from "buffer";
import { CrmUafeType, TablaRentaDocumentoType } from "../../types";

export const RevisionUafePeticionPage: React.FC<{}> = () => {
  const params = useParams();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const [listo, setListo] = useState(false);
  const [listoTercero, setListoTercero] = useState(false);
  const [idCrmParametro, setIdCrmParametro] = useState(0);
  const [idCrmParametroTercero, setIdCrmParametroTercero] = useState(0);

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      principal();
      getInfoNegocio();
    } else {
      setName("");
    }
  }, [account]);

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  async function principal() {
    await getPeticionUafe();
  }

  async function set_tipo_parametro(crm: number) {
    setIdCrmParametro(crm);
    {
      crm > 0 ? getRegistro(crm) : getRegistro(Number(params.id));
    }
  }

  async function set_tipo_parametroTercero(crm: number) {
    setIdCrmParametroTercero(crm);
    {
      crm > 0 ? getRegistroTercero(crm) : console.log("");
    }
  }

  async function set_listo(estado: boolean) {
    setListo(estado);
  }

  async function set_listoTercero(estado: boolean) {
    setListoTercero(estado);
  }

  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    UrlBot: string;
    Estado: string;
  };

  type tipoSolicitudType = {
    Id: number;
  };

  type tipoPersonaType = {
    Id: number;
  };

  type personaType = {
    Id: number;
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaType;
  };

  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    UrlBot: string;
    Estado: string;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    UrlEvidencia: string;
    Mensaje: string;
  };

  type solicitanteType = {
    Email: string;
  };

  type respuestaDetalleType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Solicitante: solicitanteType;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  type respuestaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleType;
  };

  type respuestaUnitariaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleType[];
  };

  type respuestaPDFType = {
    Codigo: string;
    Descripcion: string;
    Objeto: string;
  };

  type solicitudType = {
    TipoSolicitud: tipoSolicitudType;
    Persona: personaType;
  };

  const [respuestaTipoPersona, setRespuestaTipoPersona] =
    useState<tipoPersonaType>({
      Id: 0,
    });

  const [respuestaSolicitante, setRespuestaSolicitante] =
    useState<solicitanteType>({
      Email: "",
    });

  const [respuestaTipoSolicitud, setRespuestaTipoSolicitud] =
    useState<tipoSolicitudRespuestaType>({
      Id: 0,
      Nombre: "",
      UrlBot: "",
      Estado: "",
    });
  const [respuestaPersona, setRespuestaPersona] = useState<personaType>({
    Id: 0,
    CI: "",
    PrimerNombre: "",
    SegundoNombre: "",
    PrimerApellido: "",
    SegundoApellido: "",
    TipoPersona: respuestaTipoPersona,
  });

  const respuestaPaginas: paginaType[] = [];
  // const respuestaDetalle: respuestaDetalleType[] = [];

  const [respuestaDetalle, setRespuestaDetalleRow] =
    useState<respuestaDetalleType>({
      Id: 0,
      TipoSolicitud: respuestaTipoSolicitud,
      Fecha: "",
      IpOrigen: "",
      Estado: "",
      Persona: respuestaPersona,
      RespuestaPaginasPublicas: respuestaPaginas,
      Solicitante: respuestaSolicitante,
    });

  const [respuestaGet, setRespuestaGet] = useState<respuestaType>({
    Codigo: "",
    Descripcion: "",
    Objeto: respuestaDetalle,
  });

  const [respuestaPDF, setRespuestaPDF] = useState<respuestaPDFType>({
    Codigo: "",
    Descripcion: "",
    Objeto: "",
  });

  //tercero

  const [respuestaTipoPersonaTercero, setRespuestaTipoPersonaTercero] =
    useState<tipoPersonaType>({
      Id: 0,
    });

  const [respuestaSolicitanteTercero, setRespuestaSolicitanteTercero] =
    useState<solicitanteType>({
      Email: "",
    });

  const [respuestaTipoSolicitudTercero, setRespuestaTipoSolicitudTercero] =
    useState<tipoSolicitudRespuestaType>({
      Id: 0,
      Nombre: "",
      UrlBot: "",
      Estado: "",
    });
  const [respuestaPersonaTercero, setRespuestaPersonaTercero] =
    useState<personaType>({
      Id: 0,
      CI: "",
      PrimerNombre: "",
      SegundoNombre: "",
      PrimerApellido: "",
      SegundoApellido: "",
      TipoPersona: respuestaTipoPersonaTercero,
    });

  const respuestaPaginasTercero: paginaType[] = [];
  // const respuestaDetalle: respuestaDetalleType[] = [];

  const [respuestaDetalleTercero, setRespuestaDetalleRowTercero] =
    useState<respuestaDetalleType>({
      Id: 0,
      TipoSolicitud: respuestaTipoSolicitudTercero,
      Fecha: "",
      IpOrigen: "",
      Estado: "",
      Persona: respuestaPersonaTercero,
      RespuestaPaginasPublicas: respuestaPaginasTercero,
      Solicitante: respuestaSolicitanteTercero,
    });

  const [respuestaGetTercero, setRespuestaGetTercero] = useState<respuestaType>(
    {
      Codigo: "",
      Descripcion: "",
      Objeto: respuestaDetalleTercero,
    }
  );

  const [respuestaPDFTercero, setRespuestaPDFTercero] =
    useState<respuestaPDFType>({
      Codigo: "",
      Descripcion: "",
      Objeto: "",
    });

  const [dataInfoNegocio, setDataInfoNegocio] =
    React.useState<TablaRentaDocumentoType>({
      id: 0,
      idCrm: "",
      modelo: "",
      agencia: "",
      asesor: "",
      cedula: "",
      nombreCliente: "",
      genero: "",
      civilstatus: "",
      nombreConyuge: "",
      provincia: "",
      ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
      lugarTrabajo: "",
      cargo: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      familia: "",
      nacionalidad: "",
      tipoPersonaCliente: "",
      zona: "",
      ciudadAgencia: "",
      esBloqueado: 0,
      ciudadEmpresa: "",
      financiera: "",
      provinciaEmpresa:""
    });

  async function getInfoNegocio() {
    try {
      await axios
        .get<TablaRentaDocumentoType>(
          "https://apisatelite.azurewebsites.net/vtablarentadocumento/" +
            String(params.idcrm)
        )
        .then((response) => {
          if (response.data.id > 0) {
            setDataInfoNegocio(response.data);
          }
          console.log("datos de tercero >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 5", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPeticionUafe() {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" + params.id
        )
        .then((response) => {
          console.log("mi respuesta de crm uafe dwh: ", response.data.idUafe);
          set_tipo_parametro(response.data.idUafe);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPeticionUafeTercero() {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
            params.id +
            "_1"
        )
        .then((response) => {
          console.log("mi respuesta de crm uafe dwh: ", response.data.idUafe);
          set_tipo_parametroTercero(response.data.idUafe);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRegistro(parametro: number) {
    console.log("mi parametro final es >>>>>>>>", parametro);
    console.log("mi parametro url es >>>>>>>>", params.id);
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/Consultar?id=" +
            (parametro > 0 ? String(parametro) : params.id)
        )
        .then((response) => {
          setRespuestaGet(response.data);
          getPDF(parametro);
          getPeticionUafeTercero();
          console.log("respuesta >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getRegistroTercero(parametro: number) {
    console.log("mi parametro final es >>>>>>>>", parametro);
    console.log("mi parametro url es >>>>>>>>", params.id);
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/Consultar?id=" +
            String(parametro)
        )
        .then((response) => {
          setRespuestaGetTercero(response.data);
          getPDFTercero(parametro);
          console.log("respuesta >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPDF(parametro: number) {
    try {
      await axios
        .get<respuestaPDFType>(
          " https://rpa.corporacionproauto.com.ec/TEST/Solicitud/GeneraReporte?idSolicitud=" +
            (parametro > 0 ? String(parametro) : params.id)
        )
        .then((response) => {
          setRespuestaPDF(response.data);
          DecodificarPDFBase64_init();
          console.log("mi pdf es >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPeticionUafeReproceso(
    user: string,
    cedula: string,
    primer_nombre: string,
    segundo_nombre: string,
    primer_apellido: string,
    segundo_apellido: string,
    tipo_persona: string
  ) {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
            params.id +
            "_repro"
        )
        .then((response) => {
          console.log("mi respuesta de la peticion UAFE: ", response.data);
          {
            response.data.idUafe > 0
              ? console.log("")
              : principalReproceso(
                  cedula,
                  primer_nombre,
                  segundo_nombre,
                  primer_apellido,
                  segundo_apellido,
                  user,
                  tipo_persona === "Persona Natural"
                    ? 1
                    : tipo_persona === "Persona Jurídica"
                    ? 3
                    : 2
                );
          }
        })
        .catch((error) => {
          principalReproceso(
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            user,
            tipo_persona === "Persona Natural"
              ? 1
              : tipo_persona === "Persona Jurídica"
              ? 3
              : 2
          );
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarRegistro(parametroGuardar: CrmUafeType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setOpenDialog(true);
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarCrmUafe(
    iduafe: number,
    idcrm: string,
    fecha: string,
    user: string
  ) {
    const nuevoRegistro: CrmUafeType = {
      idCrm: idcrm,
      idUafe: iduafe,
      scuFecha: fecha,
      scuSolicitante: user,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistro(nuevoRegistro);
  }

  async function getRegistrarPeticionRepro(parametro: string, user: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud" +
            parametro
        )
        .then((response) => {
          console.log(
            "respuesta del registro peticion >>>>>>>>>>>>>> :",
            response.data
          );
          console.log(
            "id de respuesta del registro peticion es >>>>>>>>>>>>>> :",
            response.data.Objeto.Id
          );
          guardarCrmUafe(
            response.data.Objeto.Id,
            String(params.id) + "_repro",
            response.data.Objeto.Fecha,
            user
          );
        })
        .catch((error) => {
          console.log("algo paso 1 >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function principalReproceso(
    cedula: string,
    primerNombre: string,
    segundoNombre: string,
    primerApellido: string,
    segundoApellido: string,
    user: string,
    tipoPersona: number
  ) {
    await getRegistrarPeticionRepro(
      "?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
        String(1) +
        "%26quot;},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
        user +
        "%26quot;},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
        cedula +
        "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
        primerNombre +
        "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
        segundoNombre +
        "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
        primerApellido +
        "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
        segundoApellido +
        "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
        String(tipoPersona) +
        "}}}",
      user
    );
  }

  async function getPDFTercero(parametro: number) {
    try {
      await axios
        .get<respuestaPDFType>(
          " https://rpa.corporacionproauto.com.ec/TEST/Solicitud/GeneraReporte?idSolicitud=" +
            (parametro > 0 ? String(parametro) + "_1" : params.id)
        )
        .then((response) => {
          setRespuestaPDFTercero(response.data);
          DecodificarPDFBase64_initTercer();
          console.log("mi pdf es >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getReprocesar(idSolicitud: number, idPagina: number) {
    try {
      await axios
        .get<respuestaPDFType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/ReprocesarPagina?idSolicitud="+String(idSolicitud)+"&idPagina="+String(idPagina)
        )
        .then((response) => {
          setOpenDialog(true);
          console.log("respuesta de reporcesar >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function generarPDF() {
    await DecodificarPDFBase64();
  }

  async function generarPDFTercero() {
    await DecodificarPDFBase64Tercero();
  }

  async function DecodificarPDFBase64() {
    const decodedData = atob(respuestaPDF.Objeto); // Decodificar la cadena Base64
    const byteArray = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "archivo.pdf"; // Nombre del archivo de salida
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  async function DecodificarPDFBase64Tercero() {
    const decodedDataTercero = atob(respuestaPDFTercero.Objeto); // Decodificar la cadena Base64
    const byteArrayTercero = new Uint8Array(decodedDataTercero.length);

    for (let i = 0; i < decodedDataTercero.length; i++) {
      byteArrayTercero[i] = decodedDataTercero.charCodeAt(i);
    }

    const blobTercero = new Blob([byteArrayTercero], {
      type: "application/pdf",
    });
    const urlTercero = URL.createObjectURL(blobTercero);

    const aTercero = document.createElement("a");
    aTercero.href = urlTercero;
    aTercero.download = "archivoTercero.pdf"; // Nombre del archivo de salida
    document.body.appendChild(aTercero);
    aTercero.click();
    document.body.removeChild(aTercero);
    URL.revokeObjectURL(urlTercero);
  }

  async function DecodificarPDFBase64_init() {
    const decodedData = atob(respuestaPDF.Objeto); // Decodificar la cadena Base64
    const byteArray = new Uint8Array(decodedData.length);

    for (let i = 0; i < decodedData.length; i++) {
      byteArray[i] = decodedData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    //a.href = url;
    //a.download = "archivo.pdf"; // Nombre del archivo de salida
    //document.body.appendChild(a);
    //a.click();
    //document.body.removeChild(a);
    //URL.revokeObjectURL(url);
    set_listo(true);
  }

  async function DecodificarPDFBase64_initTercer() {
    const decodedDataTercero = atob(respuestaPDFTercero.Objeto); // Decodificar la cadena Base64
    const byteArrayTercero = new Uint8Array(decodedDataTercero.length);

    for (let i = 0; i < decodedDataTercero.length; i++) {
      byteArrayTercero[i] = decodedDataTercero.charCodeAt(i);
    }

    const blobTercero = new Blob([byteArrayTercero], {
      type: "application/pdf",
    });
    const urlTercero = URL.createObjectURL(blobTercero);

    const aTercero = document.createElement("a");
    //a.href = url;
    //a.download = "archivo.pdf"; // Nombre del archivo de salida
    //document.body.appendChild(a);
    //a.click();
    //document.body.removeChild(a);
    //URL.revokeObjectURL(url);
    set_listoTercero(true);
  }

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogMatriz, setOpenDialogMatriz] = React.useState(false);

  //  if (isAuthenticated) {
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Grid alignItems={"center"} justifyContent={"center"}>
        <Grid item>
          <Paper
            sx={{
              padding: "1.2em",
              borderRadius: "0.5em",
              backgroundColor: "rgb(255,255,255,0.95)",
              mt: 2,
            }}
          >
            <Typography sx={{ mt: 1 }} variant="h5" textAlign={"center"}>
              Petición Páginas Externas - UAFE
            </Typography>
          </Paper>
          <Paper
            sx={{
              padding: "1.2em",
              borderRadius: "0.5em",
              backgroundColor: "rgb(255,255,255,0.95)",
              mt: 1,
            }}
          >
            <Box>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, textAlign: "center" }}
              >
                <strong>
                  {" "}
                  {respuestaGet.Objeto.Persona.PrimerApellido +
                    " " +
                    respuestaGet.Objeto.Persona.PrimerNombre}
                </strong>
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2, mb: 2, textAlign: "center" }}
              >
                  
                  {params.id} {idCrmParametro}
                
              </Typography>

              {/* <Button variant="contained" sx={{marginLeft: "43%", width: "14%", marginRight:"43%", mt: 1, mb: 2,
                      backgroundColor: "rgba(21,57,105,0.75)"}} onClick={function(event) {setOpenDialogMatriz(true)}}>
                Calificación
              </Button> */}


              <Modal
              open={openDialogMatriz}
              onClose={function (event) {
                setOpenDialogMatriz(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={styleModal}>
              <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Por modelo:
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  1%
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Por activdad económica:
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  2%
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Por ciudad:
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  2%
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Por tipo de persona:
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  2%
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Por ingresos:
                </Typography>
                <Typography
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  4%
                </Typography>
                <Divider sx={{marginLeft: "15%", marginRight: "15%"}}/>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    marginLeft: "25%",
                    width: "40%",
                    //textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Calificación:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "25%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  9%
                </Typography>
                    
                <Button
                  variant="contained"
                  sx={{ mt: 1, mb: 1, marginLeft: "40%", width: "20%",backgroundColor: "rgba(21,57,105,0.75)", }}
                  onClick={function (event) {setOpenDialogMatriz(false)}}
                >
                  Cerrar
                </Button>
              </Box>
            </Modal>



              <Box>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    marginLeft: "12%",
                    width: "18%",
                    // textAlign: "center"
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Página</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "7%",
                    marginLeft: "2%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Estado</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "9%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Evidencia</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    textAlign: "center",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Observación</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "15%",
                    marginLeft: "3%",
                    marginRight: "10%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Reprocesar</strong>
                </Typography>

                {respuestaGet.Objeto.RespuestaPaginasPublicas.map((row) => (
                  <>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "7%",
                        width: "5%",
                      }}
                      target="_blank"
                      component="a"
                      href={row.PaginaPublica.UrlFuente}
                      style={{ display: "inline-block" }}
                    >
                      ☝️
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "22%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {row.PaginaPublica.Fuente}
                    </Typography>
                    {row.Resultado === "Ok" ? (
                      <>
                        <CheckBoxIcon sx={{ color: green[500] }}></CheckBoxIcon>
                        <Button
                          target="_blank"
                          component="a"
                          href={row.UrlEvidencia}
                          size="small"
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "10%",
                            marginRight: "3%",
                            marginLeft: "2%",
                          }}
                        >
                          <strong>Abrir</strong>
                        </Button>
                      </>
                    ) : row.Resultado === "Pendiente" ? (
                      <>
                        <AccessTimeFilledIcon
                          sx={{ color: orange[500], marginRight: "15%" }}
                        ></AccessTimeFilledIcon>
                      </>
                    ) : (
                      <>
                        <DisabledByDefaultIcon
                          sx={{ color: red[500] }}
                        ></DisabledByDefaultIcon>
                        <Button
                          target="_blank"
                          component="a"
                          href={row.UrlEvidencia}
                          size="small"
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "10%",
                            marginRight: "3%",
                            marginLeft: "2%",
                          }}
                        >
                          <strong>Abrir</strong>
                        </Button>
                      </>
                    )}
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "2%",
                      }}
                      style={{ display: "inline-block", fontSize: 12 }}
                    >
                      {row.Mensaje}
                    </Typography>{" "}
                    {row.Resultado != "Error" && row.Resultado != "Ok" ? (
                      <>
                        <Button
                          size="small"
                          sx={{
                            mt: 1,
                            mb: 1,
                            width: "10%",
                            // marginLeft: "17%",
                          }}
                          onClick={function (event) {
                            //getReprocesar(Number(row.Id), Number(row.PaginaPublica.Id));
                            // console.log("mi peticion de reprocesar >>>> ","https://rpa.corporacionproauto.com.ec/TEST/Solicitud/ReprocesarPagina?idSolicitud=" +
                            //       String(row.Id) +
                            //       "&idPagina=" +
                            //       String(row.PaginaPublica.Id))
                          }}
                        >
                          <strong>Reprocesar</strong>
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    <Divider sx={{ marginLeft: "10%", marginRight: "10%" }} />
                  </>
                ))}
              </Box>
              <Dialog
                open={openDialog}
                //onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Se envió a reprocesar
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    //href="/politicacomercial/lista"
                    onClick={function (event) {
                      setOpenDialog(false);
                    }}
                    //autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

             
              {listo ? (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "14%",
                      marginLeft: "43%",
                      backgroundColor: "rgba(21,57,105,0.75)"
                    }}
                    onClick={function (event) {
                      generarPDF();
                    }}
                  >
                    PDF
                  </Button>
                  </>
              ) : (
                <></>
              )}
                  {dataInfoNegocio.tipoPersonaCliente=== "Persona Jurídica" ? (
                    <>
                      {" "}
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          mt: 1,
                          mb: 1,
                          width: "15%",
                          marginLeft: "42%",
                          backgroundColor: "rgba(21,57,105,0.75)"
                        }}
                        onClick={function (event) {
                          getPeticionUafeReproceso(username,dataInfoNegocio.cedula,"","",dataInfoNegocio.nombreCliente,"", dataInfoNegocio.tipoPersonaCliente);
                        }}
                      >
                        Reprocesar todo
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                
            </Box>
          </Paper>

          {/* TERCERO */}
          {respuestaGetTercero.Objeto.Id > 0 ? (
            <>
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                }}
              >
                <Box>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2, mb: 2, textAlign: "center" }}
                  >
                    <strong>
                      {" "}
                      {respuestaGetTercero.Objeto.Persona.PrimerApellido +
                        " " +
                        respuestaGetTercero.Objeto.Persona.PrimerNombre}
                    </strong>
                  </Typography>

                  <Box>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "12%",
                        width: "18%",
                        // textAlign: "center"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Página</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "7%",
                        marginLeft: "2%",
                        textAlign: "center",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Estado</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "9%",
                        textAlign: "center",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Evidencia</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "23%",
                        textAlign: "center",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Observación</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "15%",
                        marginLeft: "3%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Reprocesar</strong>
                    </Typography>

                    {respuestaGetTercero.Objeto.RespuestaPaginasPublicas.map(
                      (row) => (
                        <>
                          <Typography
                            sx={{
                              mt: 1,
                              mb: 1,
                              marginLeft: "7%",
                              width: "5%",
                            }}
                            target="_blank"
                            component="a"
                            href={row.PaginaPublica.UrlFuente}
                            style={{ display: "inline-block" }}
                          >
                            ☝️
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "22%",
                            }}
                            style={{ display: "inline-block" }}
                          >
                            {row.PaginaPublica.Fuente}
                          </Typography>
                          {row.Resultado === "Ok" ? (
                            <>
                              <CheckBoxIcon
                                sx={{ color: green[500] }}
                              ></CheckBoxIcon>
                              <Button
                                target="_blank"
                                component="a"
                                href={row.UrlEvidencia}
                                size="small"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "10%",
                                  marginRight: "3%",
                                  marginLeft: "2%",
                                }}
                              >
                                <strong>Abrir</strong>
                              </Button>
                            </>
                          ) : row.Resultado === "Pendiente" ? (
                            <>
                              <AccessTimeFilledIcon
                                sx={{ color: orange[500], marginRight: "15%" }}
                              ></AccessTimeFilledIcon>
                            </>
                          ) : (
                            <>
                              <DisabledByDefaultIcon
                                sx={{ color: red[500] }}
                              ></DisabledByDefaultIcon>
                              <Button
                                target="_blank"
                                component="a"
                                href={row.UrlEvidencia}
                                size="small"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "10%",
                                  marginRight: "3%",
                                  marginLeft: "2%",
                                }}
                              >
                                <strong>Abrir</strong>
                              </Button>
                            </>
                          )}
                          <Typography
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "20%",
                              marginLeft: "2%",
                            }}
                            style={{ display: "inline-block", fontSize: 12 }}
                          >
                            {row.Mensaje}
                          </Typography>{" "}
                          {row.Resultado != "Error" && row.Resultado != "Ok" ? (
                            <>
                              <Button
                                size="small"
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                  width: "10%",
                                  // marginLeft: "17%",
                                }}
                                onClick={function (event) {
                                  // getReprocesar(
                                  //   Number(row.Id),
                                  //   row.PaginaPublica.Id
                                  // );

                                  console.log(
                                    "mi peticion de reprocesar >>>> ",
                                    "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/ReprocesarPagina?idSolicitud=" +
                                      String(row.Id) +
                                      "&idPagina=" +
                                      String(row.PaginaPublica.Id)
                                  );
                                }}
                              >
                                <strong>Reprocesar</strong>
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                          <Divider
                            sx={{ marginLeft: "10%", marginRight: "10%" }}
                          />
                        </>
                      )
                    )}
                  </Box>
                  <Dialog
                    open={openDialog}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Correcto"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Se envió a reprocesar
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        //href="/politicacomercial/lista"
                        onClick={function (event) {
                          setOpenDialog(false);
                        }}
                        //autoFocus
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={openDialog}
                    //onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Correcto"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Se envió a reprocesar
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        //href="/politicacomercial/lista"
                        onClick={function (event) {
                          setOpenDialog(false);
                        }}
                        //autoFocus
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {listoTercero ? (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "15%",
                        marginLeft: "42%",
                        backgroundColor: "rgba(21,57,105,0.75)"
                      }}
                      onClick={function (event) {
                        generarPDFTercero();
                      }}
                    >
                      PDF
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
              </Paper>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Container>
  );
  // } else {
  //   return (
  //     <Container maxWidth="lg" sx={{ mt: 10 }}>
  //       <Paper>
  //         <Grid
  //           //container
  //           alignItems={"center"}
  //           justifyContent={"center"}
  //         >
  //           <Typography
  //             sx={{ mt: 2, mb: 1.5 }}
  //             variant="h6"
  //             textAlign={"center"}
  //           >
  //             Acceso denegado
  //           </Typography>
  //         </Grid>
  //       </Paper>
  //     </Container>
  //   );
  // }
};
