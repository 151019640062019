import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import { green, orange, red } from "@mui/material/colors";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import {
  SateliteModeloRentabilidadType,
  VSateliteModeloFamiliaType,
  VSateliteModeloRentabilidadType,
  marcaType,
} from "../../types";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";

export const CargarNivelesRentaPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getMarcas();
    } else {
      setName("");
    }
  }, [account]);

  const listaMarcas: marcaType[] = [];
  const listaModeloRentabilidad: VSateliteModeloRentabilidadType[] = [];
  const listaModeloFamilia: VSateliteModeloFamiliaType[] = [];
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listaMarcas);
  const [dataVModeloRentabilidad, setDataVModeloRentabilidad] = useState<
    VSateliteModeloRentabilidadType[]
  >(listaModeloRentabilidad);
  const [dataVModeloFamilia, setDataVModeloFamilia] =
    useState<VSateliteModeloFamiliaType[]>(listaModeloFamilia);
  const [marca, setMarca] = React.useState(1);
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const [registroVModeloRenta, setRegistroVModeloRenta] =
    React.useState<VSateliteModeloRentabilidadType>({
      smrId: 0,
      smfId: 0,
      idMarca: 0,
      marca: "",
      smfFamiliaDms: "",
      smrModelo: "",
      smrAno: 0,
      smrLimite1: 0,
      smrLimite2: 0,
      texto: "",
      smrFecha: "",
      smrUsuario: "",
    });

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaModeloRentabilidad(idMarca: number) {
    try {
      await axios
        .get<VSateliteModeloRentabilidadType[]>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelorentabilidad/marca/" +
            String(idMarca)
        )
        .then((response) => {
          setDataVModeloRentabilidad(response.data);
          getListaModeloFamilia(idMarca);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaModeloFamilia(idMarca: number) {
    try {
      await axios
        .get<VSateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/marca/" +
            String(idMarca)
        )
        .then((response) => {
          setDataVModeloFamilia(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const columns: GridColDef[] = [
    //{ field: "marca", headerName: "Marca", editable: false, width: 100 },
    {headerClassName: "theme-header",
      field: "smfFamiliaDms",
      headerName: "Familia",
      editable: false,
      width: 215,
    },
    { headerClassName: "theme-header",field: "smrModelo", headerName: "Modelo", editable: false, width: 300 },
    { headerClassName: "theme-header",field: "smrAno", headerName: "Año", editable: false, width: 60 },
    {
      headerClassName: "theme-header",field: "smrLimite1",
      headerName: "Rojo",
      align: "center",
      width: 70,
      valueGetter: ({ row }) => {
        return String(row.smrLimite1) + " %";
      },
    },
    {
      headerClassName: "theme-header",field: "smrLimite2",
      headerName: "Naranja",
      align: "center",
      width: 70,
      valueGetter: ({ row }) => {
        return String(row.smrLimite2) + " %";
      },
    },
    {
      headerClassName: "theme-header",field: "smrFecha",
      headerName: "F.Modificación",
      width: 90,
      valueGetter: ({ row }) => {
        return String(row.smrFecha).substring(0, 10);
      },
    },
    {
      headerClassName: "theme-header", field: "smrUsuario",
      headerName: "Usuario Modificación",
      editable: false,
      width: 190,
    },
    {
      headerClassName: "theme-header",field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 90,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/clienterpa/${params.row.idcrm}`}
            onClick={function (event) {
              setRegistroVModeloRenta(params.row);
              setOpenModal(true);
            }}
          >
            Abrir{" "}
          </Button>
        );
      },
    },
  ];

  const handleLimites = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegistroVModeloRenta({
      ...registroVModeloRenta,
      [event.target.name]: event.target.value,
    });
  };
  function exportar2(data: VSateliteModeloFamiliaType[]) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "datos"; // Nombre del archivo Excel

    // Mapeo de encabezados
    const headersMapping: Record<string, string> = {
      smfId: "Id",
      idCotItem: "Id_Vh",
      familia: "Familia",
      modelo: "Modelo",
      ano: "Año",
      nivel1: "Limite_rojo",
      nivel2: "Limite_naranja",
      // ... otros encabezados mapeados
    };

    const formattedData = data.map((item) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (headersMapping[key]) {
          newItem[headersMapping[key]] = (item as any)[key];
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    URL.revokeObjectURL(url);
  }

  type ExcelDataType = VSateliteModeloFamiliaType[];
  const [excelData, setExcelData] = useState<ExcelDataType>([]);

  function leerExcel(data: ExcelDataType) {
    data.forEach(function (value) {
      if (value.modelo != "Modelo" && Number(value.smfId) > 0) {
        const nuevoRegistro: SateliteModeloRentabilidadType = {
          smfId: Number(value.smfId),
          idCotItem: Number(value.idCotItem),
          smrModelo: value.modelo,
          smrAno: Number(value.ano),
          smrLimite1: Number(value.nivel1),
          smrLimite2: Number(value.nivel2),
          smrFecha: new Date(Date.now()).toISOString().substring(0, 10),
          smrUsuario: username,
        };
        console.log("mi nuevo registro es >>>>", nuevoRegistro);
        postGuardarRegistro(nuevoRegistro);
      }
      setOpenDialog(true);
    });
  }

  const [openModal, setOpenModal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  async function postGuardarRegistro(
    registroGuardar: SateliteModeloRentabilidadType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitemodelorentabilidad",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const excelRows = XLSX.utils.sheet_to_json(sheet, {
          header: [
            "Id",
            "Id_Vh",
            "Familia",
            "Modelo",
            "Año",
            "Limite_rojo",
            "Limite_naranja",
          ],
        });
        const excelData: VSateliteModeloFamiliaType[] = excelRows.map(
          (row: any) => ({
            smfId: row.Id,
            idCotItem: row.Id_Vh,
            familia: row.Familia,
            modelo: row.Modelo,
            ano: row.Año,
            nivel1: row.Limite_rojo,
            nivel2: row.Limite_naranja,
            kmat: row.kmat
          })
        );
        setExcelData(excelData);
        console.log(excelData);
        leerExcel(excelData);
      }
    };
    reader.readAsBinaryString(file);
  };

  const subirArchivo = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".xlsx";
    fileInput.onchange = (e: any) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        handleFileUpload(file);
      }
    };
    fileInput.click();
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Carga Niveles de Rentabilidad
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "40%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "14%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                onClick={function (event) {
                  getListaModeloRentabilidad(marca);
                }}
              >
                Consultar
              </Button>
            </Paper>
            <Dialog
              open={openDialog}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Datos guardados correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/politicacomercial/lista"
                  onClick={function (event) {
                    setOpenDialog(false);
                  }}
                  //autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    "& .theme-header": {
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "rgba(255,255,255,1)",
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.smrId}
                    sx={{ height: 600 }}
                    editMode="row"                    
                    disableRowSelectionOnClick 
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    slots={{ toolbar: GridToolbarQuickFilter }}
                    rows={dataVModeloRentabilidad}
                    columns={columns}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pageSizeOptions={[10, 20, 30]}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                      toolbarQuickFilterPlaceholder: "Buscar",
                    }}
                  />
                </Box>
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    width: "12%",
                    marginLeft: "38%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    exportar2(dataVModeloFamilia);
                  }}
                >
                  Descargar plantilla
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 1,
                    width: "12%",
                    marginLeft: "1%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    subirArchivo();
                  }}
                >
                  Cargar Plantilla
                </Button>
                <Modal
                  open={openModal}
                  onClose={function (event) {
                    setOpenModal(false);
                  }}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    {/* <Typography sx={{ mt: 1, mb: 3, textAlign: "center" }}>
                      <strong>Titulo</strong>
                    </Typography> */}
                    <TextField
                      id="outlined-select-currency"
                      size="small"
                      margin="normal"
                      value={registroVModeloRenta.marca}
                      label="Marca"
                      style={{ backgroundColor: "rgb(242,242,242)" }}
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      size="small"
                      margin="normal"
                      value={registroVModeloRenta.smfFamiliaDms}
                      label="Familia"
                      style={{ backgroundColor: "rgb(242,242,242)" }}
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      size="small"
                      margin="normal"
                      value={registroVModeloRenta.smrModelo}
                      label="Modelo"
                      style={{ backgroundColor: "rgb(242,242,242)" }}
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      size="small"
                      margin="normal"
                      value={registroVModeloRenta.smrAno}
                      label="Año"
                      style={{ backgroundColor: "rgb(242,242,242)" }}
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "80%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      name="smrLimite1"
                      size="small"
                      margin="normal"
                      onChange={handleLimites}
                      value={registroVModeloRenta.smrLimite1}
                      label="% Limite rojo"
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "10%", width: "39%" }}
                    />
                    <TextField
                      id="outlined-select-currency"
                      name="smrLimite2"
                      size="small"
                      margin="normal"
                      onChange={handleLimites}
                      value={registroVModeloRenta.smrLimite2}
                      label="% Limite naranja"
                      sx={{
                        mt: 0.5,
                        mb: 0.5,
                        marginLeft: "2%",
                        width: "39%",
                        marginRight: "10%",
                      }}
                    />
                    <RadioButtonCheckedIcon
                      sx={{
                        color: red[500],
                        marginLeft: "23%",
                        marginRight: "5%",
                        mt: 2,
                      }}
                    />
                    <Typography
                      sx={{ mt: 1, mb: 3, width: "60%" }}
                      style={{ display: "inline-block" }}
                    >
                      Rentabilidad menor al {registroVModeloRenta.smrLimite1} %
                    </Typography>
                    <RadioButtonCheckedIcon
                      sx={{
                        color: orange[500],
                        marginLeft: "23%",
                        marginRight: "5%",
                      }}
                    />
                    <Typography
                      sx={{ mt: 1, mb: 3, width: "60%" }}
                      style={{ display: "inline-block" }}
                    >
                      Desde el {registroVModeloRenta.smrLimite1} % hasta el{" "}
                      {registroVModeloRenta.smrLimite2} %
                    </Typography>
                    <RadioButtonCheckedIcon
                      sx={{
                        color: green[500],
                        marginLeft: "23%",
                        marginRight: "5%",
                      }}
                    />
                    <Typography
                      sx={{ mt: 1, mb: 3, width: "60%" }}
                      style={{ display: "inline-block" }}
                    >
                      Rentabilidad mayor al {registroVModeloRenta.smrLimite2} %
                    </Typography>
                    <Button
                      variant="contained"
                      sx={{ marginLeft: "40%", width: "20%", mt: 2, 
                      backgroundColor: "rgba(21,57,105,0.75)",}}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Modal>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
