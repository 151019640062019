import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Button, Container, Grid } from "@mui/material";
import { NavBar } from "./common/NavBar";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { AppRouter } from "./Router";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { PageLayout } from "./components/PageLayout";
import { TablaRentaPage } from "./pages/table_renta";
import { HomePage } from "./pages/home";
import { ListaTablaRentaPage } from "./pages/table_renta/listado";
import { AprobarTablaPage } from "./pages/table_renta/aprobar";
import { AutorizacionPage } from "./pages/table_renta/autorizacion";
import { AutorizacionNivelesPage } from "./pages/table_renta/autorizacion_niveles";
import { ParametroPage } from "./pages/clasificacion_abc/parametro";
import { ParametroBodegasPage } from "./pages/clasificacion_abc/parametroBodegas";
import {PoliticaComercialPage} from "./pages/politica_comercial/index"
import { ListaPoliticaComercialPage } from "./pages/politica_comercial/listado";
import { TrackingParametrosPage } from "./pages/tracking_veh/tck_parametros";
import { ProgramaPage } from "./pages/programa/programa";
import {ConsultaTraackingVehPage} from "./pages/tracking_veh/tck_consulta"
import { TrackingDetallePage } from "./pages/tracking_veh/tck_detalle";
import { TrackingParametrosRutasPage } from "./pages/tracking_veh/tck_parametros_rutas";
import { TrackingParametrosFasesPage } from "./pages/tracking_veh/tck_parametros_fases";
import { TrackingParametrosAccPage } from "./pages/tracking_veh/tck_parametros_acc";
import { RevisionUafePage } from "./pages/revision_uafe";
import { RevisionUafeListadoPage } from "./pages/revision_uafe/listado";
import { MUCPage } from "./pages/muc";
import { TrackingIndexPage } from "./pages/tracking_veh";
import { ClienteRPAPage } from "./pages/cliente_rpa";
import { VentasPage } from "./pages/ventas";
import { RevisionUafePeticionPage } from "./pages/revision_uafe/peticion";
import { NuevoNegocioPage } from "./pages/ventas/nuevonegocio";
import { CrearClientePage } from "./pages/ventas/crearcliente";
import { VentaDocumentoPage } from "./pages/ventas/documentos";
import { MUCDetallePage } from "./pages/muc/detalle";
import { FIConsultaPage } from "./pages/f&i";
import { FINegocioPage } from "./pages/f&i/negocio";
import { MUCResumenPage } from "./pages/muc/resumen";
import { FIDetallePage } from "./pages/f&i/detalle";
import { FIResumenPage } from "./pages/f&i/resumen";
import { DataCleaningPage } from "./pages/dataclinic";
import { EventosPage } from "./pages/ventas/eventos";
import { ConozcaPage } from "./pages/table_renta/conozca";
import { HomeVentasPage } from "./pages/ventas/homeventas";
import { ModeloFamiliaPage } from "./pages/accesorizacion/modelo_familia";
import { CotizacionPage } from "./pages/accesorizacion/cotizacion";
import { TablaRentaNewPage } from "./pages/table_renta/new_index";
import { TraficoCrmChePage } from "./pages/dataclinic/trafico_diario";
import { TraficoCrmPage } from "./pages/dataclinic/trafico_mensual";
import { UsuarioPage } from "./pages/administracion/usuario";
import { CargarNivelesRentaPage } from "./pages/table_renta/cargar_niveles";
import { PesoMatrizRiegoPage } from "./pages/revision_uafe/pesos";
import { HomeAccPage } from "./pages/accesorizacion/home";
import { ChevySegurosPage } from "./pages/chevyseguros";
import { AgendaPage } from "./pages/ventas/agenda";
import { CreditoDirectoPage } from "./pages/f&i/creditodirecto";
import { CorregirNegosPage } from "./pages/administracion/corregir_negos";
import { ParametrosCotizadorPage } from "./pages/cotizador/parametros";
import { AppChevroletTraficoPage } from "./pages/dataclinic/appchevrolet";
import { NuevoNegocioGExternoPage } from "./pages/ventas/nuevonegocio_externo";
import { NuevoNegocioReferidoPage } from "./pages/referidos/nuevonegocio_referido";
import { ReferidosIndexPage } from "./pages/referidos";
import { CotizadorPage } from "./pages/cotizador";
import { ListaNegociosPage } from "./pages/cotizador/listaNegocios";

interface AppProps {
  pca: IPublicClientApplication;
}

function App({ pca }: AppProps) {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);
  // console.log('=====>>>>>>',instance.getTokenCache());
  
  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Grid container justifyContent="center">
          <Pages />
        </Grid>
      </PageLayout>
    </MsalProvider>
  );
} 

function Pages() {
  return (
    <Routes>
      <Route path="/creartablarenta/:idcrm" element={<TablaRentaPage />} />
      <Route path="/tablarenta/tabla/:nuevo/:idcrm" element={<TablaRentaNewPage />} />
      <Route path="/testtablarenta/:idcrm" element={<TablaRentaPage />} />
      <Route path="/listadotablarenta" element={<ListaTablaRentaPage />} />
      <Route path="/tablarenta/:id" element={<AprobarTablaPage />} />
      <Route path="/tablarenta/autoriza" element={<AutorizacionPage />} />
      <Route path="/tablarenta/autoriza/niveles" element={<AutorizacionNivelesPage />} />
      <Route path="/clasificacionabc/parametro" element={<ParametroPage />} />   
      <Route path="/clasificacionabc/bodegas" element={<ParametroBodegasPage />} />    
      <Route path="/politicacomercial/index" element={<PoliticaComercialPage />} />   
      <Route path="/politicacomercial/index/:id/:mes/:marca/:zona/:semana" element={<PoliticaComercialPage />} />   
      <Route path="/politicacomercial/lista" element={<ListaPoliticaComercialPage />} />      
      <Route path="/trackingveh/parametros" element={<TrackingParametrosPage />} />     
      <Route path="/trackingveh/parametros_rutas" element={<TrackingParametrosRutasPage />} />   
      <Route path="/trackingveh/parametros_fases" element={<TrackingParametrosFasesPage />} />   
      <Route path="/trackingveh/parametros_acc" element={<TrackingParametrosAccPage />} />   
      <Route path="/trackingveh/consulta" element={<ConsultaTraackingVehPage />} />    
      <Route path="/trackingveh/detalle" element={<TrackingDetallePage />} />      
      <Route path="/trackingveh/index" element={<TrackingIndexPage />} />    
      <Route path="/programa/index" element={<ProgramaPage />} />        
      <Route path="/uafe/index/:idcrm" element={<RevisionUafePage />} />        
      {/* <Route path="/uafe/index" element={<RevisionUafePage />} /> */}
      <Route path="/uafe/listado" element={<RevisionUafeListadoPage />} />  
      <Route path="/uafe/:id" element={<RevisionUafePeticionPage />} />  
      <Route path="/muc/index" element={<MUCPage />} />  
      <Route path="/mucdetalle" element={<MUCDetallePage />} />  
      <Route path="/mucresumen" element={<MUCResumenPage />} />  
      <Route path="/clienterpa/:idcrm" element={<ClienteRPAPage />} />  
      <Route path="/ventas" element={<VentasPage />} />  
      <Route path="/ventas/negocio/crear" element={<NuevoNegocioPage />} />
      <Route path="/ventas/negocio/creargexterna" element={<NuevoNegocioGExternoPage />} />
      <Route path="/ventas/negocio/crearreferido" element={<NuevoNegocioReferidoPage />} />
      <Route path="/ventas/cliente/crear/:idcrm" element={<CrearClientePage />} />
      <Route path="/ventas/documentos/:idcrm" element={<VentaDocumentoPage />} />
      <Route path="/fi/index" element={<FIConsultaPage />} />
      <Route path="/fi/negocio/:id" element={<FINegocioPage />} />
      <Route path="/fi/detalle" element={<FIDetallePage />} />
      <Route path="/fi/resumen" element={<FIResumenPage />} />
      <Route path="/datacleaning/index" element={<DataCleaningPage />} />
      <Route path="/cargamasiva/appchevrolet" element={<AppChevroletTraficoPage />} />
      <Route path="/eventos/index" element={<EventosPage />} />
      <Route path="/conozca/:idcrm" element={<ConozcaPage />} />
      <Route path="/ventas/index/:idcrm" element={<HomeVentasPage />} />
      <Route path="/modelo/familia/:idcrm/:familia" element={<ModeloFamiliaPage />} />
      <Route path="/cotizacion/:idcrm/:idcotitem" element={<CotizacionPage />} />
      <Route path="/traficocrm" element={<TraficoCrmPage />} />
      <Route path="/traficocrmche" element={<TraficoCrmChePage />} />
      <Route path="/usuarios" element={<UsuarioPage />} />
      <Route path="/modelorentabilidad" element={<CargarNivelesRentaPage />} />
      <Route path="/uafe/pesosmatrizriesgo" element={<PesoMatrizRiegoPage />} />
      <Route path="/accesorios/home" element={<HomeAccPage />} />
      <Route path="/chevyseguros/index" element={<ChevySegurosPage />} />
      <Route path="/agenda" element={<AgendaPage />} />
      <Route path="/creditodirecto" element={<CreditoDirectoPage />} />
      <Route path="/corregir" element={<CorregirNegosPage />} />
      <Route path="/cotizador/parametros" element={<ParametrosCotizadorPage />} />
      <Route path="/cotizador/index/:idcrm" element={<CotizadorPage />} />
      <Route path="/cotizador/negocios" element={<ListaNegociosPage />} />
      <Route path="/referidos/index" element={<ReferidosIndexPage />} />  
      <Route path="/" element={<HomePage />} />      
    </Routes>
  );
}

export default App;
