import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  Divider,
  Switch,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  AppBar,
  Tabs,
  Tab,
} from "@mui/material";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useParams } from "react-router-dom";
import { jsPDF } from "jspdf";
import qrImagen from "../../assets/qr_con_cli.png";
import imagenautorizacion from "../../assets/imgautorizacion.png";
import qrAutorizacion from "../../assets/pff_aut_fac.png";
//import QRCode from 'qrcode';
import QRCode from 'qrcode';
import {
  CrmUafeType,
  SateliteAuditoriaType,
  SateliteUafePesoType,
  TablaRentaConozcaType,
  TablaRentaDocumentoType,
  TablaRentaTerceroType,
  UpdateTablaRentaConozcaType,
  UpdateTablaRentaTerceroType,
} from "../../types";
import logoCorp from "../../assets/logo_corp.png";
import { useTheme } from "@mui/material/styles";
import { postGuardarAuditoria } from "../../services/auditoriaService";

export const ConozcaPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getClienteActividad(); //1
      getDatosTercero(1);
      // getDatosTercero(2);
      // getDatosTercero(3);
      getDatosConozca(); //2
      getInfoNegocio(); //3
      getvTablaRenta(); //4
      // getListadoUafePeso("pais", "tabla", 5); //pais
      // getListadoUafePeso("peso1", "tabla", 1);
      // getListadoUafePeso("peso2", "tabla", 2);
      // getListadoUafePeso("peso6", "tabla", 6);
      // getListadoUafePeso("peso7", "tabla", 7);
      getListadoUafePesoTodo();  //5
      String(params.idcrm) != "0"
        ? getvTablaRentaCrm(String(params.idcrm))
        : console.log("");
    } else {
      setName("");
    }
  }, [account]);


  // const [mostrar1, setMostrar1] = useState<boolean>(false);
  // const [mostrar2, setMostrar2] = useState<boolean>(false);
  const [mostrar3, setMostrar3] = useState<boolean>(false);
  // const [mostrar4, setMostrar4] = useState<boolean>(false);
  // const [mostrar5, setMostrar5] = useState<boolean>(true);
  //tercero

  const [dataTercero1, setDataTercero1] =
    React.useState<UpdateTablaRentaTerceroType>({
      troId: 0,
      idCrm: "",
      troCedula: "",
      troNombre: "",
      troNacionalidad: "",
      troDireccion: "",
      troProvincia: "",
      troCiudad: "",
      troTelefono: "",
      troSexo: "",
      troActividad: "",
      troOrigenFondos: "",
      troCargo: "",
      troIngresos: 0,
      troEgresos: 0,
      troRelacion: "",
      troRelacionDescribe: "",
      troFormapago1: "",
      troBanco1: "",
      troCuenta1: "",
      troTransaccion1: "",
      troDocumento1: "",
      troValor1: 0,
      troFormapago2: "",
      troBanco2: "",
      troCuenta2: "",
      troTransaccion2: "",
      troDocumento2: "",
      troValor2: 0,
      troFormapago3: "",
      troBanco3: "",
      troCuenta3: "",
      troTransaccion3: "",
      troDocumento3: "",
      troValor3: 0,
      troOrden: 0,
      troTercero1: 1,
      troTercero2: 1,
      troTercero3: 1,
    });

  const [dataTercero2, setDataTercero2] =
    React.useState<UpdateTablaRentaTerceroType>({
      troId: 0,
      idCrm: "",
      troCedula: "",
      troNombre: "",
      troNacionalidad: "",
      troDireccion: "",
      troProvincia: "",
      troCiudad: "",
      troTelefono: "",
      troSexo: "",
      troActividad: "",
      troOrigenFondos: "",
      troCargo: "",
      troIngresos: 0,
      troEgresos: 0,
      troRelacion: "",
      troRelacionDescribe: "",
      troFormapago1: "",
      troBanco1: "",
      troCuenta1: "",
      troTransaccion1: "",
      troDocumento1: "",
      troValor1: 0,
      troFormapago2: "",
      troBanco2: "",
      troCuenta2: "",
      troTransaccion2: "",
      troDocumento2: "",
      troValor2: 0,
      troFormapago3: "",
      troBanco3: "",
      troCuenta3: "",
      troTransaccion3: "",
      troDocumento3: "",
      troValor3: 0,
      troOrden: 0,
      troTercero1: 1,
      troTercero2: 1,
      troTercero3: 1,
    });

  const [dataTercero3, setDataTercero3] =
    React.useState<UpdateTablaRentaTerceroType>({
      troId: 0,
      idCrm: "",
      troCedula: "",
      troNombre: "",
      troNacionalidad: "",
      troDireccion: "",
      troProvincia: "",
      troCiudad: "",
      troTelefono: "",
      troSexo: "",
      troActividad: "",
      troOrigenFondos: "",
      troCargo: "",
      troIngresos: 0,
      troEgresos: 0,
      troRelacion: "",
      troRelacionDescribe: "",
      troFormapago1: "",
      troBanco1: "",
      troCuenta1: "",
      troTransaccion1: "",
      troDocumento1: "",
      troValor1: 0,
      troFormapago2: "",
      troBanco2: "",
      troCuenta2: "",
      troTransaccion2: "",
      troDocumento2: "",
      troValor2: 0,
      troFormapago3: "",
      troBanco3: "",
      troCuenta3: "",
      troTransaccion3: "",
      troDocumento3: "",
      troValor3: 0,
      troOrden: 0,
      troTercero1: 1,
      troTercero2: 1,
      troTercero3: 1,
    });

  const dataDatosTercero1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataTercero1({
      ...dataTercero1,
      [e.target.name]: e.target.value,
      //idCrm: String(params.idcrm),
    });
  };

  const dataDatosTercero2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataTercero2({
      ...dataTercero2,
      [e.target.name]: e.target.value,
      idCrm: String(params.idcrm),
    });
  };

  const dataDatosTercero3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataTercero3({
      ...dataTercero3,
      [e.target.name]: e.target.value,
      idCrm: String(params.idcrm),
    });
  };

  function setRegistroTercero(
    registroTercero: UpdateTablaRentaTerceroType,
    orden: number
  ) {
    console.log("tercero a guardar >> ");
    if (registroTercero.troId > 0) {
      const actualizarRegistro: UpdateTablaRentaTerceroType = {
        troId: registroTercero.troId,
        idCrm: registroTercero.idCrm,
        troCedula: registroTercero.troCedula,
        troNombre: registroTercero.troNombre,
        troNacionalidad: registroTercero.troNacionalidad,
        troDireccion: registroTercero.troDireccion,
        troProvincia: registroTercero.troProvincia,
        troCiudad: registroTercero.troCiudad,
        troTelefono: registroTercero.troTelefono,
        troSexo: registroTercero.troSexo,
        troActividad: registroTercero.troActividad,
        troOrigenFondos: registroTercero.troOrigenFondos,
        troCargo: registroTercero.troCargo,
        troIngresos: registroTercero.troIngresos,
        troEgresos: registroTercero.troEgresos,
        troRelacion: registroTercero.troRelacion,
        troRelacionDescribe: registroTercero.troRelacionDescribe,
        troFormapago1:
          registroTercero.troTercero1 === orden
            ? registroTercero.troFormapago1
            : "",
        troBanco1:
          registroTercero.troTercero1 === orden
            ? registroTercero.troBanco1
            : "",
        troCuenta1:
          registroTercero.troTercero1 === orden
            ? registroTercero.troCuenta1
            : "",
        troTransaccion1:
          registroTercero.troTercero1 === orden
            ? registroTercero.troTransaccion1
            : "",
        troDocumento1:
          registroTercero.troTercero1 === orden
            ? registroTercero.troDocumento1
            : "",
        troValor1:
          registroTercero.troTercero1 === orden ? registroTercero.troValor1 : 0,
        troFormapago2:
          registroTercero.troTercero2 === orden
            ? registroTercero.troFormapago2
            : "",
        troBanco2:
          registroTercero.troTercero2 === orden
            ? registroTercero.troBanco2
            : "",
        troCuenta2:
          registroTercero.troTercero2 === orden
            ? registroTercero.troCuenta2
            : "",
        troTransaccion2:
          registroTercero.troTercero2 === orden
            ? registroTercero.troTransaccion2
            : "",
        troDocumento2:
          registroTercero.troTercero2 === orden
            ? registroTercero.troDocumento2
            : "",
        troValor2:
          registroTercero.troTercero2 === orden ? registroTercero.troValor2 : 0,
        troFormapago3:
          registroTercero.troTercero3 === orden
            ? registroTercero.troFormapago3
            : "",
        troBanco3:
          registroTercero.troTercero3 === orden
            ? registroTercero.troBanco3
            : "",
        troCuenta3:
          registroTercero.troTercero3 === orden
            ? registroTercero.troCuenta3
            : "",
        troTransaccion3:
          registroTercero.troTercero3 === orden
            ? registroTercero.troTransaccion3
            : "",
        troDocumento3:
          registroTercero.troTercero3 === orden
            ? registroTercero.troDocumento3
            : "",
        troValor3:
          registroTercero.troTercero3 === orden ? registroTercero.troValor3 : 0,
        troOrden: registroTercero.troOrden,
        troTercero1: registroTercero.troTercero1,
        troTercero2: registroTercero.troTercero2,
        troTercero3: registroTercero.troTercero3,
      };
      postActualizarTercero(actualizarRegistro, orden);
    }
    if (registroTercero.troId === 0) {
      const nuevoRegistro: TablaRentaTerceroType = {
        idCrm: registroTercero.idCrm,
        troCedula: registroTercero.troCedula,
        troNombre: registroTercero.troNombre,
        troNacionalidad: registroTercero.troNacionalidad,
        troDireccion: registroTercero.troDireccion,
        troProvincia: registroTercero.troProvincia,
        troCiudad: registroTercero.troCiudad,
        troTelefono: registroTercero.troTelefono,
        troSexo: registroTercero.troSexo,
        troActividad: registroTercero.troActividad,
        troOrigenFondos: registroTercero.troOrigenFondos,
        troCargo: registroTercero.troCargo,
        troIngresos: registroTercero.troIngresos,
        troEgresos: registroTercero.troEgresos,
        troRelacion: registroTercero.troRelacion,
        troRelacionDescribe: registroTercero.troRelacionDescribe,
        troFormapago1: registroTercero.troFormapago1,
        troBanco1: registroTercero.troBanco1,
        troCuenta1: registroTercero.troCuenta1,
        troTransaccion1: registroTercero.troTransaccion1,
        troDocumento1: registroTercero.troDocumento1,
        troValor1: registroTercero.troValor1,
        troFormapago2: registroTercero.troFormapago2,
        troBanco2: registroTercero.troBanco2,
        troCuenta2: registroTercero.troCuenta2,
        troTransaccion2: registroTercero.troTransaccion2,
        troDocumento2: registroTercero.troDocumento2,
        troValor2: registroTercero.troValor2,
        troFormapago3: registroTercero.troFormapago3,
        troBanco3: registroTercero.troBanco3,
        troCuenta3: registroTercero.troCuenta3,
        troTransaccion3: registroTercero.troTransaccion3,
        troDocumento3: registroTercero.troDocumento3,
        troValor3: registroTercero.troValor3,
        troOrden: registroTercero.troOrden,
        troTercero1: registroTercero.troTercero1,
        troTercero2: registroTercero.troTercero2,
        troTercero3: registroTercero.troTercero3,
      };
      postGuardarNuevoTercero(nuevoRegistro, orden);
    }
  }

  const [registroTabla, setRegistroTabla] = React.useState<tablaRentaType>({
    tarId: 0,
    tarFecha: "",
    tarNumeroCotizacion: "",
    tarAgencia: "",
    tarAsesor: "",
    tarCedulaCliente: "",
    tarNombreCliente: "",
    tarModelo: "",
    tarModeloAnio: 0,
    tarColor: "",
    tarDiasInv: 0,
    tarEscenarioFlota: "",
    tarEscenarioFlotaPorcentaje: 0,
    tarFormaPago: "",
    tarAnticipos: 0,
    tarPvpMarca: 0,
    tarDealerNet: 0,
    tarDealerNetValor: 0,
    tarMargenBruto: 0,
    tarMargenBrutoValor: 0,
    tarDescCirular: 0,
    tarDescCirularValor: 0,
    tarDescConcecionario: 0,
    tarDescConcecionarioValor: 0,
    tarDescTotal: 0,
    tarDescTotalValor: 0,
    tarRebate: 0,
    tarRebateValor: 0,
    tarUtilidadBruta: 0,
    tarUtilidadBrutaValor: 0,
    tarTotalFacturar: 0,
    tarNcConcesionario: 0,
    tarNcMarca: 0,
    tarValorObsequios: 0,
    tarAccDescripcion: "",
    tarAccIncluyeFac: 0,
    tarAccValor: 0,
    tarDispDescripcion: "",
    tarDispIncluyeFac: 0,
    tarDispValor: 0,
    tarObservaciones: "",
    tarDestinatario: "",
    tarCorreo: "",
    tarCorreoRemitente: "",
    tarEnviado: 0,
    tarNotificado: 0,
    tarEstado: 0,
    tarMantenimientosPrepa: 0,

    fpropiosUsd: 0,
    fpropiosPorce: 0,
    fpropiosDetalle: "",
    ffinancieraUsd: 0,
    ffinancieraPorce: 0,
    ffinancieraDetalle: "",
    cinternoUsd: 0,
    cinternoPorce: 0,
    cinternoDetalle: "",
    vusadoUsd: 0,
    vusadoPorce: 0,
    vusadoDetalle: "",
    ftercerosUsd: 0,
    ftercerosPorce: 0,
    ftercerosDetalle: "",
    esBeneficiario: 0,
    esPep1: 0,
    esPep2: 0,
    beneficiarioNombre: "",
    beneficiarioCi: "",
    beneficiarioSexo: "",
    beneficiarioPais: "",
    beneficiarioParentesco: "",
    pep1Cargo: "",
    pep1Fecha: "",
    pep2Nombre: "",
    pep2Cargo: "",
    pep2Fecha: "",

    estadoCivil: "",
    ciudad: "",
    correoCliente: "",
    telefonoCliente: "",
    direccionCliente: "",
    lugarTrabajo: "",
    sexoCliente: "",
    nombreConyuge: "",
    provincia: "",
    cargoCliente: "",
    direccionEmpresa: "",
    telefonoTrabajo: "",

    cliIngresos: 0,
    cliEgresos: 0,
    cliActivos: 0,
    cliPasivos: 0,
    patrimonio: 0,
    actividadCliente: "",

    repre_cedula: "",
    repre_nombres: "",
    repre_sexo: "",
    repre_nacionalidad: "",
    repre_correo: "",
    repre_civil: "",
    repre_cedula_cony: "",
    repre_nombres_cony: "",

    repre_provincia: "",
    repre_ciudad: "",
    repre_telefono: "",
    repre_direccion: "",

    acc_cedula1: "",
    acc_nombres1: "",
    acc_participacion1: 0,
    acc_nacionalidad1: "",
    acc_actividad1: "",
    acc_cargo1: "",
    acc_cedula2: "",
    acc_nombres2: "",
    acc_participacion2: 0,
    acc_nacionalidad2: "",
    acc_actividad2: "",
    acc_cargo2: "",
    acc_cedula3: "",
    acc_nombres3: "",
    acc_participacion3: 0,
    acc_nacionalidad3: "",
    acc_actividad3: "",
    acc_cargo3: "",

    es_juridica: 0,
  });

  const [datosAutoriza, setDatosAutoriza] = React.useState<autorizacionType>({
    tauId: 0,
    tarId: 0,
    tauEsOtroCanton: 0,
    tauOtroCanton: "",
    tauFinanciera: "",
    tauFinanciamientoValor: 0,
    tauFinanciamientoReferencia: "",
    tauFinanciamientoFecha: "",
    tauEntradaValor: 0,
    tauEntradaReferencia: "",
    tauEntradaFecha: "",
    tauNcValor: 0,
    tauNcReferencia: "",
    tauNcFecha: "",
    tauSaldoValor: 0,
    tauSaldoReferencia: "",
    tauSaldoFecha: "",
    tauUsadoValor: 0,
    tauUsadoReferencia: "",
    tauUsadoFecha: "",
    tauTotalValor: 0,
    tauDispositivo: 0,
    tauDispositivoValor: 0,
    tauDispositivoDetalle: "",
    tauDispositivoNotas: "",
    tauSeguro: 0,
    tauSeguroValor: 0,
    tauSeguroDetalle: "",
    tauSeguroNotas: "",
    tauAcc: 0,
    tauAccValor: 0,
    tauAccDetalle: "",
    tauAccNotas: "",
    tauObsequio: 0,
    tauObsequioValor: 0,
    tauObsequioDetalle: "",
    tauObsequioNotas: "",
  });

  async function getvTablaRenta() {
    try {
      await axios
        .get<tablaRentaType>(
          "https://apisatelite.azurewebsites.net/v-tabla-renta/idcrm/" +
          params.idcrm
        )
        .then((response) => {
          // setMostrar4(true)
          console.log("response de la tabla renta", response.data);
          if (response.data.tarId > 0) {
            setRegistroTabla(response.data);
          }
          Number(response.data.tarId) > 0
            ? getRegistroAutoriza(response.data.tarId)
            : setDatosAutoriza(datosAutorizaInit);
        })
        .catch((error) => {
          console.log("algo paso >> 2", error);
          //setError(error);
        });

      console.log("data es >>", JSON.stringify(registroTabla, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [chekedAuto, setChekedAuto] = React.useState({
    dispositivo: datosAutoriza.tauDispositivo === 1 ? true : false,
    seguro: datosAutoriza.tauSeguro === 1 ? true : false,
    accesorio: datosAutoriza.tauAcc === 1 ? true : false,
    obsequio: datosAutoriza.tauObsequio === 1 ? true : false,
    otro_canton: datosAutoriza.tauEsOtroCanton === 1 ? true : false,
  });

  async function getRegistroAutoriza(idTabla: number) {
    try {
      await axios
        .get<autorizacionType>(
          "https://apisatelite.azurewebsites.net/tablarentaautfac/" +
          String(idTabla)
        )
        .then((response) => {
          console.log("response del autoriza", response.data);
          if (response.data.tauId > 0) {
            setDatosAutoriza(response.data);
          }
          //  response.data.tauId > 0
          //  ? setDatosAutoriza(response.data)
          //  : setDatosAutoriza(datosAutorizaInit);
          chekedAuto.dispositivo =
            response.data.tauDispositivo === 1 ? true : false;
          chekedAuto.seguro = response.data.tauSeguro === 1 ? true : false;
          chekedAuto.accesorio = response.data.tauAcc === 1 ? true : false;
          chekedAuto.obsequio = response.data.tauObsequio === 1 ? true : false;
          chekedAuto.otro_canton =
            response.data.tauEsOtroCanton === 1 ? true : false;
        })
        .catch((error) => {
          console.log("algo paso >> 1", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarNuevoTercero(
    registro: TablaRentaTerceroType,
    orden: number
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentatercero",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          generaPDFTercero(registro, orden);
          // setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postActualizarTercero(
    registro: UpdateTablaRentaTerceroType,
    orden: number
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentatercero",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          //setOpenDialog(true);
          generaPDFTercero(registro, orden);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function generaPDFTercero(
    registroTercero: TablaRentaTerceroType,
    orden: number
  ) {
    console.log("pdf de tercero", registroTercero);
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const margin_left = 18;
    const inicio_pdf = 50;

    const textoAutorizacion =
      "Yo " +
      registroTercero.troNombre +
      " portador de la C.I. N° " +
      registroTercero.troCedula +
      " entrego a Corporación Proauto S.A. el valor de $" +
      Number(
        Number(registroTercero.troValor1) +
        Number(registroTercero.troValor2) +
        Number(registroTercero.troValor3)
      ).toFixed(2) +
      " de la forma arriba detallada, para que sea utilizado a favor de " +
      dataInfoNegocio.nombreCliente +
      ", como cancelacion de la(s) facturas(s) o como anticipo del vehículo.";
    const textoBeneficiario1 =
      "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también a las personas naturales que ejercen el control efectivo final sobre una persona jurídica u otra estructura jurídica.";
    const textoBeneficiario2 =
      "Yo, " +
      dataInfoNegocio.nombreCliente +
      " Confirmo que SI soy el beneficiario final de la compra que esta realizando " +
      "" +
      " en Corporación Proauto S.A.";
    const textoDeclaracion1 =
      "Declaro que el valor entregado a Corporación Proauto S. A. tiene origen y destino lícito, ni está relacionado de ninguna manera con ningún acto o actividad prohibida por la ley, en especial con la producción, transporte, comercialización o tráfico ilícito de drogas o sustancias estupefacientes o pscotrópicas, o lavado de activos. Además declaro que la información indicada en este formulario es correcta y verdadera, la cual será revisada por las autoridades de la CIA., quienes la podrán considerar para todos los efectos legales. ";
    const textoDeclaracion2 =
      "Absuelvo a Corporación Proauto S. A. de toda responsabilidad, inclusive respecto de terceros si esta declaración fuese falsa o errónea.";

    doc.setFont("helvetica", "bold");

    var logo = new Image();
    logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(logo, "PNG", margin_left, 10, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(10);
    doc.text("FORMULARIO DE RECEPCIÓN", docWidth / 2, 15, {
      align: "center",
    });
    doc.text("DE VALORES DE TERCEROS", docWidth / 2, 20, {
      align: "center",
    });
    doc.setFontSize(8);
    doc.text("Código", 170, 14);
    doc.text("RG-GCC-GC-07 ", 166, 19);
    doc.text("Versión: 2.0", 168, 23);
    //doc.line(margin_left - 3, 25, docWidth - (margin_left - 3), 25);
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text("FECHA:", margin_left, 30);
    doc.text("CLIENTE:", margin_left, 35);
    doc.text("RUC/C.I.:", margin_left, 40);
    doc.text("ASESOR:", margin_left, 45);
    doc.text("AGENCIA:", margin_left + 105, 30);
    doc.text("ID TRANSACCION:", margin_left + 105, 35);

    doc.setFont("helvetica", "normal");

    doc.text(
      new Date(Date.now()).toISOString().substring(0, 10),
      margin_left + 25,
      30
    );
    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 25, 35);
    doc.text(String(dataInfoNegocio.cedula), margin_left + 25, 40);
    doc.text(String(dataInfoNegocio.asesor), margin_left + 25, 45);
    doc.text(String(dataInfoNegocio.agencia), margin_left + 130, 30);
    doc.text(String(dataInfoNegocio.idCrm), margin_left + 140, 35);

    doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

    doc.setFont("helvetica", "bold");
    doc.text("DETALLE DE VALORES", docWidth / 2, 55, {
      align: "center",
    });
    //tabla 1
    doc.setFontSize(7);
    doc.setFont("helvetica", "bold");
    doc.text("FORMA PAGO", margin_left + 8, 60, { align: "center" });
    doc.text("NOMBRE BANCO", margin_left + 45, 60, { align: "center" });
    doc.text("N° CUENTA", margin_left + 85, 60, { align: "center" });
    doc.text("FECHA", margin_left + 120, 60, { align: "center" });
    doc.text("N° DOCUMENTO", margin_left + 145, 60, { align: "center" });
    doc.text("VALOR", margin_left + 165, 60, { align: "center" });
    doc.setFont("helvetica", "normal");

    //FORMA DE PAGO
    doc.text(registroTercero.troFormapago1, margin_left + 8, 64, {
      align: "center",
    });
    doc.text(registroTercero.troFormapago2, margin_left + 8, 68, {
      align: "center",
    });
    doc.text(registroTercero.troFormapago3, margin_left + 8, 72, {
      align: "center",
    });

    //nombre banco
    doc.text(registroTercero.troBanco1, margin_left + 45, 64, {
      align: "center",
    });
    doc.text(registroTercero.troBanco2, margin_left + 45, 68, {
      align: "center",
    });
    doc.text(registroTercero.troBanco3, margin_left + 45, 72, {
      align: "center",
    });

    //n cuenta
    doc.text(registroTercero.troCuenta1, margin_left + 85, 64, {
      align: "center",
    });
    doc.text(registroTercero.troCuenta2, margin_left + 85, 68, {
      align: "center",
    });
    doc.text(registroTercero.troCuenta3, margin_left + 85, 72, {
      align: "center",
    });

    //fecha
    doc.text(registroTercero.troTransaccion1, margin_left + 120, 64, {
      align: "center",
    });
    doc.text(registroTercero.troTransaccion2, margin_left + 120, 68, {
      align: "center",
    });
    doc.text(registroTercero.troTransaccion3, margin_left + 120, 72, {
      align: "center",
    });

    //documento
    doc.text(registroTercero.troDocumento1, margin_left + 145, 64, {
      align: "center",
    });
    doc.text(registroTercero.troDocumento2, margin_left + 145, 68, {
      align: "center",
    });
    doc.text(registroTercero.troDocumento3, margin_left + 145, 72, {
      align: "center",
    });

    //VALORES
    doc.text(
      "$ " + Number(registroTercero.troValor1).toFixed(2),
      margin_left + 165,
      64,
      { align: "center" }
    );
    doc.text(
      "$ " + Number(registroTercero.troValor2).toFixed(2),
      margin_left + 165,
      68,
      { align: "center" }
    );
    doc.text(
      "$ " + Number(registroTercero.troValor3).toFixed(2),
      margin_left + 165,
      72,
      { align: "center" }
    );

    //total
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text(
      "$ " +
      Number(
        Number(registroTercero.troValor1) +
        Number(registroTercero.troValor2) +
        Number(registroTercero.troValor3)
      ).toFixed(2),
      margin_left + 165,
      77,
      { align: "center" }
    );

    doc.line(margin_left - 3, 80, docWidth - (margin_left - 3), 80);
    doc.line(margin_left - 3, 50, margin_left - 3, 80);
    doc.line(
      docWidth - (margin_left - 3),
      50,
      docWidth - (margin_left - 3),
      80
    );

    doc.line(margin_left - 3, 85, docWidth - (margin_left - 3), 85);

    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN DEL TERCERO", docWidth / 2, 90, {
      align: "center",
    });
    doc.text("NOMBRE:", margin_left, 95);
    doc.text("IDENTIFICACIÓN:", margin_left, 100);
    doc.text("DIRECCIÓN:", margin_left, 105);
    doc.text("PROVINCIA:", margin_left, 110);
    doc.text("CELULAR:", margin_left, 115);
    doc.text("ACTIVIDAD ECONOMICA:", margin_left, 120);
    doc.text("CARGO:", margin_left, 125);
    doc.text("RELACIÓN CON CLIENTE:", margin_left, 130);
    doc.text("NACIONALIDAD:", margin_left + 105, 95);
    doc.text("CIUDAD:", margin_left + 110, 110);
    doc.text("SEXO:", margin_left + 113, 115);
    doc.text("ORIGEN DE FONDOS:", margin_left + 92, 120);
    doc.text("INGRESOS:", margin_left + 80, 125);
    doc.text("EGRESOS:", margin_left + 125, 125);
    doc.text("DESCRIBIR:", margin_left + 80, 130);

    doc.setFont("helvetica", "normal");

    doc.text(registroTercero.troNombre, margin_left + 45, 95);
    doc.text(registroTercero.troCedula, margin_left + 45, 100);
    doc.text(registroTercero.troDireccion, margin_left + 45, 105);
    doc.text(registroTercero.troProvincia, margin_left + 45, 110);
    doc.text(registroTercero.troTelefono, margin_left + 45, 115);
    doc.text(registroTercero.troActividad, margin_left + 45, 120);
    doc.text(registroTercero.troCargo, margin_left + 45, 125);
    doc.text(registroTercero.troRelacion, margin_left + 45, 130);
    doc.text(registroTercero.troNacionalidad, margin_left + 105 + 30, 95);
    doc.text(registroTercero.troCiudad, margin_left + 105 + 30, 110);
    doc.text(registroTercero.troSexo, margin_left + 105 + 30, 115);
    doc.text(registroTercero.troOrigenFondos, margin_left + 105 + 30, 120);
    doc.text(
      Number(registroTercero.troIngresos).toFixed(2),
      margin_left + 80 + 30,
      125
    );
    doc.text(
      Number(registroTercero.troEgresos).toFixed(2),
      margin_left + 125 + 30,
      125
    );
    doc.text(registroTercero.troRelacionDescribe, margin_left + 80 + 30, 130);
    doc.line(margin_left - 3, 135, docWidth - (margin_left - 3), 135);
    doc.line(margin_left - 3, 140, docWidth - (margin_left - 3), 140);

    doc.line(margin_left - 3, 85, margin_left - 3, 135);
    doc.line(
      docWidth - (margin_left - 3),
      85,
      docWidth - (margin_left - 3),
      135
    );

    doc.setFontSize(9);

    doc.setFont("helvetica", "bold");
    doc.text("AUTORIZACIÓN DEL TERCERO", margin_left, 145);

    doc.setFont("helvetica", "normal");
    doc.text(textoAutorizacion, margin_left, 150, {
      maxWidth: 170,
      align: "justify",
    });

    doc.line(margin_left - 3, 160, docWidth - (margin_left - 3), 160);
    doc.line(margin_left - 3, 165, docWidth - (margin_left - 3), 165);

    doc.line(margin_left - 3, 140, margin_left - 3, 160);
    doc.line(
      docWidth - (margin_left - 3),
      140,
      docWidth - (margin_left - 3),
      160
    );

    doc.setFont("helvetica", "bold");
    doc.text("IDENTIFICACION DEL BENEFICIARIO FINAL", margin_left, 170);

    doc.setFont("helvetica", "normal");
    doc.text(textoBeneficiario1, margin_left, 175, {
      maxWidth: 170,
      align: "justify",
    });
    doc.text(textoBeneficiario2, margin_left, 190, {
      maxWidth: 170,
      align: "justify",
    });

    doc.line(margin_left - 3, 200, docWidth - (margin_left - 3), 200);
    doc.line(margin_left - 3, 205, docWidth - (margin_left - 3), 205);

    doc.line(margin_left - 3, 165, margin_left - 3, 200);
    doc.line(
      docWidth - (margin_left - 3),
      165,
      docWidth - (margin_left - 3),
      200
    );

    doc.setFont("helvetica", "bold");
    doc.text("Declaración", margin_left, 210);

    doc.setFont("helvetica", "normal");
    doc.text(textoDeclaracion1, margin_left, 215, {
      maxWidth: 170,
      align: "justify",
    });
    doc.text(textoDeclaracion2, margin_left, 235, {
      maxWidth: 170,
      align: "justify",
    });

    doc.line(margin_left - 3, 245, docWidth - (margin_left - 3), 245);
    doc.line(margin_left - 3, 205, margin_left - 3, 245);
    doc.line(
      docWidth - (margin_left - 3),
      205,
      docWidth - (margin_left - 3),
      245
    );

    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);

    doc.text("FIRMA DEL TERCERO", 50, 265, { align: "center" });
    doc.text(registroTercero.troNombre, 50, 270, { align: "center" });

    doc.text("FIRMA DEL ASESOR", 110, 265, { align: "center" });
    doc.text(dataInfoNegocio.asesor, 110, 270, { align: "center" });

    doc.text("FIRMA DEL CAJERO", 160, 265, { align: "center" });
    doc.text("", 160, 270, { align: "center" });

    doc.setFontSize(6);
    doc.setFont("helvetica", "normal");
    doc.line(margin_left - 3, 271, docWidth - (margin_left - 3), 271);
    doc.text("ADJUNTAR:", margin_left, 275);
    doc.text("COPIA DE DOCUMENTO DE IDENTIDAD DEL TERCERO", margin_left, 279);
    doc.text(
      "REVISIÓN EN LAS PÁGINAS WEB (FUNCIÓN JUDICIAL, NACIONES UNIDAS,OFAC,SUPERINTENDENCIA DE COMPAÑIAS)",
      margin_left,
      283
    );

    doc.text("Página 1 de 1", 170, 275);
    doc.text("0110IXVI24", 173, 279);
    doc.text(new Date(Date.now()).toISOString().substring(0, 10), 173, 283);

    doc.save("frmTerceros.pdf");
  }

  //conozca

  const [dataInfoNegocio, setDataInfoNegocio] =
    React.useState<TablaRentaDocumentoType>({
      id: 0,
      idCrm: "",
      modelo: "",
      agencia: "",
      asesor: "",
      cedula: "",
      nombreCliente: "",
      genero: "",
      civilstatus: "",
      nombreConyuge: "",
      provincia: "",
      ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
      lugarTrabajo: "",
      cargo: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      familia: "",
      nacionalidad: "",
      tipoPersonaCliente: "",
      zona: "",
      ciudadAgencia: "",
      esBloqueado: 0,
      ciudadEmpresa: "",
      provinciaEmpresa: "",
      financiera: "",
      valorCuota: 0,
      plazoCredito: 0,
    });

  const [dataConozca, setDataConozca] =
    React.useState<UpdateTablaRentaConozcaType>({
      trcId: 0,
      idCrm: "",
      fpropiosUsd: 0,
      fpropiosPorce: 0,
      fpropiosDetalle: "",
      ffinancieraUsd: 0,
      ffinancieraPorce: 0,
      ffinancieraDetalle: "",
      cinternoUsd: 0,
      cinternoPorce: 0,
      cinternoDetalle: "",
      vusadoUsd: 0,
      vusadoPorce: 0,
      vusadoDetalle: "",
      ftercerosUsd: 0,
      ftercerosPorce: 0,
      ftercerosDetalle: "",
      esBeneficiario: 0,
      esPep1: 0,
      esPep2: 0,
      beneficiarioNombre: "",
      beneficiarioCi: "",
      beneficiarioSexo: "",
      beneficiarioPais: "",
      beneficiarioParentesco: "",
      pep1Cargo: "",
      pep1Fecha: "",
      pep2Nombre: "",
      pep2Cargo: "",
      pep2Fecha: "",
      cliIngresos: 0,
      cliEgresos: 0,
      cliActivos: 0,
      cliPasivos: 0,
      tcaId: 0,
      repre_cedula: "",
      repre_nombres: "",
      repre_sexo: "",
      repre_nacionalidad: "",
      repre_correo: "",
      repre_civil: "",
      repre_cedula_cony: "",
      repre_nombres_cony: "",
      repre_provincia: "",
      repre_ciudad: "",
      repre_telefono: "",
      repre_direccion: "",
      acc_cedula1: "",
      acc_nombres1: "",
      acc_participacion1: 0,
      acc_nacionalidad1: "",
      acc_actividad1: "",
      acc_cargo1: "",
      acc_cedula2: "",
      acc_nombres2: "",
      acc_participacion2: 0,
      acc_nacionalidad2: "",
      acc_actividad2: "",
      acc_cargo2: "",
      acc_cedula3: "",
      acc_nombres3: "",
      acc_participacion3: 0,
      acc_nacionalidad3: "",
      acc_actividad3: "",
      acc_cargo3: "",
      es_juridica: 0,
      actividad_cliente: 0,
      combo1: 0,
      combo2: 0,
      combo3: 0,
      combo4: 0,
      combo5: 0,
      combo6: 0,
      combo7: 0,
      comboSup1: 0,
      comboSup2: 0,
      comboSup3: 0,
      comboSup4: 0,
      comboSup5: 0,
      comboSup6: 0,
      comboSup7: 0,
      comboSup8: 0,
      comboSup9: 0,
    });

  type paginaPublicaType = {
    Id: number;
    Fuente: string;
    UrlFuente: string;
    Usuario: string;
    Password: string;
    UrlBot: string;
    Estado: string;
  };

  type paginaType = {
    Id: string;
    PaginaPublica: paginaPublicaType;
    Resultado: string;
    Mensaje: string;
    UrlEvidencia: string;
  };
  type tipoSolicitudRespuestaType = {
    Id: number;
    Nombre: string;
    Estado: string;
  };

  type tipoPersonaResumenType = {
    Id: number;
  };

  type personaType = {
    CI: string;
    PrimerNombre: string;
    SegundoNombre: string;
    PrimerApellido: string;
    SegundoApellido: string;
    TipoPersona: tipoPersonaResumenType;
  };

  type respuestaDetalleType = {
    Id: number;
    TipoSolicitud: tipoSolicitudRespuestaType;
    Fecha: string;
    IpOrigen: string;
    Estado: string;
    Persona: personaType;
    RespuestaPaginasPublicas: paginaType[];
  };

  type respuestaType = {
    Codigo: string;
    Descripcion: string;
    Objeto: respuestaDetalleType;
  };

  async function postGuardarRegistro(parametroGuardar: CrmUafeType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarCrmUafe(
    iduafe: number,
    idcrm: string,
    fecha: string,
    user: string
  ) {
    const nuevoRegistro: CrmUafeType = {
      idCrm: idcrm,
      idUafe: iduafe,
      scuFecha: fecha,
      scuSolicitante: user,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistro(nuevoRegistro);
  }

  async function getRegistrarPeticion(
    parametro: string,
    user: string,
    esRepresentante: boolean
  ) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/RegistrarSolicitud" +
          parametro
        )
        .then((response) => {
          console.log(
            "respuesta del registro peticion >>>>>>>>>>>>>> :",
            response.data
          );
          console.log(
            "id de respuesta del registro peticion es >>>>>>>>>>>>>> :",
            response.data.Objeto.Id
          );
          guardarCrmUafe(
            response.data.Objeto.Id,
            esRepresentante
              ? String(params.idcrm) + "_representante"
              : String(params.idcrm),
            response.data.Objeto.Fecha,
            user
          );
        })
        .catch((error) => {
          console.log("algo paso 1 >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function principal(
    cedula: string,
    primerNombre: string,
    segundoNombre: string,
    primerApellido: string,
    segundoApellido: string,
    user: string,
    tipoPersona: number,
    esRepresentante: boolean
  ) {
    await getRegistrarPeticion(
      "?token={%26quot;TipoSolicitud%26quot;:{%26quot;Id%26quot;:1},%26quot;Empresa%26quot;:{%26quot;Id%26quot;:%26quot;" +
      String(1) +
      "%26quot;},%26quot;Solicitante%26quot;:{%26quot;Email%26quot;:%26quot;" +
      user +
      "%26quot;},%26quot;Persona%26quot;:{%26quot;CI%26quot;:%26quot;" +
      cedula +
      "%26quot;,%26quot;PrimerNombre%26quot;:%26quot;" +
      primerNombre +
      "%26quot;,%26quot;SegundoNombre%26quot;:%26quot;" +
      segundoNombre +
      "%26quot;,%26quot;PrimerApellido%26quot;:%26quot;" +
      primerApellido +
      "%26quot;,%26quot;SegundoApellido%26quot;:%26quot;" +
      segundoApellido +
      "%26quot;,%26quot;TipoPersona%26quot;:{%26quot;Id%26quot;:" +
      String(tipoPersona) +
      "}}}",
      user,
      esRepresentante
    );
  }

  async function getPeticionUafeRepreLegal(
    user: string,
    cedula: string,
    primer_nombre: string,
    segundo_nombre: string,
    primer_apellido: string,
    segundo_apellido: string,
    orden: number
  ) {
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
          params.idcrm +
          "_RLEGAL"
        )
        .then((response) => {
          console.log("mi respuesta de la peticion UAFE: ", response.data);
          {
            response.data.idUafe > 0
              ? console.log("")
              : principal(
                cedula,
                primer_nombre,
                segundo_nombre,
                primer_apellido,
                segundo_apellido,
                user,
                orden,
                false
              );
          }
        })
        .catch((error) => {
          principal(
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            user,
            orden,
            false
          );
          console.log("entre al catch >> ", error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getPeticionUafe(
    user: string,
    cedula: string,
    primer_nombre: string,
    segundo_nombre: string,
    primer_apellido: string,
    segundo_apellido: string,
    tipo_persona: string,
    esRepresentante: boolean
  ) {
    console.log("mi tipo de persona es >>> ", tipo_persona)
    try {
      await axios
        .get<CrmUafeType>(
          "https://apisatelite.azurewebsites.net/satelitecrmuafe/" +
          params.idcrm
        )
        .then((response) => {
          console.log("mi respuesta de la peticion UAFE: ", response.data);
          {
            response.data.idUafe > 0
              ? console.log("")
              : principal(
                cedula,
                primer_nombre,
                segundo_nombre,
                primer_apellido,
                segundo_apellido,
                user,
                tipo_persona.trim() === "Persona Natural"
                  ? 1
                  : tipo_persona.trim() === "Persona Juridica"
                    ? 3
                    : 2,
                esRepresentante
              );
          }
        })
        .catch((error) => {
          principal(
            cedula,
            primer_nombre,
            segundo_nombre,
            primer_apellido,
            segundo_apellido,
            user,
            tipo_persona === "Persona Natural"
              ? 1
              : tipo_persona === "Persona Jurídica"
                ? 3
                : 2,
            esRepresentante
          );
          console.log("entre al catch >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const dataDatosConozca = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      [e.target.name]: e.target.value,
      idCrm: String(params.idcrm),
    });
  };

  function enviarUafe() {
    // if (cheked.persona_natural) {
    if (
      dataInfoNegocio.cedula.length > 5 &&
      dataInfoNegocio.nombreCliente.length > 2
    ) {
      getPeticionUafe(
        username,
        dataInfoNegocio.cedula,
        "",
        "",
        dataInfoNegocio.nombreCliente,
        "",
        cheked.persona_natural ? "Persona Natural" : "Persona Juridica",
        false
      );
      //setOpenModalUafe(false);
    } else {
      //setOpenDialogErrorUafe(true);
    }
    //  } else {
    // if (data.empresaNombre.length > 2 && data.empresaRepresentante.length > 2) {
    //   enviarEmpresa()
    //   //setOpenModalUafe(false);
    // }else{
    //   //setOpenDialogErrorUafe(true);
    // }
  }

  function setRegistroConozca(registroConozca: UpdateTablaRentaConozcaType) {
    console.log("conozca a guardar >> ", registroConozca);
    if (registroConozca.trcId > 0) {
      const actualizarRegistro: UpdateTablaRentaConozcaType = {
        trcId: registroConozca.trcId,
        idCrm: String(params.idcrm),
        fpropiosUsd: registroConozca.fpropiosUsd,
        fpropiosPorce: registroConozca.fpropiosPorce,
        fpropiosDetalle: registroConozca.fpropiosDetalle,
        ffinancieraUsd: registroConozca.ffinancieraUsd,
        ffinancieraPorce: registroConozca.ffinancieraPorce,
        ffinancieraDetalle: registroConozca.ffinancieraDetalle,
        cinternoUsd: registroConozca.cinternoUsd,
        cinternoPorce: registroConozca.cinternoPorce,
        cinternoDetalle: registroConozca.cinternoDetalle,
        vusadoUsd: registroConozca.vusadoUsd,
        vusadoPorce: registroConozca.vusadoPorce,
        vusadoDetalle: registroConozca.vusadoDetalle,
        ftercerosUsd: registroConozca.ftercerosUsd,
        ftercerosPorce: registroConozca.ftercerosPorce,
        ftercerosDetalle: registroConozca.ftercerosDetalle,
        esBeneficiario: cheked.beneficiario_concli ? 1 : 0,
        esPep1: cheked.pep_1 ? 1 : 0,
        esPep2: cheked.pep_2 ? 1 : 0,
        beneficiarioNombre: registroConozca.beneficiarioNombre,
        beneficiarioCi: registroConozca.beneficiarioCi,
        beneficiarioSexo: registroConozca.beneficiarioSexo,
        beneficiarioPais: registroConozca.beneficiarioPais,
        beneficiarioParentesco: registroConozca.beneficiarioParentesco,
        pep1Cargo: registroConozca.pep1Cargo,
        pep1Fecha: registroConozca.pep1Fecha,
        pep2Nombre: registroConozca.pep2Nombre,
        pep2Cargo: registroConozca.pep2Cargo,
        pep2Fecha: registroConozca.pep2Fecha,
        cliIngresos: registroConozca.cliIngresos,
        cliEgresos: registroConozca.cliEgresos,
        cliActivos: registroConozca.cliActivos,
        cliPasivos: registroConozca.cliPasivos,
        tcaId: registroConozca.tcaId,
        repre_cedula: registroConozca.repre_cedula,
        repre_nombres: registroConozca.repre_nombres,
        repre_sexo: registroConozca.repre_sexo,
        repre_nacionalidad: registroConozca.repre_nacionalidad,
        repre_correo: registroConozca.repre_correo,
        repre_civil: registroConozca.repre_civil,
        repre_cedula_cony: registroConozca.repre_cedula_cony,
        repre_nombres_cony: registroConozca.repre_nombres_cony,
        repre_provincia: registroConozca.repre_provincia,
        repre_ciudad: registroConozca.repre_ciudad,
        repre_telefono: registroConozca.repre_telefono,
        repre_direccion: registroConozca.repre_direccion,
        acc_cedula1: registroConozca.acc_cedula1,
        acc_nombres1: registroConozca.acc_nombres1,
        acc_participacion1: registroConozca.acc_participacion1,
        acc_nacionalidad1: registroConozca.acc_nacionalidad1,
        acc_actividad1: registroConozca.acc_actividad1,
        acc_cargo1: registroConozca.acc_cargo1,
        acc_cedula2: registroConozca.acc_cedula2,
        acc_nombres2: registroConozca.acc_nombres2,
        acc_participacion2: registroConozca.acc_participacion2,
        acc_nacionalidad2: registroConozca.acc_nacionalidad2,
        acc_actividad2: registroConozca.acc_actividad2,
        acc_cargo2: registroConozca.acc_cargo2,
        acc_cedula3: registroConozca.acc_cedula3,
        acc_nombres3: registroConozca.acc_nombres3,
        acc_participacion3: registroConozca.acc_participacion3,
        acc_nacionalidad3: registroConozca.acc_nacionalidad3,
        acc_actividad3: registroConozca.acc_actividad3,
        acc_cargo3: registroConozca.acc_cargo3,
        es_juridica: cheked.persona_natural ? 0 : 1,
        actividad_cliente: registroConozca.actividad_cliente,

        combo1: registroConozca.combo1,
        combo2: registroConozca.combo2,
        combo3: registroConozca.combo3,
        combo4: registroConozca.combo4,
        combo5: registroConozca.combo5,
        combo6: registroConozca.combo6,
        combo7: registroConozca.combo7,

        comboSup1: registroConozca.comboSup1,
        comboSup2: registroConozca.comboSup2,
        comboSup3: registroConozca.comboSup3,
        comboSup4: registroConozca.comboSup4,
        comboSup5: registroConozca.comboSup5,
        comboSup6: registroConozca.comboSup6,
        comboSup7: registroConozca.comboSup7,
        comboSup8: registroConozca.comboSup8,
        comboSup9: registroConozca.comboSup9,
      };
      postActualizarConozca(actualizarRegistro);
      if (
        registroConozca.repre_cedula.length > 0 &&
        registroConozca.repre_nombres.length > 0
      ) {
        getPeticionUafeRepreLegal(
          username,
          registroConozca.repre_cedula,
          "",
          "",
          registroConozca.repre_nombres,
          "",
          99
        );
      }
    }
    if (registroConozca.trcId === 0) {
      const nuevoRegistro: TablaRentaConozcaType = {
        idCrm: String(params.idcrm),
        fpropiosUsd: registroConozca.fpropiosUsd,
        fpropiosPorce: registroConozca.fpropiosPorce,
        fpropiosDetalle: registroConozca.fpropiosDetalle,
        ffinancieraUsd: registroConozca.ffinancieraUsd,
        ffinancieraPorce: registroConozca.ffinancieraPorce,
        ffinancieraDetalle: registroConozca.ffinancieraDetalle,
        cinternoUsd: registroConozca.cinternoUsd,
        cinternoPorce: registroConozca.cinternoPorce,
        cinternoDetalle: registroConozca.cinternoDetalle,
        vusadoUsd: registroConozca.vusadoUsd,
        vusadoPorce: registroConozca.vusadoPorce,
        vusadoDetalle: registroConozca.vusadoDetalle,
        ftercerosUsd: registroConozca.ftercerosUsd,
        ftercerosPorce: registroConozca.ftercerosPorce,
        ftercerosDetalle: registroConozca.ftercerosDetalle,
        esBeneficiario: registroConozca.esBeneficiario,
        esPep1: registroConozca.esPep1,
        esPep2: registroConozca.esPep2,
        beneficiarioNombre: registroConozca.beneficiarioNombre,
        beneficiarioCi: registroConozca.beneficiarioCi,
        beneficiarioSexo: registroConozca.beneficiarioSexo,
        beneficiarioPais: registroConozca.beneficiarioPais,
        beneficiarioParentesco: registroConozca.beneficiarioParentesco,
        pep1Cargo: registroConozca.pep1Cargo,
        pep1Fecha: registroConozca.pep1Fecha,
        pep2Nombre: registroConozca.pep2Nombre,
        pep2Cargo: registroConozca.pep2Cargo,
        pep2Fecha: registroConozca.pep2Fecha,
        cliIngresos: registroConozca.cliIngresos,
        cliEgresos: registroConozca.cliEgresos,
        cliActivos: registroConozca.cliActivos,
        cliPasivos: registroConozca.cliPasivos,
        tcaId: registroConozca.tcaId,
        repre_cedula: registroConozca.repre_cedula,
        repre_nombres: registroConozca.repre_nombres,
        repre_sexo: registroConozca.repre_sexo,
        repre_nacionalidad: registroConozca.repre_nacionalidad,
        repre_correo: registroConozca.repre_correo,
        repre_civil: registroConozca.repre_civil,
        repre_cedula_cony: registroConozca.repre_cedula_cony,
        repre_nombres_cony: registroConozca.repre_nombres_cony,
        repre_provincia: registroConozca.repre_provincia,
        repre_ciudad: registroConozca.repre_ciudad,
        repre_telefono: registroConozca.repre_telefono,
        repre_direccion: registroConozca.repre_direccion,
        acc_cedula1: registroConozca.acc_cedula1,
        acc_nombres1: registroConozca.acc_nombres1,
        acc_participacion1: registroConozca.acc_participacion1,
        acc_nacionalidad1: registroConozca.acc_nacionalidad1,
        acc_actividad1: registroConozca.acc_actividad1,
        acc_cargo1: registroConozca.acc_cargo1,
        acc_cedula2: registroConozca.acc_cedula2,
        acc_nombres2: registroConozca.acc_nombres2,
        acc_participacion2: registroConozca.acc_participacion2,
        acc_nacionalidad2: registroConozca.acc_nacionalidad2,
        acc_actividad2: registroConozca.acc_actividad2,
        acc_cargo2: registroConozca.acc_cargo2,
        acc_cedula3: registroConozca.acc_cedula3,
        acc_nombres3: registroConozca.acc_nombres3,
        acc_participacion3: registroConozca.acc_participacion3,
        acc_nacionalidad3: registroConozca.acc_nacionalidad3,
        acc_actividad3: registroConozca.acc_actividad3,
        acc_cargo3: registroConozca.acc_cargo3,
        es_juridica: cheked.persona_natural ? 0 : 1,
        actividad_cliente: registroConozca.actividad_cliente,
       
        combo1: registroConozca.combo1,
        combo2: registroConozca.combo2,
        combo3: registroConozca.combo3,
        combo4: registroConozca.combo4,
        combo5: registroConozca.combo5,
        combo6: registroConozca.combo6,
        combo7: registroConozca.combo7,

        comboSup1: registroConozca.comboSup1,
        comboSup2: registroConozca.comboSup2,
        comboSup3: registroConozca.comboSup3,
        comboSup4: registroConozca.comboSup4,
        comboSup5: registroConozca.comboSup5,
        comboSup6: registroConozca.comboSup6,
        comboSup7: registroConozca.comboSup7,
        comboSup8: registroConozca.comboSup8,
        comboSup9: registroConozca.comboSup9,
      };
      
      postGuardarNuevoConozca(nuevoRegistro);
      if (
        registroConozca.repre_cedula.length > 0 &&
        registroConozca.repre_nombres.length > 0
      ) {
        getPeticionUafeRepreLegal(
          username,
          registroConozca.repre_cedula,
          "",
          "",
          registroConozca.repre_nombres,
          "",
          99
        );
      }
    }
  }

  async function postGuardarNuevoConozca(registro: TablaRentaConozcaType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaconozca",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado conozca es: ", response.data.trcId);
          guardaPDF_formularioUAFE(registro);
          setDataConozca({...dataConozca, trcId : Number(response.data.trcId)})          
          //getDatosConozca();
          registro.es_juridica === 1
            ? guardaPDF_Juridica(registro)
            : guardaPDF(registro);            
          handleSaveAditoria(1, 'IMPRIMIR', 'ID DE CONOZCA', Number(response.data.trcId), 'ID CRM', String(params.idcrm));
          // setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const handleSaveAditoria = async (smoId: number,
    sauAccion: string,
    sauKey1: string,
    sauKey1Id: number,
    sauKey2: string,
    sauKey2Id: string) => {
    try {
      const nuevoRegistro: SateliteAuditoriaType = {
        smoId: smoId,
        sauFecha: new Date(new Date().getTime()),
        sauUsuario: name,
        sauCorreo: username,
        sauAccion: sauAccion,
        sauKey1: sauKey1,
        sauKey1Id: sauKey1Id,
        sauKey2: sauKey2,
        sauKey2Id: sauKey2Id,
      }
      const respuesta = await postGuardarAuditoria(nuevoRegistro);
    } catch (error) {
      //setError('An error occurred while saving or fetching data.');
    }
  };

  async function postActualizarConozca(registro: UpdateTablaRentaConozcaType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaconozca",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado de conozca: ", Number(response.data.trcId));
          guardaPDF_formularioUAFE(registro);
          registro.es_juridica === 1
            ? guardaPDF_Juridica(registro)
            : guardaPDF(registro);
          handleSaveAditoria(1, 'IMPRIMIR', 'ID DE CONOZCA', Number(response.data.trcId), 'ID CRM', String(params.idcrm));
          // setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardaPDF(registro: TablaRentaConozcaType) {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const margin_left = 20;
    const inicio_pdf = 50;

    //doc.addImage()

    doc.setFont("helvetica", "bold");

    var logo = new Image();
    logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(10);
    doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
    doc.text("FORMULARIO CONOZCA A SU CLIENTE", docWidth / 2, 19, {
      align: "center",
    });
    doc.text("PERSONA NATURAL", docWidth / 2, 24, { align: "center" });
    doc.setFontSize(8);
    doc.text("Código", 170, 19);
    doc.text("RG-GCC-VC-01", 166, 23);
    doc.text("Versión: 1.0", 168, 27);
    doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

    doc.line(margin_left - 3, 14, margin_left - 3, 29);
    doc.line(
      docWidth - (margin_left - 3),
      14,
      docWidth - (margin_left - 3),
      29
    );

    doc.line(margin_left - 3, 30, docWidth - (margin_left - 3), 30);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(
      "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
      margin_left,
      35,
      { maxWidth: 170, align: "justify" }
    );

    doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

    doc.line(margin_left - 3, 30, margin_left - 3, 49);
    doc.line(
      docWidth - (margin_left - 3),
      30,
      docWidth - (margin_left - 3),
      49
    );

    doc.line(margin_left - 3, 55, docWidth - (margin_left - 3), 55);
    doc.setFont("helvetica", "bold");
    doc.text("DATOS PERSONALES", docWidth / 2, 60, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("C.I.:/PASAPORTE/RUC:", margin_left, 65);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.cedula), margin_left + 40, 65);
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRES Y APELLIDOS:", margin_left, 70);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 40, 70);
    doc.setFont("helvetica", "normal");
    doc.text("NACIONALIDAD:", margin_left, 75);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.nacionalidad), margin_left + 40, 75);
    doc.setFont("helvetica", "normal");
    doc.text("SEXO:", margin_left + 110, 75);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.genero), margin_left + 135, 75);
    doc.setFont("helvetica", "normal");
    doc.text("ESTADO CIVIL:", margin_left, 80);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.civilstatus), margin_left + 40, 80);
    doc.setFont("helvetica", "normal");
    doc.text("APELLIDOS Y NOMBRES DEL CÓNYUGE:", margin_left, 85);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.nombreConyuge), margin_left + 60, 85);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 89, docWidth - (margin_left - 3), 89);

    doc.line(margin_left - 3, 55, margin_left - 3, 89);
    doc.line(
      docWidth - (margin_left - 3),
      55,
      docWidth - (margin_left - 3),
      89
    );

    doc.line(margin_left - 3, 90, docWidth - (margin_left - 3), 90);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN DEL DOMICILIO", docWidth / 2, 95, {
      align: "center",
    });
    doc.setFont("helvetica", "normal");
    doc.text("PROVINCIA:", margin_left, 100);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.provincia), margin_left + 40, 100);
    doc.setFont("helvetica", "normal");
    doc.text("CIUDAD:", margin_left + 110, 100);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.ciudad), margin_left + 135, 100);
    doc.setFont("helvetica", "normal");
    doc.text("DIRECCIÓN:", margin_left, 105);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.direccion), margin_left + 40, 105);
    doc.setFont("helvetica", "normal");
    doc.text("TELÉFONO/CELULAR:", margin_left, 110);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.telefono), margin_left + 40, 110);
    doc.setFont("helvetica", "normal");
    doc.text("E-MAIL PERSONAL:", margin_left, 115);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.correo), margin_left + 40, 115);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 119, docWidth - (margin_left - 3), 119);

    doc.line(margin_left - 3, 90, margin_left - 3, 119);
    doc.line(
      docWidth - (margin_left - 3),
      90,
      docWidth - (margin_left - 3),
      119
    );

    doc.line(margin_left - 3, 120, docWidth - (margin_left - 3), 120);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN LABORAL", docWidth / 2, 125, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("LUGAR DE TRABAJO:", margin_left, 130);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.lugarTrabajo), margin_left + 40, 130);
    doc.setFont("helvetica", "normal");
    doc.text("ACTIVIDAD ECONÓMICA:", margin_left, 135);
    doc.setFont("helvetica", "bold");
    doc.text(obtenerAtributoPorId(dataConozca.combo7), margin_left + 40, 135); //aki actividad
    doc.setFont("helvetica", "normal");
    doc.text("CARGO:", margin_left, 140);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.cargo), margin_left + 40, 140);
    doc.setFont("helvetica", "normal");
    doc.text("PROVINCIA:", margin_left, 145);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.provinciaEmpresa), margin_left + 40, 145);
    doc.setFont("helvetica", "normal");
    doc.text("CIUDAD:", margin_left + 110, 145);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.ciudadEmpresa), margin_left + 135, 145);
    doc.setFont("helvetica", "normal");
    doc.text("DIRECCIÓN:", margin_left, 150);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.direccionEmpresa), margin_left + 40, 150);
    doc.setFont("helvetica", "normal");
    doc.text("E-MAIL EMPRESAR:", margin_left, 155);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.correo), margin_left + 40, 155);
    doc.setFont("helvetica", "normal");
    doc.text("TELÉFONO:", margin_left + 110, 155);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.telefonoEmpresa), margin_left + 135, 155);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 159, docWidth - (margin_left - 3), 159);

    doc.line(margin_left - 3, 120, margin_left - 3, 159);
    doc.line(
      docWidth - (margin_left - 3),
      120,
      docWidth - (margin_left - 3),
      159
    );

    doc.line(margin_left - 3, 160, docWidth - (margin_left - 3), 160);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN ECONÓMICA", docWidth / 2, 165, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("Estimados mensuales:", margin_left, 170);
    doc.text("INGRESOS:", margin_left + 10, 175);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliIngresos), margin_left + 40, 175);
    doc.setFont("helvetica", "normal");
    doc.text("ACTIVOS:", margin_left + 110, 175);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliActivos), margin_left + 135, 175);
    doc.setFont("helvetica", "normal");
    doc.text("EGRESOS:", margin_left + 10, 180);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliEgresos), margin_left + 40, 180);
    doc.setFont("helvetica", "normal");
    doc.text("PASIVOS:", margin_left + 110, 180);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliPasivos), margin_left + 135, 180);
    doc.setFont("helvetica", "normal");
    doc.text("PATRIMONIO:", margin_left + 110, 185);
    doc.setFont("helvetica", "bold");
    doc.text(
      "$ " +
      Number(
        Number(registro.cliActivos) - Number(registro.cliPasivos)
      ).toFixed(2),
      margin_left + 135,
      185
    );
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 189, docWidth - (margin_left - 3), 189);

    doc.line(margin_left - 3, 160, margin_left - 3, 189);
    doc.line(
      docWidth - (margin_left - 3),
      160,
      docWidth - (margin_left - 3),
      189
    );

    doc.line(margin_left - 3, 190, docWidth - (margin_left - 3), 190);

    doc.text(
      "Declaración: Con pleno valor vinculante y consciente de las responsabilidades que ello genera, EL CLIENTE declara (n) bajo juramento que los recursos y fondos que emplea en sus operaciones y transacciones comerciales, así como aquellos que utilizó (aron) para pagar el valor de del bien o servicio, han tenido, tienen y tendrán fuente y origen lícito, y que no han provenido, provienen o provendrán de operaciones o actividades reprimidas por la ley, especialmente no de aquellas reprimidas por la legislación sobre sustancias estupefacientes y psicotrópicas",
      margin_left,
      195,
      { maxWidth: 170, align: "justify" }
    );
    doc.text("Los fondos de esta transacción proceden de:", margin_left, 215);
    doc.setFont("helvetica", "bold");
    doc.text("AHORROS TRABAJO / PERSONAL", margin_left + 70, 215);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Los fondos de esta transacción serán usados para:",
      margin_left,
      220
    );
    doc.setFont("helvetica", "bold");
    doc.text(" COMPRA DE VEHÍCULO NUEVO", margin_left + 70, 220);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 224, docWidth - (margin_left - 3), 224);

    doc.line(margin_left - 3, 190, margin_left - 3, 224);
    doc.line(
      docWidth - (margin_left - 3),
      190,
      docWidth - (margin_left - 3),
      224
    );

    doc.line(margin_left - 3, 225, docWidth - (margin_left - 3), 225);
    doc.setFont("helvetica", "bold");
    doc.text("TRANSACCIÓN", docWidth / 2, 230, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("TIPO DE TRANSACCIÓN:", margin_left, 235);
    doc.setFont("helvetica", "bold");
    doc.text("COMPRA DE VEHÍCULO", margin_left + 40, 235);
    doc.setFont("helvetica", "normal");
    doc.text("MONEDA:", margin_left + 110, 235);
    doc.setFont("helvetica", "bold");
    doc.text("DOLAR", margin_left + 135, 235);
    doc.setFont("helvetica", "normal");
    doc.text("VALOR TRANSACCIÓN:", margin_left, 240);
    doc.setFont("helvetica", "bold");
    doc.text(
      Number(
        Number(registro.fpropiosUsd) +
        Number(registro.ffinancieraUsd) +
        Number(registro.cinternoUsd) +
        Number(registro.vusadoUsd) +
        Number(registro.ftercerosUsd)
      ).toFixed(2),
      margin_left + 40,
      240
    );
    doc.setFont("helvetica", "normal");
    doc.text("FECHA DE TRANSACCIÓN:", margin_left + 110, 240);
    doc.setFont("helvetica", "bold");
    doc.text(
      new Date(Date.now()).toISOString().substring(0, 10),
      margin_left + 150,
      240
    );
    doc.setFont("helvetica", "normal");
    doc.text("DOCUMENTO:", margin_left, 245);
    doc.setFont("helvetica", "bold");
    doc.text("TABLA DE RENTABILIDAD", margin_left + 40, 245);
    doc.setFont("helvetica", "normal");
    doc.text("N° DOCUMENTO:", margin_left + 110, 245);
    doc.setFont("helvetica", "bold");
    doc.text(String(params.idcrm), margin_left + 135, 245);
    doc.setFont("helvetica", "normal");
    doc.text("DETALLE DEL PRODUCTO*:", margin_left, 250);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.modelo), margin_left + 40, 250);
    doc.setFont("helvetica", "normal");
    doc.text("VIGENCIA:", margin_left + 110, 250);
    doc.setFont("helvetica", "bold");
    doc.text("MENSUAL", margin_left + 135, 250);
    doc.setFont("helvetica", "normal");
    doc.text(
      "*Detalle del modelo del vehículo o el repuesto y/o servicio.",
      margin_left,
      255
    );

    doc.line(margin_left - 3, 259, docWidth - (margin_left - 3), 259);

    doc.line(margin_left - 3, 225, margin_left - 3, 259);
    doc.line(
      docWidth - (margin_left - 3),
      225,
      docWidth - (margin_left - 3),
      259
    );

    const now = new Date(Date.now() - 5 * 60 * 60 * 1000); // Restar 5 horas
    // Formatear la fecha y hora
    const isoString = now.toISOString();
    const [dateText, timeText] = isoString.split('T');
    const formattedTimeText = timeText.split('.')[0];

    const qrCodeText = "Documento: Conozca a su cliente\nFecha: " + dateText + "\nHora: " + formattedTimeText + "\nUsuario: " + username + "\nN° Impresión: 1\nPágina 1/2";
    const qrCodeDataUrl = await QRCode.toDataURL(qrCodeText);
    doc.addImage(qrCodeDataUrl, 'PNG', margin_left + 145, 254, 30, 30);

    ///////////////////////// HOJA 2 ////////////////////////////////////
    doc.addPage("a4");
    doc.line(margin_left - 3, 15, docWidth - (margin_left - 3), 15);

    doc.setFont("helvetica", "bold");
    doc.text("PROCEDENCIA DE FONDOS", docWidth / 2, 18, { align: "center" });
    doc.text("FONDOS", margin_left, 23);
    doc.text("VALOR", margin_left + 60, 23);
    doc.text("% VALOR", margin_left + 90, 23);
    doc.text("DETALLE", margin_left + 130, 23);
    doc.setFont("helvetica", "normal");

    //FONDOS
    doc.text("FONDOS PROPIOS", margin_left, 27);
    doc.text("FONDOS FINANCIERA", margin_left, 31);
    doc.text("CREDITO INTERNO", margin_left, 35);
    doc.text("TOMA DE VEHÍCULO", margin_left, 39);
    doc.text("FONDOS DE TERCEROS", margin_left, 43);
    doc.setFont("helvetica", "bold");
    doc.text("TOTAL", margin_left, 47);
    doc.setFont("helvetica", "normal");

    //FONDOS
    doc.text(
      "$ " + String(Number(registro.fpropiosUsd).toFixed(2)),
      margin_left + 60,
      27
    );
    doc.text(
      "$ " + String(Number(registro.ffinancieraUsd).toFixed(2)),
      margin_left + 60,
      31
    );
    doc.text(
      "$ " + String(Number(registro.cinternoUsd).toFixed(2)),
      margin_left + 60,
      35
    );
    doc.text("$ " + String(registro.vusadoUsd), margin_left + 60, 39);
    doc.text("$ " + String(registro.ftercerosUsd), margin_left + 60, 43);
    doc.setFont("helvetica", "bold");
    doc.text(
      "$ " +
      String(
        Number(
          Number(registro.fpropiosUsd) +
          Number(registro.ffinancieraUsd) +
          Number(registro.cinternoUsd) +
          Number(registro.vusadoUsd) +
          Number(registro.ftercerosUsd)
        ).toFixed(2)
      ),
      margin_left + 60,
      47
    );
    doc.setFont("helvetica", "normal");

    //VALOR
    doc.text(String(registro.fpropiosPorce) + " %", margin_left + 90, 27);
    doc.text(String(registro.ffinancieraPorce) + " %", margin_left + 90, 31);
    doc.text(String(registro.cinternoPorce) + " %", margin_left + 90, 35);
    doc.text(String(registro.vusadoPorce) + " %", margin_left + 90, 39);
    doc.text(String(registro.ftercerosPorce) + " %", margin_left + 90, 43);
    doc.setFont("helvetica", "bold");
    doc.text("100 %", margin_left + 90, 47);
    doc.setFont("helvetica", "normal");

    //DETALLE
    doc.text(String(registro.fpropiosDetalle), margin_left + 130, 27);
    doc.text(String(registro.ffinancieraDetalle), margin_left + 130, 31);
    doc.text(String(registro.cinternoDetalle), margin_left + 130, 35);
    doc.text(String(registro.vusadoDetalle), margin_left + 130, 39);
    doc.text(String(registro.ftercerosDetalle), margin_left + 130, 43);

    doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

    doc.line(margin_left - 3, 15, margin_left - 3, 49);
    doc.line(
      docWidth - (margin_left - 3),
      15,
      docWidth - (margin_left - 3),
      49
    );

    doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

    doc.setFont("helvetica", "bold");
    doc.text("BENEFICIARIO FINAL", docWidth / 2, 55, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text(
      "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también las personas naturales",
      margin_left,
      60,
      { maxWidth: 170, align: "justify" }
    );
    doc.text(
      "¿Es la usted el beneficiario final (propietario) del vehículo?",
      margin_left,
      70
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      cheked.beneficiario_concli === true ? "SI" : "NO",
      margin_left + 100,
      70
    );
    doc.setFont("helvetica", "normal");
    doc.setFont("helvetica", "bold");
    doc.text(
      "Si coloco NO en la pregunta anterior por favor detalle los datos del beneficiario final (propietario) del vehículo:",
      margin_left,
      75
    );
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRE:", margin_left, 80);
    doc.text("N° IDENTIFICACIÓN:", margin_left + 100, 80);
    doc.text("SEXO:", margin_left, 85);
    doc.text("NACIONALIDAD:", margin_left + 100, 85);
    doc.text("PARENTESCO:", margin_left, 90);

    doc.setFont("helvetica", "bold");
    doc.text(registro.beneficiarioNombre, margin_left + 30, 80);
    doc.text(registro.beneficiarioCi, margin_left + 135, 80);
    doc.text(registro.beneficiarioSexo, margin_left + 30, 85);
    doc.text(registro.beneficiarioPais, margin_left + 135, 85);
    doc.text(registro.beneficiarioParentesco, margin_left + 30, 90);

    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 94, docWidth - (margin_left - 3), 94);

    doc.line(margin_left - 3, 50, margin_left - 3, 94);
    doc.line(
      docWidth - (margin_left - 3),
      50,
      docWidth - (margin_left - 3),
      94
    );

    doc.line(margin_left - 3, 95, docWidth - (margin_left - 3), 95);
    doc.setFont("helvetica", "bold");
    doc.text(
      "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
      docWidth / 2,
      100,
      { align: "center" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "Yo, " +
      dataInfoNegocio.nombreCliente +
      " con documento de identificación Nº " +
      dataInfoNegocio.cedula +
      " declaro que ( " +

      (cheked.pep_1 === true ? "SI" : "NO") +
      " ) soy una Persona Expuesta Políticamente (PEP'S).",
      margin_left,
      105,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      "Nota: De acuerdo con el Reglamento de la Ley Orgánica de Prevención Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, Personas Expuestas Políticamente (PEP´s) Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.",
      margin_left,
      115,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "En el caso que la respuesta sea positiva, indicar:",
      margin_left,
      135
    );
    doc.text("Cargo o Función:", margin_left, 140);
    doc.text("Fecha Nombramiento o Finalización:", margin_left, 145);

    doc.setFont("helvetica", "bold");
    doc.text(registro.pep1Cargo, margin_left + 50, 140);
    doc.text(registro.pep1Fecha, margin_left + 50, 145);

    doc.setFont("helvetica", "normal");

    doc.text(
      "Adicionalmente, declaro que ( " +
      (cheked.pep_2 === true ? "SI" : "NO") +
      " ) mantengo algun tipo de relación Familiar y con Personas Relacionadas de las Personas Expuestas Políticamente:",
      margin_left,
      150,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      "Se consideran como familiares y personas relacionadas de los PEPS que tengan relaciones comerciales o contractuales con los sujetos obligados, al cónyuge o las personas unidas bajo el régimen de unión de hecho reconocido legalmente o, los familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad, o las personas con las cuales se encuentre asociada o vinculada societariamente, o sus colaboradores cercanos.",
      margin_left,
      160,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "En el caso que la respuesta sea afirmativa, indicar el tipo de relación:",
      margin_left,
      175
    );
    doc.text("Tipo de Relación con el Cliente:", margin_left, 180);
    doc.text("que mantiene con:", margin_left, 185);
    doc.text("Nombre:", margin_left, 190);
    doc.text("Cargo o Función:", margin_left, 195);
    doc.text("Fecha Nombramiento o Finalización:", margin_left, 200);

    doc.setFont("helvetica", "bold");
    doc.text("", margin_left + 50, 180);
    doc.text("", margin_left + 50, 185);
    doc.text(registro.pep2Nombre, margin_left + 50, 190);
    doc.text(registro.pep2Cargo, margin_left + 50, 195);
    doc.text(registro.pep2Fecha, margin_left + 50, 200);

    doc.setFont("helvetica", "normal");
    doc.text(
      "Nota: La presente declaración no constituye una autoincriminación de ninguna clase, ni conlleva ninguna clase de responsabilidad administrativa, civil o penal. La empresa solicita la presente declaración en cumplimiento de la normativa vigente en Materia de Prevención, Detección, Erradicación del",
      margin_left,
      205,
      { maxWidth: 170, align: "justify" }
    );

    doc.line(margin_left - 3, 214, docWidth - (margin_left - 3), 214);

    doc.line(margin_left - 3, 95, margin_left - 3, 214);
    doc.line(
      docWidth - (margin_left - 3),
      95,
      docWidth - (margin_left - 3),
      214
    );

    doc.line(margin_left - 3, 215, docWidth - (margin_left - 3), 215);

    doc.setFontSize(6);
    doc.text(
      "En caso de que hubiese cambiado cualquier información de este formulario, me",
      margin_left,
      218
    );
    doc.text("comprometo en actualizarla.", margin_left, 221);
    doc.text(
      "Declaro que toda la información arriba detallada es verdadera.",
      margin_left,
      224
    );

    doc.line(margin_left, 235, margin_left + 80, 235);
    doc.setFontSize(8);
    doc.text("FIRMA DEL CLIENTE", margin_left + 10, 238);

    doc.setFont("helvetica", "bold");
    doc.text("USO EXCLUSIVO DE", margin_left + 115, 218);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(6);
    doc.text(
      "Certifico/certificamos que la firma que antecede concuerda con la registrada en",
      margin_left + 90,
      223
    );
    doc.text(
      "nuestros documentos y fue registrada en mi presencia.",
      margin_left + 90,
      226
    );
    //doc.line(margin_left + 90, 235, docWidth - margin_left, 235);
    doc.setFontSize(8);
    doc.text(name, 148, 233, { align: "center" });
    doc.text("ASESOR", 148, 238, { align: "center" });

    doc.line(margin_left - 3, 240, docWidth - (margin_left - 3), 240);

    doc.line(margin_left - 3, 215, margin_left - 3, 240);
    doc.line(
      docWidth - (margin_left - 3),
      215,
      docWidth - (margin_left - 3),
      240
    );

    doc.setFont("helvetica", "bold");
    doc.text("COPIA CÉDULA + PAPELETA VOTACIÓN", margin_left, 250);
    doc.text("PLANILLA SERVICIO BASICO", margin_left, 260);

    //ejemplo 45 es 255
    //doc.line(inicia, altura, termina, altura);

    //horizontales
    doc.line(margin_left + 60, 246, margin_left + 65, 246);
    doc.line(margin_left + 60, 251, margin_left + 65, 251);

    doc.line(margin_left + 60, 256, margin_left + 65, 256);
    doc.line(margin_left + 60, 261, margin_left + 65, 261);

    //verticales
    doc.line(margin_left + 60, 246, margin_left + 60, 251);
    doc.line(margin_left + 65, 246, margin_left + 65, 251);

    doc.line(margin_left + 60, 256, margin_left + 60, 261);
    doc.line(margin_left + 65, 256, margin_left + 65, 261);

    doc.text("ANEXO A LA HOJA DE NEGOCIO N°", margin_left, 270);
    doc.text(
      "F.I.: " + new Date(Date.now()).toISOString().substring(0, 10),
      margin_left ,
      275
    );

    doc.text("Página 2 de 2", 168, 274);
    doc.text("0110IXVI24", 171, 278);

    var qrImg = new Image();
    qrImg.src = qrImagen; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(qrImg, "PNG", margin_left + 120, 242, 25, 25); // Agregar la imagen al PDF (X, Y, Width, Height)


// datos de qr ya fueron declarados al final de la hoja 1

const qrCodeText2 = "Documento: Conozca a su cliente\nFecha: " + dateText + "\nHora: " + formattedTimeText + "\nUsuario: " + username + "\nN° Impresión: 1\nPágina 2/2";
const qrCodeDataUrl2 = await QRCode.toDataURL(qrCodeText2);
    doc.addImage(qrCodeDataUrl2, 'PNG', margin_left + 145, 239, 30, 30);



    // doc.save(`${data && data.cedulaCliente}` + ".pdf");
    doc.save("CON_CLI_" + dataInfoNegocio.cedula + ".pdf");
  }

  async function guardaPDF_Juridica(registro: TablaRentaConozcaType) {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const margin_left = 20;
    const inicio_pdf = 50;

    //doc.addImage()

    doc.setFont("helvetica", "bold");

    var logo = new Image();
    logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(10);
    doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
    doc.text("FORMULARIO CONOZCA A SU CLIENTE", docWidth / 2, 19, {
      align: "center",
    });
    doc.text("PERSONA JURIDICA", docWidth / 2, 24, { align: "center" });
    doc.setFontSize(8);
    doc.text("Código", 170, 19);
    doc.text("RG-GCC-VC-01", 166, 23);
    doc.text("Versión: 1.0", 168, 27);
    doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

    doc.line(margin_left - 3, 14, margin_left - 3, 29);
    doc.line(
      docWidth - (margin_left - 3),
      14,
      docWidth - (margin_left - 3),
      29
    );

    doc.line(margin_left - 3, 30, docWidth - (margin_left - 3), 30);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(8);
    doc.text(
      "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
      margin_left,
      35,
      { maxWidth: 170, align: "justify" }
    );

    doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

    doc.line(margin_left - 3, 30, margin_left - 3, 49);
    doc.line(
      docWidth - (margin_left - 3),
      30,
      docWidth - (margin_left - 3),
      49
    );

    doc.line(margin_left - 3, 55, docWidth - (margin_left - 3), 55);
    doc.setFont("helvetica", "bold");
    doc.text("DATOS DE LA EMPRESA", docWidth / 2, 60, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("RUC:", margin_left, 65);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.cedula), margin_left + 40, 65);
    doc.setFont("helvetica", "normal");
    doc.text("NACIONALIDAD:", margin_left + 110, 65);
    doc.setFont("helvetica", "bold");
    doc.text("ECUADOR", margin_left + 135, 65);
    doc.setFont("helvetica", "normal");
    doc.text("RAZON SOCIAL:", margin_left, 70);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 40, 70);
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRE COMERCIAL:", margin_left, 75);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 40, 75);
    doc.setFont("helvetica", "normal");
    doc.text("ACTIVIDAD ECONOMICA:", margin_left, 80);
    doc.setFont("helvetica", "bold");
    doc.text(obtenerAtributoPorId(dataConozca.combo7), margin_left + 40, 80); //aki actividad
    doc.setFont("helvetica", "normal");
    doc.text("CORREO ELECTRONICO:", margin_left, 85);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.correo), margin_left + 40, 85);
    doc.setFont("helvetica", "normal");
    doc.text("PROVINCIA:", margin_left, 90);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.provincia), margin_left + 40, 90);
    doc.setFont("helvetica", "normal");
    doc.text("CIUDAD:", margin_left, 95);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.ciudad), margin_left + 40, 95);
    doc.setFont("helvetica", "normal");
    doc.text("TELÉFONOS:", margin_left + 110, 95);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.telefono), margin_left + 135, 95);
    doc.setFont("helvetica", "normal");
    doc.text("DIRECCIÓN:", margin_left, 100);
    doc.setFont("helvetica", "bold");
    doc.text(String(dataInfoNegocio.direccion), margin_left + 40, 100);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 104, docWidth - (margin_left - 3), 104);

    doc.line(margin_left - 3, 55, margin_left - 3, 104);
    doc.line(
      docWidth - (margin_left - 3),
      55,
      docWidth - (margin_left - 3),
      104
    );

    doc.line(margin_left - 3, 105, docWidth - (margin_left - 3), 105);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN DEL PRESENTANTE LEGAL", docWidth / 2, 110, {
      align: "center",
    });
    doc.setFont("helvetica", "normal");
    doc.text("CI / PASAPORTE:", margin_left, 115);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_cedula, margin_left + 40, 115);
    doc.setFont("helvetica", "normal");
    doc.text("NACIONALIDAD", margin_left + 110, 115);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_nacionalidad, margin_left + 135, 115);
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRES:", margin_left, 120);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_nombres, margin_left + 40, 120);
    doc.setFont("helvetica", "normal");
    doc.text("CORREO ELECTRÓNICO:", margin_left, 125);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_correo, margin_left + 40, 125);
    doc.setFont("helvetica", "normal");
    doc.text("ESTADO CIVÍL:", margin_left, 130);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_civil, margin_left + 40, 130);
    doc.setFont("helvetica", "normal");
    doc.text("SEXO", margin_left + 110, 130);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_sexo, margin_left + 135, 130);
    doc.setFont("helvetica", "normal");
    doc.text("CI/PASAPORTE CONYUGE:", margin_left, 135);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_cedula_cony, margin_left + 40, 135);
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRE CONYUGE:", margin_left, 140);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_nombres_cony, margin_left + 40, 140);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 144, docWidth - (margin_left - 3), 144);

    doc.line(margin_left - 3, 105, margin_left - 3, 144);
    doc.line(
      docWidth - (margin_left - 3),
      105,
      docWidth - (margin_left - 3),
      144
    );

    doc.line(margin_left - 3, 145, docWidth - (margin_left - 3), 145);
    doc.setFont("helvetica", "bold");
    doc.text("DOMICILIO DEL REPRESENTANTE LEGAL", docWidth / 2, 150, {
      align: "center",
    });
    doc.setFont("helvetica", "normal");
    doc.text("PROVINCIA:", margin_left, 155);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_provincia, margin_left + 40, 155);
    doc.setFont("helvetica", "normal");
    doc.text("CIUDAD:", margin_left, 160);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_ciudad, margin_left + 40, 160);
    doc.setFont("helvetica", "normal");
    doc.text("TELÉFONO:", margin_left + 110, 160);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_telefono, margin_left + 135, 160);
    doc.setFont("helvetica", "normal");
    doc.text("DIRECCIÓN:", margin_left, 165);
    doc.setFont("helvetica", "bold");
    doc.text(registro.repre_direccion, margin_left + 40, 165);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 169, docWidth - (margin_left - 3), 169);

    doc.line(margin_left - 3, 145, margin_left - 3, 169);
    doc.line(
      docWidth - (margin_left - 3),
      145,
      docWidth - (margin_left - 3),
      169
    );

    doc.line(margin_left - 3, 170, docWidth - (margin_left - 3), 170);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN ECONÓMICA", docWidth / 2, 175, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("Estimados mensuales:", margin_left, 180);
    doc.text("INGRESOS:", margin_left + 10, 185);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliIngresos), margin_left + 40, 185);
    doc.setFont("helvetica", "normal");
    doc.text("ACTIVOS:", margin_left + 110, 185);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliActivos), margin_left + 135, 185);
    doc.setFont("helvetica", "normal");
    doc.text("EGRESOS:", margin_left + 10, 190);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliEgresos), margin_left + 40, 190);
    doc.setFont("helvetica", "normal");
    doc.text("PASIVOS:", margin_left + 110, 190);
    doc.setFont("helvetica", "bold");
    doc.text("$ " + String(registro.cliPasivos), margin_left + 135, 190);
    doc.setFont("helvetica", "normal");
    doc.text("PATRIMONIO:", margin_left + 110, 195);
    doc.setFont("helvetica", "bold");
    doc.text(
      "$ " +
      Number(
        Number(registro.cliActivos) - Number(registro.cliPasivos)
      ).toFixed(2),
      margin_left + 135,
      195
    );
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 199, docWidth - (margin_left - 3), 199);

    doc.line(margin_left - 3, 170, margin_left - 3, 199);
    doc.line(
      docWidth - (margin_left - 3),
      170,
      docWidth - (margin_left - 3),
      199
    );

    doc.line(margin_left - 3, 200, docWidth - (margin_left - 3), 200);

    doc.setFont("helvetica", "bold");
    doc.text("ACCIONISTAS Y/O APODERADOS", docWidth / 2, 203, {
      align: "center",
    });
    doc.setFontSize(6);
    // cabecera
    doc.text("CI / RUC", margin_left, 208);
    doc.text("NOMBRES", margin_left + 20, 208);
    doc.text("%PARTICIP", margin_left + 70, 208);
    doc.text("NACIONALIDAD", margin_left + 95, 208);
    doc.text("ACTIVIDAD ECONÓMICA", margin_left + 115, 208);
    doc.text("CARGO", margin_left + 150, 208);
    doc.setFont("helvetica", "normal");

    //CI / RUC
    doc.text(registro.acc_cedula1, margin_left, 212);
    doc.text(registro.acc_cedula2, margin_left, 216);
    doc.text(registro.acc_cedula3, margin_left, 220);

    //NOMBRES
    doc.text(registro.acc_nombres1, margin_left + 20, 212);
    doc.text(registro.acc_nombres2, margin_left + 20, 216);
    doc.text(registro.acc_nombres3, margin_left + 20, 220);

    //PARTICIPACION
    doc.text(String(registro.acc_participacion1) + " %", margin_left + 70, 212);
    doc.text(String(registro.acc_participacion2) + " %", margin_left + 70, 216);
    doc.text(String(registro.acc_participacion3) + " %", margin_left + 70, 220);

    //NACIONALIDAD
    doc.text(registro.acc_nacionalidad1, margin_left + 95, 212);
    doc.text(registro.acc_nacionalidad2, margin_left + 95, 216);
    doc.text(registro.acc_nacionalidad3, margin_left + 95, 220);

    //ACTIVIDAD
    doc.text(registro.acc_actividad1, margin_left + 115, 212);
    doc.text(registro.acc_actividad2, margin_left + 115, 216);
    doc.text(registro.acc_actividad3, margin_left + 115, 220);

    //CARGO
    doc.text(registro.acc_cargo1, margin_left + 150, 212);
    doc.text(registro.acc_cargo2, margin_left + 150, 216);
    doc.text(registro.acc_cargo3, margin_left + 150, 220);

    doc.line(margin_left - 3, 222, docWidth - (margin_left - 3), 222);
    doc.line(margin_left - 3, 200, margin_left - 3, 222);
    doc.line(
      docWidth - (margin_left - 3),
      200,
      docWidth - (margin_left - 3),
      222
    );

    /////

    doc.line(margin_left - 3, 223, docWidth - (margin_left - 3), 223);
    doc.text(
      "Declaración: Con pleno valor vinculante y consciente de las responsabilidades que ello genera, EL CLIENTE declara (n) bajo juramento que los recursos y fondos que emplea en sus operaciones y transacciones comerciales, así como aquellos que utilizó (aron) para pagar el valor de del bien o servicio, han tenido, tienen y tendrán fuente y origen lícito, y que no han provenido, provienen o provendrán de operaciones o actividades reprimidas por la ley, especialmente no de aquellas reprimidas por la legislación sobre sustancias estupefacientes y psicotrópicas",
      margin_left,
      226,
      { maxWidth: 170, align: "justify" }
    );

    doc.setFontSize(8);
    doc.text("Los fondos de esta transacción proceden de:", margin_left, 238);
    doc.setFont("helvetica", "bold");
    doc.text("AHORROS TRABAJO / PERSONAL", margin_left + 70, 238);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Los fondos de esta transacción serán usados para:",
      margin_left,
      242
    );
    doc.setFont("helvetica", "bold");
    doc.text(" COMPRA DE VEHÍCULO NUEVO", margin_left + 70, 242);
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 244, docWidth - (margin_left - 3), 244);

    doc.line(margin_left - 3, 223, margin_left - 3, 244);
    doc.line(
      docWidth - (margin_left - 3),
      223,
      docWidth - (margin_left - 3),
      244
    );

    doc.line(margin_left - 3, 245, docWidth - (margin_left - 3), 245);
    doc.setFont("helvetica", "bold");
    doc.text("TRANSACCIÓN", docWidth / 2, 248, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text("TIPO DE TRANSACCIÓN:", margin_left, 251);
    doc.setFont("helvetica", "bold");
    doc.text("COMPRA DE VEHÍCULO", margin_left + 40, 251);
    doc.setFont("helvetica", "normal");
    doc.text("MONEDA:", margin_left + 110, 251);
    doc.setFont("helvetica", "bold");
    doc.text("DOLAR", margin_left + 135, 251);
    doc.setFont("helvetica", "normal");
    doc.text("VALOR TRANSACCIÓN:", margin_left, 254);
    doc.setFont("helvetica", "bold");
    doc.text( "$ " + 
      Number(
        Number(registro.fpropiosUsd) +
        Number(registro.ffinancieraUsd) +
        Number(registro.cinternoUsd) +
        Number(registro.vusadoUsd) +
        Number(registro.ftercerosUsd)
      ).toFixed(2),
      margin_left + 40,
      254
    );
    doc.setFont("helvetica", "normal");
    doc.text("FECHA DE TRANSACCIÓN:", margin_left, 263);
    doc.setFont("helvetica", "bold");
    doc.text(
      new Date(Date.now()).toISOString().substring(0, 10),
      margin_left + 40,
      263
    );
    doc.setFont("helvetica", "normal");
    doc.text("DOCUMENTO:", margin_left, 257);
    doc.setFont("helvetica", "bold");
    doc.text("TABLA DE RENTABILIDAD: " + String(params.idcrm), margin_left + 40, 257);
    doc.setFont("helvetica", "normal");
    doc.text("DETALLE DEL PRODUCTO*:", margin_left, 260);
    doc.setFont("helvetica", "bold");
    doc.text(dataInfoNegocio.modelo, margin_left + 40, 260);
    doc.setFont("helvetica", "normal");
    doc.text("VIGENCIA:", margin_left , 266);
    doc.setFont("helvetica", "bold");
    doc.text("MENSUAL", margin_left + 40, 266);
    doc.setFont("helvetica", "normal");
    doc.text(
      "*Detalle del modelo del vehículo o el repuesto y/o servicio.",
      margin_left,
      271
    );

    doc.line(margin_left - 3, 273, docWidth - (margin_left - 3), 273);

    doc.line(margin_left - 3, 244, margin_left - 3, 273);
    doc.line(
      docWidth - (margin_left - 3),
      244,
      docWidth - (margin_left - 3),
      273
    );

    const now = new Date(Date.now() - 5 * 60 * 60 * 1000); // Restar 5 horas
    // Formatear la fecha y hora
    const isoString = now.toISOString();
    const [dateText, timeText] = isoString.split('T');
    const formattedTimeText = timeText.split('.')[0];

    const qrCodeText = "Documento: Conozca a su cliente\nFecha: " + dateText + "\nHora: " + formattedTimeText + "\nUsuario: " + username + "\nN° Impresión: 1\nPágina 1/2";
    const qrCodeDataUrl = await QRCode.toDataURL(qrCodeText);
    doc.addImage(qrCodeDataUrl, 'PNG', margin_left + 145, 254, 30, 30);

    ///////////////////////// HOJA 2 ////////////////////////////////////
    doc.addPage("a4");
    doc.line(margin_left - 3, 15, docWidth - (margin_left - 3), 15);

    doc.setFont("helvetica", "bold");
    doc.text("PROCEDENCIA DE FONDOS", docWidth / 2, 18, { align: "center" });
    doc.text("FONDOS", margin_left, 23);
    doc.text("VALOR", margin_left + 60, 23);
    doc.text("% VALOR", margin_left + 90, 23);
    doc.text("DETALLE", margin_left + 130, 23);
    doc.setFont("helvetica", "normal");

    //FONDOS
    doc.text("FONDOS PROPIOS", margin_left, 27);
    doc.text("FONDOS FINANCIERA", margin_left, 31);
    doc.text("CREDITO INTERNO", margin_left, 35);
    doc.text("TOMA DE VEHÍCULO", margin_left, 39);
    doc.text("FONDOS DE TERCEROS", margin_left, 43);
    doc.setFont("helvetica", "bold");
    doc.text("TOTAL", margin_left, 47);
    doc.setFont("helvetica", "normal");

    //FONDOS
    doc.text(
      "$ " + String(Number(registro.fpropiosUsd).toFixed(2)),
      margin_left + 60,
      27
    );
    doc.text(
      "$ " + String(Number(registro.ffinancieraUsd).toFixed(2)),
      margin_left + 60,
      31
    );
    doc.text(
      "$ " + String(Number(registro.cinternoUsd).toFixed(2)),
      margin_left + 60,
      35
    );
    doc.text("$ " + String(registro.vusadoUsd), margin_left + 60, 39);
    doc.text("$ " + String(registro.ftercerosUsd), margin_left + 60, 43);
    doc.setFont("helvetica", "bold");
    doc.text(
      "$ " +
      String(
        Number(registro.fpropiosUsd) +
        Number(registro.ffinancieraUsd) +
        Number(registro.cinternoUsd) +
        Number(registro.vusadoUsd) +
        Number(registro.ftercerosUsd)
      ),
      margin_left + 60,
      47
    );
    doc.setFont("helvetica", "normal");

    //VALOR
    doc.text(String(registro.fpropiosPorce) + " %", margin_left + 90, 27);
    doc.text(String(registro.ffinancieraPorce) + " %", margin_left + 90, 31);
    doc.text(String(registro.cinternoPorce) + " %", margin_left + 90, 35);
    doc.text(String(registro.vusadoPorce) + " %", margin_left + 90, 39);
    doc.text(String(registro.ftercerosPorce) + " %", margin_left + 90, 43);
    doc.setFont("helvetica", "bold");
    doc.text("100 %", margin_left + 90, 47);
    doc.setFont("helvetica", "normal");

    //DETALLE
    doc.text(registro.fpropiosDetalle, margin_left + 130, 27);
    doc.text(registro.ffinancieraDetalle, margin_left + 130, 31);
    doc.text(registro.cinternoDetalle, margin_left + 130, 35);
    doc.text(registro.vusadoDetalle, margin_left + 130, 39);
    doc.text(registro.ftercerosDetalle, margin_left + 130, 43);

    doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

    doc.line(margin_left - 3, 15, margin_left - 3, 49);
    doc.line(
      docWidth - (margin_left - 3),
      15,
      docWidth - (margin_left - 3),
      49
    );

    doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

    doc.setFont("helvetica", "bold");
    doc.text("BENEFICIARIO FINAL", docWidth / 2, 55, { align: "center" });
    doc.setFont("helvetica", "normal");
    doc.text(
      "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también las personas naturales",
      margin_left,
      60,
      { maxWidth: 170, align: "justify" }
    );
    doc.text(
      "¿Es la usted el beneficiario final (propietario) del vehículo?",
      margin_left,
      70
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      cheked.beneficiario_concli === true ? "SI" : "NO",
      margin_left + 100,
      70
    );
    doc.setFont("helvetica", "normal");
    doc.setFont("helvetica", "bold");
    doc.text(
      "Si coloco NO en la pregunta anterior por favor detalle los datos del beneficiario final (propietario) del vehículo:",
      margin_left,
      75
    );
    doc.setFont("helvetica", "normal");
    doc.text("NOMBRE:", margin_left, 80);
    doc.text("N° IDENTIFICACIÓN:", margin_left + 100, 80);
    doc.text("SEXO:", margin_left, 85);
    doc.text("NACIONALIDAD:", margin_left + 100, 85);
    doc.text("PARENTESCO:", margin_left, 90);

    doc.setFont("helvetica", "bold");
    doc.text(registro.beneficiarioNombre, margin_left + 30, 80);
    doc.text(registro.beneficiarioCi, margin_left + 135, 80);
    doc.text(registro.beneficiarioSexo, margin_left + 30, 85);
    doc.text(registro.beneficiarioPais, margin_left + 135, 85);
    doc.text(registro.beneficiarioParentesco, margin_left + 30, 90);

    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 94, docWidth - (margin_left - 3), 94);

    doc.line(margin_left - 3, 50, margin_left - 3, 94);
    doc.line(
      docWidth - (margin_left - 3),
      50,
      docWidth - (margin_left - 3),
      94
    );

    doc.line(margin_left - 3, 95, docWidth - (margin_left - 3), 95);
    doc.setFont("helvetica", "bold");
    doc.text(
      "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
      docWidth / 2,
      100,
      { align: "center" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "Yo, " +
      dataInfoNegocio.nombreCliente +
      " con documento de identificación Nº " +
      dataInfoNegocio.cedula +
      " declaro que ( " +
      (cheked.pep_1 === true ? "SI" : "NO") +
      " ) soy una Persona Expuesta Políticamente (PEP'S).",
      margin_left,
      105,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      "Nota: De acuerdo con el Reglamento de la Ley Orgánica de Prevención Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, Personas Expuestas Políticamente (PEP´s) Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.",
      margin_left,
      115,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "En el caso que la respuesta sea positiva, indicar:",
      margin_left,
      135
    );
    doc.text("Cargo o Función:", margin_left, 140);
    doc.text("Fecha Nombramiento o Finalización:", margin_left, 145);

    doc.setFont("helvetica", "bold");
    doc.text(registro.pep1Cargo, margin_left + 50, 140);
    doc.text(registro.pep1Fecha, margin_left + 50, 145);

    doc.setFont("helvetica", "normal");

    doc.text(
      "Adicionalmente, declaro que ( " +
      (cheked.pep_2 === true ? "SI" : "NO") +
      " ) mantengo algun tipo de relación Familiar y con Personas Relacionadas de las Personas Expuestas Políticamente:",
      margin_left,
      150,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "bold");
    doc.text(
      "Se consideran como familiares y personas relacionadas de los PEPS que tengan relaciones comerciales o contractuales con los sujetos obligados, al cónyuge o las personas unidas bajo el régimen de unión de hecho reconocido legalmente o, los familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad, o las personas con las cuales se encuentre asociada o vinculada societariamente, o sus colaboradores cercanos.",
      margin_left,
      160,
      { maxWidth: 170, align: "justify" }
    );
    doc.setFont("helvetica", "normal");
    doc.text(
      "En el caso que la respuesta sea afirmativa, indicar el tipo de relación:",
      margin_left,
      175
    );
    doc.text("Tipo de Relación con el Cliente:", margin_left, 180);
    doc.text("que mantiene con:", margin_left, 185);
    doc.text("Nombre:", margin_left, 190);
    doc.text("Cargo o Función:", margin_left, 195);
    doc.text("Fecha Nombramiento o Finalización:", margin_left, 200);

    doc.setFont("helvetica", "bold");
    doc.text("", margin_left + 50, 180);
    doc.text("", margin_left + 50, 185);
    doc.text(registro.pep2Nombre, margin_left + 50, 190);
    doc.text(registro.pep2Cargo, margin_left + 50, 195);
    doc.text(registro.pep2Fecha, margin_left + 50, 200);

    doc.setFont("helvetica", "normal");
    doc.text(
      "Nota: La presente declaración no constituye una autoincriminación de ninguna clase, ni conlleva ninguna clase de responsabilidad administrativa, civil o penal. La empresa solicita la presente declaración en cumplimiento de la normativa vigente en Materia de Prevención, Detección, Erradicación del",
      margin_left,
      205,
      { maxWidth: 170, align: "justify" }
    );

    doc.line(margin_left - 3, 214, docWidth - (margin_left - 3), 214);

    doc.line(margin_left - 3, 95, margin_left - 3, 214);
    doc.line(
      docWidth - (margin_left - 3),
      95,
      docWidth - (margin_left - 3),
      214
    );

    doc.line(margin_left - 3, 215, docWidth - (margin_left - 3), 215);

    doc.setFontSize(6);
    doc.text(
      "En caso de que hubiese cambiado cualquier información de este formulario, me",
      margin_left,
      218
    );
    doc.text("comprometo en actualizarla.", margin_left, 221);
    doc.text(
      "Declaro que toda la información arriba detallada es verdadera.",
      margin_left,
      224
    );

    doc.line(margin_left, 235, margin_left + 80, 235);
    doc.setFontSize(8);
    doc.text("FIRMA DEL CLIENTE", margin_left + 10, 238);

    doc.setFont("helvetica", "bold");
    doc.text("USO EXCLUSIVO DE", margin_left + 115, 218);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(6);
    doc.text(
      "Certifico/certificamos que la firma que antecede concuerda con la registrada en",
      margin_left + 90,
      223
    );
    doc.text(
      "nuestros documentos y fue registrada en mi presencia.",
      margin_left + 90,
      226
    );

    doc.setFontSize(8);
    doc.text(name, 148, 233, { align: "center" });
    doc.text("ASESOR", 148, 238, { align: "center" });

    doc.line(margin_left - 3, 240, docWidth - (margin_left - 3), 240);

    doc.line(margin_left - 3, 215, margin_left - 3, 240);
    doc.line(
      docWidth - (margin_left - 3),
      215,
      docWidth - (margin_left - 3),
      240
    );

    ///////////////////////// HOJA 3 ////////////////////////////////////

    doc.setFont("helvetica", "bold");
    doc.text("COPIA CÉDULA + PAPELETA VOTACIÓN", margin_left, 250);
    doc.text("PLANILLA SERVICIO BASICO", margin_left, 260);

    //ejemplo 45 es 255
    //doc.line(inicia, altura, termina, altura);

    //horizontales
    doc.line(margin_left + 60, 246, margin_left + 65, 246);
    doc.line(margin_left + 60, 251, margin_left + 65, 251);

    doc.line(margin_left + 60, 256, margin_left + 65, 256);
    doc.line(margin_left + 60, 261, margin_left + 65, 261);

    //verticales
    doc.line(margin_left + 60, 246, margin_left + 60, 251);
    doc.line(margin_left + 65, 246, margin_left + 65, 251);

    doc.line(margin_left + 60, 256, margin_left + 60, 261);
    doc.line(margin_left + 65, 256, margin_left + 65, 261);

    doc.text("ANEXO A LA HOJA DE NEGOCIO N°", margin_left, 270);
    doc.text(
      "F.I.: " + new Date(Date.now()).toISOString().substring(0, 10),
      margin_left ,
      275
    );

    doc.text("Página 2 de 2", 168, 274);
    doc.text("0110IXVI24", 171, 278);

    var qrImg = new Image();
    qrImg.src = qrImagen; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(qrImg, "PNG", margin_left + 120, 242, 25, 25); // Agregar la imagen al PDF (X, Y, Width, Height)


// datos de qr ya fueron declarados al final de la hoja 1

const qrCodeText2 = "Documento: Conozca a su cliente\nFecha: " + dateText + "\nHora: " + formattedTimeText + "\nUsuario: " + username + "\nN° Impresión: 1\nPágina 2/2";
const qrCodeDataUrl2 = await QRCode.toDataURL(qrCodeText2);
    doc.addImage(qrCodeDataUrl2, 'PNG', margin_left + 145, 239, 30, 30);

    // doc.save(`${data && data.cedulaCliente}` + ".pdf");
    doc.save("CON_CLI_" + dataInfoNegocio.cedula + ".pdf");
  }

  // function guardaPDF(registro: TablaRentaConozcaType) {
  //   const doc = new jsPDF();
  //   const docWidth = doc.internal.pageSize.getWidth();
  //   const docHeight = doc.internal.pageSize.getHeight();
  //   const margin_left = 20;
  //   const inicio_pdf = 50;

  //   //doc.addImage()

  //   doc.setFont("helvetica", "bold");

  //   var logo = new Image();
  //   logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
  //   doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

  //   doc.setFontSize(10);
  //   doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
  //   doc.text("FORMULARIO CONOZCA A SU CLIENTE", docWidth / 2, 19, {
  //     align: "center",
  //   });
  //   doc.text("PERSONA NATURAL", docWidth / 2, 24, { align: "center" });
  //   doc.setFontSize(8);
  //   doc.text("Código", 170, 19);
  //   doc.text("RG-GCC-VC-01", 166, 23);
  //   doc.text("Versión: 1.0", 168, 27);
  //   doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

  //   doc.line(margin_left - 3, 14, margin_left - 3, 29);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     14,
  //     docWidth - (margin_left - 3),
  //     29
  //   );

  //   doc.line(margin_left - 3, 30, docWidth - (margin_left - 3), 30);

  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(8);
  //   doc.text(
  //     "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
  //     margin_left,
  //     35,
  //     { maxWidth: 170, align: "justify" }
  //   );

  //   doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  //   doc.line(margin_left - 3, 30, margin_left - 3, 49);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     30,
  //     docWidth - (margin_left - 3),
  //     49
  //   );

  //   doc.line(margin_left - 3, 55, docWidth - (margin_left - 3), 55);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("DATOS PERSONALES", docWidth / 2, 60, { align: "center" });
  //   doc.setFont("helvetica", "normal");
  //   doc.text("C.I.:/PASAPORTE/RUC:", margin_left, 65);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.cedula), margin_left + 40, 65);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("NOMBRES Y APELLIDOS:", margin_left, 70);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(dataInfoNegocio.nombreCliente, margin_left + 40, 70);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("NACIONALIDAD:", margin_left, 75);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(dataInfoNegocio.nacionalidad, margin_left + 40, 75);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("SEXO:", margin_left + 110, 75);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.genero), margin_left + 135, 75);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("ESTADO CIVIL:", margin_left, 80);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(dataInfoNegocio.civilstatus, margin_left + 40, 80);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("APELLIDOS Y NOMBRES DEL CÓNYUGE:", margin_left, 85);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(dataInfoNegocio.nombreConyuge, margin_left + 60, 85);
  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 89, docWidth - (margin_left - 3), 89);

  //   doc.line(margin_left - 3, 55, margin_left - 3, 89);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     55,
  //     docWidth - (margin_left - 3),
  //     89
  //   );

  //   doc.line(margin_left - 3, 90, docWidth - (margin_left - 3), 90);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("INFORMACIÓN DEL DOMICILIO", docWidth / 2, 95, {
  //     align: "center",
  //   });
  //   doc.setFont("helvetica", "normal");
  //   doc.text("PROVINCIA:", margin_left, 100);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.provincia), margin_left + 40, 100);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("CIUDAD:", margin_left + 110, 100);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.ciudad), margin_left + 135, 100);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("DIRECCIÓN:", margin_left, 105);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.direccion), margin_left + 40, 105);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("TELÉFONO/CELULAR:", margin_left, 110);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.telefono), margin_left + 40, 110);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("E-MAIL PERSONAL:", margin_left, 115);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.correo), margin_left + 40, 115);
  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 119, docWidth - (margin_left - 3), 119);

  //   doc.line(margin_left - 3, 90, margin_left - 3, 119);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     90,
  //     docWidth - (margin_left - 3),
  //     119
  //   );

  //   doc.line(margin_left - 3, 120, docWidth - (margin_left - 3), 120);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("INFORMACIÓN LABORAL", docWidth / 2, 125, { align: "center" });
  //   doc.setFont("helvetica", "normal");
  //   doc.text("LUGAR DE TRABAJO:", margin_left, 130);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.lugarTrabajo), margin_left + 40, 130);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("ACTIVIDAD ECONÓMICA:", margin_left, 135);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(obtenerAtributoPorId(dataConozca.combo7), margin_left + 40, 135); //aki actividad
  //   doc.setFont("helvetica", "normal");
  //   doc.text("CARGO:", margin_left, 140);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.cargo), margin_left + 40, 140);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("PROVINCIA:", margin_left, 145);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.provincia), margin_left + 40, 145);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("CIUDAD:", margin_left + 110, 145);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.ciudad), margin_left + 135, 145);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("DIRECCIÓN:", margin_left, 150);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.direccionEmpresa), margin_left + 40, 150);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("E-MAIL EMPRESAR:", margin_left, 155);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.correo), margin_left + 40, 155);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("TELÉFONO:", margin_left + 110, 155);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.telefonoEmpresa), margin_left + 135, 155);
  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 159, docWidth - (margin_left - 3), 159);

  //   doc.line(margin_left - 3, 120, margin_left - 3, 159);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     120,
  //     docWidth - (margin_left - 3),
  //     159
  //   );

  //   doc.line(margin_left - 3, 160, docWidth - (margin_left - 3), 160);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("INFORMACIÓN ECONÓMICA", docWidth / 2, 165, { align: "center" });
  //   doc.setFont("helvetica", "normal");
  //   doc.text("Estimados mensuales:", margin_left, 170);
  //   doc.text("INGRESOS:", margin_left + 10, 175);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("$ " + String(registro.cliIngresos), margin_left + 40, 175);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("ACTIVOS:", margin_left + 110, 175);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("$ " + String(registro.cliActivos), margin_left + 135, 175);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("EGRESOS:", margin_left + 10, 180);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("$ " + String(registro.cliEgresos), margin_left + 40, 180);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("PASIVOS:", margin_left + 110, 180);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("$ " + String(registro.cliPasivos), margin_left + 135, 180);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("PATRIMONIO:", margin_left + 110, 185);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "$ " +
  //       Number(
  //         Number(registro.cliActivos) - Number(registro.cliPasivos)
  //       ).toFixed(2),
  //     margin_left + 135,
  //     185
  //   );
  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 189, docWidth - (margin_left - 3), 189);

  //   doc.line(margin_left - 3, 160, margin_left - 3, 189);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     160,
  //     docWidth - (margin_left - 3),
  //     189
  //   );

  //   doc.line(margin_left - 3, 190, docWidth - (margin_left - 3), 190);

  //   doc.text(
  //     "Declaración: Con pleno valor vinculante y consciente de las responsabilidades que ello genera, EL CLIENTE declara (n) bajo juramento que los recursos y fondos que emplea en sus operaciones y transacciones comerciales, así como aquellos que utilizó (aron) para pagar el valor de del bien o servicio, han tenido, tienen y tendrán fuente y origen lícito, y que no han provenido, provienen o provendrán de operaciones o actividades reprimidas por la ley, especialmente no de aquellas reprimidas por la legislación sobre sustancias estupefacientes y psicotrópicas",
  //     margin_left,
  //     195,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.text("Los fondos de esta transacción proceden de:", margin_left, 215);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("AHORROS TRABAJO / PERSONAL", margin_left + 70, 215);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "Los fondos de esta transacción serán usados para:",
  //     margin_left,
  //     220
  //   );
  //   doc.setFont("helvetica", "bold");
  //   doc.text(" COMPRA DE VEHÍCULO NUEVO", margin_left + 70, 220);
  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 224, docWidth - (margin_left - 3), 224);

  //   doc.line(margin_left - 3, 190, margin_left - 3, 224);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     190,
  //     docWidth - (margin_left - 3),
  //     224
  //   );

  //   doc.line(margin_left - 3, 225, docWidth - (margin_left - 3), 225);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("TRANSACCIÓN", docWidth / 2, 230, { align: "center" });
  //   doc.setFont("helvetica", "normal");
  //   doc.text("TIPO DE TRANSACCIÓN:", margin_left, 235);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("COMPRA DE VEHÍCULO", margin_left + 40, 235);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("MONEDA:", margin_left + 110, 235);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("DOLAR", margin_left + 135, 235);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("VALOR TRANSACCIÓN:", margin_left, 240);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     Number(
  //       Number(registro.fpropiosUsd) +
  //         Number(registro.ffinancieraUsd) +
  //         Number(registro.cinternoUsd) +
  //         Number(registro.vusadoUsd) +
  //         Number(registro.ftercerosUsd)
  //     ).toFixed(2),
  //     margin_left + 40,
  //     240
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text("FECHA DE TRANSACCIÓN:", margin_left + 110, 240);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     new Date(Date.now()).toISOString().substring(0, 10),
  //     margin_left + 150,
  //     240
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text("DOCUMENTO:", margin_left, 245);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("TABLA DE RENTABILIDAD", margin_left + 40, 245);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("N° DOCUMENTO:", margin_left + 110, 245);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(params.idcrm), margin_left + 135, 245);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("DETALLE DEL PRODUCTO*:", margin_left, 250);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(String(dataInfoNegocio.modelo), margin_left + 40, 250);
  //   doc.setFont("helvetica", "normal");
  //   doc.text("VIGENCIA:", margin_left + 110, 250);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("MENSUAL", margin_left + 135, 250);
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "*Detalle del modelo del vehículo o el repuesto y/o servicio.",
  //     margin_left,
  //     255
  //   );

  //   doc.line(margin_left - 3, 259, docWidth - (margin_left - 3), 259);

  //   doc.line(margin_left - 3, 225, margin_left - 3, 259);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     225,
  //     docWidth - (margin_left - 3),
  //     259
  //   );

  //   ///////////////////////// HOJA 2 ////////////////////////////////////
  //   doc.addPage("a4");
  //   doc.line(margin_left - 3, 15, docWidth - (margin_left - 3), 15);

  //   doc.setFont("helvetica", "bold");
  //   doc.text("PROCEDENCIA DE FONDOS", docWidth / 2, 18, { align: "center" });
  //   doc.text("FONDOS", margin_left, 23);
  //   doc.text("VALOR", margin_left + 60, 23);
  //   doc.text("% VALOR", margin_left + 90, 23);
  //   doc.text("DETALLE", margin_left + 130, 23);
  //   doc.setFont("helvetica", "normal");

  //   //FONDOS
  //   doc.text("FONDOS PROPIOS", margin_left, 27);
  //   doc.text("FONDOS FINANCIERA", margin_left, 31);
  //   doc.text("CREDITO INTERNO", margin_left, 35);
  //   doc.text("TOMA DE VEHÍCULO", margin_left, 39);
  //   doc.text("FONDOS DE TERCEROS", margin_left, 43);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("TOTAL", margin_left, 47);
  //   doc.setFont("helvetica", "normal");

  //   //FONDOS
  //   doc.text(
  //     "$ " + String(Number(registro.fpropiosUsd).toFixed(2)),
  //     margin_left + 60,
  //     27
  //   );
  //   doc.text(
  //     "$ " + String(Number(registro.ffinancieraUsd).toFixed(2)),
  //     margin_left + 60,
  //     31
  //   );
  //   doc.text(
  //     "$ " + String(Number(registro.cinternoUsd).toFixed(2)),
  //     margin_left + 60,
  //     35
  //   );
  //   doc.text("$ " + String(registro.vusadoUsd), margin_left + 60, 39);
  //   doc.text("$ " + String(registro.ftercerosUsd), margin_left + 60, 43);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "$ " +
  //       String(
  //         Number(
  //           Number(registro.fpropiosUsd) +
  //             Number(registro.ffinancieraUsd) +
  //             Number(registro.cinternoUsd) +
  //             Number(registro.vusadoUsd) +
  //             Number(registro.ftercerosUsd)
  //         ).toFixed(2)
  //       ),
  //     margin_left + 60,
  //     47
  //   );
  //   doc.setFont("helvetica", "normal");

  //   //VALOR
  //   doc.text(String(registro.fpropiosPorce) + " %", margin_left + 90, 27);
  //   doc.text(String(registro.ffinancieraPorce) + " %", margin_left + 90, 31);
  //   doc.text(String(registro.cinternoPorce) + " %", margin_left + 90, 35);
  //   doc.text(String(registro.vusadoPorce) + " %", margin_left + 90, 39);
  //   doc.text(String(registro.ftercerosPorce) + " %", margin_left + 90, 43);
  //   doc.setFont("helvetica", "bold");
  //   doc.text("100 %", margin_left + 90, 47);
  //   doc.setFont("helvetica", "normal");

  //   //DETALLE
  //   doc.text(String(registro.fpropiosDetalle), margin_left + 130, 27);
  //   doc.text(String(registro.ffinancieraDetalle), margin_left + 130, 31);
  //   doc.text(String(registro.cinternoDetalle), margin_left + 130, 35);
  //   doc.text(String(registro.vusadoDetalle), margin_left + 130, 39);
  //   doc.text(String(registro.ftercerosDetalle), margin_left + 130, 43);

  //   doc.line(margin_left - 3, 49, docWidth - (margin_left - 3), 49);

  //   doc.line(margin_left - 3, 15, margin_left - 3, 49);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     15,
  //     docWidth - (margin_left - 3),
  //     49
  //   );

  //   doc.line(margin_left - 3, 50, docWidth - (margin_left - 3), 50);

  //   doc.setFont("helvetica", "bold");
  //   doc.text("BENEFICIARIO FINAL", docWidth / 2, 55, { align: "center" });
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "Es toda persona natural que finalmente posee o controla a un cliente y/o la persona natural en cuyo nombre se realiza la transacción. Incluye también las personas naturales",
  //     margin_left,
  //     60,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.text(
  //     "¿Es la usted el beneficiario final (propietario) del vehículo?",
  //     margin_left,
  //     70
  //   );
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     cheked.beneficiario_concli === true ? "SI" : "NO",
  //     margin_left + 100,
  //     70
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "Si coloco NO en la pregunta anterior por favor detalle los datos del beneficiario final (propietario) del vehículo:",
  //     margin_left,
  //     75
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text("NOMBRE:", margin_left, 80);
  //   doc.text("N° IDENTIFICACIÓN:", margin_left + 100, 80);
  //   doc.text("SEXO:", margin_left, 85);
  //   doc.text("NACIONALIDAD:", margin_left + 100, 85);
  //   doc.text("PARENTESCO:", margin_left, 90);

  //   doc.setFont("helvetica", "bold");
  //   doc.text(registro.beneficiarioNombre, margin_left + 30, 80);
  //   doc.text(registro.beneficiarioCi, margin_left + 135, 80);
  //   doc.text(registro.beneficiarioSexo, margin_left + 30, 85);
  //   doc.text(registro.beneficiarioPais, margin_left + 135, 85);
  //   doc.text(registro.beneficiarioParentesco, margin_left + 30, 90);

  //   doc.setFont("helvetica", "normal");

  //   doc.line(margin_left - 3, 94, docWidth - (margin_left - 3), 94);

  //   doc.line(margin_left - 3, 50, margin_left - 3, 94);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     50,
  //     docWidth - (margin_left - 3),
  //     94
  //   );

  //   doc.line(margin_left - 3, 95, docWidth - (margin_left - 3), 95);
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "DECLARACIÓN SOBRE LA CONDICIÓN DE PERSONA EXPUESTA POLÍTICAMENTE",
  //     docWidth / 2,
  //     100,
  //     { align: "center" }
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "Yo, " +
  //       dataInfoNegocio.nombreCliente +
  //       " con documento de identificación Nº " +
  //       dataInfoNegocio.cedula +
  //       " declaro que ( " +
  //       (registro.esPep1 === 1 ? "SI" : "NO") +
  //       " ) soy una Persona Expuesta Políticamente (PEP'S).",
  //     margin_left,
  //     105,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "Nota: De acuerdo con el Reglamento de la Ley Orgánica de Prevención Detección y Erradicación del Delito de Lavado de Activos y del Financiamiento de Delitos, Personas Expuestas Políticamente (PEP´s) Son todas aquellas personas naturales, nacionales o extranjeras, que desempeñan o han desempeñado funciones o cargos públicos destacados en el Ecuador o en el Extranjero; o funciones prominentes en una organización internacional, conforme a los lineamentos establecidos por la Unidad de Análisis Financiero y Económico (UAFE), y el organismo de control respectivo.",
  //     margin_left,
  //     115,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "En el caso que la respuesta sea positiva, indicar:",
  //     margin_left,
  //     135
  //   );
  //   doc.text("Cargo o Función:", margin_left, 140);
  //   doc.text("Fecha Nombramiento o Finalización:", margin_left, 145);

  //   doc.setFont("helvetica", "bold");
  //   doc.text(registro.pep1Cargo, margin_left + 50, 140);
  //   doc.text(registro.pep1Fecha, margin_left + 50, 145);

  //   doc.setFont("helvetica", "normal");

  //   doc.text(
  //     "Adicionalmente, declaro que ( " +
  //       (registro.esPep2 === 1 ? "SI" : "NO") +
  //       " ) mantengo algun tipo de relación Familiar y con Personas Relacionadas de las Personas Expuestas Políticamente:",
  //     margin_left,
  //     150,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.setFont("helvetica", "bold");
  //   doc.text(
  //     "Se consideran como familiares y personas relacionadas de los PEPS que tengan relaciones comerciales o contractuales con los sujetos obligados, al cónyuge o las personas unidas bajo el régimen de unión de hecho reconocido legalmente o, los familiares comprendidos hasta el segundo grado de consanguinidad o primero de afinidad, o las personas con las cuales se encuentre asociada o vinculada societariamente, o sus colaboradores cercanos.",
  //     margin_left,
  //     160,
  //     { maxWidth: 170, align: "justify" }
  //   );
  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "En el caso que la respuesta sea afirmativa, indicar el tipo de relación:",
  //     margin_left,
  //     175
  //   );
  //   doc.text("Tipo de Relación con el Cliente:", margin_left, 180);
  //   doc.text("que mantiene con:", margin_left, 185);
  //   doc.text("Nombre:", margin_left, 190);
  //   doc.text("Cargo o Función:", margin_left, 195);
  //   doc.text("Fecha Nombramiento o Finalización:", margin_left, 200);

  //   doc.setFont("helvetica", "bold");
  //   doc.text("", margin_left + 50, 180);
  //   doc.text("", margin_left + 50, 185);
  //   doc.text(registro.pep2Nombre, margin_left + 50, 190);
  //   doc.text(registro.pep2Cargo, margin_left + 50, 195);
  //   doc.text(registro.pep2Fecha, margin_left + 50, 200);

  //   doc.setFont("helvetica", "normal");
  //   doc.text(
  //     "Nota: La presente declaración no constituye una autoincriminación de ninguna clase, ni conlleva ninguna clase de responsabilidad administrativa, civil o penal. La empresa solicita la presente declaración en cumplimiento de la normativa vigente en Materia de Prevención, Detección, Erradicación del",
  //     margin_left,
  //     205,
  //     { maxWidth: 170, align: "justify" }
  //   );

  //   doc.line(margin_left - 3, 214, docWidth - (margin_left - 3), 214);

  //   doc.line(margin_left - 3, 95, margin_left - 3, 214);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     95,
  //     docWidth - (margin_left - 3),
  //     214
  //   );

  //   doc.line(margin_left - 3, 215, docWidth - (margin_left - 3), 215);

  //   doc.setFontSize(6);
  //   doc.text(
  //     "En caso de que hubiese cambiado cualquier información de este formulario, me",
  //     margin_left,
  //     218
  //   );
  //   doc.text("comprometo en actualizarla.", margin_left, 221);
  //   doc.text(
  //     "Declaro que toda la información arriba detallada es verdadera.",
  //     margin_left,
  //     224
  //   );

  //   doc.line(margin_left, 235, margin_left + 80, 235);
  //   doc.setFontSize(8);
  //   doc.text("FIRMA DEL CLIENTE", margin_left + 10, 238);

  //   doc.setFont("helvetica", "bold");
  //   doc.text("USO EXCLUSIVO DE", margin_left + 115, 218);
  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(6);
  //   doc.text(
  //     "Certifico/certificamos que la firma que antecede concuerda con la registrada en",
  //     margin_left + 90,
  //     223
  //   );
  //   doc.text(
  //     "nuestros documentos y fue registrada en mi presencia.",
  //     margin_left + 90,
  //     226
  //   );
  //   //doc.line(margin_left + 90, 235, docWidth - margin_left, 235);
  //   doc.setFontSize(8);
  //   doc.text(name, 148, 233, { align: "center" });
  //   doc.text("ASESOR", 148, 238, { align: "center" });

  //   doc.line(margin_left - 3, 240, docWidth - (margin_left - 3), 240);

  //   doc.line(margin_left - 3, 215, margin_left - 3, 240);
  //   doc.line(
  //     docWidth - (margin_left - 3),
  //     215,
  //     docWidth - (margin_left - 3),
  //     240
  //   );

  //   ///////////////////////// HOJA 3 ////////////////////////////////////

  //   doc.setFont("helvetica", "bold");
  //   doc.text("COPIA CÉDULA + PAPELETA VOTACIÓN", margin_left, 250);
  //   doc.text("PLANILLA SERVICIO BASICO", margin_left, 260);

  //   //ejemplo 45 es 255
  //   //doc.line(inicia, altura, termina, altura);

  //   //horizontales
  //   doc.line(margin_left + 65, 246, margin_left + 70, 246);
  //   doc.line(margin_left + 65, 251, margin_left + 70, 251);

  //   doc.line(margin_left + 65, 256, margin_left + 70, 256);
  //   doc.line(margin_left + 65, 261, margin_left + 70, 261);

  //   //verticales
  //   doc.line(margin_left + 65, 246, margin_left + 65, 251);
  //   doc.line(margin_left + 70, 246, margin_left + 70, 251);

  //   doc.line(margin_left + 65, 256, margin_left + 65, 261);
  //   doc.line(margin_left + 70, 256, margin_left + 70, 261);

  //   doc.text("ANEXO A LA HOJA DE NEGOCIO N°", margin_left + 85, 250);
  //   doc.text(
  //     "F.I.: " + new Date(Date.now()).toISOString().substring(0, 10),
  //     margin_left + 85,
  //     260
  //   );

  //   doc.text("Página 2 de 2", 168, 270);
  //   doc.text("0110IXVI24", 171, 275);

  //   var qrImg = new Image();
  //   qrImg.src = qrImagen; //"imagen.jpg"; /// URL de la imagen
  //   doc.addImage(qrImg, "PNG", margin_left + 145, 241, 25, 25); // Agregar la imagen al PDF (X, Y, Width, Height)

  //   // doc.save(`${data && data.cedulaCliente}` + ".pdf");
  //   doc.save("CON_CLI_" + dataInfoNegocio.cedula + ".pdf");
  // }

  function guardaPDF_formularioUAFE(registro: TablaRentaConozcaType) {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const margin_left = 20;
    const inicio_pdf = 50;

    doc.setFont("helvetica", "bold");

    var logo = new Image();
    logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(logo, "PNG", margin_left, 14, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(10);
    doc.line(margin_left - 3, 14, docWidth - (margin_left - 3), 14);
    doc.text("PERFIL DE RIESGOS DEL CLIENTE", docWidth / 2, 22, {
      align: "center",
    });
    doc.setFontSize(8);
    doc.text("RG-GCC-GC-13", 165, 19);
    doc.text(new Date(Date.now()).toISOString().substring(0, 10), 169, 23);
    doc.text("Versión: 1.0", 168, 27);
    doc.line(margin_left - 3, 29, docWidth - (margin_left - 3), 29);

    doc.line(margin_left - 3, 14, margin_left - 3, 29);
    doc.line(
      docWidth - (margin_left - 3),
      14,
      docWidth - (margin_left - 3),
      29
    );

    doc.line(margin_left - 3, 40, docWidth - (margin_left - 3), 40);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.text(
      "DATOS DE LA PERSONA RESPONSABLE DE LA REVISIÓN",
      docWidth / 2,
      45,
      {
        align: "center",
      }
    );

    doc.setFont("helvetica", "bold");
    doc.text("Asesor comercial:", margin_left, 55);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.asesor), margin_left + 60, 55);
    doc.setFont("helvetica", "bold");
    doc.text("Agencia:", margin_left, 60);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.agencia), margin_left + 60, 60);
    doc.setFont("helvetica", "bold");
    doc.text("Zona:", margin_left, 65);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.zona), margin_left + 60, 65);
    doc.setFont("helvetica", "bold");
    doc.text("Ciudad:", margin_left, 70);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.ciudadAgencia), margin_left + 60, 70);
    doc.setFont("helvetica", "bold");
    doc.text("Oficial de cumplimiento:", margin_left, 75);
    doc.setFont("helvetica", "normal");
    doc.text("MICHELLE ESPINOZA", margin_left + 60, 75);

    doc.line(margin_left - 3, 80, docWidth - (margin_left - 3), 80);
    doc.line(margin_left - 3, 40, margin_left - 3, 80);
    doc.line(
      docWidth - (margin_left - 3),
      40,
      docWidth - (margin_left - 3),
      80
    );

    doc.line(margin_left - 3, 85, docWidth - (margin_left - 3), 85);

    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN DEL CLIENTE", docWidth / 2, 90, {
      align: "center",
    });

    doc.setFont("helvetica", "bold");
    doc.text("N°. Identificación / RUC:", margin_left, 100);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.cedula), margin_left + 60, 100);
    doc.setFont("helvetica", "bold");
    doc.text("Nombre/Razón social:", margin_left, 105);
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 60, 105);

    doc.line(margin_left - 3, 110, docWidth - (margin_left - 3), 110);
    doc.line(margin_left - 3, 85, margin_left - 3, 110);
    doc.line(
      docWidth - (margin_left - 3),
      85,
      docWidth - (margin_left - 3),
      110
    );

    doc.line(margin_left - 3, 115, docWidth - (margin_left - 3), 115);
    doc.setFont("helvetica", "bold");
    doc.text("INFORMACIÓN DEL NEGOCIO", docWidth / 2, 120, {
      align: "center",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Modelo de Vehículo:", margin_left, 130); //aki actividad
    doc.setFont("helvetica", "normal");
    doc.text(String(dataInfoNegocio.modelo), margin_left + 60, 130);
    doc.setFont("helvetica", "bold");
    doc.text("Tipo de cliente:", margin_left, 135);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup7),
      margin_left + 60,
      135
    );
    doc.setFont("helvetica", "bold");
    doc.text("País:", margin_left, 140);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup5),
      margin_left + 60,
      140
    );
    doc.setFont("helvetica", "bold");
    doc.text("Provincia:", margin_left, 145);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup3),
      margin_left + 60,
      145
    );
    doc.setFont("helvetica", "bold");
    doc.text("Ciudad:", margin_left, 150);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup4),
      margin_left + 60,
      150
    );
    doc.setFont("helvetica", "bold");
    doc.text("Actividad económica:", margin_left, 155);
    doc.setFont("helvetica", "normal");
    doc.text(obtenerAtributoPorId(dataConozca.combo7), margin_left + 60, 155);
    doc.setFont("helvetica", "bold");
    doc.text("Forma de pago:", margin_left, 160);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup6),
      margin_left + 60,
      160
    );
    doc.setFont("helvetica", "bold");
    doc.text("Información adicional:", margin_left, 165);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup2),
      margin_left + 60,
      165
    );
    doc.setFont("helvetica", "bold");
    doc.text("Absetenciones comerciales:", margin_left, 170);
    doc.setFont("helvetica", "normal");
    doc.text(
      obtenerPesoDescPorId(dataConozca.comboSup1),
      margin_left + 60,
      170
    );
    doc.setFont("helvetica", "bold");
    doc.text("Calificación de riesgo:", margin_left, 175);

    doc.setDrawColor(0);


    Number(
      obtenerPesoValorPorId(dataConozca.comboSup1) +
      obtenerPesoValorPorId(dataConozca.comboSup2) +
      obtenerPesoValorPorId(dataConozca.comboSup3) +
      obtenerPesoValorPorId(dataConozca.comboSup4) +
      obtenerPesoValorPorId(dataConozca.comboSup5) +
      obtenerPesoValorPorId(dataConozca.comboSup6) +
      obtenerPesoValorPorId(dataConozca.comboSup7) + 4
    ) <= 15
      ? doc.setFillColor(0, 191, 3)
      : Number(
        obtenerPesoValorPorId(dataConozca.comboSup1) +
        obtenerPesoValorPorId(dataConozca.comboSup2) +
        obtenerPesoValorPorId(dataConozca.comboSup3) +
        obtenerPesoValorPorId(dataConozca.comboSup4) +
        obtenerPesoValorPorId(dataConozca.comboSup5) +
        obtenerPesoValorPorId(dataConozca.comboSup6) +
        obtenerPesoValorPorId(dataConozca.comboSup7) + 4
      ) <= 20
        ? doc.setFillColor(233, 236, 0)
        : Number(
          obtenerPesoValorPorId(dataConozca.comboSup1) +
          obtenerPesoValorPorId(dataConozca.comboSup2) +
          obtenerPesoValorPorId(dataConozca.comboSup3) +
          obtenerPesoValorPorId(dataConozca.comboSup4) +
          obtenerPesoValorPorId(dataConozca.comboSup5) +
          obtenerPesoValorPorId(dataConozca.comboSup6) +
          obtenerPesoValorPorId(dataConozca.comboSup7) + 4
        ) <= 27
          ? doc.setFillColor(236, 125, 0)
          : doc.setFillColor(255, 0, 0);


    //doc.setFillColor(255, 0, 0);
    doc.rect(margin_left + 60, 171, 10, 5, 'FD'); //Fill and Border

    //doc.rect(40, 50, 30, 12, 'FD'); //Fill and Border
    //doc.text('hello', 42, 51);



    doc.text(
      String(
        Number(
          obtenerPesoValorPorId(dataConozca.comboSup1) +
          obtenerPesoValorPorId(dataConozca.comboSup2) +
          obtenerPesoValorPorId(dataConozca.comboSup3) +
          obtenerPesoValorPorId(dataConozca.comboSup4) +
          obtenerPesoValorPorId(dataConozca.comboSup5) +
          obtenerPesoValorPorId(dataConozca.comboSup6) +
          obtenerPesoValorPorId(dataConozca.comboSup7) + 4
        )
      ),
      margin_left + 63,
      175
    );

    doc.setTextColor(0, 0, 0);


    doc.text("Proceso a seguir:", margin_left, 180);
    doc.setFont("helvetica", "normal");
    doc.text(
      Number(
        obtenerPesoValorPorId(dataConozca.comboSup1) +
        obtenerPesoValorPorId(dataConozca.comboSup2) +
        obtenerPesoValorPorId(dataConozca.comboSup3) +
        obtenerPesoValorPorId(dataConozca.comboSup4) +
        obtenerPesoValorPorId(dataConozca.comboSup5) +
        obtenerPesoValorPorId(dataConozca.comboSup6) +
        obtenerPesoValorPorId(dataConozca.comboSup7) + 4
      ) <= 15
        ? "FORMULARIO CONOCE A TU CLIENTE"
        : Number(
          obtenerPesoValorPorId(dataConozca.comboSup1) +
          obtenerPesoValorPorId(dataConozca.comboSup2) +
          obtenerPesoValorPorId(dataConozca.comboSup3) +
          obtenerPesoValorPorId(dataConozca.comboSup4) +
          obtenerPesoValorPorId(dataConozca.comboSup5) +
          obtenerPesoValorPorId(dataConozca.comboSup6) +
          obtenerPesoValorPorId(dataConozca.comboSup7) + 4
        ) <= 20
          ? "FORMULARIO CONOCE A TU CLIENTE Y JUSTIFICACIÓN DE FONDOS OBLIGATORIO"
          : Number(
            obtenerPesoValorPorId(dataConozca.comboSup1) +
            obtenerPesoValorPorId(dataConozca.comboSup2) +
            obtenerPesoValorPorId(dataConozca.comboSup3) +
            obtenerPesoValorPorId(dataConozca.comboSup4) +
            obtenerPesoValorPorId(dataConozca.comboSup5) +
            obtenerPesoValorPorId(dataConozca.comboSup6) +
            obtenerPesoValorPorId(dataConozca.comboSup7) + 4
          ) <= 27
            ? "FORMULAIRO CONOCE A TU CLIENTE, DEBIDA DILIGENCIA REFORZADA, JUSTIFICACION DE FONDOS Y ROS"
            : "NO INICIAR RELACIÓN COMERCIAL Y REPORTE ROS",
      margin_left + 60,
      180,
      { maxWidth: 115, align: "justify" }
    );

    // doc.text(
    //   "De acuerdo a la normativa vigente en materia de prevención de lavado de activos, financiamiento del terrorismo y otros delitos, expedida para todos los concesionarios de vehículos, es necesario que todos los clientes llenen el presente formulario, y adjunten los documentos solicitados en la parte inferior. La compañía no podrá iniciar relaciones comerciales en caso de que el cliente no llene los datos mínimos de información solicitada.",
    //   margin_left,
    //   35,
    //   { maxWidth: 170, align: "justify" }
    // );

    doc.line(margin_left - 3, 190, docWidth - (margin_left - 3), 190);

    doc.line(margin_left - 3, 115, margin_left - 3, 190);
    doc.line(
      docWidth - (margin_left - 3),
      115,
      docWidth - (margin_left - 3),
      190
    );

    doc.text("Página 1 de 1", 168, 270);
    doc.text("0110IXVI24", 171, 275);

    doc.save("MAT_UAF_" + dataInfoNegocio.cedula + ".pdf");
  }

  //funciones

  const [openDialogAccionistas, setOpenDialogAccionistas] =
    React.useState(false);
  const handleClickOpenAccionistas = () => {
    setOpenDialogAccionistas(true);
  };

  type ClienteActividadType = {
    tcaId: number;
    tcaDescripcion: string;
  };

  type ClienteDetalleProcedenciaType = {
    cdpId: number;
    cdpDescripcion: string;
  };

  type ListaTercerosType = {
    id: number;
    descripcion: string;
  };

  const listadoClienteDetalleProcedencia: ClienteDetalleProcedenciaType[] = [
    { cdpId: 0, cdpDescripcion: "(Vacio)" },
    { cdpId: 1, cdpDescripcion: "Valor entregado en caja" },
    { cdpId: 2, cdpDescripcion: "Vehículo usado" },
    { cdpId: 3, cdpDescripcion: "Crédito financiera" },
    { cdpId: 5, cdpDescripcion: "Nota de crédito" },
    { cdpId: 6, cdpDescripcion: "Vehículo usado consignado" },
    { cdpId: 8, cdpDescripcion: "Crédito consumo" },
    { cdpId: 9, cdpDescripcion: "Retención" },
    { cdpId: 10, cdpDescripcion: "Tarjeta de crédito" },
  ];

  const listadoClienteDetalleProcedenciaCred: ClienteDetalleProcedenciaType[] =
    [
      { cdpId: 0, cdpDescripcion: "(Vacio)" },
      { cdpId: 3, cdpDescripcion: "Crédito financiera" },
      { cdpId: 8, cdpDescripcion: "Crédito consumo" },
    ];

  const listadoTerceros1: ListaTercerosType[] = [
    { id: 1, descripcion: "Tercero 1" },
  ];

  const listadoTerceros2: ListaTercerosType[] = [
    { id: 1, descripcion: "Tercero 1" },
    { id: 2, descripcion: "Tercero 2" },
  ];

  const listadoTerceros3: ListaTercerosType[] = [
    { id: 1, descripcion: "Tercero 1" },
    { id: 2, descripcion: "Tercero 2" },
    { id: 3, descripcion: "Tercero 3" },
  ];

  const [tercero1, setTercero1] = React.useState(1);

  const [tercero2, setTercero2] = React.useState(1);

  const [tercero3, setTercero3] = React.useState(1);

  const handleChangeTercero1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTercero1(Number(event.target.value));
  };
  const handleChangeTercero2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTercero2(Number(event.target.value));
  };
  const handleChangeTercero3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTercero3(Number(event.target.value));
  };

  const [clienteActividad, setClienteActividad] = React.useState(
    dataConozca.combo7
  );
  const [clienteActividadEco, setClienteActividadEco] = React.useState(
    dataConozca.combo6
  );
  const [conCliDetalle1, setConCliDetalle1] = React.useState(
    dataConozca.combo1
  );
  const [conCliDetalle2, setConCliDetalle2] = React.useState(
    dataConozca.combo2
  );
  const [conCliDetalle3, setConCliDetalle3] = React.useState(
    dataConozca.combo3
  );
  const [conCliDetalle4, setConCliDetalle4] = React.useState(
    dataConozca.combo4
  );
  const [conCliDetalle5, setConCliDetalle5] = React.useState(
    dataConozca.combo5
  );
  const listadoActividades: ClienteActividadType[] = [];
  const listadoPesos: SateliteUafePesoType[] = [];
  const listadoActividadesEco: ClienteActividadType[] = [
    { tcaId: 0, tcaDescripcion: "(Sin asignar)" },
    { tcaId: 1, tcaDescripcion: "A - Ama de casa o estudiante" },
    { tcaId: 2, tcaDescripcion: "B - Empleado público" },
    { tcaId: 3, tcaDescripcion: "I - Independiente" },
    { tcaId: 4, tcaDescripcion: "M - Remesas del exterior" },
    { tcaId: 5, tcaDescripcion: "R - Rentista" },
    { tcaId: 6, tcaDescripcion: "V - Empleado privado" },
  ];
  const [dataClienteActividad, setDataActividad] =
    useState<ClienteActividadType[]>(listadoActividades);
  const [dataClienteActividadEco, setDataActividadEco] = useState<
    ClienteActividadType[]
  >(listadoActividadesEco);
  // const [dataPeso1, setDataPeso1] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);
  // const [dataPeso2, setDataPeso2] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);

  // const [dataPeso6, setDataPeso6] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);

  // const [dataPeso7, setDataPeso7] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);

  // const [dataPais, setDataPais] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);
  // const [dataProvicia, setDataProvincia] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);
  // const [dataCanton, setDataCanton] =
  //   useState<SateliteUafePesoType[]>(listadoPesos);

  const [dataPesosTodo, setDataPesosTodo] =
    useState<SateliteUafePesoType[]>(listadoPesos);

  const [dataClienteDetalleProcedencia, setDataClienteDetalleProcedencia] =
    useState<ClienteDetalleProcedenciaType[]>(listadoClienteDetalleProcedencia);
  const [
    dataClienteDetalleProcedenciaCred,
    setDataClienteDetalleProcedenciaCred,
  ] = useState<ClienteDetalleProcedenciaType[]>(
    listadoClienteDetalleProcedenciaCred
  );

  const [actividadSeleccionada, setActividadSeleccionada] = React.useState("");

  const handleChangeClienteActividad = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClienteActividad(Number(event.target.value));

    setDataConozca({
      ...dataConozca,
      combo7: Number(event.target.value),
    });

    const lista = dataClienteActividad.filter((obj) => {
      return obj.tcaId === Number(event.target.value);
    });

    lista.forEach(function (value) {
      console.log(value);
      setActividadSeleccionada(value.tcaDescripcion);
    });
    console.log("actividad seleccionada:  >>>> ", actividadSeleccionada);
  };

  async function seteoActividad(ide: number) {
    setClienteActividad(ide);
    // setDataConozca({
    //   ...dataConozca,
    //   combo7: id,
    // });
    const lista = dataClienteActividad.filter((obj) => {
      return obj.tcaId === ide;
    });
    console.log("lista de activiadaes", lista);
    lista.forEach(function (value) {
      console.log(value);
      setActividadSeleccionada(value.tcaDescripcion);
    });
  }

  const handleChangeClienteActividadEco = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClienteActividadEco(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo6: Number(event.target.value),
    });
  };

  const handleChangePeso1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      comboSup1: Number(event.target.value),
    });
  };

  const handleChangePeso2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      comboSup2: Number(event.target.value),
    });
  };

  const handleChangePeso6 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      comboSup6: Number(event.target.value),
    });
  };

  const handleChangePeso7 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      comboSup7: Number(event.target.value),
    });
  };

  const handleChangePais = (event: React.ChangeEvent<HTMLInputElement>) => {
    // getListadoUafePeso("provincia", "reporta", Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      comboSup5: Number(event.target.value),
    });
  };

  const handleChangeProvincia = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // getListadoUafePeso("canton", "reporta", Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      comboSup3: Number(event.target.value),
    });
  };

  const handleChangeCanton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataConozca({
      ...dataConozca,
      comboSup4: Number(event.target.value),
    });
  };

  const handleChangeConCliDetalle1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConCliDetalle1(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo1: Number(event.target.value),
      fpropiosDetalle:
        Number(event.target.value) === 0
          ? "(Vacio)"
          : Number(event.target.value) === 1
            ? "Valor entregado en caja"
            : Number(event.target.value) === 2
              ? "Vehículo usado"
              : Number(event.target.value) === 3
                ? "Crédito financiera"
                : Number(event.target.value) === 5
                  ? "Nota de crédito"
                  : Number(event.target.value) === 6
                    ? "Vehículo usado consignado"
                    : Number(event.target.value) === 8
                      ? "Crédito consumo"
                      : Number(event.target.value) === 9
                        ? "Retención"
                        : "Tarjeta de crédito",
    });
  };

  const handleChangeConCliDetalle2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConCliDetalle2(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo2: Number(event.target.value),
      ffinancieraDetalle:
        Number(event.target.value) === 0
          ? "(Vacio)"
          : Number(event.target.value) === 1
            ? "Valor entregado en caja"
            : Number(event.target.value) === 2
              ? "Vehículo usado"
              : Number(event.target.value) === 3
                ? "Crédito financiera"
                : Number(event.target.value) === 5
                  ? "Nota de crédito"
                  : Number(event.target.value) === 6
                    ? "Vehículo usado consignado"
                    : Number(event.target.value) === 8
                      ? "Crédito consumo"
                      : Number(event.target.value) === 9
                        ? "Retención"
                        : "Tarjeta de crédito",
    });
  };
  const handleChangeConCliDetalle3 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConCliDetalle3(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo3: Number(event.target.value),
      cinternoDetalle:
        Number(event.target.value) === 0
          ? "(Vacio)"
          : Number(event.target.value) === 1
            ? "Valor entregado en caja"
            : Number(event.target.value) === 2
              ? "Vehículo usado"
              : Number(event.target.value) === 3
                ? "Crédito financiera"
                : Number(event.target.value) === 5
                  ? "Nota de crédito"
                  : Number(event.target.value) === 6
                    ? "Vehículo usado consignado"
                    : Number(event.target.value) === 8
                      ? "Crédito consumo"
                      : Number(event.target.value) === 9
                        ? "Retención"
                        : "Tarjeta de crédito",
    });
  };
  const handleChangeConCliDetalle4 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConCliDetalle4(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo4: Number(event.target.value),
      vusadoDetalle:
        Number(event.target.value) === 0
          ? "(Vacio)"
          : Number(event.target.value) === 1
            ? "Valor entregado en caja"
            : Number(event.target.value) === 2
              ? "Vehículo usado"
              : Number(event.target.value) === 3
                ? "Crédito financiera"
                : Number(event.target.value) === 5
                  ? "Nota de crédito"
                  : Number(event.target.value) === 6
                    ? "Vehículo usado consignado"
                    : Number(event.target.value) === 8
                      ? "Crédito consumo"
                      : Number(event.target.value) === 9
                        ? "Retención"
                        : "Tarjeta de crédito",
    });
  };
  const handleChangeConCliDetalle5 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConCliDetalle5(Number(event.target.value));
    setDataConozca({
      ...dataConozca,
      combo5: Number(event.target.value),
      ftercerosDetalle:
        Number(event.target.value) === 0
          ? "(Vacio)"
          : Number(event.target.value) === 1
            ? "Valor entregado en caja"
            : Number(event.target.value) === 2
              ? "Vehículo usado"
              : Number(event.target.value) === 3
                ? "Crédito financiera"
                : Number(event.target.value) === 5
                  ? "Nota de crédito"
                  : Number(event.target.value) === 6
                    ? "Vehículo usado consignado"
                    : Number(event.target.value) === 8
                      ? "Crédito consumo"
                      : Number(event.target.value) === 9
                        ? "Retención"
                        : "Tarjeta de crédito",
    });
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [openDialogErrorConCli, setOpenDialogErrorConCli] =
    React.useState(false);

  const handleCloseDialogErrorConCli = () => {
    setOpenDialogErrorConCli(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogError = () => {
    setOpenDialogError(false);
  };

  const [cheked, setCheked] = React.useState({
    accesorios_incluye: true,
    dispositivos_incluye: true,
    mantenimientos_incluye: true,
    complement_incluye: true,
    beneficiario_concli: true,
    pep_1: false,
    pep_2: false,
    persona_natural: true,
  });

  const dataAutorizacion = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosAutoriza({
      ...datosAutoriza,
      [e.target.name]: e.target.value,
    });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  type autorizacionNuevaType = {
    tarId: number;
    tauEsOtroCanton: number;
    tauOtroCanton: string;
    tauFinanciera: string;
    tauFinanciamientoValor: number;
    tauFinanciamientoReferencia: string;
    tauFinanciamientoFecha: string;
    tauEntradaValor: number;
    tauEntradaReferencia: string;
    tauEntradaFecha: string;
    tauNcValor: number;
    tauNcReferencia: string;
    tauNcFecha: string;
    tauSaldoValor: number;
    tauSaldoReferencia: string;
    tauSaldoFecha: string;
    tauUsadoValor: number;
    tauUsadoReferencia: string;
    tauUsadoFecha: string;
    tauTotalValor: number;
    tauDispositivo: number;
    tauDispositivoValor: number;
    tauDispositivoDetalle: string;
    tauDispositivoNotas: string;
    tauSeguro: number;
    tauSeguroValor: number;
    tauSeguroDetalle: string;
    tauSeguroNotas: string;
    tauAcc: number;
    tauAccValor: number;
    tauAccDetalle: string;
    tauAccNotas: string;
    tauObsequio: number;
    tauObsequioValor: number;
    tauObsequioDetalle: string;
    tauObsequioNotas: string;
  };
  async function postGuardarAutorizacion(registroGuardar: autorizacionType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaautfac",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarAutorizacion(
    registro: autorizacionType,
    tabla: tablaRentaType
  ) {
    const doc = new jsPDF();
    const docWidth = doc.internal.pageSize.getWidth();
    const docHeight = doc.internal.pageSize.getHeight();
    const margin_left = 18;
    const inicio_pdf = 50;

    const texto1 =
      "Declaro que los beneficios antes descritos me han sido presentados y explicados detalladamente por el asesor comercial y voluntariamente he escogido cada una de las opciones. Entiendo que la no contratación de cualquiera de estos elementos puede conllevar a gastos y/o pérdidas futuras, por lo cual libero al concesionario de cualquier reclamo futuro. Ratifico que, de no cumplir con el compromiso de pago acordado, en las fechas acordadas, asumiré los intereses, de acuerdo a la tasa vigente en el mercado, desde el primer día de la facturación.";
    const texto2 =
      "Autorizo expresamente a Corporación Proauto S.A a  retener el porcentaje equivalente al 5% de Precio de Venta del vehículo, por concepto de gastos administrativos, en el evento de solicitar la terminación del contrato de compraventa de un vehículo  o la anulación del negocio facturado. Renuncio, por tanto, a cualquier reclamo referente a tal concepto, en razón del derecho que me asiste previsto en el Art. 11 del Código Civil.";
    const texto3 =
      "Me encuentro en pleno conocimiento que los valores de matriculación del vehículo son de mi responsabilidad. Luego de facturado el vehículo y una vez se haya entregado al Concesionario todos los documentos necesarios para la matriculación del vehículo y una vez cancelado el valor total del vehículo; el concesionario se compromete a matricular la unidad en mínimo de 8 días  hábiles (excepto en la provincia de Pichincha, donde toma más de 10 días hábiles por nuevo impuesto de fondo vial). Es importante recalcar que los proceso de matriculación son asuntos legales ajenos a la gestión del concesionario. Los tiempos promedios del proceso detallados en el presente pueden variar dependiendo de la ubicación física de su vehículo, cumplimiento de los tiempos por parte del cliente, siempre y cuando no registre citaciones o valores pendiente con el Concesionario.";
    const texto4 =
      "Corporación Proauto S.A. se exime de responsabilidad por valores entregados a terceros o en cuentas no autorizadas para cobros. Solo se reciben pagos a cuentas registradas a nombre de Corporación Proauto S.A.";
    const texto5 =
      "Expresamente autorizamos la obtención y verificación en la Central de Riesgos y/o Burós de información crediticia, de todo tipo de información relacionada a la solicitud de crédito, liberando a los consultantes de cualquier responsabilidad civil, penal o de cualquier tipo por esta causa, facultándolos adicionalmente, a proveer a estas instituciones la información de nuestro comportamiento crediticio.";

    doc.setFont("helvetica", "bold");

    var logo = new Image();
    logo.src = logoCorp; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(logo, "PNG", margin_left, 10, 36, 14); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(10);
    doc.text("AUTORIZACIÓN DE FACTURACIÓN", docWidth / 2, 14, {
      align: "center",
    });
    doc.setFontSize(8);
    doc.text("Código", 170, 14);
    doc.text("RG-GCC-VC-01", 166, 19);
    doc.text("Versión: 1.0", 168, 23);
    doc.line(margin_left - 3, 25, docWidth - (margin_left - 3), 25);
    doc.setFontSize(8);
    doc.setFont("helvetica", "bold");
    doc.text("Nombre del cliente:", margin_left, 30);
    doc.text("Modelo del vehículo:", margin_left, 35);
    doc.text("No. Cédula/RUC:", margin_left, 40);
    doc.text("Color del vehículo:", margin_left, 45);
    doc.text("Matriculación:", margin_left, 50);
    doc.text("Forma de pago:", margin_left, 55);

    doc.text("Precio del Vehículo:", margin_left + 110, 30);
    doc.text("Descuento:", margin_left + 110, 35);
    doc.text("Accesorios:", margin_left + 110, 40);
    doc.text("15% IVA:", margin_left + 110, 45);
    doc.text("Total a facturar:", margin_left + 110, 50);

    if (String(dataInfoNegocio.formaPago).trim() === 'CREDIT' || String(dataInfoNegocio.formaPago).trim() === 'CRÉDITO' || String(dataInfoNegocio.formaPago).trim() === 'CRÉDITO DIRECTO' || String(dataInfoNegocio.formaPago).trim() === 'Credito Directo' || String(dataInfoNegocio.formaPago).trim() === 'Crédito de Consumo' || String(dataInfoNegocio.formaPago).trim() === 'CRÉDITO CONSUMO' ){
      doc.text("Financiera:", margin_left + 60, 55);
      doc.setFont("helvetica", "normal");
      doc.text(dataInfoNegocio.financiera, margin_left + 78, 55);
      doc.setFont("helvetica", "bold");
      doc.text("Valor cuota:", margin_left + 110, 55);
      doc.setFont("helvetica", "normal");
      doc.text( '$ ' + String(dataInfoNegocio.valorCuota), margin_left + 130, 55);
      doc.setFont("helvetica", "bold");
      doc.text("Plazo:", margin_left + 155, 55);
      doc.setFont("helvetica", "normal");
      doc.text( String(dataInfoNegocio.plazoCredito) + " meses", margin_left + 165, 55);
    }
   

    doc.setFont("helvetica", "normal");

    doc.text(String(dataInfoNegocio.nombreCliente), margin_left + 35, 30);
    doc.text(String(dataInfoNegocio.modelo), margin_left + 35, 35);
    doc.text(String(dataInfoNegocio.cedula), margin_left + 35, 40);
    doc.text(String(tabla.tarColor), margin_left + 35, 45);
    doc.text(
      registro.tauEsOtroCanton === 1
        ? "Otro cantón: " + registro.tauOtroCanton
        : "Local",
      margin_left + 35,
      50
    );
    doc.text(String(tabla.tarFormaPago), margin_left + 35, 55);

    doc.text(
      "$ " + Number(tabla.tarPvpMarca).toFixed(2),
      margin_left + 145,
      30
    );
    doc.text(
      "$ " + Number(tabla.tarDescTotalValor).toFixed(2),
      margin_left + 145,
      35
    );
    console.log("mi tabla es ---- ", tabla);
    doc.text(
      "$ " + Number(tabla.tarAccValor).toFixed(2),
      margin_left + 145,
      40
    );
    doc.text(
      "$ " +
      Number(Number(tabla.tarTotalFacturar) - (Number(tabla.tarTotalFacturar) / 1.15)).toFixed(
        //Number(Number(Number(tabla.tarTotalFacturar) * Number(0.15))).toFixed(
        2
      ),
      margin_left + 145,
      45
    );
    doc.text(
      "$ " + Number(tabla.tarTotalFacturar).toFixed(2),
      margin_left + 145,
      50
    );

    doc.line(margin_left - 3, 57, docWidth - (margin_left - 3), 57);
    //tabla 1
    doc.setFontSize(7);
    doc.setFont("helvetica", "bold");
    doc.text("FORMA DE PAGO", margin_left + 15, 60, { align: "center" });
    doc.text("VALORES", margin_left + 50, 60, { align: "center" });
    //doc.text("REFERENCIA RECIBO", margin_left + 105, 60, { align: "center" });
    // doc.text("FECHA COMPROMISO PAGO", margin_left + 160, 60, { align: "center" });
    doc.setFont("helvetica", "normal");

    //FORMA DE PAGO
    doc.text("Financiamiento", margin_left, 64);
    doc.text("Entrada/Anticipo", margin_left, 68);
    doc.text("Saldo", margin_left, 72);
    doc.text("Toma de vehículo", margin_left, 76);
    doc.setFont("helvetica", "bold");
    doc.text("TOTAL", margin_left, 80);
    doc.text("Nota de crédito", margin_left, 84);
    doc.setFont("helvetica", "normal");

    //VALORES
    doc.text(
      "$ " + Number(dataConozca.ffinancieraUsd).toFixed(2),
      margin_left + 50,
      64,
      { align: "center" }
    );
    doc.text(
      "$ " + Number(dataConozca.fpropiosUsd).toFixed(2),
      margin_left + 50,
      68,
      {
        align: "center",
      }
    );
    doc.text(
      "$ " +
      Number(
        Number(dataConozca.cinternoUsd) + Number(dataConozca.ftercerosUsd)
      ).toFixed(2),
      margin_left + 50,
      72,
      { align: "center" }
    );
    doc.text(
      "$ " + Number(dataConozca.vusadoUsd).toFixed(2),
      margin_left + 50,
      76,
      {
        align: "center",
      }
    );
    doc.setFont("helvetica", "bold");
    doc.text("$ " + Number(tabla.tarTotalFacturar), margin_left + 50, 80, {
      align: "center",
    });

    doc.text(
      "$ " +
      Number(
        Number(tabla.tarNcConcesionario) + Number(tabla.tarNcMarca)
      ).toFixed(2),
      margin_left + 50,
      84,
      { align: "center" }
    );
    doc.setFont("helvetica", "normal");

    doc.line(margin_left - 3, 87, docWidth - (margin_left - 3), 87);

    //tabla 2

    doc.setFontSize(7);
    doc.setFont("helvetica", "bold");
    doc.text("NEGOCIO", margin_left + 8, 90, { align: "center" });
    doc.text("SI/NO", margin_left + 30, 90, { align: "center" });
    doc.text("VALOR", margin_left + 50, 90, { align: "center" });
    doc.text("DETALLE", margin_left + 125, 90, { align: "center" });
    //doc.text("NOTAS", margin_left + 160, 90, { align: "center" });
    doc.setFont("helvetica", "normal");

    //NEGOCIO
    doc.text("Disp. Satelital", margin_left, 94);
    doc.text("Seguro", margin_left, 98);
    doc.text("Acesorios", margin_left, 102);
    doc.text("Obsequio", margin_left, 106);

    //si - no
    doc.text(
      tabla.tarDispIncluyeFac === 0 ? "NO" : "SI",
      margin_left + 30,
      94,
      {
        align: "center",
      }
    );
    doc.text(
      registro.tauSeguroValor === 0 ? "NO" : "SI",
      margin_left + 30,
      98,
      {
        align: "center",
      }
    );
    doc.text(
      tabla.tarAccIncluyeFac === 0 ? "NO" : "SI",
      margin_left + 30,
      102,
      {
        align: "center",
      }
    );
    doc.text(
      tabla.tarValorObsequios === 0 ? "NO" : "SI",
      margin_left + 30,
      106,
      {
        align: "center",
      }
    );

    //valor
    doc.text(
      tabla.tarDispIncluyeFac === 0
        ? ""
        : "$ " + Number(tabla.tarDispValor).toFixed(2),
      margin_left + 50,
      94,
      { align: "center" }
    );
    doc.text(
      "$ " + Number(registro.tauSeguroValor).toFixed(2),
      margin_left + 50,
      98,
      { align: "center" }
    );
    doc.text(
      tabla.tarAccIncluyeFac === 0
        ? ""
        : "$ " + Number(tabla.tarAccValor).toFixed(2),
      margin_left + 50,
      102,
      { align: "center" }
    );
    doc.text(
      tabla.tarValorObsequios === 0
        ? ""
        : "$ " + Number(tabla.tarValorObsequios).toFixed(2),
      margin_left + 50,
      106,
      { align: "center" }
    );

    //detalle
    doc.text(tabla.tarDispDescripcion, margin_left + 125, 94, {
      align: "center",
    });
    doc.text(registro.tauSeguroDetalle, margin_left + 125, 98, {
      align: "center",
    });
    doc.text(tabla.tarAccDescripcion, margin_left + 125, 102, {
      align: "center",
    });

    doc.line(margin_left - 3, 110, docWidth - (margin_left - 3), 110);

    doc.setFont("helvetica", "bold");
    doc.text("PASOS DE LA ENTREGA PERFECTA", margin_left, 114);
    var imgautorizacio = new Image();
    imgautorizacio.src = imagenautorizacion; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(imgautorizacio, "PNG", margin_left, 118, 170, 60); // Agregar la imagen al PDF (X, Y, Width, Height)

    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
    doc.text(texto1, margin_left, 183, { maxWidth: 170, align: "justify" });
    doc.text(texto2, margin_left, 200, { maxWidth: 170, align: "justify" });
    doc.text(texto3, margin_left, 214, { maxWidth: 170, align: "justify" });
    doc.text(texto4, margin_left, 238, { maxWidth: 170, align: "justify" });
    doc.text(texto5, margin_left, 246, { maxWidth: 170, align: "justify" });

    doc.setFontSize(8);
    doc.line(20, 270, 55, 270);
    doc.line(70, 270, 105, 270);
    doc.text("CLIENTE: " + tabla.tarCedulaCliente, 37, 275, {
      align: "center",
    });
    doc.text(tabla.tarNombreCliente, 37, 280, { align: "center" });

    doc.text(tabla.tarAsesor, 88, 275, { align: "center" });
    doc.text("ASESOR COMERCIAL", 88, 280, { align: "center" });

    doc.setFontSize(7);
    doc.text("Página 1 de 1", 145, 271);
    doc.text("0110IXVI24", 148, 275);
    doc.text(new Date(Date.now()).toISOString().substring(0, 10), 148, 279);

    var qrAut = new Image();
    qrAut.src = qrAutorizacion; //"imagen.jpg"; /// URL de la imagen
    doc.addImage(qrAut, "PNG", margin_left + 100, 256, 25, 25);


    const now = new Date(Date.now() - 5 * 60 * 60 * 1000); // Restar 5 horas
    // Formatear la fecha y hora
    const isoString = now.toISOString();
    const [dateText, timeText] = isoString.split('T');
    const formattedTimeText = timeText.split('.')[0];

    const qrCodeText = "Documento: Autorización de facturación\nFecha: " + dateText + "\nHora: " + formattedTimeText + "\nUsuario: " + username + "\nN° Impresión: 1";
    const qrCodeDataUrl = await QRCode.toDataURL(qrCodeText);
    doc.addImage(qrCodeDataUrl, 'PNG', margin_left + 145, 253, 30, 30);

    doc.save("AUT_FAC_" + tabla.tarCedulaCliente + ".pdf");
  }

  async function postNuevaAutorizacion(registroGuardar: autorizacionNuevaType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarentaautfac",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          setOpenDialog(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [datosAutorizaInit, setDatosAutorizaInit] =
    React.useState<autorizacionType>({
      tauId: 0,
      tarId: 0,
      tauEsOtroCanton: 0,
      tauOtroCanton: "",
      tauFinanciera: "",
      tauFinanciamientoValor: 0,
      tauFinanciamientoReferencia: "",
      tauFinanciamientoFecha: "",
      tauEntradaValor: 0,
      tauEntradaReferencia: "",
      tauEntradaFecha: "",
      tauNcValor: 0,
      tauNcReferencia: "",
      tauNcFecha: "",
      tauSaldoValor: 0,
      tauSaldoReferencia: "",
      tauSaldoFecha: "",
      tauUsadoValor: 0,
      tauUsadoReferencia: "",
      tauUsadoFecha: "",
      tauTotalValor: 0,
      tauDispositivo: 0,
      tauDispositivoValor: 0,
      tauDispositivoDetalle: "",
      tauDispositivoNotas: "",
      tauSeguro: 0,
      tauSeguroValor: 0,
      tauSeguroDetalle: "",
      tauSeguroNotas: "",
      tauAcc: 0,
      tauAccValor: 0,
      tauAccDetalle: "",
      tauAccNotas: "",
      tauObsequio: 0,
      tauObsequioValor: 0,
      tauObsequioDetalle: "",
      tauObsequioNotas: "",
    });

  async function setNuevaAutorizacion(
    registro: autorizacionType,
    idTabla: number
  ) {
    console.log("registro a guardar >> ");

    const nuevoRegistro: autorizacionNuevaType = {
      tarId: idTabla,
      tauEsOtroCanton: registro.tauEsOtroCanton,
      tauOtroCanton: registro.tauOtroCanton,
      tauFinanciera: registro.tauFinanciera,
      tauFinanciamientoValor: registro.tauFinanciamientoValor,
      tauFinanciamientoReferencia: registro.tauFinanciamientoReferencia,
      tauFinanciamientoFecha: registro.tauFinanciamientoFecha,
      tauEntradaValor: registro.tauEntradaValor,
      tauEntradaReferencia: registro.tauEntradaReferencia,
      tauEntradaFecha: registro.tauEntradaFecha,
      tauNcValor: registro.tauNcValor,
      tauNcReferencia: registro.tauNcReferencia,
      tauNcFecha: registro.tauNcFecha,
      tauSaldoValor: registro.tauSaldoValor,
      tauSaldoReferencia: registro.tauSaldoReferencia,
      tauSaldoFecha: registro.tauSaldoFecha,
      tauUsadoValor: registro.tauUsadoValor,
      tauUsadoReferencia: registro.tauUsadoReferencia,
      tauUsadoFecha: registro.tauUsadoFecha,
      tauTotalValor: registro.tauTotalValor,
      tauDispositivo: registro.tauDispositivo,
      tauDispositivoValor: registro.tauDispositivoValor,
      tauDispositivoDetalle: registro.tauDispositivoDetalle,
      tauDispositivoNotas: registro.tauDispositivoNotas,
      tauSeguro: registro.tauSeguro,
      tauSeguroValor: registro.tauSeguroValor,
      tauSeguroDetalle: registro.tauSeguroDetalle,
      tauSeguroNotas: registro.tauSeguroNotas,
      tauAcc: registro.tauAcc,
      tauAccValor: registro.tauAccValor,
      tauAccDetalle: registro.tauAccDetalle,
      tauAccNotas: registro.tauAccNotas,
      tauObsequio: registro.tauObsequio,
      tauObsequioValor: registro.tauObsequioValor,
      tauObsequioDetalle: registro.tauObsequioDetalle,
      tauObsequioNotas: registro.tauObsequioNotas,
    };
    registro.tauId === 0
      ? await postNuevaAutorizacion(nuevoRegistro)
      : await postGuardarAutorizacion(registro);
    await guardarAutorizacion(datosAutoriza, registroTabla);
  }

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChekedAuto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChekedAuto({
      ...chekedAuto,
      [event.target.name]: event.target.checked,
    });
  };

  type vTablaRentaCrmResponse = {
    id: number;
    agencia: string;
    idAsesor: number;
    apellidoAsesor: string;
    nombreAsesor: string;
    cedulaCliente: string;
    apellidoCliente: string;
    nombreCliente: string;
    modelo: string;
    modeloAnio: number;
    formaPago: string;
    entrada: number;
    financiera: string;
    estado_financiera: string;
    valor_financiera: number;
    idBodega: number;
    idZona: number;
  };

  const [data, setData] = React.useState<vTablaRentaCrmResponse>({
    id: 0,
    agencia: "",
    idAsesor: 0,
    apellidoAsesor: "",
    nombreAsesor: "",
    cedulaCliente: "",
    apellidoCliente: "",
    nombreCliente: "",
    modelo: "",
    modeloAnio: 0,
    formaPago: "",
    entrada: 0,
    financiera: "",
    estado_financiera: "",
    valor_financiera: 0,
    idBodega: 0,
    idZona: 0,
  });

  async function getDatosTercero(orden: number) {
    try {
      await axios
        .get<UpdateTablaRentaTerceroType>(
          "https://apisatelite.azurewebsites.net/tablarentatercero/" +
          String(params.idcrm) +
          "/" +
          String(orden)
        )
        .then((response) => {
          if (response.data.idCrm.length > 0) {
            if (orden === 1) {
              setDataTercero1(response.data);
            }
            if (orden === 2) {
              setDataTercero2(response.data);
            }
            if (orden === 3) {
              setDataTercero3(response.data);
            }
          }
          console.log("datos de tercero >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 7", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const obtenerAtributoPorId = (idBuscado: number): string => {
    const objetoEncontrado = dataClienteActividad.find(
      (objeto) => objeto.tcaId === idBuscado
    );
    return objetoEncontrado ? objetoEncontrado.tcaDescripcion : "";
  };

  const obtenerPesoValorPorId = (idBuscado: number): number => {
    const objetoEncontrado = dataPesosTodo.find(
      (objeto) => objeto.supId === idBuscado
    );
    return objetoEncontrado ? objetoEncontrado.supPeso : 0;
  };

  const obtenerPesoDescPorId = (idBuscado: number): string => {
    const objetoEncontrado = dataPesosTodo.find(
      (objeto) => objeto.supId === idBuscado
    );
    return objetoEncontrado ? objetoEncontrado.supDescripcion : "";
  };

  async function getDatosConozca() {
    try {
      await axios
        .get<UpdateTablaRentaConozcaType>(
          "https://apisatelite.azurewebsites.net/tablarentaconozca/" +
          String(params.idcrm)
        )
        .then((response) => {
          if (response.data.trcId > 0) {
            setDataConozca(response.data);
            setCheked({
              ...cheked,
              persona_natural: response.data.es_juridica === 0 ? true : false,
              // beneficiario_concli: response.data.esBeneficiario === 1 ? true:false,
              pep_1: response.data.esPep1 === 1 ? true : false,
              pep_2: response.data.esPep2 === 1 ? true : false,
            });
            seteoActividad(response.data.combo7);
            //pendiente
            // getListadoUafePeso("provincia", "reporta", response.data.comboSup5);
            // getListadoUafePeso("canton", "reporta", response.data.comboSup3);
            //  setMostrar2(true)
          }
          console.log("datos de conozca >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 6", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getInfoNegocio() {
    try {
      await axios
        .get<TablaRentaDocumentoType>(
          "https://apisatelite.azurewebsites.net/vtablarentadocumento/" +
          String(params.idcrm)
        )
        .then((response) => {
          if (response.data.id > 0) {
            setDataInfoNegocio(response.data);
            setMostrar3(true)
          }
          console.log("datos de tercero >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 5", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getClienteActividad() {
    try {
      await axios
        .get<ClienteActividadType[]>(
          "https://apisatelite.azurewebsites.net/tablarentaclienteactividad"
        )
        .then((response) => {
          setDataActividad(response.data);
          //    setMostrar1(true)
        })
        .catch((error) => {
          console.log("algo paso >> 4", error);
        });

      console.log("data es >>", JSON.stringify(dataClienteActividad, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  // async function getListadoUafePeso(
  //   tipo: string,
  //   condicion: string,
  //   id: number
  // ) {
  //   try {
  //     await axios
  //       .get<SateliteUafePesoType[]>(
  //         "https://apisatelite.azurewebsites.net/sateliteuafepeso/" +
  //           condicion +
  //           "/" +
  //           String(id)
  //       )
  //       .then((response) => {
  //         if (tipo === "peso1") {
  //           setDataPeso1(response.data);
  //         }
  //         if (tipo === "peso2") {
  //           setDataPeso2(response.data);
  //         }
  //         if (tipo === "peso6") {
  //           setDataPeso6(response.data);
  //         }
  //         if (tipo === "peso7") {
  //           setDataPeso7(response.data);
  //         }
  //         if (tipo === "pais") {
  //           setDataPais(response.data);
  //         }
  //         if (tipo === "provincia") {
  //           setDataProvincia(response.data);
  //         }
  //         if (tipo === "canton") {
  //           setDataCanton(response.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log("algo paso error de lista pesos ", tipo, error);
  //       });
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log("error message: ", error.message);
  //       return error.message;
  //     } else {
  //       console.log("algo paso unexpected error: ", error);
  //       return "An unexpected error occurred";
  //     }
  //   }
  // }

  async function getListadoUafePesoTodo() {
    try {
      await axios
        .get<SateliteUafePesoType[]>(
          "https://apisatelite.azurewebsites.net/sateliteuafepeso"
        )
        .then((response) => {
          setDataPesosTodo(response.data);
          //setMostrar5(true)
        })
        .catch((error) => {
          console.log("algo paso error de lista pesos lista total", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getvTablaRentaCrm(idCrm: string) {
    try {
      await axios
        .get<vTablaRentaCrmResponse>(
          "https://apisatelite.azurewebsites.net/tablarentacrm/" + idCrm

          //parametrosCrmData.idCotizacion
        )
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 3", error);
          //setError(error);
          console.log("alerta por activar >>> en catch");
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
        console.log("alerta por activar >>> en catch");
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
        console.log("alerta por activar >>> en catch");
      }
    }
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const dataListaAutoriza: autorizaType[] = [];
  const [listaAutorizaRows, setListaAutorizaRows] =
    useState<autorizaType[]>(dataListaAutoriza);

  type autorizaType = {
    traId: number;
    idZona: number;
    idMarca: number;
    traNombre: string;
    traCorreo: string;
    zona: string;
    marca: string;
    nivel: string;
  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const styleModalCCli = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [openCorreo, setOpenCorreo] = React.useState(false);
  const handleCloseCorreo = () => setOpenCorreo(false);
  const handleCloseAccionistas = () => setOpenDialogAccionistas(false);
  const params = useParams();

  type tablaRentaType = {
    tarId: number;
    tarFecha: string;
    tarNumeroCotizacion: string;
    tarAgencia: string;
    tarAsesor: string;
    tarCedulaCliente: string;
    tarNombreCliente: string;
    tarModelo: string;
    tarModeloAnio: number;
    tarColor: string;
    tarDiasInv: number;
    tarEscenarioFlota: string;
    tarEscenarioFlotaPorcentaje: number;
    tarFormaPago: string;
    tarAnticipos: number;
    tarPvpMarca: number;
    tarDealerNet: number;
    tarDealerNetValor: number;
    tarMargenBruto: number;
    tarMargenBrutoValor: number;
    tarDescCirular: number;
    tarDescCirularValor: number;
    tarDescConcecionario: number;
    tarDescConcecionarioValor: number;
    tarDescTotal: number;
    tarDescTotalValor: number;
    tarRebate: number;
    tarRebateValor: number;
    tarUtilidadBruta: number;
    tarUtilidadBrutaValor: number;
    tarTotalFacturar: number;
    tarNcConcesionario: number;
    tarNcMarca: number;
    tarValorObsequios: number;
    tarAccDescripcion: string;
    tarAccIncluyeFac: number;
    tarAccValor: number;
    tarDispDescripcion: string;
    tarDispIncluyeFac: number;
    tarDispValor: number;
    tarObservaciones: string;
    tarDestinatario: string;
    tarCorreo: string;
    tarCorreoRemitente: string;
    tarEnviado: number;
    tarNotificado: number;
    tarEstado: number;
    tarMantenimientosPrepa: number;

    fpropiosUsd: number;
    fpropiosPorce: number;
    fpropiosDetalle: string;
    ffinancieraUsd: number;
    ffinancieraPorce: number;
    ffinancieraDetalle: string;
    cinternoUsd: number;
    cinternoPorce: number;
    cinternoDetalle: string;
    vusadoUsd: number;
    vusadoPorce: number;
    vusadoDetalle: string;
    ftercerosUsd: number;
    ftercerosPorce: number;
    ftercerosDetalle: string;
    esBeneficiario: number;
    esPep1: number;
    esPep2: number;
    beneficiarioNombre: string;
    beneficiarioCi: string;
    beneficiarioSexo: string;
    beneficiarioPais: string;
    beneficiarioParentesco: string;
    pep1Cargo: string;
    pep1Fecha: string;
    pep2Nombre: string;
    pep2Cargo: string;
    pep2Fecha: string;

    estadoCivil: string;
    ciudad: string;
    correoCliente: string;
    telefonoCliente: string;
    direccionCliente: string;
    lugarTrabajo: string;
    sexoCliente: string;
    nombreConyuge: string;
    provincia: string;
    cargoCliente: string;
    direccionEmpresa: string;
    telefonoTrabajo: string;

    cliIngresos: number;
    cliEgresos: number;
    cliActivos: number;
    cliPasivos: number;
    patrimonio: number;
    actividadCliente: string;

    repre_cedula: string;
    repre_nombres: string;
    repre_sexo: string;
    repre_nacionalidad: string;
    repre_correo: string;
    repre_civil: string;
    repre_cedula_cony: string;
    repre_nombres_cony: string;

    repre_provincia: string;
    repre_ciudad: string;
    repre_telefono: string;
    repre_direccion: string;

    acc_cedula1: string;
    acc_nombres1: string;
    acc_participacion1: number;
    acc_nacionalidad1: string;
    acc_actividad1: string;
    acc_cargo1: string;
    acc_cedula2: string;
    acc_nombres2: string;
    acc_participacion2: number;
    acc_nacionalidad2: string;
    acc_actividad2: string;
    acc_cargo2: string;
    acc_cedula3: string;
    acc_nombres3: string;
    acc_participacion3: number;
    acc_nacionalidad3: string;
    acc_actividad3: string;
    acc_cargo3: string;

    es_juridica: number;
  };

  type autorizacionType = {
    tauId: number;
    tarId: number;
    tauEsOtroCanton: number;
    tauOtroCanton: string;
    tauFinanciera: string;
    tauFinanciamientoValor: number;
    tauFinanciamientoReferencia: string;
    tauFinanciamientoFecha: string;
    tauEntradaValor: number;
    tauEntradaReferencia: string;
    tauEntradaFecha: string;
    tauNcValor: number;
    tauNcReferencia: string;
    tauNcFecha: string;
    tauSaldoValor: number;
    tauSaldoReferencia: string;
    tauSaldoFecha: string;
    tauUsadoValor: number;
    tauUsadoReferencia: string;
    tauUsadoFecha: string;
    tauTotalValor: number;
    tauDispositivo: number;
    tauDispositivoValor: number;
    tauDispositivoDetalle: string;
    tauDispositivoNotas: string;
    tauSeguro: number;
    tauSeguroValor: number;
    tauSeguroDetalle: string;
    tauSeguroNotas: string;
    tauAcc: number;
    tauAccValor: number;
    tauAccDetalle: string;
    tauAccNotas: string;
    tauObsequio: number;
    tauObsequioValor: number;
    tauObsequioDetalle: string;
    tauObsequioNotas: string;
  };

  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const MyInput = (value: any, onChange: (event: any) => void, name: any) => (
    <div>
      <TextField value={value} onChange={onChange} name={name} />
    </div>
  );

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"} sx={{ mt: 1 }}>
                Conozca a su Cliente
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Box>
                <Typography sx={{ mt: 1, mb: 1, marginLeft: 10 }} variant="h6">
                  <strong> Persona Natural o Juridica</strong>
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "35%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cliente es persona natural:
                  </Typography>
                  <Switch
                    checked={cheked.persona_natural}
                    onChange={handleCheked}
                    name="persona_natural"
                    sx={{ mt: 0.5, mb: 0.5 }}
                  />
                </Box>

                {cheked.persona_natural ? (
                  <></>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      sx={{ mt: 0.5, mb: 0.5, marginLeft: "40%", width: "20%" }}
                      onClick={handleClickOpenAccionistas}
                    >
                      Persona Juridica
                    </Button>

                    <Modal
                      open={openDialogAccionistas}
                      onClose={handleCloseAccionistas}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={styleModalCCli}>
                        <Grid>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Información del representante legal
                          </Typography>
                        </Grid>
                        <Grid>
                          <TextField
                            name="repre_cedula"
                            size="small"
                            margin="normal"
                            label="Cédula"
                            value={dataConozca.repre_cedula}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_nombres"
                            size="small"
                            margin="normal"
                            label="Apellidos y Nombres"
                            value={dataConozca.repre_nombres}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "69%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_sexo"
                            size="small"
                            margin="normal"
                            label="Sexo"
                            value={dataConozca.repre_sexo}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "15%" }}
                          ></TextField>
                          <TextField
                            name="repre_nacionalidad"
                            size="small"
                            margin="normal"
                            label="Nacionalidad"
                            value={dataConozca.repre_nacionalidad}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_correo"
                            size="small"
                            margin="normal"
                            label="Correo"
                            value={dataConozca.repre_correo}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "69%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_civil"
                            size="small"
                            margin="normal"
                            label="Estado civil"
                            value={dataConozca.repre_civil}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "15%" }}
                          ></TextField>

                          <TextField
                            name="repre_provincia"
                            size="small"
                            margin="normal"
                            label="Provincia"
                            value={dataConozca.repre_provincia}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_ciudad"
                            size="small"
                            margin="normal"
                            label="Ciudad"
                            value={dataConozca.repre_ciudad}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_telefono"
                            size="small"
                            margin="normal"
                            label="Teléfono"
                            value={dataConozca.repre_telefono}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_direccion"
                            size="small"
                            margin="normal"
                            label="Dirección"
                            value={dataConozca.repre_direccion}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "55%" }}
                          ></TextField>

                          <TextField
                            name="repre_cedula_cony"
                            size="small"
                            margin="normal"
                            label="Cédula conyuge"
                            value={dataConozca.repre_cedula_cony}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 3,
                              mb: 1,
                              width: "14%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="repre_nombres_cony"
                            size="small"
                            margin="normal"
                            label="Nombre conyuge"
                            value={dataConozca.repre_nombres_cony}
                            onChange={dataDatosConozca}
                            sx={{ mt: 3, mb: 1, width: "85%" }}
                          ></TextField>
                        </Grid>
                        <Divider sx={{ mt: 2, mb: 1.5 }} />
                        <Grid>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            Ingrese los accionistas
                          </Typography>
                        </Grid>
                        <Grid>
                          <TextField
                            name="acc_cedula1"
                            size="small"
                            margin="normal"
                            label="Cédula"
                            value={dataConozca.acc_cedula1}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nombres1"
                            size="small"
                            margin="normal"
                            label="Nombres"
                            value={dataConozca.acc_nombres1}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "23%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_participacion1"
                            size="small"
                            type="number"
                            margin="normal"
                            label="% Participación"
                            value={dataConozca.acc_participacion1}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nacionalidad1"
                            size="small"
                            margin="normal"
                            label="Nacionalidad"
                            value={dataConozca.acc_nacionalidad1}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "15%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_actividad1"
                            size="small"
                            margin="normal"
                            label="Actividad económica"
                            value={dataConozca.acc_actividad1}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "18%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_cargo1"
                            size="small"
                            margin="normal"
                            label="Cargo"
                            value={dataConozca.acc_cargo1}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "15%" }}
                          ></TextField>
                        </Grid>
                        <Grid>
                          <TextField
                            name="acc_cedula2"
                            size="small"
                            margin="normal"
                            label="Cédula"
                            value={dataConozca.acc_cedula2}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nombres2"
                            size="small"
                            margin="normal"
                            label="Nombres"
                            value={dataConozca.acc_nombres2}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "23%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_participacion2"
                            size="small"
                            margin="normal"
                            type="number"
                            label="% Participación"
                            value={dataConozca.acc_participacion2}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nacionalidad2"
                            size="small"
                            margin="normal"
                            label="Nacionalidad"
                            value={dataConozca.acc_nacionalidad2}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "15%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_actividad2"
                            size="small"
                            margin="normal"
                            label="Actividad económica"
                            value={dataConozca.acc_actividad2}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "18%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_cargo2"
                            size="small"
                            margin="normal"
                            label="Cargo"
                            value={dataConozca.acc_cargo2}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "15%" }}
                          ></TextField>
                        </Grid>
                        <Grid>
                          <TextField
                            name="acc_cedula3"
                            size="small"
                            margin="normal"
                            label="Cédula"
                            value={dataConozca.acc_cedula3}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nombres3"
                            size="small"
                            margin="normal"
                            label="Nombres"
                            value={dataConozca.acc_nombres3}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "23%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_participacion3"
                            size="small"
                            margin="normal"
                            label="% Participación"
                            type="number"
                            value={dataConozca.acc_participacion3}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "12%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_nacionalidad3"
                            size="small"
                            margin="normal"
                            label="Nacionalidad"
                            value={dataConozca.acc_nacionalidad3}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "15%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_actividad3"
                            size="small"
                            margin="normal"
                            label="Actividad económica"
                            value={dataConozca.acc_actividad3}
                            onChange={dataDatosConozca}
                            sx={{
                              mt: 1,
                              mb: 1,
                              width: "18%",
                              marginRight: "1%",
                            }}
                          ></TextField>
                          <TextField
                            name="acc_cargo3"
                            size="small"
                            margin="normal"
                            label="Cargo"
                            value={dataConozca.acc_cargo3}
                            onChange={dataDatosConozca}
                            sx={{ mt: 1, mb: 1, width: "15%" }}
                          ></TextField>
                        </Grid>
                        <Grid></Grid>
                      </Box>
                    </Modal>
                  </>
                )}

                <Typography sx={{ mt: 1, mb: 1, marginLeft: 10 }} variant="h6">
                  <strong> Procedencia de fondos</strong>
                </Typography>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Fondos propios:
                  </Typography>
                  <TextField
                    name="fpropiosUsd"
                    size="small"
                    margin="normal"
                    label="$"
                    type="number"
                    value={dataConozca.fpropiosUsd}
                    onChange={dataDatosConozca}
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  ></TextField>
                  <TextField
                    name="fpropiosporce"
                    size="small"
                    margin="normal"
                    label="%"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    value={
                      dataConozca &&
                      Number(
                        (Number(dataConozca.fpropiosUsd) /
                          (Number(dataConozca.fpropiosUsd) +
                            Number(dataConozca.ffinancieraUsd) +
                            Number(dataConozca.cinternoUsd) +
                            Number(dataConozca.vusadoUsd) +
                            Number(dataConozca.ftercerosUsd))) *
                        100
                      ).toFixed(2)
                    }
                    // onChange={dataDatosConozca}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "12%" }}
                  ></TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Detalle"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo1}
                    onChange={handleChangeConCliDetalle1}
                    sx={{ mt: 1, mb: 1, width: "30%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedencia.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Fondos Financiera:
                  </Typography>
                  <TextField
                    name="ffinancieraUsd"
                    size="small"
                    margin="normal"
                    label="$"
                    type="number"
                    onChange={dataDatosConozca}
                    value={dataConozca.ffinancieraUsd}
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  ></TextField>
                  <TextField
                    name="ffinancieraporce"
                    size="small"
                    margin="normal"
                    label="%"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      dataConozca &&
                      Number(
                        (Number(dataConozca.ffinancieraUsd) /
                          (Number(dataConozca.fpropiosUsd) +
                            Number(dataConozca.ffinancieraUsd) +
                            Number(dataConozca.cinternoUsd) +
                            Number(dataConozca.vusadoUsd) +
                            Number(dataConozca.ftercerosUsd))) *
                        100
                      ).toFixed(2)
                    }
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "12%" }}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                  ></TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Detalle"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo2}
                    onChange={handleChangeConCliDetalle2}
                    sx={{ mt: 1, mb: 1, width: "30%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedenciaCred.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Crédito interno:
                  </Typography>
                  <TextField
                    name="cinternoUsd"
                    size="small"
                    margin="normal"
                    label="$"
                    type="number"
                    value={dataConozca.cinternoUsd}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    onChange={dataDatosConozca}
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                  ></TextField>
                  <TextField
                    name="cinternoporce"
                    size="small"
                    margin="normal"
                    label="%"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      dataConozca &&
                      Number(
                        (Number(dataConozca.cinternoUsd) /
                          (Number(dataConozca.fpropiosUsd) +
                            Number(dataConozca.ffinancieraUsd) +
                            Number(dataConozca.cinternoUsd) +
                            Number(dataConozca.vusadoUsd) +
                            Number(dataConozca.ftercerosUsd))) *
                        100
                      ).toFixed(2)
                    }
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "12%" }}
                  ></TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Detalle"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo3}
                    onChange={handleChangeConCliDetalle3}
                    sx={{ mt: 1, mb: 1, width: "30%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedencia.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Toma de vehículo:
                  </Typography>
                  <TextField
                    name="vusadoUsd"
                    size="small"
                    margin="normal"
                    label="$"
                    type="number"
                    value={dataConozca.vusadoUsd}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    onChange={dataDatosConozca}
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                  ></TextField>
                  <TextField
                    name="vusadoporce"
                    size="small"
                    margin="normal"
                    label="%"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      dataConozca &&
                      Number(
                        (Number(dataConozca.vusadoUsd) /
                          (Number(dataConozca.fpropiosUsd) +
                            Number(dataConozca.ffinancieraUsd) +
                            Number(dataConozca.cinternoUsd) +
                            Number(dataConozca.vusadoUsd) +
                            Number(dataConozca.ftercerosUsd))) *
                        100
                      ).toFixed(2)
                    }
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "12%" }}
                  ></TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Detalle"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo4}
                    onChange={handleChangeConCliDetalle4}
                    sx={{ mt: 1, mb: 1, width: "30%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedencia.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Fondos de terceros:
                  </Typography>
                  <TextField
                    name="ftercerosUsd"
                    size="small"
                    margin="normal"
                    label="$"
                    type="number"
                    value={dataConozca.ftercerosUsd}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    onChange={dataDatosConozca}
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                  ></TextField>
                  <TextField
                    name="ftercerosporce"
                    size="small"
                    margin="normal"
                    label="%"
                    type="number"
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      dataConozca &&
                      Number(
                        (Number(dataConozca.ftercerosUsd) /
                          (Number(dataConozca.fpropiosUsd) +
                            Number(dataConozca.ffinancieraUsd) +
                            Number(dataConozca.cinternoUsd) +
                            Number(dataConozca.vusadoUsd) +
                            Number(dataConozca.ftercerosUsd))) *
                        100
                      ).toFixed(2)
                    }
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "12%" }}
                  ></TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Detalle"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo5}
                    onChange={handleChangeConCliDetalle5}
                    sx={{ mt: 1, mb: 1, width: "30%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedencia.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginRight: "1%",
                      width: "25%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Total:</strong>
                  </Typography>
                  <TextField
                    name="tot"
                    size="small"
                    margin="normal"
                    label="$"
                    //type="number"
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                    }}
                    style={{ backgroundColor: "rgb(242,242,242)" }}
                    value={
                      formatter.format(Number(dataConozca.fpropiosUsd) +
                        Number(dataConozca.ffinancieraUsd) +
                        Number(dataConozca.cinternoUsd) +
                        Number(dataConozca.vusadoUsd) +
                        Number(dataConozca.ftercerosUsd)).replace("$", "$ ")
                    }
                    sx={{ mt: 1, mb: 1, width: "12%" }}
                  ></TextField>
                  <Typography
                    sx={{
                      mt: 2,
                      mb: 1,
                      marginLeft: "1%",
                      width: "20%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    <strong>Valor a facturar:  </strong>{" "}
                    {formatter.format(registroTabla.tarTotalFacturar).replace("$", "$ ")}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      marginLeft: "1%",
                      width: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    * Falta:
                    {formatter.format(Number(registroTabla.tarTotalFacturar) -
                      Number(
                        Number(dataConozca.fpropiosUsd) +
                        Number(dataConozca.ffinancieraUsd) +
                        Number(dataConozca.cinternoUsd) +
                        Number(dataConozca.vusadoUsd) +
                        Number(dataConozca.ftercerosUsd)
                      )).replace("$", "$  ")}
                  </Typography>
                </Box>

                <Divider sx={{ mt: 2, mb: 1.5 }} />
                <Typography sx={{ mt: 1, mb: 1, marginLeft: 10 }} variant="h6">
                  <strong>Beneficiario final</strong>
                </Typography>

                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "35%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cliente es beneficiario final del vehículo:
                  </Typography>
                  <Switch
                    checked={cheked.beneficiario_concli}
                    onChange={handleCheked}
                    name="beneficiario_concli"
                    sx={{ mt: 0.5, mb: 0.5 }}
                  />
                </Box>

                {cheked.beneficiario_concli ? (
                  <> </>
                ) : (
                  <>
                    <Box>
                      <TextField
                        name="beneficiarioCi"
                        size="small"
                        margin="normal"
                        label="N° Identificación"
                        value={dataConozca.beneficiarioCi}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                      ></TextField>
                      <TextField
                        name="beneficiarioNombre"
                        size="small"
                        margin="normal"
                        label="Nombre"
                        value={dataConozca.beneficiarioNombre}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "60%" }}
                      ></TextField>
                    </Box>

                    <Box>
                      <TextField
                        name="beneficiarioSexo"
                        size="small"
                        margin="normal"
                        label="Sexo"
                        value={dataConozca.beneficiarioSexo}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                      ></TextField>
                      <TextField
                        name="beneficiarioParentesco"
                        size="small"
                        margin="normal"
                        label="Parentesco"
                        value={dataConozca.beneficiarioParentesco}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "35%" }}
                      ></TextField>

                      <TextField
                        name="beneficiarioPais"
                        size="small"
                        margin="normal"
                        label="Nacionalidad"
                        value={dataConozca.beneficiarioPais}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "23%" }}
                      ></TextField>
                    </Box>
                  </>
                )}

                <Divider sx={{ mt: 2, mb: 1.5 }} />
                <Typography sx={{ mt: 1, mb: 1, marginLeft: 10 }} variant="h6">
                  <strong>Información Económica</strong>
                </Typography>
                <Box>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="País"
                    size="small"
                    margin="normal"
                    value={dataConozca.comboSup5}
                    onChange={handleChangePais}
                    sx={{
                      mt: 0.5,
                      mb: 0.5,
                      width: "26%",
                      marginLeft: 10,
                      marginRight: dataConozca.comboSup5 === 345 ? "0%" : "60%",
                    }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataPesosTodo.filter((obj) => {
                      return obj.supTabla === 5;
                    }).map((option) => (
                      <MenuItem key={option.supId} value={option.supId}>
                        {option.supDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>

                  {dataConozca.comboSup5 === 345 ? (
                    <>
                      {" "}
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Provincia"
                        size="small"
                        margin="normal"
                        value={dataConozca.comboSup3}
                        onChange={handleChangeProvincia}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "27%",
                          marginLeft: "1%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 400,
                            },
                          },
                        }}
                      >
                        {dataPesosTodo.filter((obj) => {
                          return obj.supTabla === 3;
                        }).map((option) => (
                          <MenuItem key={option.supId} value={option.supId}>
                            {option.supDescripcion}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Cantón"
                        size="small"
                        margin="normal"
                        value={dataConozca.comboSup4}
                        onChange={handleChangeCanton}
                        sx={{
                          mt: 0.5,
                          mb: 0.5,
                          width: "27%",
                          marginLeft: "1%",
                        }}
                        SelectProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            style: {
                              maxHeight: 400,
                            },
                          },
                        }}
                      >
                        {



                          dataPesosTodo.filter((obj) => {
                            return obj.supReporta === dataConozca.comboSup3;
                          })


                            .map((option) => (
                              <MenuItem key={option.supId} value={option.supId}>
                                {option.supDescripcion}
                              </MenuItem>
                            ))}
                      </TextField>
                    </>
                  ) : (
                    <></>
                  )}

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Abstenciones para iniciar actividad económica"
                    size="small"
                    margin="normal"
                    value={dataConozca.comboSup1}
                    onChange={handleChangePeso1}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {
                      dataPesosTodo.filter((obj) => {
                        return obj.supTabla === 1;
                      })
                        .map((option) => (
                          <MenuItem key={option.supId} value={option.supId}>
                            {option.supDescripcion}
                          </MenuItem>
                        ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Información adicional"
                    size="small"
                    margin="normal"
                    value={dataConozca.comboSup2}
                    onChange={handleChangePeso2}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataPesosTodo.filter((obj) => {
                      return obj.supTabla === 2;
                    }).map((option) => (
                      <MenuItem key={option.supId} value={option.supId}>
                        {option.supDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Forma de pago"
                    size="small"
                    margin="normal"
                    value={dataConozca.comboSup6}
                    onChange={handleChangePeso6}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataPesosTodo.filter((obj) => {
                      return obj.supTabla === 6;
                    }).map((option) => (
                      <MenuItem key={option.supId} value={option.supId}>
                        {option.supDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo de cliente"
                    size="small"
                    margin="normal"
                    value={dataConozca.comboSup7}
                    onChange={handleChangePeso7}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataPesosTodo.filter((obj) => {
                      return obj.supTabla === 7;
                    }).map((option) => (
                      <MenuItem key={option.supId} value={option.supId}>
                        {option.supDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Actividad económica"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo6}
                    onChange={handleChangeClienteActividadEco}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataClienteActividadEco.map((option) => (
                      <MenuItem key={option.tcaId} value={option.tcaId}>
                        {option.tcaDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label="Actividad cliente"
                    size="small"
                    margin="normal"
                    value={dataConozca.combo7}
                    onChange={handleChangeClienteActividad}
                    sx={{ mt: 0.5, mb: 0.5, width: "82%", marginLeft: 10 }}
                    SelectProps={{
                      MenuProps: {
                        disableScrollLock: true,
                        style: {
                          maxHeight: 400,
                        },
                      },
                    }}
                  >
                    {dataClienteActividad.map((option) => (
                      <MenuItem key={option.tcaId} value={option.tcaId}>
                        {option.tcaDescripcion}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Box>
                  <TextField
                    name="cliIngresos"
                    size="small"
                    margin="normal"
                    label="Ingresos $"
                    type="number"
                    onChange={dataDatosConozca}
                    value={dataConozca.cliIngresos}
                    sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                  ></TextField>
                  <TextField
                    name="cliEgresos"
                    size="small"
                    margin="normal"
                    label="Egresos $"
                    type="number"
                    onChange={dataDatosConozca}
                    value={dataConozca.cliEgresos}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "20%" }}
                  ></TextField>
                  <TextField
                    name="cliActivos"
                    size="small"
                    margin="normal"
                    label="Activos $"
                    type="number"
                    onChange={dataDatosConozca}
                    value={dataConozca.cliActivos}
                    sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "19%" }}
                  ></TextField>
                  <TextField
                    name="cliPasivos"
                    size="small"
                    margin="normal"
                    label="Pasivos $"
                    type="number"
                    onChange={dataDatosConozca}
                    value={dataConozca.cliPasivos}
                    sx={{ mt: 1, mb: 1, marginLeft: "1%", width: "19%" }}
                  ></TextField>
                </Box>

                <Divider sx={{ mt: 2, mb: 1.5 }} />
                <Typography sx={{ mt: 1, mb: 1, marginLeft: 10 }} variant="h6">
                  <strong>Persona Expuesta Políticamente</strong>
                </Typography>

                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "20%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cliente es una PEP:
                  </Typography>
                  <Switch
                    checked={cheked.pep_1}
                    onChange={handleCheked}
                    name="pep_1"
                    sx={{ mt: 0.5, mb: 0.5 }}
                  />
                </Box>

                {cheked.pep_1 ? (
                  <Box>
                    <TextField
                      name="pep1Cargo"
                      size="small"
                      margin="normal"
                      label="Cargo o función"
                      value={dataConozca.pep1Cargo}
                      onChange={dataDatosConozca}
                      sx={{ mt: 1, mb: 1, width: "52%", marginLeft: 10 }}
                    ></TextField>
                    <TextField
                      name="pep1Fecha"
                      size="small"
                      margin="normal"
                      label="F. Nombramiento o finalizacion"
                      value={dataConozca.pep1Fecha}
                      onChange={dataDatosConozca}
                      sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "28%" }}
                    ></TextField>
                  </Box>
                ) : (
                  <></>
                )}
                <Box>
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "20%",
                      marginLeft: 10,
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Cliente está relacionado a una PEP:
                  </Typography>
                  <Switch
                    checked={cheked.pep_2}
                    onChange={handleCheked}
                    name="pep_2"
                    sx={{ mt: 0.5, mb: 0.5 }}
                  />
                </Box>
                {cheked.pep_2 ? (
                  <>
                    <Box>
                      <TextField
                        name="pep2Nombre"
                        size="small"
                        margin="normal"
                        label="Nombre"
                        value={dataConozca.pep2Nombre}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, width: "82%", marginLeft: 10 }}
                      ></TextField>
                    </Box>

                    <Box>
                      <TextField
                        name="pep2Cargo"
                        size="small"
                        margin="normal"
                        label="Cargo o función"
                        value={dataConozca.pep2Cargo}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, width: "52%", marginLeft: 10 }}
                      ></TextField>
                      <TextField
                        name="pep2Fecha"
                        size="small"
                        margin="normal"
                        label="F. Nombramiento o finalizacion"
                        value={dataConozca.pep2Fecha}
                        onChange={dataDatosConozca}
                        sx={{ mt: 1, mb: 1, marginLeft: "2%", width: "28%" }}
                      ></TextField>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                {mostrar3 ? <>
                  <Button
                    variant="contained"
                    sx={{ mt: 2, mb: 1.5, width: "16%", marginLeft: "42%" }}
                    onClick={function (event) {
                      setRegistroConozca(dataConozca);
                      enviarUafe();
                    }}
                  >
                    Imprimir
                  </Button>
                </> : <><Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "50%",
                    marginLeft: "25%",
                    textAlign: "center"
                  }}
                  style={{ display: "inline-block" }}
                >
                  Cargando PDF, espera por favor..
                </Typography></>}

                <Dialog
                  open={openDialog}
                  //onClose={handleCloseDialog}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Correcto"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Datos guardados correctamente
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      // href={
                      //   String(params.idcrm) != "0"
                      //     ? `/ventas/documentos/${params.idcrm}`
                      //     : "/listadotablarenta"
                      // } //  "/listadotablarenta"
                      onClick={handleCloseDialog}
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDialogError}
                  //onClose={handleCloseDialogError}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Advertencia"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Ingrese todos los datos.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialogError} autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openDialogErrorConCli}
                  //onClose={handleCloseDialogError}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Advertencia"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      El valor Total a facturar no es igual al total de
                      Procedencia de fondos.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDialogErrorConCli} autoFocus>
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>

                {/* modal para correo autoriza */}
                <Modal
                  open={openCorreo}
                  onClose={handleCloseCorreo}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Seleccione un aprobador
                    </Typography>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Correo</TableCell>
                            <TableCell>Seleccionar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listaAutorizaRows.map((row) => (
                            <TableRow key={row.traId}>
                              <TableCell component="th" scope="row">
                                {row.traNombre}
                              </TableCell>
                              <TableCell>{row.traCorreo}</TableCell>
                              <TableCell>
                                {" "}
                                <Button onClick={function (event) { }}>
                                  ✓
                                </Button>{" "}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Modal>
              </Box>
            </Paper>

            {dataConozca.ftercerosUsd > 0 ? (
              <>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 3,
                  }}
                >
                  <Typography variant="h5" textAlign={"center"}>
                    Recepción de valores de Terceros
                  </Typography>
                </Paper>
                <Paper
                  sx={{
                    padding: "1.2em",
                    borderRadius: "0.5em",
                    backgroundColor: "rgb(255,255,255,0.95)",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  {/* <Grid flexDirection="row"> */}
                  {/* <Box sx={{ bgcolor: "background.paper" }}>
                    <AppBar position="static">
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                      >
                        <Tab label="Enero" {...a11yProps(0)} />
                        x|
                        <Tab label="Febrero" {...a11yProps(1)} />
                        <Tab label="Marzo" {...a11yProps(2)} />
                      </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0} dir={theme.direction}> */}
                  {/* TERCERO 1 */}
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: 10 }}
                    variant="h6"
                  >
                    <strong>Información básica del Tercero 1</strong>
                  </Typography>
                  <Grid>
                    {/* <MyInput value={dataTercero1.troCedula} onChange={dataDatosTercero1} name = "troCedula" /> */}
                    <TextField
                      label="Cédula"
                      name="troCedula"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCedula}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Nombre"
                      name="troNombre"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troNombre}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "61%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      label="Nacionalidad"
                      name="troNacionalidad"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troNacionalidad}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Dirección"
                      name="troDireccion"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troDireccion}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "61%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      label="Provincia"
                      name="troProvincia"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troProvincia}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Ciudad"
                      name="troCiudad"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCiudad}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Teléfono"
                      name="troTelefono"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troTelefono}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Sexo"
                      name="troSexo"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troSexo}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "19%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      label="Actividad económica"
                      name="troActividad"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troActividad}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "41%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Origen de fondos"
                      name="troOrigenFondos"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troOrigenFondos}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "40%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      label="Cargo"
                      name="troCargo"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCargo}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "41%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Ingresos"
                      name="troIngresos"
                      size="small"
                      margin="normal"
                      type="number"
                      value={dataTercero1.troIngresos}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Egresos"
                      name="troEgresos"
                      size="small"
                      margin="normal"
                      type="number"
                      value={dataTercero1.troEgresos}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "19%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      label="Relación con el cliente"
                      name="troRelacion"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troRelacion}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "20%", marginLeft: 10 }}
                    />
                    <TextField
                      label="Describir"
                      name="troRelacionDescribe"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troRelacionDescribe}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "61%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  {/* aqui lo del bloc */}

                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography
                    sx={{ mt: 1, mb: 1, marginLeft: 10 }}
                    variant="h6"
                  >
                    <strong>Detalle de valores</strong>
                  </Typography>
                  {/* <TextField
                    id="outlined-select-currency"
                    select
                    label="N°Tercero"
                    size="small"
                    margin="normal"
                    value={conCliDetalle1}
                    onChange={handleChangeConCliDetalle1}
                    sx={{ mt: 1, mb: 1, width: "5%", marginLeft: "1%" }}
                  >
                    {dataClienteDetalleProcedencia.map((option) => (
                      <MenuItem key={option.cdpId} value={option.cdpId}>
                        {option.cdpDescripcion}
                      </MenuItem>
                    ))}
                  </TextField> */}

                  <Grid>
                    <TextField
                      select
                      label="Tercero"
                      size="small"
                      margin="normal"
                      value={tercero1}
                      onChange={handleChangeTercero1}
                      sx={{ mt: 1, mb: 1, width: "10%", marginLeft: 6 }}
                    >
                      {(dataTercero3.troCedula.length > 0
                        ? listadoTerceros3
                        : dataTercero2.troCedula.length > 0
                          ? listadoTerceros2
                          : listadoTerceros1
                      ).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Forma de pago"
                      name="troFormapago1"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troFormapago1}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "12%", marginLeft: "1%" }}
                    />
                    <TextField
                      label="Nombre del banco tarjeta habiente"
                      name="troBanco1"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troBanco1}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Cuenta"
                      name="troCuenta1"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCuenta1}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="F.Transaccion"
                      name="troTransaccion1"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troTransaccion1}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Documento"
                      name="troDocumento1"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troDocumento1}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Valor"
                      name="troValor1"
                      size="small"
                      margin="normal"
                      type="number"
                      value={dataTercero1.troValor1}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "8%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      select
                      label="Tercero"
                      size="small"
                      margin="normal"
                      value={tercero2}
                      onChange={handleChangeTercero2}
                      sx={{ mt: 1, mb: 1, width: "10%", marginLeft: 6 }}
                    >
                      {(dataTercero3.troCedula.length > 0
                        ? listadoTerceros3
                        : dataTercero2.troCedula.length > 0
                          ? listadoTerceros2
                          : listadoTerceros1
                      ).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Forma de pago"
                      name="troFormapago2"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troFormapago2}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "12%", marginLeft: "1%" }}
                    />
                    <TextField
                      label="Nombre del banco tarjeta habiente"
                      name="troBanco2"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troBanco2}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Cuenta"
                      name="troCuenta2"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCuenta2}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="F.Transaccion"
                      name="troTransaccion2"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troTransaccion2}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Documento"
                      name="troDocumento2"
                      size="small"
                      value={dataTercero1.troDocumento2}
                      onChange={dataDatosTercero1}
                      margin="normal"
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Valor"
                      name="troValor2"
                      size="small"
                      margin="normal"
                      type="number"
                      value={dataTercero1.troValor2}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "8%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <TextField
                      select
                      label="Tercero"
                      size="small"
                      margin="normal"
                      value={tercero3}
                      onChange={handleChangeTercero3}
                      sx={{ mt: 1, mb: 1, width: "10%", marginLeft: 6 }}
                    >
                      {(dataTercero3.troCedula.length > 0
                        ? listadoTerceros3
                        : dataTercero2.troCedula.length > 0
                          ? listadoTerceros2
                          : listadoTerceros1
                      ).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Forma de pago"
                      name="troFormapago3"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troFormapago3}
                      onChange={dataDatosTercero1}
                      sx={{ mt: 1, mb: 1, width: "12%", marginLeft: "1%" }}
                    />
                    <TextField
                      label="Nombre del banco tarjeta habiente"
                      name="troBanco3"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troBanco3}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "20%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Cuenta"
                      name="troCuenta3"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troCuenta3}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="F.Transaccion"
                      name="troTransaccion3"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troTransaccion3}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="N° Documento"
                      name="troDocumento3"
                      size="small"
                      margin="normal"
                      value={dataTercero1.troDocumento3}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "10%",
                        marginLeft: "1%",
                      }}
                    />
                    <TextField
                      label="Valor"
                      name="troValor3"
                      size="small"
                      margin="normal"
                      type="number"
                      value={dataTercero1.troValor3}
                      onChange={dataDatosTercero1}
                      sx={{
                        mt: 1,
                        mb: 1,
                        width: "8%",
                        marginLeft: "1%",
                      }}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "16%",
                      marginLeft: "42%",
                    }}
                    onClick={function (event) {
                      // if (value === 0) {
                      setRegistroTercero(dataTercero1, 1);
                      getPeticionUafe(
                        username,
                        dataTercero1.troCedula,
                        "",
                        "",
                        dataTercero1.troNombre,
                        "",
                        "Persona Natural",
                        false
                      );
                      //}
                      // if (value === 1) {
                      //   setRegistroTercero(dataTercero2, 2);
                      //   getPeticionUafe(
                      //     username,
                      //     dataTercero2.troCedula,
                      //     "",
                      //     "",
                      //     dataTercero2.troNombre,
                      //     "",
                      //     2
                      //   );
                      // }
                      // if (value === 2) {
                      //   setRegistroTercero(dataTercero3, 3);
                      //   getPeticionUafe(
                      //     username,
                      //     dataTercero3.troCedula,
                      //     "",
                      //     "",
                      //     dataTercero3.troNombre,
                      //     "",
                      //     3
                      //   );
                      // }
                    }}
                  >
                    Imprimir Tercero{String(Number(value) + 1)}
                  </Button>
                </Paper>
              </>
            ) : (
              <></>
            )}

            {/* AUTORIZACION DE FACTURACION */}

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 3,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Autorización de Facturación
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 2,
              }}
            >
              <Grid>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 1, textAlign: "center" }}
                >
                  <strong>Autorizacion de facturación</strong>
                </Typography>
              </Grid>

              <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "10%",
                    marginLeft: "3%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Financiera:
                </Typography>
                <TextField
                  name="tauFinanciera"
                  size="small"
                  margin="normal"
                  value={dataInfoNegocio.financiera}
                  onChange={dataAutorizacion}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                />

                {chekedAuto.otro_canton ? (
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "21%",
                      marginLeft: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Matriculación en otro cantón
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      mt: 1.5,
                      mb: 1,
                      width: "21%",
                      marginLeft: "15%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Matriculación local
                  </Typography>
                )}
                <Switch
                  checked={chekedAuto.otro_canton}
                  onChange={handleChekedAuto}
                  name="otro_canton"
                  sx={{ mt: 0.5, mb: 0.5 }}
                />

                {chekedAuto.otro_canton ? (
                  <TextField
                    name="tauOtroCanton"
                    size="small"
                    margin="normal"
                    label="Canton"
                    value={datosAutoriza.tauOtroCanton}
                    onChange={dataAutorizacion}
                    sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                  />
                ) : (
                  <></>
                )}
              </Box>
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    marginLeft: "3%",
                    width: "7%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Seguro:
                </Typography>
                <Switch
                  checked={chekedAuto.seguro}
                  onChange={handleChekedAuto}
                  name="seguro"
                  sx={{ mt: 0.5, mb: 0.5 }}
                />
                <TextField
                  name="tauSeguroValor"
                  size="small"
                  margin="normal"
                  label="$"
                  type="number"
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={datosAutoriza.tauSeguroValor}
                  onChange={dataAutorizacion}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "1%",
                    width: "15%",
                  }}
                />

                <TextField
                  name="tauSeguroDetalle"
                  size="small"
                  margin="normal"
                  label="Detalle del seguro"
                  value={datosAutoriza.tauSeguroDetalle}
                  onChange={dataAutorizacion}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginLeft: "1%",
                    width: "62%",
                  }}
                />
              </Box>

              <Divider sx={{ mt: 2, mb: 2 }} />
              <Box>
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Financiamiento:
                </Typography>
                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(dataConozca.ffinancieraUsd)
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    marginRight: "2%",
                    width: "16%",
                  }}
                />
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Entrada/Anticipo:
                </Typography>

                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(dataConozca.fpropiosUsd)
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "16%",
                  }}
                />
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Saldo:
                </Typography>

                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(
                      Number(dataConozca.cinternoUsd) +
                      Number(dataConozca.ftercerosUsd)
                    )
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "16%",
                  }}
                />
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Toma de vehículo:
                </Typography>

                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(dataConozca.vusadoUsd)
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "16%",
                  }}
                />

                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>Total a facturar:</strong>
                </Typography>

                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(registroTabla.tarTotalFacturar)
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "16%",
                  }}
                />
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography
                  sx={{
                    mt: 1.5,
                    mb: 1,
                    width: "25%",
                    marginLeft: "28%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Nota de Credito:
                </Typography>

                <TextField
                  size="small"
                  margin="normal"
                  style={{ backgroundColor: "rgb(242,242,242)" }}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                  value={formatter
                    .format(
                      Number(registroTabla.tarNcMarca) +
                      Number(registroTabla.tarNcConcesionario)
                    )
                    .replace("$", "$  ")}
                  sx={{
                    mt: 0.5,
                    mb: 0.5,
                    width: "16%",
                  }}
                />
              </Box>
              {mostrar3 ? <>
                <Button
                  variant="contained"
                  sx={{
                    mt: 1.5,
                    mb: 1.5,
                    width: "20%",
                    marginLeft: "40%",
                    marginRight: "40%",
                    backgroundColor: "rgba(21,57,105,0.75)",
                  }}
                  onClick={function (event) {
                    console.log(
                      "estos son mi datos de autorizacion",
                      datosAutoriza
                    );
                    setNuevaAutorizacion(datosAutoriza, registroTabla.tarId);
                  }}
                >
                  Imprimir
                </Button>
              </> : <><Typography
                sx={{
                  mt: 1.5,
                  mb: 1,
                  width: "50%",
                  marginLeft: "25%",
                  textAlign: "center"
                }}
                style={{ display: "inline-block" }}
              >
                Cargando PDF, espera por favor..
              </Typography></>}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid
          //container
          alignItems={"center"}
          justifyContent={"center"}
        //sx={{ minHeight: "90vh" }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 1.5,

              width: "20%",
              marginLeft: "40%",
            }}
            onClick={() => handleLogin("redirect")}
          //href="/listadotablarenta"
          >
            Inicia sesión
          </Button>
        </Grid>
      </Container>
    );
  }
};
