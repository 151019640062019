import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  AnoType,
  VAppChevroletTraficoErroresType,
  VAppChevroletTraficoType,
  MesType,
  bodegaType,
  marcaType,
} from "../../types";
import * as XLSX from "xlsx";

export const AppChevroletTraficoPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getBodegas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
    } else {
      setName("");
    }
  }, [account]);

  async function descargar() {
    await getListado(ano, mes, agencia);
    await getListadoErrores(ano, mes, agencia);

    setMostrar(true);
  }

  const [errorAsesor, setErrorAsesor] = React.useState(0)
  const [errorCedula, setErrorCedula] = React.useState(0)
  const [errorNombre, setErrorNombre] = React.useState(0)
  const [errorTelefono, setErrorTelefono] = React.useState(0)
  const [errorModelo, setErrorModelo] = React.useState(0)
  const [errorCorreo, setErrorCorreo] = React.useState(0)
  const [contCoticacion, setContCoticacion] = React.useState(0)

  async function conteoError(lista: VAppChevroletTraficoErroresType[]) {
    const condicionAsesor = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR ASESOR NO REGISTRADO EN DWH";
    const condicionCedula = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR CÉDULA";
    const condicionNombre = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR NOMBRE DE CLIENTE";
    const condicionTelefono = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR TELÉFONO";
    const condicionModeloInteres = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR MODELO DE INTERES";
    const condicionCorreo = (objeto: VAppChevroletTraficoErroresType) => objeto.validador === "ERROR CORREO";
    // Filtrar los objetos que cumplen la condición
    const objetosAsesor = lista.filter(condicionAsesor);
    const objetosCedula = lista.filter(condicionCedula);
    const objetosNombre = lista.filter(condicionNombre);
    const objetosTelefono = lista.filter(condicionTelefono);
    const objetosModeloInteres = lista.filter(condicionModeloInteres);
    const objetosCorreo = lista.filter(condicionCorreo);
    // Contar la cantidad de objetos que cumplen la condición
    setErrorAsesor(Number(objetosAsesor.length))
    setErrorCedula(Number(objetosCedula.length))
    setErrorNombre(Number(objetosNombre.length))
    setErrorTelefono(Number(objetosTelefono.length))
    setErrorModelo(Number(objetosModeloInteres.length))
    setErrorCorreo(Number(objetosCorreo.length))
    // console.log("lista inicial >>",lista )
    // console.log("condicion >>", condicion);
    // console.log("objetosQueCumplenCondicion >>", objetosQueCumplenCondicion);
    // console.log(`La cantidad de objetos que cumplen la condición es: ${errorAsesor}`);
  }


  async function conteoOK(lista: VAppChevroletTraficoType[]) {
    const condicionCotizacio = (objeto: VAppChevroletTraficoType) => Number(objeto.prospectos) === 1;
    // Filtrar los objetos que cumplen la condición
    const objetosCotizacion = lista.filter(condicionCotizacio);
    // Contar la cantidad de objetos que cumplen la condición
    setContCoticacion(Number(objetosCotizacion.length))
    // console.log("lista inicial >>",lista )
    // console.log("condicion >>", condicion);
    // console.log("objetosQueCumplenCondicion >>", objetosQueCumplenCondicion);
    // console.log(`La cantidad de objetos que cumplen la condición es: ${errorAsesor}`);
  }


  const listadoMarcas: marcaType[] = [
    { marId: 1, marNombre: "CHEVROLET" },
  ];
  const listadoAgencias: bodegaType[] = [];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(2023);
  const [mes, setMes] = React.useState(12);
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoAgencias);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [agencia, setAgencia] = React.useState(0);
  const [mostrar, setMostrar] = React.useState<boolean>(false);

  const handleChangeMarca = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMarca(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAno = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAno(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeMes = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMes(Number(event.target.value));
    setMostrar(false);
  };
  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
    setMostrar(false);
    console.log(agencia);
  };

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [listaDatos, setListaDatos] = useState<VAppChevroletTraficoType[]>([]);
  const [listaDatosError, setListaDatosError] = useState<
    VAppChevroletTraficoErroresType[]
  >([]);

  async function getListado(
    anio: number,
    mes: number,
    id_bodega: number
  ) {
    try {
      await axios
        .get<VAppChevroletTraficoType[]>(
          "https://apisatelite.azurewebsites.net/appchevrolet/consultar/" +
          String(anio) +
          "/" +
          String(mes) +
          "/" +
          String(id_bodega) +
          "/OK"
        )
        .then((response) => {
          setListaDatos(response.data);
          exportar2(response.data);
          conteoOK(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoErrores(
    anio: number,
    mes: number,
    id_bodega: number
  ) {
    try {
      await axios
        .get<VAppChevroletTraficoErroresType[]>(
          "https://apisatelite.azurewebsites.net/appchevrolet/consultarerrores/" +
          String(anio) +
          "/" +
          String(mes) +
          "/" +
          String(id_bodega) +
          "/OK"
        )
        .then((response) => {
          if (response.data.length > 0) {
            setListaDatosError(response.data);
            exportarError(response.data);
            conteoError(response.data);
          }
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  function exportar2(data: VAppChevroletTraficoType[]) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "datos_chevrolet"; // Nombre del archivo Excel

    // Mapeo de encabezados
    const headersMapping: Record<string, string> = {

      tipo_identificacion: "tipo_identificacion",
      identificacion: "identificacion",
      nombres: "nombres",
      apellidos: "apellidos",
      celular: "celular",
      correo: "correo",
      codigo_bac: "codigo_bac",
      genero: "genero",
      prospectos: "prospectos",
      cotizaciones: "cotizaciones",
      solicitudes: "solicitudes",
      ventas: "ventas",
      tipo_lead: "tipo_lead",
      sucursal: "sucursal",
      fecha: "fecha",
      modelo: "modelo",
      anio: "anio",
      financiera_bpac: "financiera_bpac",
      financiera_guayaquil: "financiera_guayaquil",
      financiera_produbanco: "financiera_produbanco",
      financiera_mupi: "financiera_mupi",
      financiera_cfc: "financiera_cfc",
      financiera_otra: "financiera_otra",
      // ... otros encabezados mapeados
    };

    const formattedData = data.map((item) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (headersMapping[key]) {
          newItem[headersMapping[key]] = (item as any)[key];
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    URL.revokeObjectURL(url);
  }

  function exportarError(data: VAppChevroletTraficoErroresType[]) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "datos_chevrolet_Error"; // Nombre del archivo Excel

    // Mapeo de encabezados
    const headersMapping: Record<string, string> = {
      tipo_identificacion: "tipo_identificacion",
      identificacion: "identificacion",
      nombres: "nombres",
      apellidos: "apellidos",
      celular: "celular",
      correo: "correo",
      codigo_bac: "codigo_bac",
      genero: "genero",
      prospectos: "prospectos",
      cotizaciones: "cotizaciones",
      solicitudes: "solicitudes",
      ventas: "ventas",
      tipo_lead: "tipo_lead",
      sucursal: "sucursal",
      fecha: "fecha",
      modelo: "modelo",
      anio: "anio",
      financiera_bpac: "financiera_bpac",
      financiera_guayaquil: "financiera_guayaquil",
      financiera_produbanco: "financiera_produbanco",
      financiera_mupi: "financiera_mupi",
      financiera_cfc: "financiera_cfc",
      financiera_otra: "financiera_otra",
      id_crm: "id_crm",
      validador: "validador"
      // ... otros encabezados mapeados
    };

    const formattedData = data.map((item) => {
      const newItem: Record<string, any> = {};
      Object.keys(item).forEach((key) => {
        if (headersMapping[key]) {
          newItem[headersMapping[key]] = (item as any)[key];
        }
      });
      return newItem;
    });

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
    URL.revokeObjectURL(url);
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Carga Masiva de Trafico - AppChevrolet
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="comboanio"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={handleChangeAno}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="combomes"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={handleChangeMes}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "20%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="combomarca"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={handleChangeMarca}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "19%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Agencia:
              </Typography>
              <TextField
                id="comboagencia"
                select
                size="small"
                margin="normal"
                value={agencia}
                onChange={handleChangeAgencia}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "19%" }}
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
              >
                {dataAgencia.map((option) => (
                  <MenuItem key={option.bodId} value={option.bodId}>
                    {option.agencia}
                  </MenuItem>
                ))}
              </TextField>
              {/*  {mostrar ? ( */}
              <Button
                id="obtenerxls"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                  marginRight: "44%",
                }}
                onClick={function (event) {
                  descargar();
                }}
              >
                Obtener XLSX
              </Button>
            </Paper>
            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "20%",
                    marginLeft: "44%",
                    marginRight: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>MENSAJES DE CARGA</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>TOTAL REGISTROS GENERADOS:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="total_registros"
                >
                  {Number(listaDatosError.length) + Number(listaDatos.length)}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>TOTAL REGISTROS PARA CARGAR:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="total_para_cargar"
                >
                  {Number(listaDatos.length)}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>COTIZACIONES:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="total_cotizaciones"
                >
                  {contCoticacion}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  <strong>TOTAL REGISTROS CON ERROR:</strong>
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="total_errores"
                >
                  {errorAsesor + errorCedula + errorModelo + errorNombre + errorTelefono + errorCorreo}
                </Typography>
                <Divider sx={{ marginLeft: "20%", marginRight: "20%", mt: 2, mb: 2 }}></Divider>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR ASESOR NO REGISTRADO EN DWH:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_dwh"
                >
                  {errorAsesor}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR CÉDULA:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_cedula"
                >

                  {errorCedula}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR NOMBRE DE CLIENTE:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_nombre"
                >
                  {errorNombre}
                </Typography>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR TELÉFONO:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_telefono"
                >
                  {errorTelefono}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR CORREO:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_correo"
                >
                  {errorCorreo}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "30%",
                    marginLeft: "30%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  ERROR MODELO DE INTERES:
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "10%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                  id="error_modelo"
                >
                  {errorModelo}
                </Typography>


              </Paper>) : <></>}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
