import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as XLSX from "xlsx";
import {
  AnoType,
  canalNegocioType,
  marcaType,
  MesType,
  MucPesosType,
  PlantillaMUCAsesorType,
  PlantillaMUCGuardarType,
  PlantillaMUCResumenType,
  PlantillaMUCType,
} from "../../types";
import { GridCellParams } from "@mui/x-data-grid";

export const MUCPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    getMarcas();
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getPesosParametro(account.username);
    } else {
      setName("");
    }
  }, [account]);


  type lineaType = {
    linId: number;
    linNombre: string;
  };

  const listadoMarcas: marcaType[] = [];
  const listadoLineas: lineaType[] = [
    // { linId: 1, linNombre: "Carc" },
    { linId: 2, linNombre: "Livianos" },
    { linId: 3, linNombre: "Pesados" },
    { linId: 4, linNombre: "Flotas" },
  ];
  const listadoCanales: canalNegocioType[] = [
    { id: 1, descripcion: "SHOWROOM" },
    { id: 2, descripcion: "DIGITAL" },
    { id: 3, descripcion: "BBDD" },
    { id: 4, descripcion: "GESTION EXTERNA" },
    { id: 5, descripcion: "REFERIDOS" },
    { id: 6, descripcion: "RECOMPRA" },
    { id: 7, descripcion: "LLAMADAS" },
  ];
  const listaAnos: AnoType[] = [
    { id: 2023, ano: 2023 },
    { id: 2024, ano: 2024 },
  ];
  const listaMes: MesType[] = [
    { id: 1, mes: "Enero" },
    { id: 2, mes: "Febrero" },
    { id: 3, mes: "Marzo" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Mayo" },
    { id: 6, mes: "Junio" },
    { id: 7, mes: "Julio" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Septiembre" },
    { id: 10, mes: "Octubre" },
    { id: 11, mes: "Noviembre" },
    { id: 12, mes: "Diciembre" },
  ];

  const [listaPlantilla2, setListaPlantilla2] = useState<PlantillaMUCType[]>([]);
  const [listaResumen, setListaResumen] = useState<PlantillaMUCResumenType[]>(
    []
  );


  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const mesActual = fechaActual.getMonth() + 1;
  const [dataMarca, setDataMarca] = useState<marcaType[]>(listadoMarcas);
  const [dataAno, setDataAno] = useState<AnoType[]>(listaAnos);
  const [dataMes, setDataMes] = useState<MesType[]>(listaMes);
  const [dataLinea, setDataLinea] = useState<lineaType[]>(listadoLineas);
  const [marca, setMarca] = React.useState(1);
  const [ano, setAno] = React.useState(anioActual);
  const [mes, setMes] = React.useState(mesActual);
  const [linea, setLinea] = React.useState(2);
  const [canal, setCanal] = React.useState(1);
  const [idBodega, setIdBodega] = React.useState(0);
  const [nombreAsesor, setNombreAsesor] = React.useState("");
  const [mostrar, setMostrar] = React.useState<boolean>(false);
  const [agenciaFiltro, setAgenciaFiltro] = React.useState("");
  const [lineaFiltro, setLineaFiltro] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [openAsesor, setOpenAsesor] = React.useState(false);

  const handleChangeCanal = (event: React.ChangeEvent<HTMLInputElement>) => {
    //obtengo listado por canal
    getListado2(marca === 1
      ? "Chevrolet"
      : marca === 2
        ? "GAC" : marca === 3 ? "Volkswagen" : "", ano, mes, linea === 2 ? "Livianos" : linea === 3 ? "Pesados" : "Flotas", idBodega, Number(event.target.value), false)
    setCanal(Number(event.target.value));

  };

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const styleModalAsesor = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  async function getMarcas() {
    try {
      await axios
        .get<marcaType[]>("https://apisatelite.azurewebsites.net/dimmarca")
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListado2(
    marca: string,
    anio: number,
    mes: number,
    linea: string,
    idBodega: number,
    idCanal: number,
    esEditado: boolean,
  ) {
    console.log("https://apisatelite.azurewebsites.net/vcrmmetasmuc/vista/" +
      marca +
      "/" +
      String(anio) +
      "/" +
      String(mes) +
      "/" +
      linea +
      "/" +
      String(idBodega) +
      "/" +
      String(idCanal))
    try {
      await axios
        .get<PlantillaMUCType[]>(
          "https://apisatelite.azurewebsites.net/vcrmmetasmuc/vista/" +
          marca +
          "/" +
          String(anio) +
          "/" +
          String(mes) +
          "/" +
          linea +
          "/" +
          String(idBodega) +
          "/" +
          String(idCanal)
        )
        .then((response) => {
          sumarTotales(response.data);
          esEditado ? setOpenAsesor(false) : console.log()
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  var totalVentas: number = 0;
  var totalPac: number = 0;
  var totalProsBruta: number = 0;
  var totalGestion: number = 0;
  var totalProsValida: number = 0;
  var totalCita: number = 0;
  var totalCitaConfirmada: number = 0;
  var totalTrafico: number = 0;
  var totalTestDrive: number = 0;
  var totalCotizacion: number = 0;
  var totalSolicitud: number = 0;
  var totalSolicitudApro: number = 0;
  var totalCredito: number = 0;

  function sumarTotales(lista: PlantillaMUCType[]) {
    lista.forEach(function (value) {
      const ventasUnit = Number(value.muc_ventas);
      totalVentas = totalVentas + ventasUnit;

      const ventasPac = Number(value.muc_pac);
      totalPac = totalPac + ventasPac;

      const ventasProsBruta = Number(value.muc_prospeccion_bruta);
      totalProsBruta = totalProsBruta + ventasProsBruta;

      const ventasGestion = Number(value.muc_gestion);
      totalGestion = totalGestion + ventasGestion;

      const ventasProsValida = Number(value.muc_prospeccion_valida);
      totalProsValida = totalProsValida + ventasProsValida;

      const ventasCita = Number(value.muc_cita);
      totalCita = totalCita + ventasCita;

      const ventasCitaConfiramda = Number(value.muc_cita_confirmada);
      totalCitaConfirmada = totalCitaConfirmada + ventasCitaConfiramda;

      const ventasTrafico = Number(value.muc_trafico);
      totalTrafico = totalTrafico + ventasTrafico;

      const ventasTestDrive = Number(value.muc_test_drive);
      totalTestDrive = totalTestDrive + ventasTestDrive;

      const ventasCotizacion = Number(value.muc_cotizacion);
      totalCotizacion = totalCotizacion + ventasCotizacion;

      const ventasSolicitud = Number(value.muc_solicitud);
      totalSolicitud = totalSolicitud + ventasSolicitud;

      const ventasSolicitudApro = Number(value.muc_solicitud_aprobada);
      totalSolicitudApro = totalSolicitudApro + ventasSolicitudApro;

      const ventasCredito = Number(value.muc_credito);
      totalCredito = totalCredito + ventasCredito;

      console.log(totalVentas);
    });

    let regitroTotal: PlantillaMUCType = {
      muc_id: 0,
      muc_anio: 0,
      muc_mes: 0,
      marca: "",
      zona: "",
      agencia: "",
      id_asesor: "",
      nombre_asesor: "TOTAL",
      canal: "",
      linea: "",
      muc_pac: totalPac,
      muc_ventas: totalVentas,
      muc_peso_cierre: 0,
      muc_peso_trafico: 0,
      muc_peso_prospeccion_valida: 0,
      muc_peso_gestion: 0,
      muc_peso_cita: 0,
      muc_peso_cita_confirmada: 0,
      muc_peso_test: 0,
      muc_peso_cotizacion: 0,
      muc_peso_solicitud: 0,
      muc_peso_aprobado: 0,
      muc_peso_vta_credito: 0,
      muc_prospeccion_bruta: totalProsBruta,
      muc_gestion: totalGestion,
      muc_prospeccion_valida: totalProsValida,
      muc_cita: totalCita,
      muc_cita_confirmada: totalCitaConfirmada,
      muc_trafico: totalTrafico,
      muc_test_drive: totalTestDrive,
      muc_cotizacion: totalCotizacion,
      muc_solicitud: totalSolicitud,
      muc_solicitud_aprobada: totalSolicitudApro,
      muc_credito: totalCredito,
    };

    lista.push(regitroTotal);
    setOpen(true)
    setListaPlantilla2(lista);
  }

  const columns: GridColDef[] = [
    {
      headerClassName: "theme-header", field: "marca", headerName: "Marca", editable: false, width: 180
    },
    {

      headerClassName: "theme-header", field: "zona",
      headerName: "Zona",
      width: 180,
      valueGetter: ({ row }) => {
        return "ZONA " + String(row.zona);
      },
    },
    { headerClassName: "theme-header", field: "linea", headerName: "Linea", editable: false, width: 180 },
    { headerClassName: "theme-header", field: "agencia", headerName: "Agencia", editable: false, width: 300 },
    {
      headerClassName: "theme-header", field: "contador",
      headerName: "N° de Asesores",
      editable: false,
      width: 140,
    },

    {
      headerClassName: "theme-header", field: "action",
      headerName: "Detalle",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        //{params.row.Id}
        return (
          <Button
            //href={`/clienterpa/${params.row.idcrm}`}
            onClick={function (event) {
              console.log(" mi row es >>", params.row)
              setAgenciaFiltro(params.row.agencia);
              setLineaFiltro(params.row.linea);
              setLinea(String(params.row.linea).toUpperCase().trim() === "LIVIANOS" ? 2 : params.row.linea === "PESADOS" ? 3 : 4);
              setIdBodega(params.row.id_bodega);
              getListado2(params.row.marca, params.row.muc_anio, params.row.muc_mes, params.row.linea, params.row.id_bodega, canal, false);
            }}
          >
            Abrir
          </Button>
        );
      },
    },
  ];

  const columnsAsesor: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "nombre_asesor",
      headerName: "Asesor",
      editable: false,
      width: 240,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Editar",
      sortable: false,
      width: 80,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => {
              // params.row.id
              console.log("mi row is >>", params.row)
              setOpenAsesor(true);
              setNombreAsesor(params.row.nombre_asesor);
              setRegistroMuc({
                muc_id: params.row.muc_id,
                muc_pac: params.row.muc_pac,
                muc_ventas: params.row.muc_ventas,
                muc_peso_cierre: params.row.muc_peso_cierre,
                muc_peso_trafico: params.row.muc_peso_trafico,
                muc_peso_prospeccion_valida: params.row.muc_peso_prospeccion_valida,
                muc_peso_gestion: params.row.muc_peso_gestion,
                muc_peso_cita: params.row.muc_peso_cita,
                muc_peso_cita_confirmada: params.row.muc_peso_cita_confirmada,
                muc_peso_test: params.row.muc_peso_test,
                muc_peso_cotizacion: params.row.muc_peso_cotizacion,
                muc_peso_solicitud: params.row.muc_peso_solicitud,
                muc_peso_aprobado: params.row.muc_peso_aprobado,
                muc_peso_vta_credito: params.row.muc_peso_vta_credito,
                muc_prospeccion_bruta: params.row.muc_prospeccion_bruta,
                muc_gestion: params.row.muc_gestion,
                muc_prospeccion_valida: params.row.muc_prospeccion_valida,
                muc_cita: params.row.muc_cita,
                muc_cita_confirmada: params.row.muc_cita_confirmada,
                muc_trafico: params.row.muc_trafico,
                muc_test_drive: params.row.muc_test_drive,
                muc_cotizacion: params.row.muc_cotizacion,
                muc_solicitud: params.row.muc_solicitud,
                muc_solicitud_aprobada: params.row.muc_solicitud_aprobada,
                muc_credito: params.row.muc_credito,
                muc_usuario: params.row.muc_usuario,
                muc_fecha_modifica: params.row.muc_fecha_modifica
              });
            }}
          >
            Abrir
          </Button>
        );
      },
    },
    {
      headerClassName: "theme-header",
      field: "muc_ventas",
      headerName: "Ventas",
      editable: false,
      width: 70,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_prospeccion_bruta",
      headerName: "Pros Bruta",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_gestion",
      headerName: "Gestion",
      editable: false,
      width: 70,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_prospeccion_valida",
      headerName: "Pros Valida",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_cita",
      headerName: "Cita",
      editable: false,
      width: 70,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_cita_confirmada",
      headerName: "Cita Conf",
      editable: false,
      width: 75,
      align: "right",
    },

    {
      headerClassName: "theme-header",
      field: "muc_trafico",
      headerName: "Trafico",
      editable: false,
      width: 70,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_test_drive",
      headerName: "Test Drive",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_cotizacion",
      headerName: "Cotizacion",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_solicitud",
      headerName: "Solicitud",
      editable: false,
      width: 70,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_solicitud_aprobada",
      headerName: "Sol.Aprobada",
      editable: false,
      width: 75,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_credito",
      headerName: "Credito",
      editable: false,
      width: 75,
      align: "right",
    },
  ];

  const [pesosMuc, setPesosMuc] = useState<MucPesosType>({
    muc_peso_cierre: 0,
    muc_peso_trafico: 0,
    muc_peso_prospeccion_valida: 0,
    muc_peso_gestion: 0,
    muc_peso_cita: 0,
    muc_peso_cita_confirmada: 0,
    muc_peso_test: 0,
    muc_peso_cotizacion: 0,
    muc_peso_solicitud: 0,
    muc_peso_aprobado: 0,
    muc_peso_vta_credito: 0,
  })

  const [registroMuc, setRegistroMuc] =
    useState<PlantillaMUCGuardarType>({
      muc_id: 0,
      muc_pac: 0,
      muc_ventas: 0,
      muc_peso_cierre: 0,
      muc_peso_trafico: 0,
      muc_peso_prospeccion_valida: 0,
      muc_peso_gestion: 0,
      muc_peso_cita: 0,
      muc_peso_cita_confirmada: 0,
      muc_peso_test: 0,
      muc_peso_cotizacion: 0,
      muc_peso_solicitud: 0,
      muc_peso_aprobado: 0,
      muc_peso_vta_credito: 0,
      muc_prospeccion_bruta: 0,
      muc_gestion: 0,
      muc_prospeccion_valida: 0,
      muc_cita: 0,
      muc_cita_confirmada: 0,
      muc_trafico: 0,
      muc_test_drive: 0,
      muc_cotizacion: 0,
      muc_solicitud: 0,
      muc_solicitud_aprobada: 0,
      muc_credito: 0,
      muc_usuario: "",
      muc_fecha_modifica: ""
    });

  const HandleChangeRegistroMuc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistroMuc({
      ...registroMuc,
      [e.target.name]: e.target.value,
      muc_peso_cierre: pesosMuc.muc_peso_cierre,
      muc_peso_trafico: canal === 1 ? 1 : pesosMuc.muc_peso_trafico,
      muc_peso_prospeccion_valida: canal === 1 ? 1 : pesosMuc.muc_peso_prospeccion_valida,
      muc_peso_gestion: canal === 1 ? 1 : pesosMuc.muc_peso_gestion,
      muc_peso_cita: canal === 1 ? 1 : pesosMuc.muc_peso_cita,
      muc_peso_cita_confirmada: canal === 1 ? 1 : pesosMuc.muc_peso_cita_confirmada,
      muc_peso_test: pesosMuc.muc_peso_test,
      muc_peso_cotizacion: pesosMuc.muc_peso_cotizacion,
      muc_peso_solicitud: pesosMuc.muc_peso_solicitud,
      muc_peso_aprobado: pesosMuc.muc_peso_aprobado,
      muc_peso_vta_credito: pesosMuc.muc_peso_vta_credito,
      muc_usuario: username,
      muc_fecha_modifica: new Date(Date.now()).toISOString().substring(0, 10),
    });
  };

  const HandleChangePesosMuc = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPesosMuc({
      ...pesosMuc,
      [e.target.name]: e.target.value,
    });
  };

  async function postGuardarRegistro(registro: PlantillaMUCGuardarType) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/crmmetasmuc",
          registro,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response);
          getListado2(marca === 1
            ? "Chevrolet"
            : marca === 2
              ? "GAC" : marca === 3 ? "Volkswagen" : "", ano, mes, linea === 2 ? "Livianos" : linea === 3 ? "Pesados" : "Flotas", idBodega, canal, true)
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getPesosParametro(correo: string) {
    try {
      await axios
        .get<MucPesosType[]>(
          "https://apisatelite.azurewebsites.net/vcrmmetasmuc/parametros/" + correo
        )
        .then((response) => {
          if (response.data.length > 0) {
            setPesosMuc(response.data[0])
          }
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListadoResumen(
    marca: string,
    anio: number,
    mes: number
  ) {
    try {
      await axios
        .get<PlantillaMUCResumenType[]>(
          "https://apisatelite.azurewebsites.net/vcrmmetasmucresumen/buscar/" +
          marca +
          "/" +
          String(anio) +
          "/" +
          String(mes)
        )
        .then((response) => {
          setListaResumen(response.data);
          setMostrar(true);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [openDialog, setOpenDialog] = React.useState(false);

  const columnsShowRoom: GridColDef[] = [
    {
      headerClassName: "theme-header",
      field: "nombre_asesor",
      headerName: "Asesor",
      editable: false,
      width: 450,
    },
    {
      headerClassName: "theme-header",
      field: "action",
      headerName: "Editar",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            onClick={(e) => {
              // params.row.id
              console.log("mi row is >>", params.row)
              setOpenAsesor(true);
              setNombreAsesor(params.row.nombre_asesor)
              setRegistroMuc({
                muc_id: params.row.muc_id,
                muc_pac: params.row.muc_pac,
                muc_ventas: params.row.muc_ventas,
                muc_peso_cierre: params.row.muc_peso_cierre,
                muc_peso_trafico: params.row.muc_peso_trafico,
                muc_peso_prospeccion_valida: params.row.muc_peso_prospeccion_valida,
                muc_peso_gestion: params.row.muc_peso_gestion,
                muc_peso_cita: params.row.muc_peso_cita,
                muc_peso_cita_confirmada: params.row.muc_peso_cita_confirmada,
                muc_peso_test: params.row.muc_peso_test,
                muc_peso_cotizacion: params.row.muc_peso_cotizacion,
                muc_peso_solicitud: params.row.muc_peso_solicitud,
                muc_peso_aprobado: params.row.muc_peso_aprobado,
                muc_peso_vta_credito: params.row.muc_peso_vta_credito,
                muc_prospeccion_bruta: params.row.muc_prospeccion_bruta,
                muc_gestion: params.row.muc_gestion,
                muc_prospeccion_valida: params.row.muc_prospeccion_valida,
                muc_cita: params.row.muc_cita,
                muc_cita_confirmada: params.row.muc_cita_confirmada,
                muc_trafico: params.row.muc_trafico,
                muc_test_drive: params.row.muc_test_drive,
                muc_cotizacion: params.row.muc_cotizacion,
                muc_solicitud: params.row.muc_solicitud,
                muc_solicitud_aprobada: params.row.muc_solicitud_aprobada,
                muc_credito: params.row.muc_credito,
                muc_usuario: params.row.muc_usuario,
                muc_fecha_modifica: params.row.muc_fecha_modifica,
              });
            }}
          >
            Abrir
          </Button>
        );
      },
    },

    {
      headerClassName: "theme-header",
      field: "muc_ventas",
      headerName: "Ventas",
      editable: false,
      width: 90,
      align: "right",
    },

    {
      headerClassName: "theme-header",
      field: "muc_trafico",
      headerName: "Trafico",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_test_drive",
      headerName: "Test Drive",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_cotizacion",
      headerName: "Cotizacion",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_solicitud",
      headerName: "Solicitud",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_solicitud_aprobada",
      headerName: "Solicitud Apro",
      editable: false,
      width: 90,
      align: "right",
    },
    {
      headerClassName: "theme-header",
      field: "muc_credito",
      headerName: "Credito",
      editable: false,
      width: 90,
      align: "right",
    },
  ];

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                MUC
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "25%",
                }}
                style={{ display: "inline-block" }}
              >
                Año:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={ano}
                onChange={(e) => setAno(Number(e.target.value))}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "14%" }}
              >
                {dataAno.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.ano}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                }}
                style={{ display: "inline-block" }}
              >
                Mes:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={mes}
                onChange={(e) => setMes(Number(e.target.value))}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "10%", width: "14%" }}
              >
                {dataMes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.mes}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "6%",
                  marginLeft: "25%",
                }}
                style={{ display: "inline-block" }}
              >
                Marca:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                size="small"
                margin="normal"
                value={marca}
                onChange={(e) => setMarca(Number(e.target.value))}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "39%" }}
              >
                {dataMarca.map((option) => (
                  <MenuItem key={option.marId} value={option.marId}>
                    {option.marNombre}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 1,
                  width: "12%",
                  marginLeft: "44%",
                }}
                onClick={function (event) {

                  getListadoResumen(marca === 1
                    ? "Chevrolet"
                    : marca === 2
                      ? "GAC"
                      : "Volkswagen", ano, mes);
                }}
              >
                Consultar
              </Button>
            </Paper>
            <Dialog
              open={openDialog}
              //onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Datos guardados correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/politicacomercial/lista"
                  onClick={function (event) {
                    setOpenDialog(false);
                  }}
                //autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
            {mostrar ? (
              <Paper
                sx={{
                  padding: "1.2em",
                  borderRadius: "0.5em",
                  backgroundColor: "rgb(255,255,255,0.95)",
                  mt: 1,
                  mb: 4,
                }}
              >
                <Box
                  sx={{
                    "& .theme-header": {
                      backgroundColor: "rgba(21,57,105,0.75)",
                      color: "rgba(255,255,255,1)",
                    },
                  }}
                >
                  <DataGrid
                    getRowId={(row) => row.id}
                    sx={{ height: 600 }}
                    editMode="row"
                    rows={listaResumen}
                    columns={columns}
                    initialState={{
                      pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pageSizeOptions={[10, 20, 30]}
                    slotProps={{
                      pagination: {
                        labelRowsPerPage: "Registros por página:",
                      },
                    }}
                    localeText={{
                      noRowsLabel: "No se ha encontrado datos.",
                      noResultsOverlayLabel:
                        "No se ha encontrado ningún resultado",
                      toolbarColumns: "Columnas",
                      toolbarColumnsLabel: "Seleccionar columnas",
                      toolbarFilters: "Filtros",
                      toolbarFiltersLabel: "Ver filtros",
                      toolbarFiltersTooltipHide: "Quitar filtros",
                      toolbarFiltersTooltipShow: "Ver filtros",
                    }}
                  />
                </Box>
                <Modal
                  open={open}
                  onClose={(e) => setOpen(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModal}>
                    <Typography sx={{ mt: 1, mb: 2, textAlign: "center" }}>
                      {" "}
                      <strong>
                        {agenciaFiltro} - {lineaFiltro}
                      </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "8%",
                        ml: "37%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Canal:
                    </Typography>
                    <TextField
                      id="outlined-select-currency"
                      select
                      size="small"
                      margin="normal"
                      value={canal}
                      onChange={handleChangeCanal}
                      sx={{ mt: 1, mb: 1, marginRight: "37%", width: "17%" }}
                    >
                      {listadoCanales.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </TextField>
                    <Divider sx={{ ml: "10%", mr: "10%", mt: 0.5, mb: 0.5 }} />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        ml: "7%",
                        width: "12%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cierre:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_cierre"
                      value={pesosMuc.muc_peso_cierre}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, width: "10%", mr: "10%" }}
                    />
                    {canal === 1 ? <></> : <><Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Tráfico:
                    </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        type="number"
                        name="muc_peso_trafico"
                        value={pesosMuc.muc_peso_trafico}
                        onChange={HandleChangePesosMuc}
                        sx={{ mt: 1, mb: 1, mr: "10%", width: "10%" }}
                      />
                      <Typography
                        sx={{
                          mt: 2,
                          mb: 1,
                          width: "12%"
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Prospección válida:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        type="number"
                        name="muc_peso_prospeccion_valida"
                        value={pesosMuc.muc_peso_prospeccion_valida}
                        onChange={HandleChangePesosMuc}
                        sx={{ mt: 1, mb: 1, width: "10%" }}
                      />
                      <Typography
                        sx={{
                          mt: 0,
                          mb: 1,
                          width: "12%",
                          ml: "7%"
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Gestión:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        type="number"
                        name="muc_peso_gestion"
                        value={pesosMuc.muc_peso_gestion}
                        onChange={HandleChangePesosMuc}
                        sx={{ mt: 1, mb: 1, mr: "10%", width: "10%" }}
                      />
                      <Typography
                        sx={{
                          mt: 0,
                          mb: 1,
                          width: "12%"
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Cita:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        type="number"
                        name="muc_peso_cita"
                        value={pesosMuc.muc_peso_cita}
                        onChange={HandleChangePesosMuc}
                        sx={{ mt: 1, mb: 1, mr: "10%", width: "10%" }}
                      />
                      <Typography
                        sx={{
                          mt: 2,
                          mb: 1,
                          width: "12%"
                        }}
                        style={{ display: "inline-block" }}
                      >
                        Cita confirmada:
                      </Typography>
                      <TextField
                        size="small"
                        margin="normal"
                        type="number"
                        name="muc_peso_cita_confirmada"
                        value={pesosMuc.muc_peso_cita_confirmada}
                        onChange={HandleChangePesosMuc}
                        sx={{ mt: 1, mb: 1, width: "10%" }}
                      /></>}

                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%",
                        ml: canal === 1 ? "0%" : "7%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Test:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_test"
                      value={pesosMuc.muc_peso_test}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, mr: "10%", width: "10%" }}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Cotización:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_cotizacion"
                      value={pesosMuc.muc_peso_cotizacion}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, mr: canal === 1 ? "0%" : "10%", width: "10%" }}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%",
                        ml: canal === 1 ? "7%" : "0%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Solicitud:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_solicitud"
                      value={pesosMuc.muc_peso_solicitud}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, width: "10%", mr: canal === 1 ? "10%" : "0%" }}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%",
                        ml: canal === 1 ? "0%" : "7%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Sol. aprobada:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_aprobado"
                      value={pesosMuc.muc_peso_aprobado}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, mr: "10%", width: "10%" }}
                    />
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "12%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Venta credito:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_peso_vta_credito"
                      value={pesosMuc.muc_peso_vta_credito}
                      onChange={HandleChangePesosMuc}
                      sx={{ mt: 1, mb: 1, width: "10%", mr: "0%" }}
                    />

                    <Box
                      sx={{
                        //height: 300,
                        width: "100%",
                        "& .theme-header": {
                          backgroundColor: "rgba(21,57,105,0.75)",
                          color: "rgba(255,255,255,1)",
                        },
                        "& .verdeoscuro": {
                          backgroundColor: "#BCF39B",
                          color: "#1a3e72",
                        },
                        "& .verde": {
                          backgroundColor: "#CFFFB2",
                          color: "#1a3e72",
                        },
                        "& .naranja": {
                          backgroundColor: "#FEE777",
                          color: "#1a3e72",
                        },
                        "& .amarillo": {
                          backgroundColor: "#FEFFCF",
                          color: "#1a3e72",
                        },
                        "& .gris": {
                          backgroundColor: "#DEDEDE",
                          color: "#1a3e72",
                        },
                        "& .TOTAL": {
                          fontPalette: "dark",
                          fontSize: 16,
                          fontWeight: 'bold'
                        },
                      }}
                    >
                      <DataGrid
                        getRowClassName={(params) => `${params.row.nombre_asesor}`}
                        getCellClassName={(
                          params: GridCellParams<any, any, number>
                        ) => {
                          if (params.value === "TOTAL") {
                            return "totales";
                          }
                          if (params.field === "nombre_asesor") {
                            return "";
                          }
                          if (params.field === "muc_pac") {
                            return "verde";
                          }
                          if (params.field === "muc_ventas") {
                            return "verdeoscuro";
                          }
                          if (
                            params.field === "muc_prospeccion_bruta" ||
                            params.field === "muc_gestion" ||
                            params.field === "muc_prospeccion_valida" ||
                            params.field === "muc_cita" ||
                            params.field === "muc_cita_confirmada"
                          ) {
                            if (canal === 1) {
                              return "gris";
                            } else {
                              return "naranja";
                            }
                          }
                          if (
                            params.field === "muc_trafico" ||
                            params.field === "muc_test_drive" ||
                            params.field === "muc_cotizacion" ||
                            params.field === "muc_solicitud" ||
                            params.field === "muc_solicitud_aprobada" ||
                            params.field === "muc_credito"
                          ) {
                            return "amarillo";
                          }
                          return "";
                          // return params.value >= 15 ? 'hot' : 'cold';
                        }}
                        getRowId={(row) => row.muc_id}
                        sx={{ height: 500 }}
                        editMode="row"
                        rows={listaPlantilla2}
                        columns={canal === 1 ? columnsShowRoom : columnsAsesor}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 20 } },
                        }}
                        pageSizeOptions={[10, 20, 30]}
                        slotProps={{
                          pagination: {
                            labelRowsPerPage: "Registros por página:",
                          },
                        }}
                        localeText={{
                          noRowsLabel: "No se ha encontrado datos.",
                          noResultsOverlayLabel:
                            "No se ha encontrado ningún resultado",
                          toolbarColumns: "Columnas",
                          toolbarColumnsLabel: "Seleccionar columnas",
                          toolbarFilters: "Filtros",
                          toolbarFiltersLabel: "Ver filtros",
                          toolbarFiltersTooltipHide: "Quitar filtros",
                          toolbarFiltersTooltipShow: "Ver filtros",
                        }}
                      />
                    </Box>
                  </Box>
                </Modal>
                {/* modal asesor */}
                <Modal
                  open={openAsesor}
                  onClose={(e) => setOpenAsesor(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={styleModalAsesor}>
                    <Typography sx={{ mt: 1, mb: 3, textAlign: "center" }}>
                      {" "}
                      <strong>
                        {nombreAsesor}
                      </strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 2,
                        mb: 1,
                        width: "30%",
                        ml: "20%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      Ventas:
                    </Typography>
                    <TextField
                      size="small"
                      margin="normal"
                      type="number"
                      name="muc_ventas"
                      value={registroMuc.muc_ventas}
                      onChange={HandleChangeRegistroMuc}
                      sx={{ mt: 1, mb: 1, ml: "5%", width: "25%", mr: "20%" }}
                    />

                    <Button variant="contained" sx={{ mt: 2, mb: 2, ml: "35%", width: "30%" }} onClick={(e) => { console.log("registro a guardar >> ", registroMuc); postGuardarRegistro(registroMuc) }}>
                      Guardar
                    </Button>
                  </Box>
                </Modal>
              </Paper>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
