import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { green } from "@mui/material/colors";
import { Colorize } from "@mui/icons-material";
import { NotificacionWPPType, TablaRentaDocumentoType, TablaRentaType, VTablaRentaAutorizaType } from "../../types";

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1050,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const AprobarTablaPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [openCorreo, setOpenCorreo] = React.useState(false);
  const [openDialogEnviado, setOpenDialogEnviado] = React.useState(false)

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getvTablaRenta();
      setEstado(data.tarEstado);
      getTablaRenta(Number(params.id));

      const userAgent = navigator.userAgent;
      const mobileKeywords = [
        "Mobile",
        "Android",
        "iPhone",
        "iPad",
        "Windows Phone",
      ];

      const checkDeviceType = () => {
        setIsMobile(
          mobileKeywords.some((keyword) => userAgent.includes(keyword))
        );
      };
      // Verifica el tipo de dispositivo al cargar la página
      checkDeviceType();
      // No necesitamos limpiar el listener en este caso
    } else {
      setName("");
    }
  }, [account]);

  const handleLogin = (loginType: string) => {
    setAnchorEl(null);

    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const params = useParams();

  const [estado, setEstado] = useState<number>();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);
  const [openDialogRedireccion, setOpenDialogRedireccion] =
    React.useState(false);


  type TypeDatosInfo = {
    nuevoCorreo: string;
    nuevaObservacion: string;
  };

  const [datosInfo, setDatosInfo] = React.useState<TypeDatosInfo>({
    nuevoCorreo: "",
    nuevaObservacion: "",
  });

  const dataDatosInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatosInfo({
      ...datosInfo,
      [e.target.name]: e.target.value,
    });
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const dataListaAutoriza: VTablaRentaAutorizaType[] = [];
  const [listaAutorizaRows, setListaAutorizaRows] =
    useState<VTablaRentaAutorizaType[]>(dataListaAutoriza);

  async function getvListaAprueba(
    zona: number,
    bodega: number,
    marca: number,
    nivel: number,
    idLinea: number
  ) {
    console.log(
      "zona >>",
      zona,
      ", bodega >>",
      bodega,
      ", marca >>",
      marca,
      ", nivel >>",
      nivel,
      ", linea >>",
      idLinea
    );
    try {
      await axios
        .get<VTablaRentaAutorizaType[]>(
          "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscar/" +
          String(zona) +
          "/" +
          String(bodega) +
          "/" +
          String(marca) +
          "/" +
          String(nivel) +
          "/" +
          String(idLinea)
        )
        .then((response) => {
          setListaAutorizaRows(response.data);
          nivel < 3
            ? getConCopiaNiv2(zona, bodega, marca, idLinea)
            : console.log("");
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(listaAutorizaRows, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [dataConCopiaNiv2, setDataConCopiaNiv2] =
    useState<VTablaRentaAutorizaType>({
      traId: 0,
      idZona: 0,
      idMarca: 0,
      traNombre: "",
      traCorreo: "",
      zona: "",
      marca: "",
      nivel: "",
      idBodega: 0,
      niveles: "",
      familia: "",
      traTelefono: "",
      idLinea: 0,
      linea: "",
    });

  const [dataConCopiaNiv3, setDataConCopiaNiv3] =
    useState<VTablaRentaAutorizaType>({
      traId: 0,
      idZona: 0,
      idMarca: 0,
      traNombre: "",
      traCorreo: "",
      zona: "",
      marca: "",
      nivel: "",
      idBodega: 0,
      niveles: "",
      familia: "",
      traTelefono: "",
      idLinea: 0,
      linea: "",
    });




  async function getConCopiaNiv3(bodega: number, marca: number, linea: number) {
    try {
      await axios
        .get<VTablaRentaAutorizaType>(
          "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscarccagencia/" +
          String(bodega) +
          "/" +
          String(marca) +
          "/3/" +
          String(linea)
        )
        .then((response) => {
          setDataConCopiaNiv3(response.data);
        })
        .catch((error) => {
          console.log("algo paso con copia 2>> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getConCopiaNiv2(
    zona: number,
    bodega: number,
    marca: number,
    linea: number
  ) {
    try {
      await axios
        .get<VTablaRentaAutorizaType>(
          "https://apisatelite.azurewebsites.net/tablarentaautoriza/buscarcczona/" +
          String(zona) +
          "/" +
          String(bodega) +
          "/" +
          String(marca) +
          "/2/" +
          String(linea)
        )
        .then((response) => {
          setDataConCopiaNiv2(response.data);
          getConCopiaNiv3(bodega, marca, linea);
        })
        .catch((error) => {
          console.log("algo paso con copia 2>> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }



  function setTablaRenta(
    esPrimerEnvio: boolean,
    datosTabla: tablaRentaType,
    estado: number,
    estaaprobado: boolean,
    nombre_aprueba: string,
    nuevo_correo: string,
    es_redireccion: boolean,
    volver_a_notificar: number,
    nuevaObservacion: string,
    telefono: string,
  ) {
    console.log("tabla a guardar >> ");
    const nuevaTabla: tablaRentaType = {
      tarId: datosTabla.tarId,
      tarFecha: datosTabla.tarFecha,
      tarNumeroCotizacion: datosTabla.tarNumeroCotizacion,
      tarAgencia: datosTabla.tarAgencia,
      tarAsesor: datosTabla.tarAsesor,
      tarCedulaCliente: datosTabla.tarCedulaCliente,
      tarNombreCliente: datosTabla.tarNombreCliente,
      tarModelo: datosTabla.tarModelo,
      tarModeloAnio: datosTabla.tarModeloAnio,
      tarColor: datosTabla.tarColor,
      tarDiasInv: datosTabla.tarDiasInv,
      tarEscenarioFlota: datosTabla.tarEscenarioFlota,
      tarEscenarioFlotaPorcentaje: datosTabla.tarEscenarioFlotaPorcentaje,
      tarFormaPago: datosTabla.tarFormaPago,
      tarAnticipos: datosTabla.tarAnticipos,
      tarPvpMarca: datosTabla.tarPvpMarca,
      tarDealerNet: datosTabla.tarDealerNet,
      tarDealerNetValor: datosTabla.tarDealerNetValor,
      tarMargenBruto: datosTabla.tarMargenBruto,
      tarMargenBrutoValor: datosTabla.tarMargenBrutoValor,
      tarDescCirular: datosTabla.tarDescCirular,
      tarDescCirularValor: datosTabla.tarDescCirularValor,
      tarDescConcecionario: datosTabla.tarDescConcecionario,
      tarDescConcecionarioValor: datosTabla.tarDescConcecionarioValor,
      tarDescTotal: datosTabla.tarDescTotal,
      tarDescTotalValor: datosTabla.tarDescTotalValor,
      tarRebate: datosTabla.tarRebate,
      tarRebateValor: datosTabla.tarRebateValor,
      tarUtilidadBruta: datosTabla.tarUtilidadBruta,
      tarUtilidadBrutaValor: datosTabla.tarUtilidadBrutaValor,
      tarTotalFacturar: datosTabla.tarTotalFacturar,
      tarNcConcesionario: datosTabla.tarNcConcesionario,
      tarNcMarca: datosTabla.tarNcMarca,
      tarValorObsequios: datosTabla.tarValorObsequios,
      tarAccDescripcion: datosTabla.tarAccDescripcion,
      tarAccIncluyeFac: datosTabla.tarAccIncluyeFac,
      tarAccValor: datosTabla.tarAccValor,
      tarDispDescripcion: datosTabla.tarDispDescripcion,
      tarDispIncluyeFac: datosTabla.tarDispIncluyeFac,
      tarDispValor: datosTabla.tarDispValor,
      tarObservaciones: datosTabla.tarObservaciones,
      tarDestinatario: nombre_aprueba,
      tarCorreo: nuevo_correo,
      tarCorreoRemitente: datosTabla.tarCorreoRemitente,
      tarEnviado: volver_a_notificar,
      tarNotificado: datosTabla.tarNotificado,
      tarEstado: estado,
      tarMantenimientosPrepaDescripcion:
        datosTabla.tarMantenimientosPrepaDescripcion,
      tarMantenimientosPrepa: datosTabla.tarMantenimientosPrepa,
      tarMantenimientosPrepaIncluye: datosTabla.tarMantenimientosPrepaIncluye,
      tarNuevaObservacion: nuevaObservacion,
      fechaCompra: datosTabla.fechaCompra,
      valor_transporte: datosTabla.valor_transporte,
      tarVin: datosTabla.tarVin,
      tarVinAcc: datosTabla.tarVinAcc,
      tarVinAccValor: datosTabla.tarVinAccValor,
      margenNivel: datosTabla.margenNivel,
    };
    console.log("tabla a guardar >> ", nuevaTabla);

    postGuardarTabla(nuevaTabla, esPrimerEnvio, telefono);
    console.log("este es el nuevo estado >>", estaaprobado);
    console.log("es una redireccion? >>", es_redireccion);
    es_redireccion
      ? setOpenDialogRedireccion(true)
      : estaaprobado && esPrimerEnvio === false
        ? setOpenDialog(true)
        : setOpenDialogError(true);
  }

  function EnviarNotificacionWPP(
    typing_time: number,
    to: string,
    body: string
  ) {
    const nuevoRegistro: NotificacionWPPType = {
      typing_time: typing_time,
      to: to,
      body: body,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postNotificarWPP(nuevoRegistro);
  }

  async function postNotificarWPP(nuevoRegistro: NotificacionWPPType) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + "gMlELccOPZC3Uwy9zIyvQoe8feZ0xRjD",
    };
    try {
      axios
        .post<any>("https://gate.whapi.cloud/messages/text", nuevoRegistro, {
          headers: headers,
        })
        .then((response) => {
          console.log("mi wpp: ", response);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error peticion crear cli dms: ", error.message);
        return error.message;
      } else {
        console.log("error peticion crear cli dms: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function postGuardarTabla(tablaGuardar: tablaRentaType, esPrimerEnvio: boolean, telefono: string) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/tablarenta",
          tablaGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          if (esPrimerEnvio) {
            EnviarNotificacionWPP(
              0,
              telefono,
              (tablaGuardar.margenNivel === 1
                ? "🚦🔴"
                : tablaGuardar.margenNivel === 2
                  ? "🚦🟡"
                  : "🚦🟢") +
              " Hola " +
              tablaGuardar.tarDestinatario.trim() +
              ", tienes una tabla de rentabilidad pendiente de aprobar de *" +
              tablaGuardar.tarAsesor +
              "* de " +
              tablaGuardar.tarAgencia +
              ". Modelo " +
              tablaGuardar.tarModelo +
              " - " +
              String(tablaGuardar.tarModeloAnio) +
              " con una rentabilidad de *" +
              tablaGuardar.tarUtilidadBruta +
              " %*. Valor a facturar: *" +
              formatter
                .format(tablaGuardar.tarTotalFacturar)
                .replace("$", "$ ") +
              "*. Enlace: https://satelite.corporacionproauto.com.ec/tablarenta/" +
              String(Number(response.data.tarId))
            )
            setOpenDialogEnviado(true)
              ;
          }
        }); //setArticleId(response.data.id));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  type tablaRentaType = {
    tarId: number;
    tarFecha: string;
    tarNumeroCotizacion: string;
    tarAgencia: string;
    tarAsesor: string;
    tarCedulaCliente: string;
    tarNombreCliente: string;
    tarModelo: string;
    tarModeloAnio: number;
    tarColor: string;
    tarDiasInv: number;
    tarEscenarioFlota: string;
    tarEscenarioFlotaPorcentaje: number;
    tarFormaPago: string;
    tarAnticipos: number;
    tarPvpMarca: number;
    tarDealerNet: number;
    tarDealerNetValor: number;
    tarMargenBruto: number;
    tarMargenBrutoValor: number;
    tarDescCirular: number;
    tarDescCirularValor: number;
    tarDescConcecionario: number;
    tarDescConcecionarioValor: number;
    tarDescTotal: number;
    tarDescTotalValor: number;
    tarRebate: number;
    tarRebateValor: number;
    tarUtilidadBruta: number;
    tarUtilidadBrutaValor: number;
    tarTotalFacturar: number;
    tarNcConcesionario: number;
    tarNcMarca: number;
    tarValorObsequios: number;
    tarAccDescripcion: string;
    tarAccIncluyeFac: number;
    tarAccValor: number;
    tarDispDescripcion: string;
    tarDispIncluyeFac: number;
    tarDispValor: number;
    tarObservaciones: string;
    tarDestinatario: string;
    tarCorreo: string;
    tarCorreoRemitente: string;
    tarEnviado: number;
    tarNotificado: number;
    tarEstado: number;
    tarMantenimientosPrepaDescripcion: string;
    tarMantenimientosPrepa: number;
    tarMantenimientosPrepaIncluye: number;
    tarNuevaObservacion: string;
    fechaCompra: string;
    valor_transporte: number;
    tarVin: string;
    tarVinAcc: string;
    tarVinAccValor: number;
    margenNivel: number;
    //financiera: string;
  };

  const [data, setData] = React.useState<tablaRentaType>({
    tarId: 0,
    tarFecha: "",
    tarNumeroCotizacion: "",
    tarAgencia: "",
    tarAsesor: "",
    tarCedulaCliente: "",
    tarNombreCliente: "",
    tarModelo: "",
    tarModeloAnio: 0,
    tarColor: "",
    tarDiasInv: 0,
    tarEscenarioFlota: "",
    tarEscenarioFlotaPorcentaje: 0,
    tarFormaPago: "",
    tarAnticipos: 0,
    tarPvpMarca: 0,
    tarDealerNet: 0,
    tarDealerNetValor: 0,
    tarMargenBruto: 0,
    tarMargenBrutoValor: 0,
    tarDescCirular: 0,
    tarDescCirularValor: 0,
    tarDescConcecionario: 0,
    tarDescConcecionarioValor: 0,
    tarDescTotal: 0,
    tarDescTotalValor: 0,
    tarRebate: 0,
    tarRebateValor: 0,
    tarUtilidadBruta: 0,
    tarUtilidadBrutaValor: 0,
    tarTotalFacturar: 0,
    tarNcConcesionario: 0,
    tarNcMarca: 0,
    tarValorObsequios: 0,
    tarAccDescripcion: "",
    tarAccIncluyeFac: 0,
    tarAccValor: 0,
    tarDispDescripcion: "",
    tarDispIncluyeFac: 0,
    tarDispValor: 0,
    tarObservaciones: "",
    tarDestinatario: "",
    tarCorreo: "",
    tarCorreoRemitente: "",
    tarEnviado: 0,
    tarNotificado: 0,
    tarEstado: 0,
    tarMantenimientosPrepaDescripcion: "",
    tarMantenimientosPrepa: 0,
    tarMantenimientosPrepaIncluye: 0,
    tarNuevaObservacion: "",
    fechaCompra: "",
    valor_transporte: 0,
    tarVin: "",
    tarVinAcc: "",
    tarVinAccValor: 0,
    margenNivel: 0,
    //financiera: ""
  });

  //https://apisatelite.azurewebsites.net/tablarenta/8

  const [dataInfoNegocio, setDataInfoNegocio] =
    React.useState<TablaRentaDocumentoType>({
      id: 0,
      idCrm: "",
      modelo: "",
      agencia: "",
      asesor: "",
      cedula: "",
      nombreCliente: "",
      genero: "",
      civilstatus: "",
      nombreConyuge: "",
      provincia: "",
      ciudad: "",
      direccion: "",
      telefono: "",
      correo: "",
      lugarTrabajo: "",
      cargo: "",
      direccionEmpresa: "",
      telefonoEmpresa: "",
      familia: "",
      nacionalidad: "",
      tipoPersonaCliente: "",
      zona: "",
      ciudadAgencia: "",
      esBloqueado: 0,
      ciudadEmpresa: "",
      provinciaEmpresa: "",
      financiera: "",
    });

  async function getvTablaRenta() {
    try {
      await axios
        .get<tablaRentaType>(
          "https://apisatelite.azurewebsites.net/tablarenta/" + params.id
        )
        .then((response) => {
          setData(response.data);
          getInfoNegocio(response.data.tarNumeroCotizacion);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          //setError(error);
        });

      console.log("data es >>", JSON.stringify(data, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getInfoNegocio(id: string) {
    try {
      await axios
        .get<TablaRentaDocumentoType>(
          "https://apisatelite.azurewebsites.net/vtablarentadocumento/" + id
        )
        .then((response) => {
          if (response.data.id > 0) {
            setDataInfoNegocio(response.data);
          }
          console.log("datos de tercero >>> ", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> 5", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [idZona, setIdZona] = React.useState(0);
  const [idBodega, setIdBodega] = React.useState(0);
  const [idMarca, setIdMarca] = React.useState(0);
  const [idNivel, setIdNivel] = React.useState(0);
  const [idLinea, setIdLinea] = React.useState(0);


  async function getTablaRenta(_id: number) {
    try {
      await axios
        .get<TablaRentaType>(
          "https://apisatelite.azurewebsites.net/tablarenta/" + String(_id)
        )
        .then((response) => {
          setIdBodega(response.data.idBodega);
          setIdZona(response.data.idZona);
          setIdMarca(response.data.idMarca);
          setIdNivel(response.data.margenNivel);
          setIdLinea(response.data.idLinea);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
          console.log("alerta por activar >>> en catch");
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Tabla de Rentabilidad
              </Typography>
            </Paper>

            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
              }}
            >
              {isMobile ? (
                <>
                  <Grid>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Fecha:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarFecha.substring(0, 10)}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>ID CRM:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarNumeroCotizacion}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Asesor:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAsesor}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cliente:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarNombreCliente}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Modelo:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarModelo + " - " + data.tarModeloAnio}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Chasis:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarVin}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Color:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarColor}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Días inv:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDiasInv}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>F.Compra:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {String(data.fechaCompra).substring(0, 10)}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Forma pago:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "70%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarFormaPago}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "25%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Anticipos:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarAnticipos).replace("$", "$ ")}
                    </Typography>
                    {String(dataInfoNegocio.financiera).length > 0 ? (
                      <>
                        <Typography
                          sx={{
                            mt: 0.2,
                            mb: 0.2,
                            width: "25%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          <strong>Financiera:</strong>
                        </Typography>
                        <Typography
                          sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                          style={{ display: "inline-block" }}
                        >
                          {dataInfoNegocio.financiera}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>PVP con IVA:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                        marginRight: "30%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarPvpMarca).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dealer NET:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                        marginRight: "30%"
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDealerNetValor)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Margen bruto:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarMargenBrutoValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMargenBruto} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dscto Circular:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescCirularValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {data.tarDescCirular} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>D Concesionario:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescConcecionarioValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {data.tarDescConcecionario} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Total descuentos:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescTotalValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDescTotal} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Rebate:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                        justifyContent: "right",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarRebateValor).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarRebate} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Util. Bruta:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarUtilidadBrutaValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{
                        display: "inline-block",
                        color:
                          data.margenNivel === 3
                            ? "green"
                            : data.margenNivel === 1
                              ? "red"
                              : "darkorange",
                      }}
                      align="right"
                    >
                      <strong>{data.tarUtilidadBruta} %</strong>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "45%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Total facturar:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "55%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      <strong>
                        {formatter
                          .format(data.tarTotalFacturar)
                          .replace("$", "$ ")}{" "}
                      </strong>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>NC Marca:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter.format(data.tarNcMarca).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>NC Concesionario:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarNcConcesionario)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor de Obsequios:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarValorObsequios)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Gastos de transporte / otro:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.valor_transporte)
                        .replace("$", "$ ")}
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Accesorios:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "70%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAccDescripcion}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginRight: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAccIncluyeFac == 1 && data.tarAccValor > 0
                        ? "SI"
                        : data.tarAccIncluyeFac == 0 && data.tarAccValor > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarAccValor).replace("$", "$  ")}
                    </Typography>
                    <Divider
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "30%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dispositivos:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "70%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDispDescripcion}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDispIncluyeFac == 1 && data.tarDispValor > 0
                        ? "SI"
                        : data.tarDispIncluyeFac == 0 && data.tarDispValor > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarDispValor).replace("$", "$ ")}
                    </Typography>
                    <Divider
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mant.Prepagados:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "60%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMantenimientosPrepaDescripcion}
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "60%" }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "40%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMantenimientosPrepaIncluye == 1 &&
                        data.tarMantenimientosPrepa > 0
                        ? "SI"
                        : data.tarMantenimientosPrepaIncluye == 0 &&
                          data.tarMantenimientosPrepa > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "50%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarMantenimientosPrepa)
                        .replace("$", "$ ")}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Fecha:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarFecha.substring(0, 10)}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>ID CRM:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarNumeroCotizacion}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Asesor:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAsesor}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Cliente:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "80%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarCedulaCliente + " - " + data.tarNombreCliente}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Modelo:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "80%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarModelo + " - " + data.tarModeloAnio}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Chasis:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarVin}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Color:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarColor}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Días inventario:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDiasInv}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Fecha compra:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {String(data.fechaCompra).substring(0, 10)}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginLeft: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Forma de pago:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarFormaPago}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Anticipos:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "35%" }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarAnticipos).replace("$", "$ ")}
                    </Typography>
                    {String(dataInfoNegocio.financiera).length > 0 ? (
                      <>
                        <Typography
                          sx={{
                            mt: 0.2,
                            mb: 0.2,
                            width: "10%",
                            marginLeft: "10%",
                          }}
                          style={{ display: "inline-block" }}
                        >
                          <strong>Financiera:</strong>
                        </Typography>
                        <Typography
                          sx={{ mt: 0.2, mb: 0.2, width: "30%" }}
                          style={{ display: "inline-block" }}
                        >
                          {dataInfoNegocio.financiera}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>PVP con IVA:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarPvpMarca).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dealer NET:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDealerNetValor)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Margen bruto:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarMargenBrutoValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Margen bruto:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMargenBruto} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dscto Circular:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescCirularValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dscto Circular:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {data.tarDescCirular} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dscto Conces.:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescConcecionarioValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dscto Conces.:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {data.tarDescConcecionario} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Total descuentos:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarDescTotalValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Total descuentos:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDescTotal} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Rebate:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                        justifyContent: "right",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarRebateValor).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Rebate:</strong>
                    </Typography>
                    <Typography
                      align="right"
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarRebate} %
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Utilidad bruta:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarUtilidadBrutaValor)
                        .replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Utilidad bruta:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{
                        display: "inline-block",
                        color:
                          data.margenNivel === 3
                            ? "green"
                            : data.margenNivel === 1
                              ? "red"
                              : "darkorange",
                      }}
                      align="right"
                    >
                      <strong>{data.tarUtilidadBruta} %</strong>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "17%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Total facturar:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "13%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      <strong>
                        {formatter
                          .format(data.tarTotalFacturar)
                          .replace("$", "$ ")}{" "}
                      </strong>
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>NC Marca:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter.format(data.tarNcMarca).replace("$", "$ ")}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>NC Concesionario:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarNcConcesionario)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor de Obsequios:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.tarValorObsequios)
                        .replace("$", "$ ")}
                    </Typography>

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "20%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Gastos de transporte / otro:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "10%",
                      }}
                      style={{ display: "inline-block" }}
                      align="right"
                    >
                      {formatter
                        .format(data.valor_transporte)
                        .replace("$", "$ ")}
                    </Typography>

                    <Divider sx={{ mt: 1, mb: 1 }} />

                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Accesorios:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAccDescripcion}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarAccIncluyeFac == 1 && data.tarAccValor > 0
                        ? "SI"
                        : data.tarAccIncluyeFac == 0 && data.tarAccValor > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarAccValor).replace("$", "$  ")}
                    </Typography>
                    <Divider
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Dispositivos:</strong>
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, width: "75%" }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDispDescripcion}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarDispIncluyeFac == 1 && data.tarDispValor > 0
                        ? "SI"
                        : data.tarDispIncluyeFac == 0 && data.tarDispValor > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter.format(data.tarDispValor).replace("$", "$ ")}
                    </Typography>
                    <Divider
                      sx={{
                        mt: 1,
                        mb: 1,
                        marginLeft: "10%",
                        marginRight: "10%",
                      }}
                    />
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Mant.Prepagados:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMantenimientosPrepaDescripcion}
                    </Typography>
                    <Typography
                      sx={{ mt: 0.2, mb: 0.2, marginLeft: "10%", width: "15%" }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Incluye en factura:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {data.tarMantenimientosPrepaIncluye == 1 &&
                        data.tarMantenimientosPrepa > 0
                        ? "SI"
                        : data.tarMantenimientosPrepaIncluye == 0 &&
                          data.tarMantenimientosPrepa > 0
                          ? "NO"
                          : ""}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        marginLeft: "10%",
                        width: "15%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      <strong>Valor con IVA:</strong>
                    </Typography>
                    <Typography
                      sx={{
                        mt: 0.2,
                        mb: 0.2,
                        width: "10%",
                        marginRight: "65%",
                      }}
                      style={{ display: "inline-block" }}
                    >
                      {formatter
                        .format(data.tarMantenimientosPrepa)
                        .replace("$", "$ ")}
                    </Typography>
                  </Grid>
                </>
              )}

              <Divider sx={{ mt: 1, mb: 1 }} />

              {/* aqui va la validacion de autorizacion */}

              {data.tarEstado === 0 && data.tarCorreo === username ? (
                <>
                  <Grid>
                    <Typography sx={{ mt: 0.2, mb: 0.2, width: "100%" }}>
                      {data.tarObservaciones}
                    </Typography>
                  </Grid>
                  <Grid>
                    <TextField
                      name="nuevaObservacion"
                      size="small"
                      margin="normal"
                      label="Añadir observaciones"
                      onChange={dataDatosInfo}
                      sx={{
                        mt: 2,
                        mb: 0.5,
                        width: "100%",
                      }}
                    ></TextField>
                  </Grid>
                  <TextField
                    name="nuevoCorreo"
                    size="small"
                    margin="normal"
                    label="Correo para redireccionamiento"
                    onChange={dataDatosInfo}
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "49%",
                    }}
                  ></TextField>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "15%",
                      marginLeft: "2%",
                    }}
                    onClick={function (event) {
                      datosInfo.nuevoCorreo.length > 15
                        ? setTablaRenta(
                          false,
                          data,
                          0,
                          false,
                          datosInfo.nuevoCorreo,
                          datosInfo.nuevoCorreo,
                          true,
                          0,
                          datosInfo.nuevaObservacion, ""
                        )
                        : console.log("Sin Correo");
                    }}
                  >
                    Redireccionar
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "15%",
                      marginLeft: "2%",
                      backgroundColor: green,
                      Colorize: "green",
                    }}
                    onClick={function (event) {
                      setTablaRenta(
                        false,
                        data,
                        1,
                        true,
                        name,
                        data.tarCorreo,
                        false,
                        data.tarEnviado,
                        datosInfo.nuevaObservacion, ""
                      );
                    }}
                  // href="/listadotablarenta"
                  >
                    Aprobar
                  </Button>
                  <Button
                    color="error"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "15%",
                      marginLeft: "2%",
                    }}
                    onClick={function (event) {
                      setTablaRenta(
                        false,
                        data,
                        2,
                        false,
                        name,
                        data.tarCorreo,
                        false,
                        data.tarEnviado,
                        datosInfo.nuevaObservacion, ""
                      );
                    }}
                  >
                    Denegar
                  </Button>
                </>
              ) : (
                <></>
              )}

              {data.tarEstado > 0 ? (
                <>
                  <Box>
                    <Grid>
                      <Typography sx={{ mt: 0.2, mb: 0.2, width: "100%" }}>
                        {data.tarObservaciones}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{ mt: 0.2, mb: 0.2, width: "100%" }}
                        align="center"
                      >
                        <strong>
                          Estado:{" "}
                          {Number(data.tarEstado) === 1
                            ? "APROBADO"
                            : Number(data.tarEstado) === 2
                              ? "DENEGADO"
                              : Number(data.tarEstado) === 3 ? "POR ENVIAR" : ""}
                        </strong>
                      </Typography>
                      <Typography
                        sx={{ mt: 0.2, mb: 0.2, width: "100%" }}
                        align="center"
                      >
                        <strong>
                          {Number(data.tarEstado) === 1 || Number(data.tarEstado) === 2 ? <>Observaciones:  {data.tarNuevaObservacion}</> : <></>}
                        </strong>
                      </Typography>
                      <Typography
                        sx={{ mt: 0.2, mb: 0.2, width: "100%" }}
                        align="center"
                      >
                        <strong>

                          {Number(data.tarEstado) === 1
                            ? "Rentabilidad aprobada por: " : Number(data.tarEstado) === 2 ? "Rentabilidad denegada por: " : ""}
                          {data.tarDestinatario}{" "}
                        </strong>
                      </Typography>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {data.tarEstado === 0 && data.tarCorreo != username ? (
                <>
                  <Box>
                    <Grid>
                      <Typography sx={{ mt: 0.2, mb: 0.2, width: "100%" }}>
                        {data.tarObservaciones}
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography
                        sx={{ mt: 0.2, mb: 0.2, width: "100%" }}
                        align="center"
                      >
                        <strong>Estado: POR APROBAR </strong>
                      </Typography>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {data.tarEstado === 3 && data.tarVin != "" && data.tarCorreoRemitente === username ? (
                <>
                  <Box>
                    <Grid>
                      <Button onClick={(e) => {
                        setOpenCorreo(true); getvListaAprueba(
                          idZona, //zona,//data.idZona,
                          idBodega, // bodega,//data.idBodega,
                          idMarca,
                          idNivel,
                          idLinea
                        )
                      }} variant="contained" sx={{ ml: "40%", width: "20%", mr: "40%" }}>
                        Enviar
                      </Button>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}

              {data.tarEstado === 2 && data.tarCorreo === username ? (
                <>
                  <Box>
                    <Grid>
                    <Button
                    color="error"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1.5,
                      width: "20%",
                      ml: "40%",
                      mr: "20%"
                    }}
                    onClick={function (event) {
                      setTablaRenta(
                        false,
                        data,
                        2,
                        false,
                        name,
                        data.tarCorreo,
                        false,
                        data.tarEnviado,
                        datosInfo.nuevaObservacion, ""
                      );
                    }}
                  >
                    Denegar
                  </Button>
                    </Grid>
                  </Box>
                </>
              ) : (
                <></>
              )}

              <Modal
                open={openCorreo}
                onClose={(e) => setOpenCorreo(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleModal}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Seleccione un aprobador
                  </Typography>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nombre</TableCell>
                          <TableCell>Correo</TableCell>
                          <TableCell>Seleccionar</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {listaAutorizaRows.map((row) => (
                          <TableRow key={row.traId}>
                            <TableCell component="th" scope="row">
                              {row.traNombre}
                            </TableCell>
                            <TableCell>{row.traCorreo}</TableCell>
                            <TableCell>
                              {" "}
                              <Button
                                onClick={function (event) {
                                  setTablaRenta(
                                    true,
                                    data,
                                    0,
                                    false,
                                    row.traNombre,
                                    row.traCorreo,
                                    false,
                                    0,
                                    "", row.traTelefono
                                  );
                                }}
                              >
                                ✓
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Modal>

              <Dialog
                open={openDialogRedireccion}
                onClose={(e) => setOpenDialogRedireccion(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"La autorizacion ha sido redireccionada"}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText id="alert-dialog-description">
                      Rentabilidad aprobada
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    href="/listadotablarenta"
                    onClick={(e) => setOpenDialogRedireccion(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openDialog}
                onClose={(e) => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Rentabilidad aprobada"}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText id="alert-dialog-description">
                      Rentabilidad aprobada
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    href="/listadotablarenta"
                    onClick={(e) => setOpenDialog(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openDialogError}
                onClose={(e) => setOpenDialogError(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Rentabilidad denegada"}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText id="alert-dialog-description">
                      Rentabilidad denegada
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    href="/listadotablarenta"
                    onClick={(e) => setOpenDialogError(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openDialogEnviado}
                onClose={(e) => setOpenDialogEnviado(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Enviado correctamente!"}
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                  <Button
                    href={`/ventas/index/${data.tarNumeroCotizacion}`}
                    onClick={(e) => setOpenDialogEnviado(false)}
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid
          //container
          alignItems={"center"}
          justifyContent={"center"}
        //sx={{ minHeight: "90vh" }}
        >
          <Button
            variant="contained"
            sx={{
              mt: 2,
              mb: 1.5,

              width: "20%",
              marginLeft: "40%",
            }}
            onClick={() => handleLogin("redirect")}
          //href="/listadotablarenta"
          >
            Inicia sesión
          </Button>
        </Grid>
      </Container>
    );
  }
};
