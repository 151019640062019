import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CrmAsesorType,
  VSateliteUsuarioType,
  VVentasSateliteValidacionType,
  bodegaType,
  canalNegocioType,
  cantidad_cargas,
  datos_en_relacion_dependencia,
  datos_relacion_independencia,
  datos_separados_nombres,
  empleador_1,
  empleador_2,
  marcaType,
  medios,
  medios_2,
  medios_contacto,
  socio_demografico,
  SateliteModeloFamiliaType,
  VSateliteModeloFamiliaType,
  SateliteSolicitudNegocioType,
} from "../../types";
import CancelIcon from "@mui/icons-material/Cancel";
import { orange, red } from "@mui/material/colors";

export const NuevoNegocioReferidoPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getUsuario(account.username);
      getBodegas();
    } else {
      setName("");
    }
  }, [account]);

  const listaMarca: marcaType[] = [
    { marId: 1, marNombre: "Chevrolet" },
    { marId: 2, marNombre: "GAC" },
    { marId: 3, marNombre: "Volkswagen" },
  ];

  const listaFinaciantype: canalNegocioType[] = [
    { id: 1, descripcion: "CREDITO" },
    { id: 2, descripcion: "CONTADO" },
  ];

  const listaTipoPersona: canalNegocioType[] = [
    { id: 1, descripcion: "Natural" },
    { id: 2, descripcion: "Natural RUC" },
    { id: 3, descripcion: "Empresa" },
    { id: 4, descripcion: "Extranjero" },
  ];

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  const listadoBods: bodegaType[] = [];
  const listadoAsesores: CrmAsesorType[] = [];
  const listadoModelosVh: VSateliteModeloFamiliaType[] = [];
  // const listadoEventos: EventosLangingType[] = [];
  const [dataAgencia, setDataAgencia] = useState<bodegaType[]>(listadoBods);
  const [dataAsesores, setDataAsesores] =
    useState<CrmAsesorType[]>(listadoAsesores);
  const [dataModelosVh, setDataModelosVh] =
    useState<VSateliteModeloFamiliaType[]>(listadoModelosVh);

  const [vhModeloSeleccionado, setVhModeloSeleccionado] = useState<VSateliteModeloFamiliaType>({
    smfId: 0,
    idCotItem: 0,
    familia: "",
    modelo: "",
    ano: 0,
    nivel1: 0,
    nivel2: 0,
    kmat: "",
  });

  const [modeloVh, setModeloVh] = useState<SateliteModeloFamiliaType>({
    smfId: 0,
    idMarca: 0,
    smfFamiliaCrm: "",
    smfFamiliaDms: "",
    smfFamiliaCrm2: "",
  });

  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });
  const [validacionNegocio, setValidacionNegocio] =
    useState<VVentasSateliteValidacionType>({
      id: 0,
      idCrm: "",
      idCotBodega: 0,
      contactdocument: "",
      interestedin: "",
      agency: "",
      createdate: "",
      dias: 0,
      canal: "",
    });

  const dataListaFamilia: SateliteModeloFamiliaType[] = [];
  const [listaFamilia, setListaFamilia] =
    useState<SateliteModeloFamiliaType[]>(dataListaFamilia);

  const [dataAsesor, setDataAsesor] = useState<CrmAsesorType>({
    id: 0,
    nit: "",
    nombre: "",
    grupo: "",
    estado: 0,
    idCotBodega: 0,
    cargo: "",
    anulado: 0,
    ownerId: "",
    correo: "",
  });

  const [marca, setMarca] = React.useState(0);
  const [modeloId, setModeloId] = React.useState(0);
  const [familia, setFamilia] = React.useState(0);
  const [financian, setFinancian] = React.useState(1);
  const [tipoPersona, setTipoPersona] = React.useState(1);
  const [asesor, setAsesor] = React.useState(0);
  const [agencia, setAgencia] = React.useState(0);

  const validarCedula = (cedula: string): boolean => {
    // Verificar que la cédula tenga 10 dígitos
    if (cedula.length !== 10) {
      return false;
    }

    // Verificar que los dos primeros dígitos correspondan a una provincia válida (de 01 a 24)
    const provincia = parseInt(cedula.substring(0, 2), 10);
    if (provincia < 1 || provincia > 24) {
      return false;
    }

    // Verificar que el tercer dígito sea válido para el tipo de documento
    const tipoDocumento = parseInt(cedula.charAt(2), 10);
    if (tipoDocumento < 0 || tipoDocumento > 5) {
      return false;
    }

    // Aplicar un algoritmo de validación para los últimos siete dígitos (puede variar)
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    let suma = 0;
    for (let i = 0; i < 9; i++) {
      let multiplicacion = parseInt(cedula.charAt(i), 10) * coeficientes[i];
      if (multiplicacion > 9) {
        multiplicacion -= 9;
      }
      suma += multiplicacion;
    }
    const ultimoDigitoCalculado = suma % 10 === 0 ? 0 : 10 - (suma % 10);
    const ultimoDigito = parseInt(cedula.charAt(9), 10);

    return ultimoDigito === ultimoDigitoCalculado;
  };

  enum TipoIdentificacion {
    cero,
    Cedula,
    RUCPersonaNatural,
    RUCJuridica,
    CedulaExtranjeria,
    Otro,
  }

  const validarNumeroIdentificacion = (numero: string): TipoIdentificacion => {
    // Verificar que el número tenga una longitud válida

    // Verificar si es cédula de ciudadanía
    if (numero.length === 10) {
      const esCedulaValida = validarCedula(numero);
      return esCedulaValida
        ? TipoIdentificacion.Cedula
        : TipoIdentificacion.CedulaExtranjeria;
    }

    // Verificar si es RUC de persona natural
    if (
      numero.length === 13 &&
      numero.substring(10, 13) === "001" &&
      validarCedula(numero.substring(0, 10))
    ) {
      return TipoIdentificacion.RUCPersonaNatural;
    }

    // Verificar si es RUC de empresa
    if (numero.length === 13 && Number(numero.substring(2, 3)) > 5) {
      return TipoIdentificacion.RUCJuridica;
    }

    // Verificar si es cédula de extranjero
    if (numero.length === 13 && numero.substring(10, 13) === "002") {
      return TipoIdentificacion.CedulaExtranjeria;
    }

    // Otros casos
    return TipoIdentificacion.CedulaExtranjeria;
  };

  const handleChangeFamilia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamilia(Number(event.target.value));
    getListaModelosVh(Number(event.target.value));
    getListaModeloVh(Number(event.target.value));
    console.log("mi id modelo", Number(event.target.value));
  };

  const handleChangeTipoPersona = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipoPersona(Number(event.target.value));
  };

  const handleChangeAgencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgencia(Number(event.target.value));
    getAsesores(Number(event.target.value));
  };

  const handleChangeAsesor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsesor(Number(event.target.value));
    getAsesor(Number(event.target.value));
  };

  const [isChecked, setIsChecked] = useState<boolean>(false);

  // Función para manejar el cambio de estado del checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  type respuestaType = {
    Codigo: number;
    Descripcion: string;
    Objeto: objetoRespuestaType;
  };

  type objetoRespuestaType = {
    socio_demografico: socio_demografico;
    datos_en_relacion_dependencia: datos_en_relacion_dependencia;
    datos_relacion_independencia: datos_relacion_independencia;
    medios_contacto: medios_contacto;
    empleador_1: empleador_1;
    empleador_2: empleador_2;
    medios: medios;
    medios_2: medios_2;
    cantidad_cargas: cantidad_cargas;
    datos_separados_nombres: datos_separados_nombres;
  };

  const [respuesta1Objeto, setRespuesta1Objeto] = useState<socio_demografico>({
    nut: "",
    nombre: "",
    sexo: "",
    fecha_1: "",
    nacionalidad: "",
    civil: "",
    fecha_2: "",
    fecha_3: "",
    profesion: "",
  });
  const [respuesta2Objeto, setRespuesta2Objeto] =
    useState<datos_en_relacion_dependencia>({
      ruc1: "",
      nombre1: "",
      direccion1: "",
      telefono1: "",
      descripcion1: "",
      actividad1: "",
      provincia1: "",
      canton1: "",
      parroquia1: "",
      rango1: "",
      rango2: "",
      rango3: "",
      rango4: "",
      fecha_4: "",
      fecha_5: "",
      ocupacion: "",
    });
  const [respuesta3Objeto, setRespuesta3Objeto] =
    useState<datos_relacion_independencia>({
      RUC: "",
      RAZON: "",
      FANTASIA: "",
      INICIO: "",
      CANCELACION: "",
      SUSPENSION: "",
      REINICIO: "",
      DIRECCION_RUC: "",
      REFERENCIA: "",
      ACTIVIDAD_RUC: "",
      PROVINCIA_RUC: "",
      CANTON_RUC: "",
      PARROQUIA_RUC: "",
    });
  const [respuesta4Objeto, setRespuesta4Objeto] = useState<medios_contacto>({
    medio1: "",
    medio2: "",
    medio3: "",
    medio4: "",
    medio5: "",
    medio6: "",
    DIRECCION_ADICIONAL: "",
    TELEFONO_ADICIONAL: "",
    PROVINCIA_ADICIONAL: "",
    CANTON_ADICIONAL: "",
    PARROQUIA_ADICIONAL: "",
    EMAIL1: "",
    EMAIL2: "",
  });
  const [respuesta5Objeto, setRespuesta5Objeto] = useState<empleador_1>({
    empleador1ruc1: "",
    empleador1nombre1: "",
    empleador1direccion1: "",
    empleador1telefono1: "",
    empleador1descripcion1: "",
    empleador1actividad1: "",
    empleador1cargo1: "",
    empleador1salario1: "",
    empleador1ingreso1: "",
    empleador1salida1: "",
    empleador1provincia1: "",
    empleador1canton1: "",
    empleador1parroquia1: "",
  });
  const [respuesta6Objeto, setRespuesta6Objeto] = useState<empleador_2>({
    empleador2ruc2: "",
    empleador2nombre2: "",
    empleador2direccion2: "",
    empleador2telefono2: "",
    empleador2descripcion2: "",
    empleador2actividad2: "",
    empleador2cargo2: "",
    empleador2salario2: "",
    empleador2ingreso2: "",
    empleador2salida2: "",
    empleador2provincia2: "",
    empleador2canton2: "",
    empleador2parroquia2: "",
  });
  const [respuesta7Objeto, setRespuesta7Objeto] = useState<medios>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta8Objeto, setRespuesta8Objeto] = useState<medios_2>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta9Objeto, setRespuesta9Objeto] = useState<cantidad_cargas>({
    cargas: "",
  });
  const [respuesta0Objeto, setRespuesta0Objeto] =
    useState<datos_separados_nombres>({
      primer_apellido: "",
      segundo_apellido: "",
      primer_nombre: "",
      segundo_nombre: "",
    });

  const [respuestaObjeto, setRespuestaObjeto] = useState<objetoRespuestaType>({
    socio_demografico: respuesta1Objeto,
    datos_en_relacion_dependencia: respuesta2Objeto,
    datos_relacion_independencia: respuesta3Objeto,
    medios_contacto: respuesta4Objeto,
    empleador_1: respuesta5Objeto,
    empleador_2: respuesta6Objeto,
    medios: respuesta7Objeto,
    medios_2: respuesta8Objeto,
    cantidad_cargas: respuesta9Objeto,
    datos_separados_nombres: respuesta0Objeto,
  });

  const [data, setData] = useState<respuestaType>({
    Codigo: 0,
    Descripcion: "",
    Objeto: respuestaObjeto,
  });

  const [dataMarca, setDataMarca] = useState<marcaType>({
    marId: 0,
    marNombre: "",
  });

  const [dataBodega, setDataBodega] = useState<bodegaType>({
    bodId: 0,
    agencia: "",
    descripcion: "",
    agency: "",
  });

  async function getBuscarDocumentoEmpleado(
    _cedula: string
  ) {
    try {
      setBotonDeshabilitado(true);
      await axios
        .get<any>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/buscar/" +
          _cedula 
        )
        .then((response) => {
          if (Number(response.data.id) > 0) {
            //empleado existe
            setOpenDialogEsEmpeado(true)
          } else {
            getHistorico(
              respuesta1Objeto.nut,
              dataBodega.bodId,
              modeloVh.smfFamiliaCrm,
              dataMarca.marId
            );
          }
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
      console.log("data es >>", JSON.stringify(validacionNegocio, null, 4));
    } catch (error) {
      setBotonDeshabilitado(false);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }


  async function getHistorico(
    _cedula: string,
    _idBodega: number,
    _modelo: string,
    _marca: number
  ) {
    try {
      setBotonDeshabilitado(true);
      await axios
        .get<VVentasSateliteValidacionType>(
          "https://apisatelite.azurewebsites.net/vventassatelitevalidacion/buscarduplicado/" +
          _cedula +
          "/" +
          String(_idBodega) +
          "/" +
          _modelo +
          "/" +
          _marca
        )
        .then((response) => {

          if (Number(response.data.id) > 0) {
            setOpenDialog1(true)
          } else {
            guardarRegistro(new Date(Date.now()).toISOString().substring(0, 10), username, 0, sateliteUsuario.idBodega, sateliteUsuario.idMarca, dataBodega.agency, dataBodega.agency, tipoPersona === 3
              ? respuesta0Objeto.primer_apellido + " - " + modeloVh.smfFamiliaCrm
              : respuesta0Objeto.primer_apellido +
              " " +
              respuesta0Objeto.primer_nombre +
              " - " +
              modeloVh.smfFamiliaCrm, dataMarca.marId === 1
              ? "235777854" // anterior >> 19582632
              : dataMarca.marId === 2
                ? "10889473"
                : "10889408", 'Referidos', dataMarca.marId === 1
              ? "136919880"
              : dataMarca.marId === 2
                ? "10889471"
                : "10889406", 'Referidos',
              tipoPersona === 1 ? "Natural" : tipoPersona === 2 ? "Natural RUC" : tipoPersona === 3 ? "Empresa" : "Extranjero", "Referidos", respuesta1Objeto.nut, tipoPersona,
              respuesta0Objeto.primer_apellido, respuesta0Objeto.segundo_apellido, respuesta0Objeto.primer_nombre, respuesta0Objeto.segundo_nombre, respuesta4Objeto.EMAIL1,
              respuesta4Objeto.medio1, name, modeloVh.smfId, modeloVh.smfFamiliaCrm, vhModeloSeleccionado.idCotItem, vhModeloSeleccionado.modelo, vhModeloSeleccionado.kmat,
              dataAsesor.id, dataAsesor.nombre, dataAsesor.correo, dataAsesor.ownerId, Number(financian), Number(financian) === 1 ? "CREDIT" : "CASH", cheked.usado ? cheked.placa : "")
          }

        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(validacionNegocio, null, 4));
    } catch (error) {
      setBotonDeshabilitado(false);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }



  async function guardarRegistro(
    //ssnId: number,
    ssnFecha: string,
    ssnUsuario: string,
    ssnEstado: number,
    idBodega: number,
    idMarca: number,
    ssnAgency: string,
    ssnAgencia: string,
    ssnDealname: string,
    ssnDealstage: string,
    ssnOrigen: string,
    ssnPipeline: string,
    ssnSource: string,
    ssnTipoCliente: string,
    ssnChannel: string,
    ssnIdentificacion: string,
    ssnTipoPersona: number,
    ssnApellido1: string,
    ssnApellido2: string,
    ssnNombre1: string,
    ssnNombre2: string,
    ssnCorreo: string,
    ssnTelefono: string,
    ssnReferidor: string,
    ssnIdFamiliaVh: number,
    ssnFamiliaVh: string,
    ssnIdVh: number,
    ssnVh: string,
    ssnKmat: string,
    ssnAsesorId: number,
    ssnAsesorNombre: string,
    ssnAsesorCorreo: string,
    ssnOwnerId: string,
    ssnFinanciamientoId: number,
    ssnFinanciamiento: string,
    ssnPlacaUsado: string,
  ) {
    const nuevoRegistro: SateliteSolicitudNegocioType = {
      //ssnId: ssnId,
      ssnFecha: ssnFecha,
      ssnUsuario: ssnUsuario,
      ssnEstado: ssnEstado,
      idBodega: idBodega,
      idMarca: idMarca,
      ssnAgency: ssnAgency,
      ssnAgencia: ssnAgencia,
      ssnDealname: ssnDealname,
      ssnDealstage: ssnDealstage,
      ssnOrigen: ssnOrigen,
      ssnPipeline: ssnPipeline,
      ssnSource: ssnSource,
      ssnTipoCliente: ssnTipoCliente,
      ssnChannel: ssnChannel,
      ssnIdentificacion: ssnIdentificacion,
      ssnTipoPersona: ssnTipoPersona,
      ssnApellido1: ssnApellido1,
      ssnApellido2: ssnApellido2,
      ssnNombre1: ssnNombre1,
      ssnNombre2: ssnNombre2,
      ssnCorreo: ssnCorreo,
      ssnTelefono: ssnTelefono,
      ssnReferidor: ssnReferidor,
      ssnIdFamiliaVh: ssnIdFamiliaVh,
      ssnFamiliaVh: ssnFamiliaVh,
      ssnIdVh: ssnIdVh,
      ssnVh: ssnVh,
      ssnKmat: ssnKmat,
      ssnAsesorId: ssnAsesorId,
      ssnAsesorNombre: ssnAsesorNombre,
      ssnAsesorCorreo: ssnAsesorCorreo,
      ssnOwnerId: ssnOwnerId,
      ssnFinanciamientoId: ssnFinanciamientoId,
      ssnFinanciamiento: ssnFinanciamiento,
      ssnPlacaUsado: ssnPlacaUsado,
    };

    postGuardarNegocio(nuevoRegistro);
  }



  async function getBodegas() {
    try {
      await axios
        .get<bodegaType[]>(
          "https://apisatelite.azurewebsites.net/dimbodega" ///zona/" + idZona
        )
        .then((response) => {
          setDataAgencia(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAgencia, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaFamilias(marId: number) {
    try {
      await axios
        .get<SateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/satelitemodelofamilia/marca/" +
          String(marId)
        )
        .then((response) => {
          setListaFamilia(response.data);
        })
        .catch((error) => {
          console.log("algo paso en familias >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getAsesor(idAsesor: number) {
    try {
      await axios
        .get<CrmAsesorType>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/" + String(idAsesor)
        )
        .then((response) => {
          setDataAsesor(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesor, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
          correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuario(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaModeloVh(id: number) {
    try {
      await axios
        .get<SateliteModeloFamiliaType>(
          "https://apisatelite.azurewebsites.net/satelitemodelofamilia/" +
          String(id)
        )
        .then((response) => {
          setModeloVh(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaModelosVh(idFamilia: number) {
    try {
      await axios
        .get<VSateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/familia/" +
          String(idFamilia)
        )
        .then((response) => {
          setDataModelosVh(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModeloVhSeleccionado(id: number) {
    console.log("mi peticion kmat >> ", "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/idcotitem/" +
      String(id))
    try {
      await axios
        .get<VSateliteModeloFamiliaType>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/idcotitem/" +
          String(id)
        )
        .then((response) => {
          setVhModeloSeleccionado(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarca(idMarca: number) {
    try {
      await axios
        .get<marcaType>(
          "https://apisatelite.azurewebsites.net/dimmarca/" + String(idMarca)
        )
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataMarca, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodega(idBodega: number) {
    try {
      await axios
        .get<bodegaType>(
          "https://apisatelite.azurewebsites.net/dimbodega/" + String(idBodega)
        )
        .then((response) => {
          setDataBodega(response.data);
          console.log("mi agencias es >>>>>>>>", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getAsesores(idBodega: number) {
    console.log("https://apisatelite.azurewebsites.net/vcrmasesor/bodega/" +
      String(idBodega))
    try {
      await axios
        .get<CrmAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/bodega/" +
          String(idBodega)
        )
        .then((response) => {
          setDataAsesores(response.data);
          getBodega(idBodega);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDataBook(cedula: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/DataBook?ci=" +
          cedula
        )
        .then((response) => {
          setData(response.data);
          setRespuesta0Objeto(response.data.Objeto.datos_separados_nombres);
          setRespuesta4Objeto(response.data.Objeto.medios_contacto);
          console.log("respuesta databook>>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const handle_socio_demografico = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRespuesta1Objeto({
      ...respuesta1Objeto,
      [e.target.name]: e.target.value,
    });
  };

  const handle_datos_separados_nombres = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z0-9\s]+$/;
    const value = e.target.value;

    if (regex.test(value) || value === "") {
      setRespuesta0Objeto({
        ...respuesta0Objeto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handle_medios_contacto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRespuesta4Objeto({
      ...respuesta4Objeto,
      [e.target.name]: e.target.value,
    });
  };

  const [openDialog0, setOpenDialog0] = React.useState(false);
  const [openDialog1, setOpenDialog1] = React.useState(false);
  const [openDialogErrorRuc, setOpenDialogErrorRuc] = React.useState(false);
  const [openDialogEsEmpeado, setOpenDialogEsEmpeado] = React.useState(false);
  const [openDialogErrorCedula, setOpenDialogErrorCedula] =
    React.useState(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);

  async function postGuardarNegocio(
    registroGuardar: SateliteSolicitudNegocioType
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitesolicitudnegocio",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setOpenDialog0(true)
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [cheked, setCheked] = React.useState({
    cedula: true,
    usado: false,
    placa: "",
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Nuevo Negocio - Referidos internos
              </Typography>

              <Typography textAlign={"center"}>
                {dataMarca.marNombre.toUpperCase()} - {dataBodega.agencia}
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Grid>


                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Marca:
                </Typography>

                <TextField
                  id="outlined-select-currency"
                  select
                  onChange={(e) => {
                    getListaFamilias(Number(e.target.value));
                    getMarca(Number(e.target.value));
                    setMarca(Number(e.target.value))
                  }}
                  value={marca}
                  size="small"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                >
                  {listaMarca.map((option) => (
                    <MenuItem key={option.marId} value={option.marId}>
                      {option.marNombre}
                    </MenuItem>
                  ))}
                </TextField>

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "4%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Agencia:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  onChange={handleChangeAgencia}
                  value={agencia}
                  size="small"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                >
                  {dataAgencia.map((option) => (
                    <MenuItem key={option.bodId} value={option.bodId}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>








                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Identificación:
                </Typography>

                <TextField
                  name="nut"
                  onChange={handle_socio_demografico}
                  size="small"
                  margin="normal"
                  type="number"
                  //onBlur={function (event) { getDataBook(respuesta1Objeto.nut); Number(respuesta1Objeto.nut.substring(2,3)) > 5 && respuesta1Objeto.nut.length === 13 ? setTipoPersona(3):  setTipoPersona(1)}}
                  onBlur={function (event) {
                    //console.log("mi respuesta", validarNumeroIdentificacion(respuesta1Objeto.nut));
                    if (respuesta1Objeto.nut.length > 0) {
                      validarNumeroIdentificacion(respuesta1Objeto.nut) === 1 ||
                        validarNumeroIdentificacion(respuesta1Objeto.nut) === 2 ||
                        validarNumeroIdentificacion(respuesta1Objeto.nut) === 3
                        ? getDataBook(respuesta1Objeto.nut.substring(0, 10))
                        : setOpenDialogErrorCedula(true);
                      setTipoPersona(
                        validarNumeroIdentificacion(respuesta1Objeto.nut)
                      );
                    }
                  }}
                  value={respuesta1Objeto.nut}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                />

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "4%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tipo Persona:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  onChange={handleChangeTipoPersona}
                  value={tipoPersona}
                  size="small"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                >
                  {listaTipoPersona.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {tipoPersona === 3 ? (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Nombre empresa:
                  </Typography>
                  <TextField
                    name="primer_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "67%" }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    1er Apellido:
                  </Typography>
                  <TextField
                    name="primer_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "2%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    2do Apellido:
                  </Typography>
                  <TextField
                    name="segundo_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.segundo_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    1er Nombre:
                  </Typography>
                  <TextField
                    name="primer_nombre"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_nombre}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "2%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    2do Nombre:
                  </Typography>
                  <TextField
                    name="segundo_nombre"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.segundo_nombre}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                  />
                </>
              )}

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Correo:
              </Typography>
              <TextField
                name="EMAIL1"
                size="small"
                margin="normal"
                value={respuesta4Objeto.EMAIL1}
                onChange={handle_medios_contacto}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "2%",
                }}
                style={{ display: "inline-block" }}
              >
                Teléfono:
              </Typography>
              <TextField
                name="medio1"
                size="small"
                margin="normal"
                type="number"
                value={respuesta4Objeto.medio1}
                onChange={handle_medios_contacto}
                sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
              />

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Modelo:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeFamilia}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, width: "67%", mr: "2%" }}
              >
                {listaFamilia.map((option) => (
                  <MenuItem key={option.smfId} value={option.smfId}>
                    {option.smfFamiliaCrm}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Asesor sugerido:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeAsesor}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "67%" }}
              >
                {dataAsesores.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>
              <FormControlLabel
                sx={{ ml: "25%", width: "60%", mt: 3 }}
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary" // Puedes cambiar el color según tus necesidades
                  />
                }
                label="Acepto términos y condiciones de manejo de información de mi referido y yo (referidor)"
              />
              <Button
                variant="contained"
                sx={{
                  marginLeft: "35%",
                  width: "14%",
                  mt: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                href="/ventas/negocio/crearreferido"
              >
                Limpiar campos
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "14%",
                  marginLeft: "2%",
                  mt: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                disabled={botonDeshabilitado || (marca > 0 && agencia > 0 && familia > 0 && asesor > 0 && respuesta1Objeto.nut.length > 0 && isChecked ? false : true)}
                onClick={function (event) {
                  if (respuesta1Objeto.nut.length === 13 && tipoPersona === 1) {
                    setOpenDialogErrorRuc(true);
                  } else {
                    getBuscarDocumentoEmpleado(respuesta1Objeto.nut)
                  }
                }}
              >
                Guardar solicitud
              </Button>
            </Paper>

            <Dialog
              open={openDialog0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(213,255,213)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Negocio creado correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>

                <Button
                  sx={{ width: "50%" }}
                  href="/ventas/negocio/crearreferido"
                  onClick={function (event) {
                    setOpenDialog0(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorRuc}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Tipo de persona incorrecto!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogErrorRuc(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogEsEmpeado}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  No se puede crear el negocio, el referido es empleado!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogEsEmpeado(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorCedula}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  //backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Número de identificación incorrecto.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogErrorCedula(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialog1}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent
                sx={{ width: 300 }}>
                <DialogContentText id="alert-dialog-description">
                  Negocio ya existe
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href="/ventas/negocio/crearreferido"
                  onClick={function (event) {
                    setOpenDialog1(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* dialog error */}
            <Dialog
              open={openDialogError}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Error al grabar negocio
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogError(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
