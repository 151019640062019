import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  CRMRespuesta1Type,
  CRMRespuesta2Type,
  CrmAsesorType,
  EventosLangingType,
  SateliteCrearNegocioType,
  VSateliteUsuarioType,
  UpdateEventosCRMType,
  VVentasSateliteValidacionType,
  bodegaType,
  canalNegocioType,
  cantidad_cargas,
  datos_en_relacion_dependencia,
  datos_relacion_independencia,
  datos_separados_nombres,
  empleador_1,
  empleador_2,
  marcaType,
  medios,
  medios_2,
  medios_contacto,
  socio_demografico,
  zonaType,
  SateliteModeloFamiliaType,
  VSateliteModeloFamiliaType,
  CrearDealHubSpotType,
  CrearDealHubSpotPropiedadesType,
} from "../../types";
import CancelIcon from "@mui/icons-material/Cancel";
import { orange, red } from "@mui/material/colors";

export const NuevoNegocioPage: React.FC<{}> = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (account && account.name) {
      console.log("este son los atributos de msal >>>>>>", account);
      setName(account.name);
      setUsername(account.username);
      console.log(account);
      getUsuario(account.username);
    } else {
      setName("");
    }
  }, [account]);

  const listaCanales: canalNegocioType[] = [
    { id: 1, descripcion: "SHOWROOM" },
    { id: 2, descripcion: "GESTION EXTERNA" },
   // { id: 3, descripcion: "BASE DE DATOS" },
    { id: 4, descripcion: "REFERIDOS" },
   // { id: 5, descripcion: "RECOMPRA" },
  ];

  const listaFinaciantype: canalNegocioType[] = [
    { id: 1, descripcion: "CREDITO" },
    { id: 2, descripcion: "CONTADO" },
  ];

  const listaTipoPersona: canalNegocioType[] = [
    { id: 1, descripcion: "Natural" },
    { id: 2, descripcion: "Natural RUC" },
    { id: 3, descripcion: "Empresa" },
    { id: 4, descripcion: "Extranjero" },
  ];

  const [botonDeshabilitado, setBotonDeshabilitado] = useState(false);

  // const listaEventos: EventosLangingType[] = [
  //   { id: 1, descripcion: "Evento 1" },
  //   { id: 2, descripcion: "Evento 2" },
  //   { id: 3, descripcion: "Evento 3" },
  //   { id: 4, descripcion: "Evento 4" },
  // ];
  const listadoAsesores: CrmAsesorType[] = [];
  const listadoModelosVh: VSateliteModeloFamiliaType[] = [];
  // const listadoEventos: EventosLangingType[] = [];
  const [dataAsesores, setDataAsesores] =
    useState<CrmAsesorType[]>(listadoAsesores);
  const [dataModelosVh, setDataModelosVh] =
    useState<VSateliteModeloFamiliaType[]>(listadoModelosVh);

    const [vhModeloSeleccionado, setVhModeloSeleccionado] = useState<VSateliteModeloFamiliaType>({
      smfId: 0,
      idCotItem: 0,
      familia: "",
      modelo: "",
      ano: 0,
      nivel1: 0,
      nivel2: 0,
      kmat: "",
    });

  const [modeloVh, setModeloVh] = useState<SateliteModeloFamiliaType>({
    smfId: 0,
    idMarca: 0,
    smfFamiliaCrm: "",
    smfFamiliaDms: "",
    smfFamiliaCrm2: "",
  });

  const [sateliteUsuario, setSateliteUsuario] = useState<VSateliteUsuarioType>({
    id: 0,
    nombre: "",
    correo: "",
    idMarca: 0,
    idBodega: 0,
    usuSupervisor: 0,
    usuJefeAgencia: 0,
    agenciaCrm: "",
    marca: "",
    bodega: "",
  });
  const [validacionNegocio, setValidacionNegocio] =
    useState<VVentasSateliteValidacionType>({
      id: 0,
      idCrm: "",
      idCotBodega: 0,
      contactdocument: "",
      interestedin: "",
      agency: "",
      createdate: "",
      dias: 0,
      canal: "",
    });

  const dataListaEventos: UpdateEventosCRMType[] = [];
  const dataListaFamilia: SateliteModeloFamiliaType[] = [];
  const [listaEventos, setListaEventos] =
    useState<UpdateEventosCRMType[]>(dataListaEventos);

  const [listaFamilia, setListaFamilia] =
    useState<SateliteModeloFamiliaType[]>(dataListaFamilia);

  const [dataAsesor, setDataAsesor] = useState<CrmAsesorType>({
    id: 0,
    nit: "",
    nombre: "",
    grupo: "",
    estado: 0,
    idCotBodega: 0,
    cargo: "",
    anulado: 0,
    ownerId: "",
    correo: "",
  });

  const [modeloId, setModeloId] = React.useState(0);
  const [familia, setFamilia] = React.useState(0);
  const [canal, setCanal] = React.useState(1);
  const [financian, setFinancian] = React.useState(1);
  const [tipoPersona, setTipoPersona] = React.useState(1);
  const [evento, setEvento] = React.useState(0);
  const [asesor, setAsesor] = React.useState(0);

  const validarCedula = (cedula: string): boolean => {
    // Verificar que la cédula tenga 10 dígitos
    if (cedula.length !== 10) {
      return false;
    }

    // Verificar que los dos primeros dígitos correspondan a una provincia válida (de 01 a 24)
    const provincia = parseInt(cedula.substring(0, 2), 10);
    if (provincia < 1 || provincia > 24) {
      return false;
    }

    // Verificar que el tercer dígito sea válido para el tipo de documento
    const tipoDocumento = parseInt(cedula.charAt(2), 10);
    if (tipoDocumento < 0 || tipoDocumento > 5) {
      return false;
    }

    // Aplicar un algoritmo de validación para los últimos siete dígitos (puede variar)
    const coeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    let suma = 0;
    for (let i = 0; i < 9; i++) {
      let multiplicacion = parseInt(cedula.charAt(i), 10) * coeficientes[i];
      if (multiplicacion > 9) {
        multiplicacion -= 9;
      }
      suma += multiplicacion;
    }
    const ultimoDigitoCalculado = suma % 10 === 0 ? 0 : 10 - (suma % 10);
    const ultimoDigito = parseInt(cedula.charAt(9), 10);

    return ultimoDigito === ultimoDigitoCalculado;
  };

  enum TipoIdentificacion {
    cero,
    Cedula,
    RUCPersonaNatural,
    RUCJuridica,
    CedulaExtranjeria,
    Otro,
  }

  const validarNumeroIdentificacion = (numero: string): TipoIdentificacion => {
    // Verificar que el número tenga una longitud válida

    // Verificar si es cédula de ciudadanía
    if (numero.length === 10) {
      const esCedulaValida = validarCedula(numero);
      return esCedulaValida
        ? TipoIdentificacion.Cedula
        : TipoIdentificacion.CedulaExtranjeria;
    }

    // Verificar si es RUC de persona natural
    if (
      numero.length === 13 &&
      numero.substring(10, 13) === "001" &&
      validarCedula(numero.substring(0, 10))
    ) {
      return TipoIdentificacion.RUCPersonaNatural;
    }

    // Verificar si es RUC de empresa
    if (numero.length === 13 && Number(numero.substring(2, 3)) > 5) {
      return TipoIdentificacion.RUCJuridica;
    }

    // Verificar si es cédula de extranjero
    if (numero.length === 13 && numero.substring(10, 13) === "002") {
      return TipoIdentificacion.CedulaExtranjeria;
    }

    // Otros casos
    return TipoIdentificacion.CedulaExtranjeria;
  };

  const handleChangeFamilia = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFamilia(Number(event.target.value));
    getListaModelosVh(Number(event.target.value));
    getListaModeloVh(Number(event.target.value));
    console.log("mi id modelo", Number(event.target.value));
  };

  const handleChangeModelo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModeloId(Number(event.target.value));
    getModeloVhSeleccionado(Number(event.target.value));
  };

  const handleChangeCanal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanal(Number(event.target.value));
  };

  const handleChangeFinancian = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFinancian(Number(event.target.value));
  };

  const handleChangeTipoPersona = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTipoPersona(Number(event.target.value));
  };

  const handleChangeEvento = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvento(Number(event.target.value));
  };

  const handleChangeAsesor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAsesor(Number(event.target.value));
    getAsesor(Number(event.target.value));
  };

  type respuestaType = {
    Codigo: number;
    Descripcion: string;
    Objeto: objetoRespuestaType;
  };

  type objetoRespuestaType = {
    socio_demografico: socio_demografico;
    datos_en_relacion_dependencia: datos_en_relacion_dependencia;
    datos_relacion_independencia: datos_relacion_independencia;
    medios_contacto: medios_contacto;
    empleador_1: empleador_1;
    empleador_2: empleador_2;
    medios: medios;
    medios_2: medios_2;
    cantidad_cargas: cantidad_cargas;
    datos_separados_nombres: datos_separados_nombres;
  };

  const [respuesta1Objeto, setRespuesta1Objeto] = useState<socio_demografico>({
    nut: "",
    nombre: "",
    sexo: "",
    fecha_1: "",
    nacionalidad: "",
    civil: "",
    fecha_2: "",
    fecha_3: "",
    profesion: "",
  });
  const [respuesta2Objeto, setRespuesta2Objeto] =
    useState<datos_en_relacion_dependencia>({
      ruc1: "",
      nombre1: "",
      direccion1: "",
      telefono1: "",
      descripcion1: "",
      actividad1: "",
      provincia1: "",
      canton1: "",
      parroquia1: "",
      rango1: "",
      rango2: "",
      rango3: "",
      rango4: "",
      fecha_4: "",
      fecha_5: "",
      ocupacion: "",
    });
  const [respuesta3Objeto, setRespuesta3Objeto] =
    useState<datos_relacion_independencia>({
      RUC: "",
      RAZON: "",
      FANTASIA: "",
      INICIO: "",
      CANCELACION: "",
      SUSPENSION: "",
      REINICIO: "",
      DIRECCION_RUC: "",
      REFERENCIA: "",
      ACTIVIDAD_RUC: "",
      PROVINCIA_RUC: "",
      CANTON_RUC: "",
      PARROQUIA_RUC: "",
    });
  const [respuesta4Objeto, setRespuesta4Objeto] = useState<medios_contacto>({
    medio1: "",
    medio2: "",
    medio3: "",
    medio4: "",
    medio5: "",
    medio6: "",
    DIRECCION_ADICIONAL: "",
    TELEFONO_ADICIONAL: "",
    PROVINCIA_ADICIONAL: "",
    CANTON_ADICIONAL: "",
    PARROQUIA_ADICIONAL: "",
    EMAIL1: "",
    EMAIL2: "",
  });
  const [respuesta5Objeto, setRespuesta5Objeto] = useState<empleador_1>({
    empleador1ruc1: "",
    empleador1nombre1: "",
    empleador1direccion1: "",
    empleador1telefono1: "",
    empleador1descripcion1: "",
    empleador1actividad1: "",
    empleador1cargo1: "",
    empleador1salario1: "",
    empleador1ingreso1: "",
    empleador1salida1: "",
    empleador1provincia1: "",
    empleador1canton1: "",
    empleador1parroquia1: "",
  });
  const [respuesta6Objeto, setRespuesta6Objeto] = useState<empleador_2>({
    empleador2ruc2: "",
    empleador2nombre2: "",
    empleador2direccion2: "",
    empleador2telefono2: "",
    empleador2descripcion2: "",
    empleador2actividad2: "",
    empleador2cargo2: "",
    empleador2salario2: "",
    empleador2ingreso2: "",
    empleador2salida2: "",
    empleador2provincia2: "",
    empleador2canton2: "",
    empleador2parroquia2: "",
  });
  const [respuesta7Objeto, setRespuesta7Objeto] = useState<medios>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta8Objeto, setRespuesta8Objeto] = useState<medios_2>({
    direccion_medio: "",
    telefono_medio: "",
    celular_medio: "",
    email_medio: "",
    provincia_medio: "",
    canton_medio: "",
    parroquia_medio: "",
  });
  const [respuesta9Objeto, setRespuesta9Objeto] = useState<cantidad_cargas>({
    cargas: "",
  });
  const [respuesta0Objeto, setRespuesta0Objeto] =
    useState<datos_separados_nombres>({
      primer_apellido: "",
      segundo_apellido: "",
      primer_nombre: "",
      segundo_nombre: "",
    });

  const [respuestaObjeto, setRespuestaObjeto] = useState<objetoRespuestaType>({
    socio_demografico: respuesta1Objeto,
    datos_en_relacion_dependencia: respuesta2Objeto,
    datos_relacion_independencia: respuesta3Objeto,
    medios_contacto: respuesta4Objeto,
    empleador_1: respuesta5Objeto,
    empleador_2: respuesta6Objeto,
    medios: respuesta7Objeto,
    medios_2: respuesta8Objeto,
    cantidad_cargas: respuesta9Objeto,
    datos_separados_nombres: respuesta0Objeto,
  });

  const [data, setData] = useState<respuestaType>({
    Codigo: 0,
    Descripcion: "",
    Objeto: respuestaObjeto,
  });

  const [dataMarca, setDataMarca] = useState<marcaType>({
    marId: 0,
    marNombre: "",
  });

  const [dataBodega, setDataBodega] = useState<bodegaType>({
    bodId: 0,
    agencia: "",
    descripcion: "",
    agency: "",
  });

  const [dataCrmRespuesta2, setDataCrmRespuesta2] = useState<CRMRespuesta2Type>(
    {
      archived: false,
      createdAt: "",
      id: "",
    }
  );

  const [respuestaHSDelegado, setRespuestaHSDelegado] =
    useState<CRMRespuesta1Type>({
      Codigo: 0,
      Descripcion: "",
      Objeto: dataCrmRespuesta2,
    });

    const [respuestaCrearNego, setRespuestaCrearNego] = React.useState("");

  async function guardarNegocio(existe: number) {
    console.log("mi kmat es >> ", vhModeloSeleccionado.kmat)
    
    const propiedades: CrearDealHubSpotPropiedadesType = {      
      contactdocument: respuesta1Objeto.nut,
      contactname:
        tipoPersona === 3
          ? ""
          : respuesta0Objeto.primer_nombre +
            " " +
            respuesta0Objeto.segundo_nombre,
      contactlastname:
        tipoPersona === 3
          ? respuesta0Objeto.primer_apellido
          : respuesta0Objeto.primer_apellido +
            " " +
            respuesta0Objeto.segundo_apellido,
      contactemail: respuesta4Objeto.EMAIL1,
      contactphone: respuesta4Objeto.medio1,
      dealname:
        tipoPersona === 3
          ? respuesta0Objeto.primer_apellido + " - " + modeloVh.smfFamiliaCrm
          : respuesta0Objeto.primer_apellido +
            " " +
            respuesta0Objeto.primer_nombre +
            " - " +
            modeloVh.smfFamiliaCrm,
      dealstage:
        dataMarca.marId === 1
          ? "235777854" // anterior >> 19582632
          : dataMarca.marId === 2
          ? "10889473"
          : "10889408",
      financingtype: Number(financian) === 1 ? "CREDIT" : "CASH",
      hubspot_owner_id: Number(dataAsesor.ownerId),
      interestedin: modeloVh.smfFamiliaCrm,
      origen:
        canal === 1
          ? "Showroom"
          : canal === 2
          ? "Gestión Externa"
          : canal === 3
          ? "Base de Datos"
          : canal === 4
          ? "Referidos"
          : "Recompra",
      pipeline:
        dataMarca.marId === 1
          ? "136919880"// antiguo >> "6580563" >> 27/08/2024
          : dataMarca.marId === 2
          ? "10889471"
          : "10889406",
      source:
        canal === 1
          ? "Showroom"
          : canal === 2
          ? "Gestión Externa"
          : canal === 3
          ? "Base de Datos"
          : canal === 4
          ? "Referidos"
          : "Recompra",
      tipo_persona_cliente:
        Number(tipoPersona) === 1
          ? "Persona Natural"
          : Number(tipoPersona) === 2
          ? "Persona Natural"
          : Number(tipoPersona) === 3
          ? "Persona Jurídica"
          : "Extranjero",
      usado___placa: cheked.usado ? cheked.placa : "",       
      channel: canal === 1
      ? "Showroom"
      : canal === 2
      ? "Gestión Externa"
      : canal === 3
      ? "Base de Datos"
      : canal === 4
      ? "Referidos"
      : "Recompra"
    };
    dataMarca.marId === 1 ? propiedades.modelo= vhModeloSeleccionado.kmat : console.log("");
    dataMarca.marId === 3 ? propiedades.agencia = sateliteUsuario.agenciaCrm : propiedades.agency = sateliteUsuario.agenciaCrm;
    const nuevoRegistro: CrearDealHubSpotType = {
      accessToken:
        dataMarca.marId === 1
          ? "pat-na1-4775b2d8-02a8-49b5-b6d0-a6b9967924e5"
          : dataMarca.marId === 2
          ? "pat-na1-31beb53b-a3dd-46bf-9833-e4c990d44ff1"
          : "pat-na1-1a846a5e-e897-492a-9d37-84cfd60aef1a",
      email: respuesta4Objeto.EMAIL1,
      firstName:
        tipoPersona === 3
          ? ""
          : respuesta0Objeto.primer_nombre +
            " " +
            respuesta0Objeto.segundo_nombre,
      lastName:
        tipoPersona === 3
          ? respuesta0Objeto.primer_apellido
          : respuesta0Objeto.primer_apellido +
            " " +
            respuesta0Objeto.segundo_apellido,
      phone:
        respuesta4Objeto.medio1.length === 10 &&
        respuesta4Objeto.medio1.substring(0, 2) === "09"
          ? "+593" + respuesta4Objeto.medio1.substring(1, 100)
          : respuesta4Objeto.medio1,
      ownerId: Number(dataAsesor.ownerId),
      dealData: propiedades,
    };     
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    postGuardarRegistro(nuevoRegistro, existe);
  }

  async function postGuardarRegistro(parametroGuardar: CrearDealHubSpotType, existe: number) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/hubspotdeal/crearnegocio",
          parametroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: >>>>>>>> ", response);

          if (response.statusText === "Created" && String(response.data) != "undefined" &&  String(response.data).length > 0)  {
            console.log(
              "mi nuevo id es >>>>>>>>>>>> ", response.data
            );
            setRespuestaCrearNego(String(response.data));
            guardarRegistroNotificacion(
              new Date(Date.now()).toISOString().substring(0, 10),
              dataBodega.bodId,
              dataMarca.marId,
              username,
              response.data,
              "",
              parametroGuardar.lastName + " " + parametroGuardar.lastName,
              "",
              existe,
              0,
              modeloId,
              dataAsesor.id,
              existe,
              "" //placa
            );
          } else {
            setOpenDialogError(true);
          }
          console.log("respuesta del registro peticion >>> :", response.data);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function hubspot_delegado(
    token: string,
    contactdocument: string,
    contactname: string,
    contactlastname: string,
    dealname: string,
    owner_id: string,
    dealstage: string,
    pipeline: string,
    modelo: string,
    modelo_interes: string,
    puntoventa: string,
    correo: string,
    origen: string,
    parametro_agencia: string,
    contactphone: string,
    existe: number,
    _placa: string
  ) {
    try {
      await axios
        .get<CRMRespuesta1Type>(
          "https://rpa.corporacionproauto.com.ec/TEST/HubSpot/create?input={%26quot;key%26quot;:%26quot;" +
            token +
            "%26quot;,%26quot;body%26quot;: {%26quot;" +
            "contactdocument" +
            "%26quot;: %26quot;" +
            contactdocument +
            "%26quot;,%26quot;" +
            "dealname" +
            "%26quot;: %26quot;" +
            dealname +
            "%26quot;,%26quot;" +
            "pipeline" +
            "%26quot;: %26quot;" +
            pipeline +
            "%26quot;,%26quot;" +
            "contactname" +
            "%26quot;: %26quot;" +
            contactname +
            "%26quot;,%26quot;" +
            "contactlastname" +
            "%26quot;: %26quot;" +
            contactlastname +
            "%26quot;,%26quot;" +
            //"modelo" +
            //"%26quot;: %26quot;" +
            //modelo +
            //"%26quot;,%26quot;" +
            "interestedin" +
            "%26quot;: %26quot;" +
            modelo_interes +
            "%26quot;,%26quot;" +
            parametro_agencia +
            "%26quot;: %26quot;" +
            sateliteUsuario.agenciaCrm +
            "%26quot;,%26quot;" +
            "contactemail" +
            "%26quot;: %26quot;" +
            correo +
            "%26quot;,%26quot;" +
            "source" +
            "%26quot;: %26quot;" +
            origen +
            "%26quot;,%26quot;" +
            "origen" +
            "%26quot;: %26quot;" +
            origen +
            "%26quot;,%26quot;" +
            "usado___placa" +
            "%26quot;: %26quot;" +
            _placa +
            "%26quot;,%26quot;" +
            "dealstage" +
            "%26quot;: %26quot;" +
            dealstage +
            "%26quot;,%26quot;" +
            "financingtype" +
            "%26quot;: %26quot;" +
            (Number(financian) === 1 ? "CREDIT" : "CASH") +
            "%26quot;,%26quot;" +
            "tipo_persona_cliente" +
            "%26quot;: %26quot;" +
            (Number(tipoPersona) === 1
              ? "Persona Natural"
              : Number(tipoPersona) === 2
              ? "Persona Natural"
              : Number(tipoPersona) === 3
              ? "Persona Jurídica"
              : "Extranjero") +
            "%26quot;,%26quot;" +
            "contactphone" +
            "%26quot;: %26quot;" +
            (contactphone.length === 10 && contactphone.substring(0, 2) === "09"
              ? "+593" + contactphone.substring(1, 100)
              : contactphone) +
            "%26quot;,%26quot;" +
            "hubspot_owner_id" +
            "%26quot;: %26quot;" +
            owner_id +
            "%26quot;}}"
        )
        .then((response) => {
          setRespuestaHSDelegado(response.data);
          if (response.data.Descripcion === "Ok") {
            console.log(
              "mi nuevo id es >>>>>>>>>>>> ",
              response.data.Objeto.id
            );
            guardarRegistroNotificacion(
              new Date(Date.now()).toISOString().substring(0, 10),
              dataBodega.bodId,
              dataMarca.marId,
              username,
              response.data.Objeto.id,
              "",
              contactlastname + " " + contactname,
              "",
              existe,
              0,
              modeloId,
              dataAsesor.id,
              existe,
              "" //placa
            );
          } else {
            setOpenDialogError(true);
          }
          console.log("respuesta del registro peticion >>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso grabar >> ", error);
        });

      console.log("data es >>", JSON.stringify(respuestaHSDelegado, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function guardarRegistroNotificacion(
    scnFecha: string,
    idBodega: number,
    idMarca: number,
    scnUsuario: string,
    scnIdentificacion: string,
    scnCanal: string,
    scnCorreo: string,
    scnTelefono: string,
    scnNuevo: number,
    scnDias: number,
    mfaId: number,
    idAsesor: number,
    existe: number,
    placa: string
  ) {
    const nuevoRegistro: SateliteCrearNegocioType = {
      scnFecha: scnFecha,
      idBodega: idBodega,
      idMarca: idMarca,
      scnUsuario: scnUsuario,
      scnIdentificacion: scnIdentificacion,
      scnCanal: scnCanal,
      scnCorreo: scnCorreo,
      scnTelefono: scnTelefono,
      scnNuevo: scnNuevo,
      scnDias: scnDias,
      mfaId: mfaId,
      idAsesor: idAsesor,
      placa: placa,
    };

    postGuardarNegocio(nuevoRegistro, existe);
  }

  async function getListaFamilias(marId: number) {
    try {
      await axios
        .get<SateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/satelitemodelofamilia/marca/" +
            String(marId)
        )
        .then((response) => {
          setListaFamilia(response.data);
        })
        .catch((error) => {
          console.log("algo paso en familias >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  // async function getListoEventos(marId: number) {
  //   try {
  //     await axios
  //       .get<UpdateEventosCRMType[]>(
  //         "https://apisatelite.azurewebsites.net/sateliteeventoscrm/marca/" +
  //           String(marId)
  //       )
  //       .then((response) => {
  //         setListaEventos(response.data);
  //       })
  //       .catch((error) => {
  //         console.log("algo paso >> ", error);
  //       });

  //     console.log("data es >>", JSON.stringify(dataAsesor, null, 4));
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log("error message: ", error.message);
  //       return error.message;
  //     } else {
  //       console.log("algo paso unexpected error: ", error);
  //       return "An unexpected error occurred";
  //     }
  //   }
  // }

  async function getAsesor(idAsesor: number) {
    try {
      await axios
        .get<CrmAsesorType>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/" + String(idAsesor)
        )
        .then((response) => {
          setDataAsesor(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesor, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getUsuario(correo: string) {
    try {
      await axios
        .get<VSateliteUsuarioType>(
          "https://apisatelite.azurewebsites.net/vsateliteusuario/correo/" +
            correo
        )
        .then((response) => {
          console.log("mi user es >>>>>>", response.data);
          setSateliteUsuario(response.data);
          getAsesores(response.data.idBodega);
          //getListoEventos(response.data.idMarca);
          getListaFamilias(response.data.idMarca);
          getMarca(response.data.idMarca);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(sateliteUsuario, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getHistorico(
    _cedula: string,
    _idBodega: number,
    _canal: string,
    _marca: number
  ) {
    try {
      setBotonDeshabilitado(true);
      await axios
        .get<VVentasSateliteValidacionType>(
          "https://apisatelite.azurewebsites.net/vventassatelitevalidacion/buscar/" +
            _cedula +
            "/" +
            String(_idBodega) +
            "/" +
            _canal +
            "/" +
            _marca
        )
        .then((response) => {
          setValidacionNegocio(response.data);
          console.log(
            "mi historico es >>>>>",
            JSON.stringify(validacionNegocio, null, 4)
          );
          setRegistroGuardar(
            new Date(Date.now()).toISOString().substring(0, 10),
            dataBodega.bodId,
            dataMarca.marId,
            username,
            respuesta1Objeto.nut,
            canal === 1
              ? "SHOWROOM"
              : canal === 2
              ? "GESTION EXTERNA"
              : "BASE DE DATOS",
            respuesta4Objeto.EMAIL1,
            respuesta4Objeto.medio1,
            1,
            0,
            modeloId,
            asesor,
            response.data.id,
            response.data.idCrm,
            "" //placa
          );
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(validacionNegocio, null, 4));
    } catch (error) {
      setBotonDeshabilitado(false);
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  // async function getListaModeloVh(id: number) {
  //   try {
  //     await axios
  //       .get<SateliteModeloFamiliaType>(
  //         "https://apisatelite.azurewebsites.net/satelitemodelofamilia/" +
  //           String(id)
  //       )
  //       .then((response) => {
  //         setModeloVh(response.data);
  //       })
  //       .catch((error) => {
  //         console.log("algo paso >> ", error);
  //       });
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       console.log("error message: ", error.message);
  //       return error.message;
  //     } else {
  //       console.log("algo paso unexpected error: ", error);
  //       return "An unexpected error occurred";
  //     }
  //   }
  // }


  async function getListaModeloVh(id: number) {
    try {
      await axios
        .get<SateliteModeloFamiliaType>(
          "https://apisatelite.azurewebsites.net/satelitemodelofamilia/" +
            String(id)
        )
        .then((response) => {
          setModeloVh(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getListaModelosVh(idFamilia: number) {
    try {
      await axios
        .get<VSateliteModeloFamiliaType[]>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/familiacrm/" +
            String(idFamilia)
        )
        .then((response) => {
          setDataModelosVh(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getModeloVhSeleccionado(id: number) {
    console.log("mi peticion kmat >> ",  "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/idcotitem/" +
      String(id))
    try {
      await axios
        .get<VSateliteModeloFamiliaType>(
          "https://apisatelite.azurewebsites.net/vsatelitemodelofamilia/idcotitem/" +
            String(id)
        )
        .then((response) => {
          setVhModeloSeleccionado(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getMarca(idMarca: number) {
    try {
      await axios
        .get<marcaType>(
          "https://apisatelite.azurewebsites.net/dimmarca/" + String(idMarca)
        )
        .then((response) => {
          setDataMarca(response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataMarca, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getBodega(idBodega: number) {
    try {
      await axios
        .get<bodegaType>(
          "https://apisatelite.azurewebsites.net/dimbodega/" + String(idBodega)
        )
        .then((response) => {
          setDataBodega(response.data);
          console.log("mi agencias es >>>>>>>>", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataBodega, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getAsesores(idBodega: number) {
    try {
      await axios
        .get<CrmAsesorType[]>(
          "https://apisatelite.azurewebsites.net/vcrmasesor/bodega/" +
            String(idBodega)
        )
        .then((response) => {
          setDataAsesores(response.data);
          getBodega(idBodega);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      console.log("data es >>", JSON.stringify(dataAsesores, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  async function getDataBook(cedula: string) {
    try {
      await axios
        .get<respuestaType>(
          "https://rpa.corporacionproauto.com.ec/TEST/Solicitud/DataBook?ci=" +
            cedula
        )
        .then((response) => {
          setData(response.data);
          setRespuesta0Objeto(response.data.Objeto.datos_separados_nombres);
          setRespuesta4Objeto(response.data.Objeto.medios_contacto);
          console.log("respuesta databook>>> :", response.data);
        })
        .catch((error) => {
          console.log("algo paso >> ", error);
        });

      //console.log("data es >>", JSON.stringify(respuestaGet, null, 4));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const handle_socio_demografico = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRespuesta1Objeto({
      ...respuesta1Objeto,
      [e.target.name]: e.target.value,
    });
  };

  const handle_datos_separados_nombres = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[a-zA-Z0-9\s]+$/;
    const value = e.target.value;

    if (regex.test(value) || value === "") {
      setRespuesta0Objeto({
        ...respuesta0Objeto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handle_medios_contacto = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRespuesta4Objeto({
      ...respuesta4Objeto,
      [e.target.name]: e.target.value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDialog0, setOpenDialog0] = React.useState(false);
  const [openDialog1, setOpenDialog1] = React.useState(false);
  const [openDialogErrorRuc, setOpenDialogErrorRuc] = React.useState(false);
  const [openDialogErrorCedula, setOpenDialogErrorCedula] =
    React.useState(false);
  const [openDialogError, setOpenDialogError] = React.useState(false);

  async function setRegistroGuardar(
    scnFecha: string,
    idBodega: number,
    idMarca: number,
    scnUsuario: string,
    scnIdentificacion: string,
    scnCanal: string,
    scnCorreo: string,
    scnTelefono: string,
    scnNuevo: number,
    scnDias: number,
    mfaId: number,
    idAsesor: number,
    existe: number,
    idNegocioExiste: string,
    placa: string
  ) {
    console.log("tabla a guardar >> ");
    const nuevoRegistro: SateliteCrearNegocioType = {
      scnFecha: scnFecha,
      idBodega: idBodega,
      idMarca: idMarca,
      scnUsuario: scnUsuario,
      scnIdentificacion: scnIdentificacion,
      scnCanal: scnCanal,
      scnCorreo: scnCorreo,
      scnTelefono: scnTelefono,
      scnNuevo: scnNuevo,
      scnDias: scnDias,
      mfaId: mfaId,
      idAsesor: idAsesor,
      placa: placa,
    };
    console.log("datos a guardar >> ", JSON.stringify(nuevoRegistro, null, 4));
    console.log(
      "validacion historico >> ",
      JSON.stringify(validacionNegocio, null, 4)
    );
    //postGuardarNegocio(nuevoRegistro, validacionNegocio.id);
    console.log("mi modelo ++ >>>>>>>>", modeloVh);
    existe > 0
      ? guardarRegistroNotificacion(
          new Date(Date.now()).toISOString().substring(0, 10),
          dataBodega.bodId,
          dataMarca.marId,
          username,
          idNegocioExiste,
          "",
          respuesta0Objeto.primer_apellido +
            " " +
            respuesta0Objeto.primer_nombre,
          "",
          existe,
          0,
          modeloVh.smfId,
          dataAsesor.id,
          existe,
          "" //placa
        )
      : 
      guardarNegocio(existe); 
      // hubspot_delegado(
      //     dataMarca.marId === 1
      //       ? "pat-na1-4775b2d8-02a8-49b5-b6d0-a6b9967924e5"
      //       : dataMarca.marId === 2
      //       ? "pat-na1-31beb53b-a3dd-46bf-9833-e4c990d44ff1"
      //       : "pat-na1-1a846a5e-e897-492a-9d37-84cfd60aef1a",
      //     scnIdentificacion,
      //     tipoPersona === 3
      //       ? ""
      //       : respuesta0Objeto.primer_nombre +
      //           " " +
      //           respuesta0Objeto.segundo_nombre,
      //     tipoPersona === 3
      //       ? respuesta0Objeto.primer_apellido
      //       : respuesta0Objeto.primer_apellido +
      //           " " +
      //           respuesta0Objeto.segundo_apellido,
      //     tipoPersona === 3
      //       ? respuesta0Objeto.primer_apellido + " - " + modeloVh.smfFamiliaCrm
      //       : respuesta0Objeto.primer_apellido +
      //           " " +
      //           respuesta0Objeto.primer_nombre +
      //           " - " +
      //           modeloVh.smfFamiliaCrm, //modelo
      //     dataAsesor.ownerId,
      //     dataMarca.marId === 1 //deal stage
      //       ? "235578207"
      //       : dataMarca.marId === 2
      //       ? "10889473"
      //       : "10889408",
      //     dataMarca.marId === 1 //pipeline
      //       ? "6580563"
      //       : dataMarca.marId === 2
      //       ? "10889471"
      //       : "10889406",
      //     modeloVh.smfFamiliaCrm2,
      //     modeloVh.smfFamiliaCrm,
      //     dataBodega.agency, //dataBodega.agencia,
      //     respuesta4Objeto.EMAIL1,

      //     canal === 1
      //       ? "Showroom"
      //       : canal === 2
      //       ? "Gestión Externa"
      //       : canal === 3
      //       ? "Base de Datos"
      //       : canal === 4
      //       ? "Referidos"
      //       : "Recompra",
      //     dataMarca.marId === 3 ? "agencia" : "agency",
      //     respuesta4Objeto.medio1,
      //     existe,
      //     cheked.usado ? cheked.placa : ""
      //   );
  }

  async function postGuardarNegocio(
    registroGuardar: SateliteCrearNegocioType,
    existe: number
  ) {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      axios
        .post(
          "https://apisatelite.azurewebsites.net/satelitecrearnegocio",
          registroGuardar,
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("id guardado es: ", response.data.id);
          console.log("mi existe es >>>>>", existe);
          existe > 0 ? setOpenDialog1(true) : setOpenDialog0(true);
        }); //setArticleId(response.data.id));
      // handleClickOpen();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message);
        return error.message;
      } else {
        console.log("algo paso unexpected error: ", error);
        return "An unexpected error occurred";
      }
    }
  }

  const [cheked, setCheked] = React.useState({
    cedula: true,
    usado: false,
    placa: "",
  });

  const handleCheked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheked({
      ...cheked,
      [event.target.name]: event.target.checked,
    });
  };

  if (isAuthenticated) {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Grid alignItems={"center"} justifyContent={"center"}>
          <Grid item>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 2,
              }}
            >
              <Typography variant="h5" textAlign={"center"}>
                Nuevo Negocio - Showroom
              </Typography>

              <Typography textAlign={"center"}>
                {dataMarca.marNombre.toUpperCase()} - {dataBodega.agencia}
              </Typography>
            </Paper>
            <Paper
              sx={{
                padding: "1.2em",
                borderRadius: "0.5em",
                backgroundColor: "rgb(255,255,255,0.95)",
                mt: 1,
                mb: 4,
              }}
            >
              <Grid>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "5%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Identificación:
                </Typography>

                <TextField
                  name="nut"
                  onChange={handle_socio_demografico}
                  size="small"
                  margin="normal"
                  type="number"
                  //onBlur={function (event) { getDataBook(respuesta1Objeto.nut); Number(respuesta1Objeto.nut.substring(2,3)) > 5 && respuesta1Objeto.nut.length === 13 ? setTipoPersona(3):  setTipoPersona(1)}}
                  onBlur={function (event) {
                    //console.log("mi respuesta", validarNumeroIdentificacion(respuesta1Objeto.nut));
                    if (respuesta1Objeto.nut.length > 0) {
                      validarNumeroIdentificacion(respuesta1Objeto.nut) === 1 ||
                      validarNumeroIdentificacion(respuesta1Objeto.nut) === 2 ||
                      validarNumeroIdentificacion(respuesta1Objeto.nut) === 3
                        ? getDataBook(respuesta1Objeto.nut.substring(0, 10))
                        : setOpenDialogErrorCedula(true);
                      setTipoPersona(
                        validarNumeroIdentificacion(respuesta1Objeto.nut)
                      );
                    }
                  }}
                  value={respuesta1Objeto.nut}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                />

                <Typography
                  sx={{
                    mt: 1,
                    mb: 1,
                    width: "23%",
                    marginLeft: "4%",
                  }}
                  style={{ display: "inline-block" }}
                >
                  Tipo Persona:
                </Typography>
                <TextField
                  id="outlined-select-currency"
                  select
                  onChange={handleChangeTipoPersona}
                  value={tipoPersona}
                  size="small"
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      disableScrollLock: true,
                      style: {
                        maxHeight: 300,
                      },
                    },
                  }}
                  sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                >
                  {listaTipoPersona.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {tipoPersona === 3 ? (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    Nombre empresa:
                  </Typography>
                  <TextField
                    name="primer_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "67%" }}
                  />

                  {/* <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    R. Legal:
                  </Typography>
                  <TextField
                    name="primer_nombre"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_nombre}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "67%" }}
                  /> */}
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    1er Apellido:
                  </Typography>
                  <TextField
                    name="primer_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "2%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    2do Apellido:
                  </Typography>
                  <TextField
                    name="segundo_apellido"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.segundo_apellido}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "5%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    1er Nombre:
                  </Typography>
                  <TextField
                    name="primer_nombre"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.primer_nombre}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
                  />
                  <Typography
                    sx={{
                      mt: 1,
                      mb: 1,
                      width: "23%",
                      marginLeft: "2%",
                    }}
                    style={{ display: "inline-block" }}
                  >
                    2do Nombre:
                  </Typography>
                  <TextField
                    name="segundo_nombre"
                    size="small"
                    margin="normal"
                    value={respuesta0Objeto.segundo_nombre}
                    onChange={handle_datos_separados_nombres}
                    sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
                  />
                </>
              )}

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Correo:
              </Typography>
              <TextField
                name="EMAIL1"
                size="small"
                margin="normal"
                value={respuesta4Objeto.EMAIL1}
                onChange={handle_medios_contacto}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
              />
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "2%",
                }}
                style={{ display: "inline-block" }}
              >
                Telefono:
              </Typography>
              <TextField
                name="medio1"
                size="small"
                margin="normal"
                type="number"
                value={respuesta4Objeto.medio1}
                onChange={handle_medios_contacto}
                sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
              />

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Canal:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeCanal}
                value={canal}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "2%", width: "20%" }}
              >
                {listaCanales.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "2%",
                }}
                style={{ display: "inline-block" }}
              >
                Familia VH:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeFamilia}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
              >
                {listaFamilia.map((option) => (
                  <MenuItem key={option.smfId} value={option.smfId}>
                    {option.smfFamiliaCrm}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Modelo:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeModelo}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "67%" }}
              >
                {dataModelosVh.map((option) => (
                  <MenuItem key={option.idCotItem} value={option.idCotItem}>
                    {option.modelo}
                  </MenuItem>
                ))}
              </TextField> 
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Asesor:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeAsesor}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, marginRight: "5%", width: "67%" }}
              >
                {dataAsesores.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.nombre}
                  </MenuItem>
                ))}
              </TextField>
              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "23%",
                  marginLeft: "5%",
                }}
                style={{ display: "inline-block" }}
              >
                Financiamiento:
              </Typography>
              <TextField
                id="outlined-select-currency"
                select
                onChange={handleChangeFinancian}
                value={financian}
                size="small"
                margin="normal"
                SelectProps={{
                  MenuProps: {
                    disableScrollLock: true,
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                sx={{ mt: 0.5, mb: 0.5, width: "20%" }}
              >
                {listaFinaciantype.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.descripcion}
                  </MenuItem>
                ))}
              </TextField>

              <Typography
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "8%",
                  marginLeft: "4%",
                }}
                style={{ display: "inline-block" }}
              >
                Usado:
              </Typography>
              <Switch
                checked={cheked.usado}
                onChange={handleCheked}
                name="usado"
                sx={{
                  mt: 0.5,
                  mb: 0.5,
                  marginLeft: "4%",
                  marginRight: cheked.usado ? "0%" : "10%",
                }} // marginRight: cheked.usado && canal != 2 ? "0%":"50%" }}
              />
              {cheked.usado ? (
                <TextField
                  id="outlined-select-currency"
                  size="small"
                  margin="normal"
                  label="placa"
                  sx={{ mt: 0.5, mb: 0.5, width: "19%", marginLeft: "4%" }}
                  onChange={handleCheked}
                />
              ) : (
                <></>
              )}

              <Button
                variant="contained"
                sx={{
                  marginLeft: "35%",
                  width: "14%",
                  mt: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                href="/ventas/negocio/crear"
              >
                Limpiar campos
              </Button>
              <Button
                variant="contained"
                sx={{
                  width: "14%",
                  marginLeft: "2%",
                  mt: 2,
                  backgroundColor: "rgba(21,57,105,0.75)",
                }}
                disabled={botonDeshabilitado}
                onClick={function (event) {
                  if (respuesta1Objeto.nut.length === 13 && tipoPersona === 1) {
                    setOpenDialogErrorRuc(true);
                  } else {
                    getHistorico(
                      respuesta1Objeto.nut,
                      dataBodega.bodId,
                      canal === 1
                        ? "SHOWROOM"
                        : canal === 2
                        ? "GESTION EXTERNA"
                        : "BASE DE DATOS",
                      dataMarca.marId
                    );
                  }
                }}
              >
                Guardar
              </Button>
            </Paper>

            <Dialog
              open={openDialog0}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(213,255,213)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Correcto"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Negocio creado correctamente
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ width: "50%" }}
                  target="_blank"
                  component="a"
                  href={
                    dataMarca.marId === 1
                      ? `https://app.hubspot.com/contacts/8008779/record/0-3/${respuestaCrearNego}`
                      : dataMarca.marId === 2
                      ? `https://app.hubspot.com/contacts/9244718/record/0-3/${respuestaCrearNego}`
                      : `https://app.hubspot.com/contacts/9269500/record/0-3/${respuestaCrearNego}`
                  }
                  size="small"
                >
                  Ir al negocio
                </Button>

                <Button
                  sx={{ width: "50%" }}
                  href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialog0(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorRuc}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Tipo de persona incorrecto!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogErrorRuc(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialogErrorCedula}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  //backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Número de identificación incorrecto.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogErrorCedula(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDialog1}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
              <DialogContent
            sx={{width: 300}}>
                <DialogContentText id="alert-dialog-description">
                  Negocio ya existe
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialog1(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            {/* dialog error */}
            <Dialog
              open={openDialogError}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  backgroundColor: "rgb(253,247,159)",
                  boxShadow: "none",
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                {" "}
                <CancelIcon
                  sx={{ marginRight: "10%", color: red[500] }}
                ></CancelIcon>{" "}
                {"      Error"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Error al grabar negocio
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  //href="/ventas/negocio/crear"
                  onClick={function (event) {
                    setOpenDialogError(false);
                    setBotonDeshabilitado(false);
                  }}
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" sx={{ mt: 10 }}>
        <Paper>
          <Grid
            //container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography
              sx={{ mt: 2, mb: 1.5 }}
              variant="h6"
              textAlign={"center"}
            >
              Acceso denegado
            </Typography>
          </Grid>
        </Paper>
      </Container>
    );
  }
};
